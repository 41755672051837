import React from "react";
import InstructionsCard from "./InstructionsCard";
import { Link, useSearchParams } from "react-router-dom";
import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { Formik, Form } from "formik";

import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";

import {
	RenderTextField,
	// RenderCheckboxField,
	DarkButton,
	type AppDispatch,
	SET_MESSAGE,
	ERROR_MSG,
	formatErrorMessage,
	resetPasswordRequestSupplier,
} from "@karkhanaui/react";
import { Mixpanel } from "../../Mixpanel.js";
const ForgotPassword: React.FC<any> = () => {
	const [isMobileTablet] = useMediaQuery("(max-width: 768px)");
	const [searchParams, setSearchParams] = useSearchParams();

	const SO_UUID = searchParams.get("po");
	const uuid = searchParams.get("rfq");

	const dispatch = useDispatch<AppDispatch>();
	const [apiLoading] = useSelector((state: any) => [state.apiLoading]);
	const [showCard, setShowCard] = React.useState<boolean>(false);

	const onSubmit = async (event: any) => {
		try {
			const res: any = await resetPasswordRequestSupplier(
				event.email,
				uuid,
				SO_UUID
			);
			if (res) {
				setShowCard(true);
				Mixpanel.track("forgotPassword");
			}
		} catch (e) {
			const message = formatErrorMessage(e);
			dispatch({
				type: SET_MESSAGE,
				payload: { case: ERROR_MSG, data: message },
			});
			Mixpanel.track("forgotPasswordFailed", {
				error: message,
			});
		}
	};

	const basicValidation = Yup.object().shape({
		email: Yup.string().email().required("Required"),
	});

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent={{ base: "top", lg: "center" }}
			flexDirection="column"
			h="100%"
			bg="#fff"
		>
			<Box
				width={{ base: "100%", lg: "50%" }}
				p={{ base: "2rem 1rem", lg: "0" }}
			>
				{/* eslint-disable-next-line multiline-ternary */}
				{!showCard ? (
					<Formik
						initialValues={{ email: "" }}
						validationSchema={basicValidation}
						onSubmit={onSubmit}
					>
						{(formik) => {
							return (
								<Form>
									<Flex
										mb="1rem"
										flexDirection="column"
										justifyContent="center"
									>
										<Text
											textStyle="primary.poster"
											color={isMobileTablet ? "#4BA18A" : ""}
										>
											Forgot Password
										</Text>
										<Text
											textStyle="primary.secondaryText"
											fontSize="14px"
											fontWeight="400"
											mt="1.375rem"
										>
											Enter your email ID for the verification process, we will
											send a link to reset your password.
										</Text>
									</Flex>
									<Box mt="1.5rem">
										<RenderTextField
											label="Email ID"
											name="email"
											type="email"
											placeholder="Enter Email Address"
											isMandatory={true}
										/>

										<DarkButton
											type="submit"
											id="forgotPasswordBtn"
											isLoading={apiLoading}
											// width="23.75rem"
											width="100%"
											height="50px"
											mt="1.5rem"
											children={"Reset"}
											bg="#4BA18A"
											_hover={{ bg: "#38846D" }}
										/>
									</Box>

									<Box
										display="flex"
										justifyContent={{ base: "center", lg: "left" }}
										alignItems="center"
										mt="1rem"
									>
										<Text textStyle="primary.secondaryText">Go back to</Text>
										<Text
											textStyle="primary.link"
											sx={{ fontWeight: "600" }}
											as={Link}
											to={`/login${uuid != null ? `/?rfq=${uuid}` : ""}${
												SO_UUID != null ? `/?po=${SO_UUID}` : ""
											}`}
											color="#4BA18A"
										>
											&nbsp;Login
										</Text>
									</Box>
								</Form>
							);
						}}
					</Formik>
				) : (
					<Box
						display="flex"
						justifyContent="center"
						ml={5}
						mt={{ base: "1rem", lg: "5em" }}
						mr={5}
					>
						<InstructionsCard
							showCardChange={() => {
								setShowCard(false);
							}}
							uuid={uuid}
							SO_UUID={SO_UUID}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default ForgotPassword;
