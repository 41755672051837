import * as React from "react";
import { Box, Flex, Text, Link } from "@chakra-ui/react";
import { DarkButton } from "@karkhanaui/button";
import { useDropzone } from "react-dropzone";
import { BsFileEarmarkArrowUpFill } from "react-icons/bs";

const bi =
	"repeating-linear-gradient(0deg, #333333, #333333 9px, transparent 9px, transparent 22px, #333333 22px), repeating-linear-gradient(90deg, #333333, #333333 9px, transparent 9px, transparent 22px, #333333 22px), repeating-linear-gradient(180deg, #333333, #333333 9px, transparent 9px, transparent 22px, #333333 22px), repeating-linear-gradient(270deg, #333333, #333333 9px, transparent 9px, transparent 22px, #333333 22px);";
const bs = "2px 100%, 100% 2px, 2px 100% , 100% 2px;";
const bp = "0 0, 0 0, 100% 0, 0 100%;";

const UploadDoc = (props: any) => {
	const onDrop = React.useCallback(
		(acceptedFiles: any) => {
			props.updateFile(acceptedFiles);
		},
		[props]
	);
	const {
		getRootProps,
		getInputProps,
		open,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		onDrop,
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: props.isMultiple,
	});
	return (
		<Box height="auto" w="100%">
			<Flex
				flexDirection={"column"}
				alignItems="center"
				justifyContent="space-between"
				gap="2rem"
				py="2.5rem"
				borderRadius={"5px"}
				backgroundImage={bi}
				backgroundSize={bs}
				backgroundPosition={bp}
				backgroundRepeat="no-repeat"
				backgroundColor={"#E2F1EE"}
				{...getRootProps()}
			>
				<Flex lineHeight="25px" alignItems="center" flexDirection="column">
					<Flex gap="1em">
						<BsFileEarmarkArrowUpFill
							size="22px"
							style={{ color: "#4BA18A" }}
						/>
						<Text textStyle="primary.text" mr="1em">
							Drag and Drop files to upload
						</Text>
					</Flex>

					<Text textStyle="primary.thin" mt=".1rem">
						OR
					</Text>
					<input {...getInputProps({})} />
					<DarkButton
						variant="ghost"
						border="1px solid #4BA18A"
						bg="#fff"
						color="#4BA18A"
						onClick={open}
						_hover={{ bg: "#E2F1EE" }}
					>
						Upload
					</DarkButton>
				</Flex>
			</Flex>
		</Box>
	);
};

export default UploadDoc;
