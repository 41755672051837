import React, { useEffect, useRef, lazy } from "react";
import {
	Box,
	Button,
	Text,
	Flex,
	chakra,
	Grid,
	GridItem,
	Divider,
	Tag,
	Tooltip,
	Show,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Img,
	Stack,
	useMediaQuery,
} from "@chakra-ui/react";
import { GiAlarmClock } from "react-icons/gi";
import {
	GenericTables,
	RenderTextField,
	AppDispatch,
	formatErrorMessage,
	SET_MESSAGE,
	ERROR_MSG,
	setPageLoading,
	supplierGetSingleQuotationService,
	normalDate,
	yupString,
	supplierUpdateQuotationService,
	supplierGetSingleOpportunityService,
	BasicDialog,
	get_doc,
	RenderDropDown,
	RenderTextAreaField,
	RenderPhoneField,
	FilesSlider,
	DeletePrompt,
	frappeMethodService,
	getItemAttachedFilesService,
	supplierUpdateOpportunityService,
	submitDoc,
	Overlay,
	BottomSheet,
	correctImageOrientation,
	RenderTextFieldWithCustomRightElement,
} from "@karkhanaui/react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, useFormikContext } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import UploadDoc from "../quotes/UploadDoc";
import AddRateModal from "./AddRateModal";
import { RfqPopupCard } from "../rfq/RfqPopupCard";
import Extend from "../../img/Extend.png";
import { BsArrowRightCircle, BsCalendarPlus, BsXLg } from "react-icons/bs";
import Submit from "../../img/Submit.png";
import ViewPartForm from "./ViewPartForm";
import * as Yup from "yup";
import Cookies from "js-cookie";
import alternativeImg from "../../img/k.svg";
import { Mixpanel } from "../../Mixpanel.js";
import { ViewRFQCard } from "../rfq/RfqMainCard";
import Parts from "../../img/parts.svg";
import TotalAndQty from "./TotalAndQty";
import { getSupplierProfile } from "../digitalFactory/services/digitalfactory/createProfile";
const ViewRfq = lazy(() => import("../rfq/ViewRfq"));

const CreateQuote: React.FC<any> = () => {
	const params = useParams();
	const quoteId: any = params.quoteId;
	const loggedInSuppplier = String(Cookies.get("user_id"));
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const [isExtensionPopupOpen, setIsExtensionPopupOpen] = React.useState(false);
	const [isConfirm, setIsConfirm] = React.useState(false);
	const [isPartOpen, setIsPartOpen] = React.useState(false);
	const [data, setData] = React.useState<any>();
	const [itemsData, setItemsData] = React.useState<any>([]);
	const [showAddRatePopup, setShowAddRatePopup] = React.useState(false);
	const [tagProps, setTagProps] = React.useState<any>({
		bg: "gray.100",
		color: "gray.400",
	});
	const [showExpireBox, setShowExpireBox] = React.useState<boolean>(true);
	const [selectedItem, setSelectedItem] = React.useState<any>();
	const [selectedItemForRate, setSelectedItemForRate] = React.useState<any>();
	const [supplierAddresses, setSupplierAddresses] = React.useState<any>([]);
	const [allFormvalues, setAllFormValues] = React.useState<any>({
		supplier_company_name: "",
		supplier_address: "",
		supplier_contact: "",
		supplier_email: "",
		timeline_number: "",
		timeline_field: "Days",
		valid_till: "",
		terms: "",
		supplier_gst: "",
		payment_terms: "",
	});

	const [showSaveDraftPopup, setShowSaveDraftPopup] =
		React.useState<boolean>(false);
	const [showSubmitConfirmPopup, setShowSubmitConfirmPopup] =
		React.useState<boolean>(false);
	const [showSubmitAcknowledgePopup, setShowSubmitAcknowledgePopup] =
		React.useState<boolean>(false);
	const [opportunityItems, setOpportunityItems] = React.useState<any>([]);
	const [files, setFiles] = React.useState<any>([]);
	const [selectedFile, setSelectedFile] = React.useState<any>();
	const [deleteFilePrompt, setDeleteFilePrompt] =
		React.useState<boolean>(false);
	const [isSubmitClicked, setIsSubmitClicked] = React.useState<boolean>(false);
	const [authState, supplier] = useSelector((state: any) => {
		return [state.authSupplier, state.getUser];
	});
	const [accordianIndices, setAccordianIndices] = React.useState<any>([0]);
	const [isMobileTablet] = useMediaQuery("(max-width: 768px)");
	const [baseGrandTotal, setBaseGrandTotal] = React.useState<number>(0);
	const [viewRfq, setViewRfq] = React.useState<boolean>(false);
	const [leadTime, setLeadTime] = React.useState<any>(null);

	const scrollToElement = (fieldId: string, val: any) => {
		if (val === undefined) {
			const element = document.getElementById(fieldId);
			element?.scrollIntoView();
			return false;
		} else {
			return true;
		}
	};

	const basicValidation = Yup.object().shape({
		supplier_company_name: yupString,
		supplier_address: Yup.string().test(
			"custom-validation",
			"Required",
			(val) => {
				const validationState = scrollToElement("supplier_address", val);
				if (!validationState)
					if (!accordianIndices.includes(0) && isMobileTablet)
						setAccordianIndices([...accordianIndices, 0]); // for opening the accordianItems with errors in input fields (mobile view only)
				return validationState;
			}
		),
		supplier_contact: Yup.string().test(
			"custom-validation",
			"Required",
			(val) => {
				const validationState = scrollToElement("supplier_contact", val);
				if (!validationState)
					if (!accordianIndices.includes(0) && isMobileTablet)
						setAccordianIndices([...accordianIndices, 0]);
				return validationState;
			}
		),
		supplier_email: Yup.string().test(
			"custom-validation",
			"Required",
			(val) => {
				const validationState = scrollToElement("supplier_email", val);
				if (!validationState)
					if (!accordianIndices.includes(0) && isMobileTablet)
						setAccordianIndices([...accordianIndices, 0]);
				return validationState;
			}
		),
		timeline_number: Yup.string().test(
			"custom-validation",
			"Required",
			(val) => {
				const validationState = scrollToElement("timeline", val);
				if (!validationState)
					if (!accordianIndices.includes(2) && isMobileTablet)
						setAccordianIndices([...accordianIndices, 2]);
				return validationState;
			}
		),
		valid_till: Yup.string().test("custom-validation", "Required", (val) => {
			const validationState = scrollToElement("valid_till", val);
			if (!validationState)
				if (!accordianIndices.includes(2) && isMobileTablet)
					setAccordianIndices([...accordianIndices, 2]);
			return validationState;
		}),
	});

	const formRef = useRef();

	const handleAddRateModalClose = () => {
		setShowAddRatePopup(false);
	};

	const handleAcceptExtension = () => {
		setIsExtensionPopupOpen(true);
	};

	const handleCloseExtension = () => {
		setIsExtensionPopupOpen(false);
	};

	const handleSave = (expiryDate: Date) => {
		handleCloseExtension();
	};

	const getOpportunityItemDetails = async (itemCode: string) => {
		const _selectedItem = await opportunityItems?.filter(
			(item: any) => item.item_code === itemCode
		)[0];
		setSelectedItem(_selectedItem);
	};

	const column = React.useMemo(
		() => [
			{
				Header: "Part Name",
				accessor: "item_name",
				Cell: (i: any) => (
					<Flex>
						<Tooltip label={i.row.original.item_name}>
							<Text
								color="#4BA18A"
								textStyle="primary.link"
								onClick={() => {
									getOpportunityItemDetails(i.row.original.item_code);
									setIsPartOpen(true);
								}}
								cursor="pointer"
							>
								{i.row.original.item_name.slice(0, 20) || "-"}
								{i.row.original.item_name.length > 20 ? "..." : ""}
							</Text>
						</Tooltip>
					</Flex>
				),
			},
			{
				Header: "Category",
				accessor:
					data?.originated_from === "procurement"
						? "item_category"
						: "item_type",
				Cell: (i: any) => (
					<Text>
						{(data?.originated_from === "procurement"
							? i.row.original?.item_category
							: i.row.original?.item_type) || "-"}
					</Text>
				),
				disableSortBy: true,
			},
			{
				Header: "Quantity",
				accessor: "qty",
				Cell: (i: any) => (
					<Text>{i.row.original?.qty >= 0 ? i.row.original?.qty : "-"}</Text>
				),
				disableSortBy: true,
			},
			{
				Header: "UOM",
				accessor: "uom",
				Cell: (i: any) => <Text>{i.row.original?.uom || "-"}</Text>,
				disableSortBy: true,
			},
			{
				Header: "Target Cost",
				accessor: "target_cost",
				Cell: (i: any) => (
					<Text>
						{i.row.original?.target_cost >= 0
							? i.row.original?.target_cost
							: "-"}
					</Text>
				),
				disableSortBy: true,
			},
			{
				Header: "Rate",
				accessor: "rate",
				Cell: (i: any) => (
					<Button
						onClick={() => {
							setSelectedItemForRate(i.row.original);
							getOpportunityItemDetails(i.row.original.item_code);
							setShowAddRatePopup(true);
						}}
						bg="#F3F6F9"
						color="grey"
						size="md"
					>
						{i.row.original?.rate || "Add Rate"}
					</Button>
				),
				disableSortBy: true,
			},
			{
				Header: "Amount",
				accessor: "amount",
				Cell: (i: any) => (
					<Text>
						{i.row.original?.amount >= 0 ? i.row.original?.amount : "-"}
					</Text>
				),
				disableSortBy: true,
			},
			{
				Header: "",
				accessor: "view_part",
				Cell: (i: any) => (
					<Text
						color="#4BA18A"
						textStyle="primary.link"
						onClick={() => {
							getOpportunityItemDetails(i.row.original.item_code);
							setIsPartOpen(true);
						}}
						cursor="pointer"
					>
						View Part
					</Text>
				),
				disableSortBy: true,
			},
		],

		[opportunityItems]
	);

	const fetchData = async () => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Loading...",
			})
		);
		const res: any = await supplierGetSingleQuotationService(quoteId);
		const supplierDetailsRes: any = await get_doc({
			doc: "Supplier",
			fields: ["*"],
			filters: [["name", "=", loggedInSuppplier]],
		});
		const supplierData: any = await getSupplierProfile();
		const attachedFilesRes: any = await getItemAttachedFilesService({
			filters: JSON.stringify([
				["attached_to_doctype", "=", "Quotation"],
				["attached_to_name", "in", quoteId],
			]),
			fields: JSON.stringify(["*"]),
			limit: 0,
		});
		Promise.all([res, supplierDetailsRes, attachedFilesRes, supplierData])
			.then(async (response) => {
				if (response[0].data.data?.status !== "Draft") {
					navigate(`/quotemanagement/${quoteId}`);
				}
				let _baseGrandTotal = await response[0].data.data?.items?.reduce(
					(accumulator: any, currentValue: any) =>
						accumulator + currentValue.amount,
					0
				);
				setBaseGrandTotal(_baseGrandTotal);
				setData(response[0].data.data);
				getStatusColor(response[0].data.data?.status);
				setItemsData([...response[0].data.data?.items]);

				// fetch opportunity items to display part details
				try {
					const opp: any = await supplierGetSingleOpportunityService(
						response[0].data.data?.opportunity
					);
					if (opp.status === 200) {
						setOpportunityItems(opp.data.data?.items);
						setLeadTime(opp.data.data?.lead_time);
					}
				} catch (error) {
					dispatch(
						setPageLoading({
							loading: false,
							loadingText: "",
						})
					);
					dispatch({
						type: SET_MESSAGE,
						payload: {
							case: ERROR_MSG,
							data: formatErrorMessage(error),
						},
					});
				}

				// construct supplier address string from supplier doctype
				const _supplierAddresses: any = [];
				response[3].address?.map((each: any) => {
					_supplierAddresses.push(
						`${each.address_title} - ${each.address_line_1}, ${each.city}, ${each.state}, ${each.country}, ${each.pincode}`
					);
				});
				setSupplierAddresses([..._supplierAddresses]);

				// assign initial values for the fields
				setAllFormValues({
					supplier_company_name:
						response[0].data.data?.supplier_company_name ||
						response[1].data.data[0]?.supplier_name,
					supplier_address: response[0].data.data?.supplier_address,
					supplier_contact:
						response[0].data.data?.supplier_contact ||
						response[1].data.data[0]?.supplier_mobile,
					supplier_email:
						response[0].data.data?.supplier_email ||
						response[1].data.data[0]?.supplier_email,
					timeline_number: response[0].data.data?.timeline_number,
					timeline_field: response[0].data.data?.timeline_field || "Days",
					valid_till: response[0].data.data?.valid_till,
					terms: response[0].data.data?.terms,
					supplier_gst: response[1].data.data[0]?.gstin,
					payment_terms: response[0].data.data?.payment_terms,
					// inco_terms: response[0].data.data?.inco_terms,
				});

				// set files list
				const filesList: any = [];
				await response[2].data.data?.map((file: any) => {
					filesList.push({
						fileId: file.name,
						subtitle: file.file_name,
						file: process.env.REACT_APP_LIVE_URL + file.file_url,
						isPrivateFile: file.is_private,
					});
				});
				setFiles(filesList);

				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
			})
			.catch((error) => {
				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: formatErrorMessage(error),
					},
				});
			});
	};

	useEffect(() => {
		fetchData();
	}, [quoteId]);

	const getStatusColor = (status: string) => {
		switch (status) {
			case "Active":
			case "Open":
				setTagProps({ bg: "#E2F1EE", color: "#4BA18A" });
				break;
			case "Expired":
				setTagProps({ bg: "rgba(253, 185, 19, 0.2)", color: "#EBA700" });
				break;
			case "Cancelled":
				setTagProps({
					bg: "rgba(0, 0, 0, 0.05)",
					color: "#rgba(0, 0, 0, 0.5)",
				});
				break;
			case "Draft":
				setTagProps({ bg: "rgba(0, 135, 238, 0.1)", color: "#0087EE" });
				break;
			case "Rejected":
				setTagProps({ bg: "rgba(239, 111, 108, 0.2)", color: "#EF6F6C" });
				break;
			default:
				setTagProps({ bg: "gray.100", color: "gray.400" });
		}
	};

	const getDateDiff = (expiryDate: any) => {
		const currentDate: any = new Date();
		const date2: any = new Date(expiryDate);
		const diffTime = date2 - currentDate;
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays;
	};

	const updateRateAndCloseModal = async (
		itemRate: number,
		itemCostBreakup: any
	) => {
		dispatch(setPageLoading({ loading: true, loadingText: "Saving Price" }));
		const newItems: any = [...itemsData];
		const indexOfItem: number = await itemsData.findIndex(
			(item: any) => item.item_code == selectedItemForRate.item_code
		);
		if (indexOfItem >= 0) {
			newItems[indexOfItem].cost_breakup = JSON.stringify(itemCostBreakup);
			newItems[indexOfItem].rate = itemRate;
			newItems[indexOfItem].amount = itemRate * newItems[indexOfItem].qty;
		}

		try {
			const quoteData = {
				...data,
				supplier_address: formRef.current.values?.supplier_address,
				supplier_company_name: formRef.current.values?.supplier_company_name,
				supplier_contact: formRef.current.values?.supplier_contact,
				supplier_email: formRef.current.values?.supplier_email,
				terms: formRef.current.values?.terms,
				timeline_number: formRef.current.values?.timeline_number,
				timeline_field: formRef.current.values?.timeline_field,
				valid_till: formRef.current.values?.valid_till,
				items: newItems,
				company_gstin: formRef.current.values?.supplier_gst,
				payment_terms: formRef.current.values?.payment_terms,
				// inco_terms: formRef.current.values?.inco_terms,
			};
			const res: any = await supplierUpdateQuotationService(quoteId, quoteData);
			if (res.status === 200) {
				setShowAddRatePopup(false);
				fetchData();
			}
		} catch (error) {
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	const onSubmit = async (formData: any) => {
		dispatch(
			setPageLoading({ loading: true, loadingText: "Quote Saving as Draft" })
		);
		try {
			let quoteData = {
				...data,
				supplier_address: formData?.supplier_address,
				supplier_company_name: formData?.supplier_company_name,
				supplier_contact: formData?.supplier_contact,
				supplier_email: formData?.supplier_email,
				terms: formData?.terms,
				timeline_number: formData?.timeline_number,
				timeline_field: formData?.timeline_field,
				valid_till: formData?.valid_till,
				company_gstin: formData?.supplier_gst,
				payment_terms: formData?.payment_terms,
				// inco_terms: formData?.inco_terms,
			};

			const res: any = await supplierUpdateQuotationService(quoteId, quoteData);
			if (res.status === 200) {
				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
				if (isSubmitClicked) {
					setShowSubmitConfirmPopup(true);
				} else {
					setShowSaveDraftPopup(true);
				}
			}
		} catch (error) {
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	const updateFile = async (uploadedFiles: any) => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: `Uploading ${uploadedFiles.length} file(s)...`,
			})
		);
		try {
			const files = await uploadedFiles.map(async (item: any) => {
				if (item.type === "image/jpeg") {
					item = await correctImageOrientation(item).catch((error: any) => {
						console.error(
							"Error correcting orientation for:",
							item.name,
							error
						);
						return item; // Use the original file if correction fails
					});
				}
				return item;
			});
			const readyFiles = (await Promise.all(files)).filter(Boolean);
			readyFiles?.map(async (file: any) => {
				const formData = new FormData();
				formData.append("file", file);
				formData.append("is_private", 0);
				formData.append("folder", "Home/Attachments");
				formData.append("doctype", "Quotation");
				formData.append("docname", quoteId);

				try {
					const res: any = await frappeMethodService("upload_file", formData);
					if (res.status === 200) {
						dispatch(
							setPageLoading({
								loading: false,
								loadingText: "",
							})
						);
						fetchData();
					}
				} catch (error) {
					dispatch({
						type: SET_MESSAGE,
						payload: {
							case: ERROR_MSG,
							data: formatErrorMessage(error),
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
		}
	};

	const deleteFileConfirm = (file: any) => {
		setSelectedFile(file);
		setDeleteFilePrompt(true);
	};

	const deleteFile = async () => {
		const res: any = await frappeMethodService(
			"frappe.desk.form.utils.remove_attach",
			{
				fid: selectedFile.fileId,
				dt: "Quotation",
				dn: quoteId,
			}
		);
		if (res.status === 200) {
			fetchData();
		}
	};

	const submitQuote = async () => {
		dispatch(
			setPageLoading({ loading: true, loadingText: "Submitting the Quote..." })
		);
		const params = {
			doctype: "Quotation",
			docname: quoteId,
		};
		try {
			const res: any = await submitDoc(params);
			if (res.status === 200) {
				// change portal status of opportunity to "Quoted"
				const result: any = await supplierUpdateOpportunityService(
					data?.opportunity,
					{
						portal_status: "Quoted",
					}
				);
				if (result.status === 200) {
					dispatch(
						setPageLoading({
							loading: false,
							loadingText: "",
						})
					);
					setShowSubmitConfirmPopup(false);
					setShowSubmitAcknowledgePopup(true);
					window.dataLayer?.push({
						event: "Quotation Submitted",
						page_name: window.location.pathname,
						quote_id: quoteId,
						rfq_id: data?.opportunity,
						user_email: supplier?.customer?.email,
						status: "Submitted",
						user_id: authState?.uniqueUserId,
						organization_name: supplier?.customer?.supplier_name,
						buyer_organization_name: data?.company_name || data?.party_name,
						quote_amount: data?.base_grand_total,
						total_parts: data?.items?.length,
					});
					Mixpanel.track("quotationSubmitted", {
						page_name: window.location.pathname,
						quote_id: quoteId,
						rfq_id: data?.opportunity,
						user_email: supplier?.customer?.email,
						status: "Submitted",
						user_id: authState?.uniqueUserId,
						organization_name: supplier?.customer?.supplier_name,
						buyer_organization_name: data?.company_name || data?.party_name,
						quote_amount: data?.base_grand_total,
						total_parts: data?.items?.length,
					});
				}
			}
		} catch (error) {
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	return (
		<Box w="100%" pt="0" mt="0">
			<Flex
				alignItems="center"
				justifyContent="space-between"
				bg="#fff"
				p="1rem"
				mt="1px"
			>
				<Flex
					alignItems="center"
					justifyContent={{ base: "space-between", lg: "left" }}
					gap="3rem"
					w={{ base: "100%", md: "auto" }}
				>
					<Text>
						<chakra.span textStyle="primary.bold">Quote ID:&nbsp;</chakra.span>
						{data?.name}
					</Text>
					<Flex alignItems="center" gap=".5rem" ml={{ base: "auto", md: "0" }}>
						<Show above="sm">
							<Text textStyle="primary.bold">Status:</Text>
						</Show>
						<Tag bg={tagProps?.bg} color={tagProps?.color}>
							{data?.status}
						</Tag>
					</Flex>
				</Flex>
				<Show above="sm">
					<Text>
						<chakra.span textStyle="primary.bold">
							Quote Date:&nbsp;
						</chakra.span>
						{normalDate(data?.creation)}
					</Text>
				</Show>
			</Flex>

			<Box
				bg={{ base: "transparent", lg: "#fff" }}
				m={{ base: "0", lg: "1rem" }}
				borderRadius="0.75rem"
			>
				<Formik
					initialValues={allFormvalues}
					validationSchema={basicValidation}
					enableReinitialize={true}
					onSubmit={onSubmit}
					innerRef={formRef}
					validateOnBlur={false}
					validateOnChange={false}
				>
					{(formik) => {
						return (
							<Form>
								<Show above="md">
									<Flex
										alignItems="center"
										justifyContent={"space-between"}
										bg="#fff"
										borderRadius="12px 12px 0px 0px"
										px="1rem"
									>
										<Text
											color={"#4BA18A"}
											textStyle="primary.large"
											fontSize={"28"}
											fontWeight="bold"
											alignContent={"center"}
										>
											Quotation
										</Text>
										<Flex textAlign={"end"} gap="1em" m="1em">
											<Button
												bg="#fff"
												variant="outline"
												border="1px solid #4BA18A"
												color="#4BA18A"
												onClick={() => setViewRfq(true)}
												_hover={{ bg: "#E2F1EE" }}
											>
												View RFQ
											</Button>
											<Button
												size="md"
												borderRadius={"5px"}
												bg="#4BA18A"
												_hover={{ bg: "#38846D" }}
												color="white"
												variant="outline"
												type="submit"
												onClick={() => setIsSubmitClicked(true)}
											>
												Submit Quote
											</Button>

											<Button
												size="md"
												borderRadius={"5px"}
												bg="#E2F1EE"
												_hover={{ bg: "#B7DDD4" }}
												color="#4BA18A"
												variant="outline"
												type="submit"
												border="1px solid #4BA18A"
											>
												Save as Draft
											</Button>
										</Flex>
									</Flex>
								</Show>
								{["Expired", "Cancelled", "Rejected", "Lost"].indexOf(
									data?.status
								) < 0 && (
									<Box
										bg="#E2F1EE"
										h="39px"
										display={showExpireBox ? "flex" : "none"}
										alignItems="center"
										justifyContent="center"
										position="relative"
									>
										<Text
											fontSize={{ base: "16px", lg: "18px" }}
											textAlign="center"
											display="flex"
											alignItems="center"
										>
											<span>
												{getDateDiff(data?.rfq_expiry_date) < 0 ? (
													<Flex gap="0.2em">
														<GiAlarmClock size={"28px"} /> This RFQ expired
														<chakra.span fontWeight="bold">
															{Math.abs(getDateDiff(data?.rfq_expiry_date)) ||
																"--"}
														</chakra.span>
														&nbsp;days ago
													</Flex>
												) : (
													<Flex gap="0.2em">
														<GiAlarmClock size={"28px"} /> This RFQ expires
														in&nbsp;
														<chakra.span fontWeight="bold">
															{getDateDiff(data?.rfq_expiry_date) || "--"}
														</chakra.span>
														&nbsp;days
													</Flex>
												)}
											</span>
											{/* <Box mx="1em" /> */}
											{/* <chakra.span color="teal" fontWeight="bold">
												<Text>
													<Flex gap="0.2em">
														<BsCalendarPlus
															size={"22px"}
															onClick={handleAcceptExtension}
														/>
														<Link
															onClick={handleAcceptExtension}
															textDecoration="underline"
														>
															Request for Extension
														</Link>
													</Flex>
												</Text>
											</chakra.span> */}
										</Text>
										<Box position="absolute" right="1rem">
											<BsXLg
												size="1.25rem"
												cursor="pointer"
												onClick={() => setShowExpireBox(false)}
											/>
										</Box>
									</Box>
								)}
								<Show above="md">
									<Box
										bg="white"
										height={showExpireBox ? "65vh" : "70vh"}
										overflow="auto"
										px="1rem"
									>
										<Flex
											alignItems="flex-start"
											justifyContent="space-between"
											mt="1.5rem"
										>
											<Box>
												<Flex alignItems="center" gap=".75rem">
													<Box
														borderRadius={"full"}
														height="70px"
														width="70px"
														bg="green.50"
														display="flex"
														alignItems="center"
														justifyContent="center"
													>
														<Text
															fontSize="32px"
															fontWeight={"semibold"}
															color="#4BA18A"
														>
															{data?.company_name
																? data?.company_name?.slice(0, 1).toUpperCase()
																: data?.party_name?.slice(0, 1).toUpperCase()}
														</Text>
													</Box>
													<Box>
														<Text
															mt="0.5em"
															fontSize={"20px"}
															fontWeight="bold"
															color="#4BA18A"
														>
															{data?.company_name || data?.party_name}
														</Text>
														<Text mt="0.2em" fontSize={"18px"}>
															{data?.party_name}
														</Text>
													</Box>
												</Flex>
											</Box>
											<Box>
												<Text>
													<chakra.span color="teal">
														Recieved On:&nbsp;
													</chakra.span>
													{normalDate(data?.creation)}
												</Text>
												<Flex alignItems="center">
													<Text color="teal">Expires On:&nbsp;</Text>
													<Text>
														{data?.rfq_expiry_date
															? normalDate(data?.rfq_expiry_date)
															: "--"}
														&nbsp;
													</Text>
													{/* {["Expired", "Cancelled", "Rejected", "Lost"].indexOf(
													data?.status
												) < 0 && (
													<BsCalendarPlus
														size="1rem"
														color="#38846D"
														cursor="pointer"
														onClick={() => setIsExtensionPopupOpen(true)}
													/>
												)} */}
												</Flex>
												{/* <Text>
													<chakra.span color="teal">
														Expected Lead Time:&nbsp;
													</chakra.span>
													{leadTime !== null ? leadTime : "--"}
												</Text> */}
											</Box>
										</Flex>
										<Text
											fontWeight="bold"
											color="#4BA18A"
											fontSize={"18px"}
											mt="3rem"
											ml=".2em"
										>
											Supplier Details
										</Text>
										<Divider
											variant="solid"
											border="1px solid grey "
											w="100%"
											mt="1em"
											mb="1em"
										/>
										{/* The following component contains input fields for basic supplier details */}
										<SupplierDetails supplierAddresses={supplierAddresses} />

										<Divider
											variant="solid"
											border="1px solid grey "
											w="100%"
											mt="2em"
											mb="1em"
										/>
										<Box mt="2.5em">
											<Text
												color="#4BA18A"
												fontSize="18px"
												fontWeight="bold"
												mb=".5rem"
											>{`Parts(${itemsData?.length})`}</Text>
											<GenericTables
												headerColor={"#E2F1EE"}
												column={column}
												data={itemsData}
												renderCheckBox={false}
												renderSerialNo={true}
												isShowSearch={false}
												paginationbg={"#4BA18A"}
												borderColor={"#B7DDD4"}
											/>
											<Flex justifyContent="flex-end" mt="2.25rem">
												<Box width="25rem">
													<TotalAndQty
														totalQty={data?.total_qty}
														totalAmount={baseGrandTotal}
													/>
												</Box>
											</Flex>

											{/* The following component deals with input fields for Quote Valid Till and TImeline */}
											<Timeline />

											<Divider
												variant="solid"
												border="1px solid grey "
												w="100%"
												mt="2.5rem"
												mb="1em"
											/>

											<Text
												mb="1em"
												mt="1em"
												color={"#4BA18A"}
												fontWeight="semibold"
												fontSize="18px"
											>
												Payment Terms
											</Text>
											<RenderTextAreaField
												name="payment_terms"
												placeholder="Add Payment Terms"
												padding=".75rem 1rem"
												size="xl"
												height="4.8rem"
												borderRadius={"5px"}
											/>

											<Text
												mb="1em"
												mt="1em"
												color={"#4BA18A"}
												fontWeight="semibold"
												fontSize="18px"
											>
												Terms & Conditions
											</Text>
											<RenderTextAreaField
												name="terms"
												placeholder="Add your terms & Conditions here"
												padding=".75rem 1rem"
												size="xl"
												height="17rem"
												borderRadius={"5px"}
											/>
											<Box mt="2em">
												<Text
													color={"#4BA18A"}
													fontSize={"18px"}
													fontWeight={"semibold"}
												>
													Add Documents
												</Text>
											</Box>
											{/* Component for adding documents */}
											<FileUpload
												files={files}
												deleteFileConfirm={deleteFileConfirm}
												updateFile={updateFile}
											/>
										</Box>
									</Box>
								</Show>

								{/* ***************************** Mobile Screen ****************************** */}
								<Show below="md">
									<Box padding="1rem">
										{data !== undefined && (
											<ViewRFQCard
												companyName={data.company_name}
												rfqName={data.rfq_name}
												partyName={data.party_name}
												expectedLeadTime={leadTime}
												rfqExpiry={normalDate(data.rfq_expiry_date)}
												receivedOn={normalDate(data.creation)}
												rfqId={data.customer_rfq_id}
											/>
										)}
									</Box>

									{/* The following accordian has controlled inputs. The state accordianIndices manages it */}
									{/* Controlled inputs used to open certain accordians incase of form errors like required fields not filled, validation errors */}
									<Accordion
										marginTop="1em"
										px="1rem"
										width="100%"
										mb="6.5rem"
										index={accordianIndices}
										onChange={(index) => {
											setAccordianIndices([...accordianIndices, index]);
										}}
									>
										{/* Supplier Details */}
										<AccordionItem
											marginBottom="1em"
											borderRadius="lg"
											borderTop="none"
										>
											<h2>
												<AccordionButton
													_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
													fontSize="14px"
													borderRadius="lg"
													bgColor="#fff"
													boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
													onClick={() => {
														if (accordianIndices.includes(0))
															setAccordianIndices(
																accordianIndices.filter(
																	(ind: number) => ind !== 0
																)
															);
													}}
												>
													<Box
														as="span"
														flex="1"
														textAlign="left"
														color="#4BA18A"
													>
														Add Supplier Details
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4} px="0em" bg="#fff">
												<SupplierDetails
													supplierAddresses={supplierAddresses}
												/>
											</AccordionPanel>
										</AccordionItem>

										{/* Add Rates */}
										<AccordionItem
											marginBottom="1em"
											borderRadius="lg"
											borderTop="none"
										>
											<h2>
												<AccordionButton
													_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
													fontSize="14px"
													borderRadius="lg"
													bgColor="#fff"
													boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
													onClick={() => {
														if (accordianIndices.includes(1))
															setAccordianIndices(
																accordianIndices.filter(
																	(ind: number) => ind !== 1
																)
															);
													}}
												>
													<Box
														as="span"
														flex="1"
														textAlign="left"
														color="#4BA18A"
													>
														Add Rate
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4} px="0em" p="0.625rem" bg="#fff">
												<Box>
													{itemsData.map((item: any, index: number) => {
														return (
															<Box
																w="100%"
																padding="1rem"
																boxShadow="0px 4px 8px 0px #0000000F, 0px 0px 4px 0px #0000000A"
																borderRadius="0.625rem"
																fontSize="14px"
																mb="1rem"
																key={index}
															>
																<Flex w="100%" gap="1rem" alignItems="center">
																	<Img src={Parts} alt="parts illustration" />
																	<Stack
																		alignItems="left"
																		cursor="pointer"
																		onClick={() => {
																			getOpportunityItemDetails(item.item_code);
																			setIsPartOpen(true);
																		}}
																	>
																		<Text
																			fontWeight="semibold"
																			title={
																				item.item_name.length > 12
																					? item.item_name
																					: null
																			}
																		>
																			{item.item_name.length > 12
																				? item.item_name.substring(0, 10) +
																				  "..."
																				: item.item_name}
																		</Text>
																		<Text>{item.qty}&nbsp;&nbsp;Units</Text>
																	</Stack>
																	<Button
																		variant="link"
																		fontWeight="regular"
																		color="#4ba18a"
																		mb="auto"
																		ml="auto"
																		fontSize="14px"
																		onClick={() => {
																			getOpportunityItemDetails(item.item_code);
																			setIsPartOpen(true);
																		}}
																	>
																		View Part&nbsp;
																		<BsArrowRightCircle color="#4ba18a" />
																	</Button>
																</Flex>
																<Divider my="0.5rem" />
																<Flex w="100%" gap="0.625rem">
																	<Stack
																		alignItems="left"
																		fontSize="12px"
																		w="30%"
																	>
																		<Text fontWeight="semibold" color="#4ba18a">
																			Category
																		</Text>
																		<Text>
																			{item.item_type === undefined
																				? "--"
																				: item.item_type}
																		</Text>
																	</Stack>
																	<Stack
																		alignItems="left"
																		fontSize="12px"
																		w="30%"
																	>
																		<Text fontWeight="semibold" color="#4ba18a">
																			Target Cost
																		</Text>
																		<Text>
																			{item.target_cost === undefined
																				? "--"
																				: item.target_cost}
																		</Text>
																	</Stack>
																	<Button
																		bg="#4ba18a"
																		color="#fff"
																		_hover={{ backgroundColor: "#4ba18a" }}
																		ml="auto"
																		my="auto"
																		fontSize="14px"
																		onClick={() => {
																			setSelectedItemForRate(item);
																			getOpportunityItemDetails(item.item_code);
																			setShowAddRatePopup(true);
																		}}
																	>
																		Add Rate
																	</Button>
																</Flex>
															</Box>
														);
													})}
													<Flex justifyContent="flex-end">
														<Box width="25rem">
															<TotalAndQty
																totalQty={data?.total_qty}
																totalAmount={baseGrandTotal}
															/>
														</Box>
													</Flex>
												</Box>
											</AccordionPanel>
										</AccordionItem>

										{/* Quote Details */}
										<AccordionItem
											marginBottom="1em"
											borderRadius="lg"
											borderTop="none"
										>
											<h2>
												<AccordionButton
													_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
													fontSize="14px"
													borderRadius="lg"
													bgColor="#fff"
													boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
													onClick={() => {
														if (accordianIndices.includes(2))
															setAccordianIndices(
																accordianIndices.filter(
																	(ind: number) => ind !== 2
																)
															);
													}}
												>
													<Box
														as="span"
														flex="1"
														textAlign="left"
														color="#4BA18A"
													>
														Add Quote Details
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4} px="0em" bg="#fff">
												<Flex flexDir="column" width="100%" padding="1rem">
													<Timeline />
													<Box>
														<Text
															mb={{ base: "0.25rem", lg: "1em" }}
															mt="1em"
															color={{ base: "#000", lg: "#4BA18A" }}
															fontWeight={{ base: "regular", lg: "semibold" }}
															fontSize={{ base: "14px", lg: "18px" }}
														>
															Payment Terms
														</Text>
														<RenderTextAreaField
															name="payment_terms"
															placeholder="Add Payment Terms"
															padding=".75rem 1rem"
															size="xl"
															height="4.8rem"
															borderRadius={"5px"}
														/>
													</Box>
												</Flex>
											</AccordionPanel>
										</AccordionItem>

										{/* Terms and Conditions */}
										<AccordionItem
											marginBottom="1em"
											borderRadius="lg"
											borderTop="none"
										>
											<h2>
												<AccordionButton
													_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
													fontSize="14px"
													borderRadius="lg"
													bgColor="#fff"
													boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
													onClick={() => {
														if (accordianIndices.includes(3))
															setAccordianIndices(
																accordianIndices.filter(
																	(ind: number) => ind !== 3
																)
															);
													}}
												>
													<Box
														as="span"
														flex="1"
														textAlign="left"
														color="#4BA18A"
													>
														Add Terms and Conditions
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4} px="0em" bg="#fff" padding="1rem">
												<RenderTextAreaField
													name="terms"
													placeholder="Add your terms & Conditions here"
													padding=".75rem 1rem"
													size="xl"
													height="17rem"
													borderRadius={"5px"}
												/>
											</AccordionPanel>
										</AccordionItem>

										{/* Add Documents */}
										<AccordionItem
											marginBottom="1em"
											borderRadius="lg"
											borderTop="none"
										>
											<h2>
												<AccordionButton
													_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
													fontSize="14px"
													borderRadius="lg"
													bgColor="#fff"
													boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
													onClick={() => {
														if (accordianIndices.includes(4))
															setAccordianIndices(
																accordianIndices.filter(
																	(ind: number) => ind !== 4
																)
															);
													}}
												>
													<Box
														as="span"
														flex="1"
														textAlign="left"
														color="#4BA18A"
													>
														Add Documents
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4} p="1rem" bg="#fff">
												<FileUpload
													files={files}
													deleteFileConfirm={deleteFileConfirm}
													updateFile={updateFile}
												/>
											</AccordionPanel>
										</AccordionItem>
									</Accordion>
								</Show>

								{/* Buttons for submit and draft */}
								<Show below="md">
									<Flex
										w="100%"
										padding="1rem"
										position="fixed"
										bottom="0"
										left="0"
										boxShadow="0 -4px 4px #00000006"
										justifyContent="space-around"
										gap="1rem"
										bg="#fff"
									>
										<Button
											bg="#fff"
											variant="outline"
											border="1px solid #4BA18A"
											color="#4BA18A"
											onClick={() => setViewRfq(true)}
											_hover={{ bg: "#E2F1EE" }}
											flex={1}
										>
											View RFQ
										</Button>
										<Button
											type="submit"
											fontSize="16px"
											flex={1}
											bg="#E2F1EE"
											_hover={{ bg: "#B7DDD4" }}
											color="#4BA18A"
											variant="outline"
											border="1px solid #4BA18A"
										>
											Save as Draft
										</Button>
										<Button
											fontSize="16px"
											type="submit"
											bg="#4ba18a"
											color="#fff"
											_hover={{ bg: "#4ba18a" }}
											onClick={() => setIsSubmitClicked(true)}
											flex={1}
										>
											Submit Quote
										</Button>
									</Flex>
								</Show>
							</Form>
						);
					}}
				</Formik>
			</Box>
			{/* </Box> */}

			{/* ********************** desktop pop-ups************************* */}
			<Show above="md">
				{/* view RFQ popup */}
				<BasicDialog
					isOpen={viewRfq}
					showCloseButton={true}
					onClose={() => setViewRfq(false)}
					header="Request for Quotation"
					content={
						<Box height="80vh" overflow="auto">
							<ViewRfq rfqId={data?.opportunity} />
						</Box>
					}
					size="6xl"
				/>

				<RfqPopupCard
					userRequest={"form"}
					isOpen={isExtensionPopupOpen}
					onClose={handleCloseExtension}
					title="Request for Extension"
					// message="Please choose the current expiry date and the requested expiry date:"
					onCancel={handleCloseExtension}
					onSave={handleSave}
					imageURL={Extend}
					BCancel={"Cancel"}
					BSave={"Save"}
				/>

				{/* save draft success popup */}
				<BasicDialog
					isOpen={showSaveDraftPopup}
					onClose={() => {
						fetchData();
						setShowSaveDraftPopup(false);
					}}
					showCloseButton={true}
					header="Save as Draft"
					content={
						<SaveAsDraftPopup
							quoteId={quoteId}
							handleSaveAsDraft={() => {
								fetchData();
								setShowSaveDraftPopup(false);
							}}
						/>
					}
				/>

				{/* submit confirm popup */}
				<BasicDialog
					isOpen={showSubmitConfirmPopup}
					onClose={() => {
						setIsSubmitClicked(false);
						fetchData();
						setShowSubmitConfirmPopup(false);
					}}
					showCloseButton={true}
					header="Submit Quote"
					content={
						<SubmitConfirmPopup
							rfqName={data?.rfq_name}
							companyName={data?.company_name}
							handleCancel={() => {
								setIsSubmitClicked(false);
								fetchData();
								setShowSubmitConfirmPopup(false);
							}}
							handleSubmit={() => submitQuote()}
						/>
					}
				/>

				{/* submit acknowledgement popup */}
				<BasicDialog
					isOpen={showSubmitAcknowledgePopup}
					onClose={() => {
						fetchData();
						setShowSubmitAcknowledgePopup(false);
					}}
					showCloseButton={true}
					header="Quote Submitted"
					content={<QuoteSubmittedAcknowledgement quoteId={quoteId} />}
				/>

				{/* View Item details popup */}
				<BasicDialog
					isOpen={isPartOpen}
					showCloseButton={true}
					onClose={() => {
						setIsPartOpen(false);
					}}
					header={`Part Name: ${selectedItem?.item_name}`}
					content={
						<Box maxHeight="80vh" overflow="auto">
							<ViewPartForm
								itemData={selectedItem}
								dataOriginatedFrom={data?.originated_from}
							/>
						</Box>
					}
					size="4xl"
				/>

				{/* add rate popup */}
				<BasicDialog
					isOpen={showAddRatePopup}
					showCloseButton={true}
					onClose={() => {
						setShowAddRatePopup(false);
					}}
					header={`Part Name: ${selectedItem?.item_name}`}
					size="6xl"
					content={
						<Box maxHeight="90vh" bg="#F3F6F9">
							<AddRateModal
								onSave={handleAddRateModalClose}
								itemData={selectedItem}
								selectedItemForRate={selectedItemForRate}
								closeAddRateModal={() => {
									setShowAddRatePopup(false);
								}}
								updateRateAndCloseModal={updateRateAndCloseModal}
								dataOriginatedFrom={data?.originated_from}
							/>
						</Box>
					}
				/>

				<BasicDialog
					header="Delete?"
					isOpen={deleteFilePrompt}
					onClose={() => {
						setDeleteFilePrompt(false);
					}}
					showCloseButton={true}
					content={
						<DeletePrompt
							name={selectedFile?.subtitle}
							deleteFunction={() => {
								setDeleteFilePrompt(false);
								deleteFile();
							}}
							cancelFunction={() => {
								setDeleteFilePrompt(false);
							}}
						/>
					}
				/>
			</Show>

			{/* ******************mobile pop-ups***************** */}
			<Show below="md">
				{/* view RFQ */}
				<Overlay isOpen={viewRfq}>
					<BottomSheet isOpen={viewRfq} onClose={() => setViewRfq(false)}>
						<Box height="80vh" overflowY="auto">
							<ViewRfq rfqId={data?.opportunity} />
						</Box>
					</BottomSheet>
				</Overlay>

				{/* For parts details */}
				<Overlay isOpen={isPartOpen}>
					<BottomSheet
						isOpen={isPartOpen}
						onClose={() => {
							setIsPartOpen(false);
						}}
					>
						<Text
							fontSize="14px"
							fontWeight="bold"
							color="#4BA18A"
							textAlign="center"
							mb="1rem"
						>
							{selectedItem?.item_name}
						</Text>
						<Box mb="3rem">
							<ViewPartForm
								itemData={selectedItem}
								dataOriginatedFrom={data?.originated_from}
							/>
						</Box>
					</BottomSheet>
					<Box w="100%" padding="1rem" position="fixed" bottom="0" left="0">
						<Button
							bg="#4ba18a"
							w="100%"
							color="#fff"
							onClick={() => {
								setIsPartOpen(false);
								setShowAddRatePopup(true);
							}}
						>
							Add Rate
						</Button>
					</Box>
				</Overlay>

				{/* For Adding rates of parts/items  */}
				<Overlay isOpen={showAddRatePopup}>
					<BottomSheet
						isOpen={showAddRatePopup}
						onClose={() => {
							setShowAddRatePopup(false);
						}}
					>
						<Text
							fontSize="18px"
							fontWeight="bold"
							color="#4BA18A"
							textAlign="center"
							mb="0.625rem"
						>
							Add Rate
						</Text>
						<Text
							fontSize="14px"
							fontWeight="semibold"
							textAlign="center"
							mb="1rem"
						>
							Part : {selectedItem?.item_name}
						</Text>
						<Box>
							<AddRateModal
								onSave={handleAddRateModalClose}
								itemData={selectedItem}
								selectedItemForRate={selectedItemForRate}
								closeAddRateModal={() => {
									setShowAddRatePopup(false);
								}}
								updateRateAndCloseModal={updateRateAndCloseModal}
								dataOriginatedFrom={data?.originated_from}
							/>
						</Box>
					</BottomSheet>
				</Overlay>

				{/* Saved as Draft bottom sheet */}
				<Overlay isOpen={showSaveDraftPopup}>
					<BottomSheet
						isOpen={showSaveDraftPopup}
						onClose={() => {
							fetchData();
							setShowSaveDraftPopup(false);
						}}
					>
						<Text
							fontSize="18px"
							fontWeight="bold"
							color="#4BA18A"
							textAlign="center"
							mb="1rem"
						>
							Saved as Draft
						</Text>
						<SaveAsDraftPopup
							quoteId={quoteId}
							handleSaveAsDraft={() => {
								fetchData();
								setShowSaveDraftPopup(false);
							}}
						/>
					</BottomSheet>
				</Overlay>

				{/* Submit Confirmation */}
				<Overlay isOpen={showSubmitConfirmPopup}>
					<BottomSheet
						isOpen={showSubmitConfirmPopup}
						onClose={() => {
							setIsSubmitClicked(false);
							fetchData();
							setShowSubmitConfirmPopup(false);
						}}
					>
						<Text
							fontSize="18px"
							fontWeight="bold"
							color="#4BA18A"
							textAlign="center"
							mb="1rem"
						>
							Submit Quote
						</Text>
						<SubmitConfirmPopup
							rfqName={data?.rfq_name}
							companyName={data?.company_name}
							handleCancel={() => {
								setIsSubmitClicked(false);
								fetchData();
								setShowSubmitConfirmPopup(false);
							}}
							handleSubmit={() => submitQuote()}
						/>
					</BottomSheet>
				</Overlay>

				{/* Submit Acknowledgement */}
				<Overlay isOpen={showSubmitAcknowledgePopup}>
					<BottomSheet
						isOpen={showSubmitAcknowledgePopup}
						onClose={() => {
							fetchData();
							setShowSubmitAcknowledgePopup(false);
						}}
					>
						<Text
							fontSize="18px"
							fontWeight="bold"
							color="#4BA18A"
							textAlign="center"
							mb="1rem"
						>
							Quote Submitted
						</Text>
						<QuoteSubmittedAcknowledgement quoteId={quoteId} />
					</BottomSheet>
				</Overlay>

				{/* Delete file */}
				<Overlay isOpen={deleteFilePrompt}>
					<BottomSheet
						isOpen={deleteFilePrompt}
						onClose={() => {
							setDeleteFilePrompt(false);
						}}
					>
						<Text
							fontSize="18px"
							fontWeight="bold"
							color="red.500"
							textAlign="center"
							mb="1.5rem"
						>
							Delete
						</Text>
						<DeletePrompt
							name={selectedFile?.subtitle}
							deleteFunction={() => {
								setDeleteFilePrompt(false);
								deleteFile();
							}}
							cancelFunction={() => {
								setDeleteFilePrompt(false);
							}}
						/>
					</BottomSheet>
				</Overlay>
			</Show>
		</Box>
	);
};

// local components common for both mobile and desktop

// Suggestion: add props for the name of input fields
const SupplierDetails = ({ supplierAddresses }: any) => {
	const navigate = useNavigate();

	const { setFieldError } = useFormikContext();

	const handleFocus = (fieldName) => {
		setFieldError(fieldName, "");
	};

	return (
		<Grid
			templateColumns={{
				sm: "repeat(1,1fr)",
				md: "repeat(2,1fr)",
			}}
			gap={{ base: 6, lg: 12 }}
			w={{ base: "100%", lg: "90%" }}
			p={{ base: "1rem", lg: "0" }}
		>
			<GridItem id="supplier_company_name">
				<RenderTextField
					name="supplier_company_name"
					label="Company Name"
					placeholder="Company Name"
					disabled={true}
					variant="outline"
					labelsize="1rem"
					labelweight={600}
				/>
			</GridItem>

			<GridItem id="supplier_address">
				<RenderDropDown
					name="supplier_address"
					onFocus={() => handleFocus("supplier_address")}
					label="Address"
					placeholder="Address"
					isMandatory={true}
					values={supplierAddresses}
					variant="outline"
					labelsize="1rem"
					labelweight={600}
				/>
				{supplierAddresses?.length === 0 && (
					<Text
						fontSize="14px"
						color="#4BA18A"
						fontWeight="semibold"
						textDecoration="underline"
						cursor="pointer"
						onClick={() =>
							window.open("/digitalFactory/?step=1&tab=2", "_blank")
						}
					>
						Add Address to your Profile
					</Text>
				)}
			</GridItem>

			<GridItem id="supplier_contact">
				<RenderPhoneField
					label="Contact"
					name="supplier_contact"
					onFocus={() => handleFocus("supplier_contact")}
					placeholder="+91 9999999999"
					isMandatory={true}
					variant="outline"
					labelsize="1rem"
					labelweight={600}
				/>
			</GridItem>

			<GridItem id="supplier_email">
				<RenderTextField
					name="supplier_email"
					label="Email"
					type="email"
					placeholder="Email"
					onFocus={() => handleFocus("supplier_email")}
					isMandatory={true}
					variant="outline"
					labelsize="1rem"
					labelweight={600}
				/>
			</GridItem>
		</Grid>
	);
};

// Suggestion: add props for the name of input fields
// TODO : Look into controlled input warning for this component
const Timeline = () => {
	const { setFieldError } = useFormikContext();

	const handleFocus = (fieldName) => {
		setFieldError(fieldName, "");
	};

	return (
		<Flex
			mt={{ base: "0", lg: "2.5rem" }}
			width={{ sm: "100%", md: "50%" }}
			flexDirection="column"
			gap="1.5rem"
		>
			<Box id="timeline">
				<RenderTextFieldWithCustomRightElement
					name="timeline_number"
					label="Timeline"
					placeholder="Ex: 30 days after receiving PO"
					isMandatory={true}
					type="number"
					min="1"
					variant="outline"
					labelsize="1rem"
					labelweight={600}
					rightelementwidth="auto"
					rightelementpadding="0 2rem 0 0"
					onFocus={() => handleFocus("timeline_number")}
					rightelement={
						<RenderDropDown
							name="timeline_field"
							defaultValue="Days"
							values={["Days", "Weeks", "Months"]}
							variant="filled"
							size="sm"
							borderRadius="sm"
						/>
					}
				/>
			</Box>
			<Box id="valid_till">
				<RenderTextField
					name="valid_till"
					label="Quote valid Till"
					placeholder="Add Date"
					type="date"
					min={new Date().toISOString().split("T")[0]}
					isMandatory={true}
					variant="outline"
					labelsize="1rem"
					labelweight={600}
					onFocus={() => handleFocus("valid_till")}
				/>
			</Box>
		</Flex>
	);
};

// Common component for Uploading Documents
const FileUpload = ({ files, deleteFileConfirm, updateFile }: any) => {
	return (
		<Box>
			<Box overflowX="auto">
				{files?.length > 0 ? (
					<Box>
						<Text ml="1em">{files.length}&nbsp; files uploaded</Text>
						<Box mt="1rem" ml="1rem">
							<FilesSlider
								files={files}
								alternativeImg={alternativeImg}
								deleteIcon={true}
								deleteFunction={deleteFileConfirm}
							/>
						</Box>
					</Box>
				) : (
					<Text fontStyle="Rubik">No files uploaded</Text>
				)}
			</Box>
			<Box mt="1em" mb="2rem">
				<UploadDoc updateFile={updateFile} isMultiple={true} />
			</Box>
		</Box>
	);
};

// The following components are for the contents of several pop-up in desktop as well as mobile
const SaveAsDraftPopup = ({ quoteId, handleSaveAsDraft }: any) => {
	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			justifyContent="space-around"
			pb="2rem"
		>
			<lottie-player
				src="https://assets2.lottiefiles.com/temp/lf20_5tgmik.json"
				background="transparent"
				speed="1"
				style={{ width: "150px", height: "150px" }}
				loop
				autoplay
			></lottie-player>
			<Text fontSize={"18px"} fontWeight={"bold"}>
				Your Quote has been Saved as Draft
			</Text>
			<Flex mt="1rem" alignItems="center">
				<Text fontWeight="semibold">Quote ID:&nbsp;</Text>
				<Text>{quoteId}</Text>
			</Flex>
			<Button
				mt="1.5rem"
				color="#fff"
				bg="#4BA18A"
				_hover={{ bg: "#38846D" }}
				onClick={() => handleSaveAsDraft()}
				children="Okay"
				width={{ base: "100%", lg: "auto" }}
			/>
		</Flex>
	);
};

const SubmitConfirmPopup = ({
	rfqName,
	companyName,
	handleCancel,
	handleSubmit,
}: any) => {
	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			justifyContent="space-around"
			pb="2rem"
		>
			<Box
				width={{ base: "10rem", lg: "16rem" }}
				height={{ base: "10.125rem", lg: "16.125rem" }}
				objectFit="contain"
			>
				<img src={Submit} />
			</Box>
			<Text textAlign={"center"} mt="2rem">
				Are you sure you want to Submit the Quote&nbsp;
				<chakra.span color="#4BA18A">{rfqName}&nbsp;</chakra.span>
				to
				<chakra.span color="#4BA18A">
					&nbsp;{companyName || "Karkhana.io"}&nbsp;
				</chakra.span>
				?
			</Text>
			<Flex
				alignItems="center"
				justifyContent="center"
				gap="1.25rem"
				mt="1rem"
				w={{ base: "100%", lg: "auto" }}
			>
				<Button
					children="Cancel"
					color="#000"
					bg="#fff"
					_hover={{ bg: "#fff" }}
					border="1px solid #000"
					flexGrow={{ base: 1, lg: 0 }}
					onClick={() => handleCancel()}
				/>
				<Button
					children="Confirm"
					color="#fff"
					bg="#4BA18A"
					_hover={{ bg: "#38846D" }}
					flexGrow={{ base: 1, lg: 0 }}
					onClick={() => handleSubmit()}
				/>
			</Flex>
		</Flex>
	);
};

const QuoteSubmittedAcknowledgement = ({ quoteId }: any) => {
	const navigate = useNavigate();
	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			justifyContent="space-around"
			pb="2rem"
		>
			<lottie-player
				src="https://assets4.lottiefiles.com/packages/lf20_mpej2rnq.json"
				background="transparent"
				speed="1"
				style={{ width: "300px", height: "300px" }}
				loop
				autoplay
			></lottie-player>
			<Text fontSize={"18px"} fontWeight={"bold"}>
				Your Quote has been Submitted
			</Text>
			<Flex mt="1rem" alignItems="center">
				<Text fontWeight="semibold">Quote ID:&nbsp;</Text>
				<Text>{quoteId}</Text>
			</Flex>
			<Button
				mt="1.5rem"
				color="#fff"
				bg="#4BA18A"
				_hover={{ bg: "#38846D" }}
				onClick={() => {
					navigate("/quotes");
				}}
				children="View all Quotes"
			/>
		</Flex>
	);
};

export default CreateQuote;
