import {
	Box,
	Button,
	Center,
	Divider,
	Flex,
	Img,
	Text,
	VStack,
	Link as ChakraLink,
	Show,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverHeader,
	PopoverContent,
} from "@chakra-ui/react";
import React from "react";
import parts from "../../img/parts.svg"; // eslint-disable-line
import { BsChat, BsChevronLeft, BsArrowRightCircle } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { getStatusColor } from "./RfqStatusBar";

interface CardProps {
	name: string;
	rfqName: string;
	rfqId?: string;
	status: string;
	quantity?: any;
	receivedOn?: any;
	expiresOn?: any;
	deliveryDate?: string;
	totalAmount?: string;
	updateButton?: boolean;
	width?: any;
	original_name?: any;
	getQuoteAndNavigate?: Function;
	poId?: any;
	rfq?: any;
	isUsedInPopUp?: boolean;
	getPurchaseOrderAndNavigate?: Function;
}
const RfqMainCard = ({
	name,
	rfqName,
	rfqId,
	status,
	quantity,
	receivedOn,
	expiresOn,
	deliveryDate,
	totalAmount,
	updateButton,
	width,
	getQuoteAndNavigate,
	poId,
	rfq,
	original_name,
	getPurchaseOrderAndNavigate,
	isUsedInPopUp,
}: CardProps) => {
	const navigate = useNavigate();
	const statusColor = getStatusColor(status);

	return (
		<Box
			fontSize="12px"
			borderRadius="lg"
			boxShadow={
				isUsedInPopUp
					? "none"
					: "0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
			}
			padding="1em 1em"
			maxWidth={width ? width : "360px"}
			margin="1em auto 0 auto"
			onClick={() => {
				if (rfqId !== undefined) navigate(`/viewrfq/${rfqId}`);
			}}
		>
			<Flex gap="1em" alignItems="center" marginBottom="1em">
				<Center
					w="60px"
					h="60px"
					bg="#E2F1EE"
					color="#4BA18A"
					borderRadius="50%"
					fontSize="35px"
					onClick={() => {
						if (rfqId !== undefined) navigate(`/viewrfq/${rfqId}`);
					}}
					cursor="pointer"
				>
					{name?.slice(0, 1)}
				</Center>
				<Flex flexDirection="column">
					<Text
						fontWeight="bold"
						onClick={() => {
							if (rfqId !== undefined) navigate(`/viewrfq/${rfqId}`);
						}}
						cursor="pointer"
						fontSize="14px"
					>
						{name}
					</Text>
					<Text>RFQ Name: {rfqName}</Text>
					{rfqId && <Text>RFQ ID: {rfqId?.split(" | ")[1]}</Text>}
					{poId && <Text>PO ID: {poId}</Text>}
				</Flex>
				<Flex
					flexDirection="column"
					gap="1.5em"
					marginLeft="auto"
					justifyContent="space-between"
					alignItems="flex-end"
				>
					<Box onClick={(e) => e.stopPropagation()}>
						<Popover placement="bottom" closeOnBlur={true}>
							<PopoverTrigger>
								<span>
									<BsChat size={"16px"} />
								</span>
							</PopoverTrigger>
							<PopoverContent
								color="white"
								bg="#131B18"
								border="none"
								width="auto"
								mr="1rem"
							>
								<PopoverHeader px={4} fontWeight="semibold" border="none">
									Coming soon...
								</PopoverHeader>
								<PopoverArrow bg="#131B18" ml="0.5rem" />
							</PopoverContent>
						</Popover>

						{/* <Box
							position="absolute"
							fontSize="6px"
							color="#E2F1EE"
							top="-1.5px"
							right="1px"
							backgroundColor="#4BA18A"
							padding="1px"
							borderRadius="50%"
							fontWeight="600"
							width="10px"
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							1
						</Box> */}
					</Box>
					<Text
						bg={statusColor.bg}
						color={statusColor.color}
						padding="0.3em 0.6em"
						borderRadius="md"
					>
						{status}
					</Text>
				</Flex>
			</Flex>
			<Divider />
			<Flex justifyContent="space-between" marginTop="1em" alignItems="center">
				{quantity !== undefined && (
					<VStack alignItems={"left"}>
						<Text fontWeight="600" fontSize="12px" color="#4BA18A">
							Quantity
						</Text>
						<Text mt="0.2rem">{quantity}</Text>
					</VStack>
				)}
				{receivedOn !== undefined && (
					<VStack alignItems={"left"}>
						<Text fontWeight="600" fontSize="12px" color="#4BA18A">
							Received On
						</Text>
						<Text mt="0.2rem">{receivedOn}</Text>
					</VStack>
				)}
				{expiresOn !== undefined && (
					<VStack alignItems={"left"}>
						<Text fontWeight="600" fontSize="12px" color="#4BA18A">
							RFQ Expiry Date
						</Text>
						<Text mt="0.2rem">{expiresOn}</Text>
					</VStack>
				)}
				{deliveryDate !== undefined && (
					<VStack alignItems={"left"}>
						<Text fontWeight="600" fontSize="12px" color="#4BA18A">
							Delivery Date
						</Text>
						<Text mt="0.2rem">{deliveryDate}</Text>
					</VStack>
				)}
				{totalAmount !== undefined && (
					<VStack alignItems={"left"}>
						<Text fontWeight="600" fontSize="12px" color="#4BA18A">
							Total Amount
						</Text>
						<Text fontWeight="bold" mt="0.2rem">
							{totalAmount}
						</Text>
					</VStack>
				)}
				{updateButton && (
					<Link to={`/orderslive/${original_name}`}>
						<Button
							fontSize="12px"
							fontWeight="700"
							backgroundColor="#4BA18A"
							_hover={{ bg: "#38846D" }}
							color="white"
							size="sm"
						>
							Update Order
						</Button>
					</Link>
				)}
				{/* {status === "Quoted" && (
					<Button
						size="sm"
						fontWeight="bold"
						colorScheme="teal"
						variant="outline"
						onClick={() => getQuoteAndNavigate(rfq)}
						fontSize={{base: "12px", lg: "14px"}}
					>
						View Quote
					</Button>
				)}
				{status === "Confirmed" && (
					<Button
						size="sm"
						fontWeight="bold"
						colorScheme="teal"
						variant="outline"
						onClick={() => getPurchaseOrderAndNavigate(rfqId)}
						fontSize={{base: "12px", lg: "14px"}}
					>
						View Order
					</Button>
				)}
				{status === "Accepted" && (
					<Button
						color="#fff"
						size="sm"
						bg="#4BA18A"
						_hover={{ bg: "#38846D" }}
						fontWeight="bold"
						onClick={() => getQuoteAndNavigate(rfq)}
						fontSize={{base: "12px", lg: "14px"}}
					>
						Create Quote
					</Button>
				)}
				{["Lost", "Cancelled", "Rejected", "Open"].indexOf(status) >= 0 && ( */}
				{!updateButton && (
					<Flex alignItems="center" gap=".25rem">
						<ChakraLink as={Link} to={`/viewrfq/${rfqId}`} color="#4BA18A">
							View RFQ
						</ChakraLink>
						<BsArrowRightCircle color="#4BA18A" size="1.25rem" />
					</Flex>
				)}
				{/* )} */}
			</Flex>
		</Box>
	);
};

interface ViewRFQCardProps {
	companyName: string;
	rfqName: string;
	rfqId: string;
	expectedLeadTime?: any;
	receivedOn?: any;
	rfqExpiry?: any;
	acceptButtonFunction?: any;
	rejectButtonFunction?: any;
	viewQuotesFunction?: any;
	createNewQuoteFunction?: any;
	quotes?: any;
	partyName?: string;
	getPurchaseOrderAndNavigate?: Function;
	status?: any;
	contactNo?: any;
}

function ViewRFQCard({
	companyName,
	rfqName,
	rfqId,
	expectedLeadTime,
	receivedOn,
	rfqExpiry,
	partyName,
	contactNo,
}: ViewRFQCardProps) {
	const navigate = useNavigate();
	return (
		<Box
			fontSize="12px"
			borderRadius="lg"
			boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
			padding="1em 1.5em"
			width={"100%"}
			margin="0em auto"
			border="1px solid var(--primary-green-30, #B7DDD4)"
			bgColor="#fff"
		>
			<Flex gap="1em" alignItems="center" marginBottom="1em">
				<Center
					w="60px"
					h="60px"
					bg="#E2F1EE"
					color="#4BA18A"
					borderRadius="50%"
					fontSize="35px"
				>
					{companyName?.slice(0, 1) || "K"}
				</Center>
				<Flex flexDirection="column">
					<Text fontWeight="bold" fontSize="14px">
						{companyName}
					</Text>
					<Text>RFQ Name: {rfqName}</Text>
					<Text>{partyName}</Text>
					{contactNo && (
						<Text color="gray">
							Contact No:&nbsp;<a href={`tel:${contactNo}`}>{contactNo}</a>
						</Text>
					)}
				</Flex>
			</Flex>
			<Divider />
			<Flex justifyContent="space-between" marginTop="1em">
				{expectedLeadTime && (
					<VStack alignItems="flex-start">
						<Text fontWeight="600" fontSize="11px" color="#4BA18A">
							Expected Lead Time
						</Text>
						<Text>{expectedLeadTime}</Text>
					</VStack>
				)}
				{receivedOn && (
					<VStack alignItems="flex-start">
						<Text fontWeight="600" fontSize="11px" color="#4BA18A">
							Received On
						</Text>
						<Text>{receivedOn}</Text>
					</VStack>
				)}
				{rfqExpiry && (
					<VStack alignItems="flex-start">
						<Text fontWeight="600" fontSize="11px" color="#4BA18A">
							RFQ Expiry
						</Text>
						<Text>{rfqExpiry}</Text>
					</VStack>
				)}
			</Flex>
		</Box>
	);
}

function ViewRFQActions({
	rfqId,
	status,
	acceptButtonFunction,
	rejectButtonFunction,
	quotes,
	createNewQuoteFunction,
	getPurchaseOrderAndNavigate,
	...props
}: any) {
	const navigate = useNavigate();
	return (
		<Flex
			gap="1em"
			justifyContent="space-between"
			padding="0.5rem 1rem"
			position="fixed"
			overflow="hidden"
			bg="#fff"
			bottom="0"
			left="0"
			width="100%"
			mt="4rem"
			{...props}
		>
			{acceptButtonFunction && (
				<Button
					width="47%"
					backgroundColor="#4BA18A"
					_hover={{ bg: "#38846D" }}
					color="white"
					onClick={acceptButtonFunction}
					fontSize="14px"
					flexGrow={1}
				>
					Accept RFQ
				</Button>
			)}
			{rejectButtonFunction && (
				<Button
					width="47%"
					bg="#fff"
					border="1px solid #63B29D"
					color="#4BA18A"
					_hover={{ bg: "#E2F1EE" }}
					onClick={rejectButtonFunction}
					fontSize="14px"
					flexGrow={1}
				>
					Reject RFQ
				</Button>
			)}

			{quotes && (
				<Button
					width="47%"
					backgroundColor="#4BA18A"
					_hover={{ bg: "#38846D" }}
					color="white"
					fontSize="14px"
					flexGrow={1}
					onClick={() => {
						if (quotes?.length === 1) {
							navigate(`/quotemanagement/${quotes[0].name}`);
						} else if (quotes?.length > 1) {
							navigate(`/quotes/filter?opportunity=${rfqId}`);
						}
					}}
				>
					{`View Quote (${quotes?.length})`}
				</Button>
			)}
			{createNewQuoteFunction && (
				<Button
					width={status && status == "Accepted" ? "100%" : "47%"}
					bg="#fff"
					border="1px solid #63B29D"
					color="#4BA18A"
					_hover={{ bg: "#E2F1EE" }}
					onClick={createNewQuoteFunction}
					fontSize="14px"
					flexGrow={1}
				>
					Create New Quote
				</Button>
			)}
			{getPurchaseOrderAndNavigate && (
				<Button
					width="47%"
					backgroundColor="#4BA18A"
					_hover={{ bg: "#38846D" }}
					color="white"
					onClick={() => getPurchaseOrderAndNavigate(rfqId)}
					fontSize="14px"
					flexGrow={1}
				>
					View Order
				</Button>
			)}
		</Flex>
	);
}

interface RFQPartsCardProps {
	name: any;
	units: number;
	category: string;
	targetCost: any;
	viewPartFunction?: any;
}

function RFQPartsCard({
	name,
	units,
	category,
	targetCost,
	viewPartFunction,
}: RFQPartsCardProps) {
	return (
		<Box
			fontSize="12px"
			borderRadius="lg"
			boxShadow="lg"
			padding="1em 2em"
			width={"100%"}
			margin="1em auto 0 auto"
			bgColor="#fff"
		>
			<Flex gap="1em" alignItems="center" marginBottom="1em">
				<Img
					src={parts}
					w="60px"
					h="60px"
					bg="#E2F1EE"
					color="#4BA18A"
					borderRadius="lg"
					padding="1em"
					alt="parts"
				/>
				<Flex flexDirection="column">
					<Text fontSize="14px" fontWeight="bold">
						{name}
					</Text>
					<Text>{units} Units</Text>
				</Flex>
			</Flex>
			<Divider mb="0.5rem" bgColor="rgba(19, 27, 24, 0.60)" />
			<Flex justifyContent="space-between" alignItems="center">
				<VStack alignItems="flex-start">
					<Text fontWeight="600" fontSize="10px">
						Category
					</Text>
					{category === undefined || category.length ? (
						<Text>-</Text>
					) : (
						category
					)}
				</VStack>
				<VStack alignItems="flex-start">
					<Text fontWeight="600" fontSize="10px">
						Target Cost
					</Text>
					<Text>{targetCost}</Text>
				</VStack>
				<Flex alignItems="center" gap="0.245rem">
					<Button
						variant="link"
						fontSize="12px"
						fontWeight="700"
						color="#4BA18A"
						onClick={() => {
							if (viewPartFunction !== undefined) viewPartFunction();
						}}
					>
						View Part
					</Button>
					<BsArrowRightCircle color="#4BA18A" size="1.25rem" />
				</Flex>
			</Flex>
		</Box>
	);
}

const BackButton = () => {
	let navigate = useNavigate();
	return (
		<BsChevronLeft size="20px" onClick={() => navigate(-1)} cursor="pointer" />
	);
};

const RFQMainCardSmall = ({
	name,
	rfqName,
	rfqId,
	status,
	expiresOn,
	receivedOn,
	poId,
	original_name,
}: CardProps) => {
	let navigate = useNavigate();
	const statusColor = getStatusColor(status);

	return (
		<Box
			fontSize="12px"
			borderRadius="lg"
			boxShadow={
				"0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
			}
			padding="1em 1em"
			margin="1em auto 0 auto"
			bg="#fff"
			onClick={() => {
				if (rfqId !== undefined) navigate(`/viewrfq/${rfqId}`);
				else if (poId !== undefined) navigate(`/orderslive/${original_name}`);
			}}
		>
			<Flex gap="1em" alignItems="center" marginBottom="1em">
				<Center
					w="60px"
					h="60px"
					bg="#E2F1EE"
					color="#4BA18A"
					borderRadius="50%"
					fontSize="35px"
					onClick={() => {
						if (rfqId !== undefined) navigate(`/viewrfq/${rfqId}`);
					}}
					cursor="pointer"
				>
					{name?.slice(0, 1)}
				</Center>
				<Flex flexDirection="column">
					<Text
						fontWeight="bold"
						onClick={() => {
							if (rfqId !== undefined) navigate(`/viewrfq/${rfqId}`);
						}}
						cursor="pointer"
						fontSize="14px"
					>
						{name}
					</Text>
					<Text>RFQ Name: {rfqName}</Text>
					{rfqId && <Text>RFQ ID: {rfqId?.split(" | ")[1]}</Text>}
					{poId && <Text>PO ID: {poId}</Text>}
				</Flex>
			</Flex>
			<Divider />
			<Flex justifyContent="space-between" marginTop="1em" alignItems="center">
				{expiresOn !== undefined && (
					<VStack alignItems={"left"}>
						<Text fontWeight="600" fontSize="12px" color="#4BA18A">
							RFQ Expiry Date
						</Text>
						<Text mt="0.2rem">{expiresOn}</Text>
					</VStack>
				)}
				{receivedOn !== undefined && (
					<VStack alignItems={"left"}>
						<Text fontWeight="600" fontSize="12px" color="#4BA18A">
							Received On
						</Text>
						<Text mt="0.2rem">{receivedOn}</Text>
					</VStack>
				)}
				<Text
					bg={statusColor.bg}
					color={statusColor.color}
					padding="0.3em 0.6em"
					borderRadius="md"
				>
					{status}
				</Text>
				{/* )} */}
			</Flex>
		</Box>
	);
};

export {
	RfqMainCard,
	RFQPartsCard,
	ViewRFQCard,
	BackButton,
	ViewRFQActions,
	RFQMainCardSmall,
};
