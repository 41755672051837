import DFAdIcon1 from "./dfAdIcon1.png";
import DFAdIcon2 from "./dfAdIcon2.png";
import DFAdIcon3 from "./dfAdIcon3.png";
import SupplierProfilePage from "./Supplier_Profile_Page_Home.png";
import SupplierProfileMobilePage from "./Supplier_profile_mobile_page.png";
import FileUploadIcon from "./fileUploadIcon.png";

import Edit from "./Write.png";
import Pdf from "./pdf.svg";
import Doc from "./doc.svg";
import Delete from "./Trash.png";
import ImgDelete from "./Delete.png";

import ProductsImage1 from "./productsImage1.png";
import ProductsImage2 from "./productsImage2.png";
import ProductsImage3 from "./productsImage3.png";

import CartSupplier from "./cartSupplier.svg";
import Calculate from "./calculate.svg";
import Capability from "./capability.svg";
import Quote from "./quote.svg";
import openRFQ from "./openRFQ.svg";
import liveOrder from "./liveOrder.svg";
import recentActivities from "./recentActivities.svg";
import DashboardEqualizer from "./DashboardEqualizer.svg";
import DigitalFactory from "./dashboardDigitalFactory.svg";
import SupplierProfile from "./SupplierProfile.svg";

import WhatsApp from "./whatsApp.svg";
import LinkedIn from "./linkedin.svg";
import Gmail from "./gmail.svg";

import InviteSent from "./InviteSent.svg";
import MapMarker from "./Mapmarker.svg";
import EmailDuotone from "./EmailDuotone.svg";

import UploadComplete from "./uploadComplete.svg";

export {
	DFAdIcon1,
	DFAdIcon2,
	DFAdIcon3,
	SupplierProfilePage,
	SupplierProfileMobilePage,
	FileUploadIcon,
	Edit,
	Delete,
	ImgDelete,
	Pdf,
	Doc,
	ProductsImage1,
	ProductsImage2,
	ProductsImage3,
	CartSupplier,
	Calculate,
	Capability,
	Quote,
	openRFQ,
	liveOrder,
	recentActivities,
	DashboardEqualizer,
	DigitalFactory,
	SupplierProfile,
	WhatsApp,
	LinkedIn,
	Gmail,
	InviteSent,
	MapMarker,
	EmailDuotone,
	UploadComplete,
};
