import { frappeDocService, get_doc } from "@karkhanaui/react";
import axios from "axios"
import Cookies from "js-cookie";

export const createProfileService = async () => {
	const supplier = Cookies.get('user_id') || 'Guest'

	// if guest return
	if(supplier == 'Guest') {
		return
	}


	const supplierData = (await frappeDocService("Supplier", supplier)).data.data


	const doc = 'Pre Stage'

	const data = {
		email: supplier,
		company_name: supplierData.supplier_name
	}


	await axios.post(process.env.REACT_APP_BASE_URL + `/api/resource/${doc}`, data)
}




export const getSupplierProfile = async () => {
	const supplier = Cookies.get('user_id') || 'Guest'

	// if guest return
	if(supplier == 'Guest') {
		return
	}


	return (await frappeDocService("Pre Stage", supplier)).data.data
}



export const updateSupplierProfile = async (data) => {
	const supplier = Cookies.get('user_id') || 'Guest'

	// if guest return
	if(supplier == 'Guest') {
		return
	}


	return await (
    await axios.put(
      process.env.REACT_APP_BASE_URL + `/api/resource/Pre Stage/${supplier}`,
			data
    )
  ); 
}