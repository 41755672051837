import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import * as Yup from "yup";
import {
	yupConfirmPassword,
	yupPassword,
	DarkButton,
	RenderTextField,
	SET_MESSAGE,
	type AppDispatch,
	SUCCESS_MSG,
	setMessage,
	ERROR_MSG,
	formatErrorMessage,
	supplierResetPasswordService,
} from "@karkhanaui/react";
import { useDispatch } from "react-redux";
import { Mixpanel } from "../../Mixpanel.js";
const ResetPwd = () => {
	const params = useParams();
	const changePasswordKey = params.changePasswordKey;
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const SO_UUID = searchParams.get("po");
	const uuid = searchParams.get("rfq");

	const basicValidation = Yup.object().shape({
		password: yupPassword,
		confirmPassword: yupConfirmPassword,
	});

	const onSubmit = async (event: any) => {
		try {
			setButtonLoading(true);
			const res: any = await supplierResetPasswordService(
				changePasswordKey,
				event.password
			);
			if (res.status === 200) {
				const message = `${res.data.message}. Please login now`;
				setButtonLoading(false);
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: SUCCESS_MSG,
						data: message,
					},
				});
				Mixpanel.track("forgotPasswordSuccess", {
					message: message,
				});
				navigate(
					`/login${uuid != null ? `/?rfq=${uuid}` : ""}${
						SO_UUID != null ? `/?po=${SO_UUID}` : ""
					}`
				);
			}
		} catch (error) {
			setButtonLoading(false);
			let mes = formatErrorMessage(error);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
			Mixpanel.track("forgotPasswordFailed", {
				message: mes,
			});
		}
	};
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent={{ base: "top", lg: "center" }}
			flexDirection="column"
			h="100%"
			bg="#fff"
		>
			<Box width={{ base: "100%", lg: "50%" }} p={{ base: "2rem 1rem", lg: 0 }}>
				<Text textStyle="primary.title" fontSize={"32px"}>
					Reset Password
				</Text>

				<Formik
					initialValues={{ password: "", confirmPassword: "" }}
					validationSchema={basicValidation}
					onSubmit={onSubmit}
				>
					{(formik) => {
						return (
							<Form>
								<Box mt="3rem">
									<Box>
										<RenderTextField
											label="New Password"
											name="password"
											type="password"
											placeholder="********"
											isMandatory={true}
										/>
									</Box>
									<Box mt="1.5rem">
										<RenderTextField
											label="Confirm password"
											name="confirmPassword"
											type="password"
											placeholder="********"
											isMandatory={true}
										/>
									</Box>
									<Box mt="2.5rem">
										<DarkButton
											type="submit"
											id="resetPwdBtn"
											width="100%"
											height="50px"
											children="Reset"
											bg="#4BA18A"
											isLoading={buttonLoading}
											_hover={{ bg: "#38846D" }}
										/>
									</Box>
									<Box
										display="flex"
										mt="1rem"
										justifyContent={{ base: "center", lg: "left" }}
										alignItems="center"
									>
										<Text textStyle="primary.secondaryText">Go back to</Text>
										<Text
											textStyle="primary.link"
											sx={{ fontWeight: "600" }}
											as={Link}
											to={`/login${uuid != null ? `/?rfq=${uuid}` : ""}${
												SO_UUID != null ? `/?po=${SO_UUID}` : ""
											}`}
											color="#4BA18A"
										>
											&nbsp;Login
										</Text>
									</Box>
								</Box>
							</Form>
						);
					}}
				</Formik>
			</Box>
		</Box>
	);
};

export default ResetPwd;
