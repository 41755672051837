import React, { useEffect } from "react";
import { Text, Grid, GridItem, Box } from "@chakra-ui/react";

const ItemDataDisplay = ({ itemData, dataOriginatedFrom }: any) => {
	const [customPropertiesArray, setCustomPropertiesArray] = React.useState<any>(
		[]
	);
	const [itemDetails, setItemDetails] = React.useState<any>([]);

	const getDimensions = () => {
		if (dataOriginatedFrom === "procurement") {
			if (itemData?.dimension?.includes("undefined")) {
				return null;
			} else {
				return itemData?.dimension + itemData?.dimension_unit;
			}
		} else {
			return itemData?.length ||
				itemData?.bredth ||
				itemData?.height ||
				itemData?.width
				? `${
						itemData?.length ? Number(itemData?.length).toFixed(2) : 0
				  } mm \u00D7 ${
						itemData?.width
							? Number(itemData?.width).toFixed(2)
							: itemData?.bredth
							? Number(itemData?.bredth).toFixed(2)
							: 0
				  } mm \u00D7 ${
						itemData?.height ? Number(itemData?.height).toFixed(2) : 0
				  } mm`
				: null;
		}
	};
	const prepareData = async () => {
		const keys = await Object.keys(itemData);
		let _data: any = {};
		keys?.map((property: any) => {
			_data = {
				category: itemData?.item_category || itemData?.item_type,
				dimensions: getDimensions(),
				special_instructions: itemData?.any_special_requirements,
				qty: itemData?.qty,
				uom: itemData?.uom,
				material_grade:
					dataOriginatedFrom === "procurement"
						? itemData?.material_name
						: itemData?.material_grade,
				process: itemData?.process_name,
				manufacturing_sub_process: itemData?.manufacturing_sub_process,
				finishing_type:
					dataOriginatedFrom === "procurement"
						? itemData?.post_process
						: itemData?.finishing_type,
				color: itemData?.color,
				target_cost: itemData?.target_cost,
				tolerance: itemData?.tolerance,
				description: itemData?.description,
			};
		});
		setItemDetails(_data);
	};

	const fieldTtitle = (fieldName: string) => {
		switch (fieldName) {
			case "item_name":
				return "Item Name";
			case "target_cost":
				return "Target Cost";
			case "qty":
				return "Quantity";
			case "finishing_type":
				return "Surface Finish";
			case "manufacturing_sub_process":
				return "Sub Process";
			case "material_grade":
				return "Material Name";
			case "uom":
				return "UOM";
			case "special_instructions":
				return "Special Instructions";
			default:
				// 1st cahracter is uppercase rest is lowercase
				return (
					fieldName.charAt(0).toUpperCase() + fieldName.slice(1).toLowerCase()
				);
		}
	};

	useEffect(() => {
		prepareData();
		if (itemData?.item_data) {
			setCustomPropertiesArray(JSON.parse(itemData?.item_data));
		}
	}, [itemData]);

	return (
		<Grid templateColumns="repeat(2, 1fr)" gap={{base:"1em", md:"2em"}} gridRowGap="1rem">
			{Object.keys(itemDetails)?.map((property: any, index: number) => {
				if (itemDetails[property]) {
					return (
						<GridItem
							key={index}
							colSpan={["Description"].includes(fieldTtitle(property)) ? 2 : 1}
						>
							<Box>
								<Text fontSize={{base: "14px", lg: "16px"}} fontWeight={{base: "bold", lg: "regular"}} color={{base: "#4BA18A", md: "#000" }}>{fieldTtitle(property)}</Text>
								<Box
									border={{base: "none", md:"1px solid #BABCBE"}}
									bg={{base: "none", md:"#F3F6F9"}}
									borderRadius="6px"
									padding={{base:"0", md:".5rem"}}
									fontSize={{base: "14px", lg: "16px"}}
								>
									{itemDetails[property]}
								</Box>
							</Box>
						</GridItem>
					);
				}
			})}
			{Object.keys(customPropertiesArray)?.map(
				(property: any, index: number) => (
					<GridItem key={index} colSpan={1}>
						<Box>
							<Text fontSize={{base: "14px", lg: "16px"}} fontWeight={{base: "bold", lg: "regular"}} color={{base: "#4BA18A", md: "#000" }}>{property}</Text>
							<Box
								border={{base: "none", md:"1px solid #BABCBE"}}
								bg={{base: "none", md:"#F3F6F9"}}
								borderRadius="6px"
								padding={{base:"0", md:".5rem"}}
								fontSize={{base: "14px", lg: "16px"}}
							>
								{customPropertiesArray[property]}
							</Box>
						</Box>
					</GridItem>
				)
			)}
		</Grid>
	);
};

export default ItemDataDisplay;
