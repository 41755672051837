import React from "react";
import {
	Table,
	Tbody,
	Tr,
	Td,
	TableContainer,
	Text,
	Box,
} from "@chakra-ui/react";

interface TotalAndQtyProps {
	totalQty: number;
	totalAmount: number;
}

const TotalAndQty = ({ totalQty, totalAmount }: TotalAndQtyProps) => {
	return (
		<Box width="100%">
			<TableContainer
				border="1px solid #4BA18A"
				borderRadius=".375rem"
				boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
			>
				<Table>
					<Tbody>
						<Tr>
							<Td
								borderBlockEnd="1px solid #4BA18A"
								borderRight="1px solid #4BA18A"
								width="50%"
							>
								<Text
									color="#4BA18A"
									textStyle="primary.bold"
									fontSize="1.125rem"
								>
									Total Qty
								</Text>
							</Td>
							<Td
								borderBlockEnd="1px solid #4BA18A"
								textStyle="primary.bold"
								fontSize="1rem"
							>
								{totalQty}
							</Td>
						</Tr>
						<Tr>
							<Td borderRight="1px solid #4BA18A">
								<Text
									color="#4BA18A"
									textStyle="primary.bold"
									fontSize="1.125rem"
								>
									Grand Total
								</Text>
							</Td>
							<Td textStyle="primary.bold" fontSize="1rem">
								{`RS. ${totalAmount}/-`}
							</Td>
						</Tr>
					</Tbody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default TotalAndQty;
