import React, { useEffect, useState, lazy } from "react";
import {
	Box,
	Text,
	Flex,
	Textarea,
	Show,
	Center,
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
} from "@chakra-ui/react";

import { BottomSheet, Button, Overlay, get_doc } from "@karkhanaui/react";
import { BsDownload, BsPaperclip } from "react-icons/bs";
import AcceptRfq from "../../img/AcceptRfq.png"; // eslint-disable-line
import { RfqPopupCard } from "../rfq/RfqPopupCard";
import OrderStatusBar from "./OrderStatusBar";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	type AppDispatch,
	setPageLoading,
	SET_MESSAGE,
	ERROR_MSG,
	formatErrorMessage,
	getSingleSalesOrder,
	supplierUpdateOpportunityService,
	updateSalesOrder,
	submitDoc,
	normalDate,
	BasicDialog,
	postChat,
	getItemAttachedFilesService,
	frappeMethodService,
} from "@karkhanaui/react";
import { Mixpanel } from "../../Mixpanel.js";
import { SUCCESS_MSG } from "../../store/actions/types";
const ViewRfq = lazy(() => import("../rfq/ViewRfq"));
const saveAs = require("file-saver").saveAs;
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Worker } from "@react-pdf-viewer/core";
import * as PDFJS from "pdfjs-dist";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { ViewRFQCard } from "../rfq/RfqMainCard";
import { PurchaseOrder, ViewOrdersCard } from "./OrdersMainCard";
import { message } from "../../store/reducers/messages";
import PartsAndAttachments from "./PartsAndAttachments";

const OrdersPoReceived: React.FC<any> = () => {
	const { rfq_name } = useParams();
	const dispatch = useDispatch<AppDispatch>();
	const [isPopupOpen, setIsPopupOpen] = React.useState(false);
	const [isfeedbackPopupOpen, setIsfeedbackPopupOpen] = React.useState(false);
	const [viewRfq, setViewRfq] = React.useState<boolean>(false);
	const [salesOrder, setSalesOrder] = React.useState<any>({});
	const [supplierFeedback, setSupplierFeedback] = React.useState<string>("");
	const [authState, supplier] = useSelector((state: any) => {
		return [state.authSupplier, state.getUser];
	});
	const [salesOrderPdf, setSalesOrderPdf] = React.useState<string>("");
	const navigate = useNavigate();
	const defaultLayoutPluginInstance = defaultLayoutPlugin();
	const [directPOFromProcurement, setDirectPOFromProcurement] =
		React.useState<boolean>(false);
	const [viewPartsAndAttachments, setViewPartsAndAttachments] =
		React.useState<boolean>(false);
	const [feedbackSent, setFeedbackSent] = React.useState<boolean>(false);
	const [relatedOrders, setRelatedOrders] = React.useState<any>([]);
	const [activeTab, setActiveTab] = useState(0);

	const handleOpenFeedbackPopup = () => {
		setIsfeedbackPopupOpen(true);
	};

	const handleCloseFeedbackPopup = () => {
		setIsfeedbackPopupOpen(false);
	};
	const handleAcceptRFQPopup = () => {
		setIsPopupOpen(true);
	};

	const handleCloseRFQPopup = () => {
		setIsPopupOpen(false);
	};

	const sendFeedback = async () => {
		if (supplierFeedback.trim().length === 0) {
			return;
		}
		dispatch(
			setPageLoading({ loading: true, loadingtext: "Sending feedback" })
		);
		let paramData = {
			feedback: supplierFeedback.trim(),
			customer: salesOrder?.customer || salesOrder?.company_name,
			// rfq: salesOrder.rfq_name,
			rfq: salesOrder?.opportunity,
			opportunity: salesOrder?.opportunity,
			type: "Supplier",
			sales_order: salesOrder.name,
		};
		try {
			let res: any = await postChat(paramData);
			if (res.status === 200) {
				setIsfeedbackPopupOpen(false);
				setFeedbackSent(true);
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: SUCCESS_MSG,
						data: "Feedback Submitted",
					},
				});
				window.dataLayer?.push({
					event: "PO Action",
					po_id: rfq_name,
					user_email: supplier?.customer?.email,
					user_id: authState?.uniqueUserId,
					po_status: "PO Accepted",
					status: "feedback sent",
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name: salesOrder?.company_name,
					po_amount: salesOrder?.grand_total,
					total_parts: salesOrder?.items?.length,
				});
				Mixpanel.track("po_Action", {
					po_id: rfq_name,
					user_email: supplier?.customer?.email,
					user_id: authState?.uniqueUserId,
					po_status: "PO Accepted",
					status: "feedback sent",
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name: salesOrder?.company_name,
					po_amount: salesOrder?.grand_total,
					total_parts: salesOrder?.items?.length,
				});
				dispatch(setPageLoading({ loading: false, loadingText: "" }));
			}
		} catch (error) {
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: "Failed to send feedback. " + formatErrorMessage(error),
				},
			});
			dispatch(setPageLoading({ loading: false, loadingText: "" }));
		}
	};

	const acceptPO = async () => {
		// api to accept PO
		dispatch(setPageLoading({ loading: true, loadingText: "Loading..." }));
		const data = { accepted: true };
		try {
			setIsPopupOpen(false);
			setButtonLoading(true);
			const res: any = await updateSalesOrder(rfq_name, data);
			const params = {
				doctype: "Sales Order",
				docname: rfq_name,
			};
			try {
				const submitRes: any = await submitDoc(params);
				// no opportunity available for direct po from procurement so no need to update the opportunity status
				if (!directPOFromProcurement) {
					supplierUpdateOpportunityService(salesOrder?.opportunity, {
						portal_status: "Confirmed",
						card_status: "PO_ACCEPTED",
					});
				}

				dispatch(setPageLoading({ loading: false, loadingText: "" }));
				window.dataLayer?.push({
					event: "PO Action",
					po_id: rfq_name,
					user_email: supplier?.customer?.email,
					user_id: authState?.uniqueUserId,
					po_status: "PO Accepted",
					status: "accepted",
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name: salesOrder?.company_name,
					po_amount: salesOrder?.grand_total,
					total_parts: salesOrder?.items?.length,
				});
				Mixpanel.track("po_Action", {
					po_id: rfq_name,
					user_email: supplier?.customer?.email,
					user_id: authState?.uniqueUserId,
					po_status: "PO Accepted",
					status: "accepted",
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name: salesOrder?.company_name,
					po_amount: salesOrder?.grand_total,
					total_parts: salesOrder?.items?.length,
				});
				navigate(`/orderslive/${rfq_name}`);
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: SUCCESS_MSG,
						data: "PO Accepted",
					},
				});
			} catch (e) {
				const message = formatErrorMessage(e);
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: message,
					},
				});
				dispatch(setPageLoading({ loading: false, loadingText: "" }));
			}
		} catch (error) {
			const message =
				"There was an error in updating the order. " +
				formatErrorMessage(error);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: message,
				},
			});
			dispatch(setPageLoading({ loading: false, loadingText: "" }));
		}
		setIsPopupOpen(false);
	};

	const fetchSOPDF = async (SalesOrderID: string) => {
		const res: any = await getItemAttachedFilesService({
			filters: JSON.stringify([
				["attached_to_doctype", "=", "Sales Order"],
				["attached_to_name", "=", SalesOrderID],
			]),
			fields: JSON.stringify(["*"]),
			limit: 0,
		});
		if (res.status === 200) {
			return process.env.REACT_APP_LIVE_URL + res.data.data[0].file_url;
		}
	};
	const getOrderDetails = async () => {
		dispatch(setPageLoading({ loading: true, loadingText: "Loading..." }));
		try {
			let soParams = {
				filters: JSON.stringify([
					["attached_to_doctype", "=", "Sales Order"],
					["attached_to_name", "=", rfq_name],
				]),
				fields: JSON.stringify(["*"]),
				limit: 0,
			};
			const res: any = await getSingleSalesOrder(rfq_name);
			const attachedFiles: any = await getItemAttachedFilesService(soParams);
			const feedbackStatus = await frappeMethodService(
				"supplierv2.api.sales_order.check_feedback",
				{ po_name: rfq_name }
			);

			Promise.all([res, attachedFiles, feedbackStatus]).then(async (result) => {
				if (result[0].data.data?.originated_from === "erp") {
					const res: any = await getItemAttachedFilesService({
						filters: JSON.stringify([
							["attached_to_doctype", "=", "Sales Order"],
							["attached_to_name", "=", result[0].data.data?.name],
						]),
						fields: JSON.stringify(["file_name", "file_url"]),
						limit: 0,
					});
					if (res.status === 200) {
						let _allRelatedOrders = res.data?.data;
						await _allRelatedOrders?.map((item: any, index: number) => {
							_allRelatedOrders[index].name = item?.file_name.split(".")[0];
							_allRelatedOrders[index].portal_status =
								result[0].data.data?.portal_status;
							_allRelatedOrders[index].salesOrder_pdf =
								process.env.REACT_APP_LIVE_URL + item.file_url;
						});

						_allRelatedOrders?.sort((a: any, b: any) => {
							if (a.file_name > b.file_name) {
								return -1;
							}
							if (a.file_name < b.file_name) {
								return 1;
							}
							return 0;
						});
						setRelatedOrders(_allRelatedOrders);
						setSalesOrderPdf(_allRelatedOrders[0]?.salesOrder_pdf);
					}
				} else if (result[0].data.data?.opportunity?.length > 0) {
					const allRelatedOrders = await get_doc({
						doc: "Sales Order",
						fields: ["name", "portal_status"],
						limit: 0,
						filters: [["opportunity", "=", result[0].data.data.opportunity]],
					});
					if (allRelatedOrders.status === 200) {
						let _allRelatedOrders = allRelatedOrders.data.data;
						await allRelatedOrders.data.data.map(
							async (item: any, index: any) => {
								_allRelatedOrders[index].salesOrder_pdf = await fetchSOPDF(
									item.name
								);
							}
						);
						if (_allRelatedOrders?.length > 1) {
							let itemIndex = _allRelatedOrders.findIndex(
								(item: any) => item.name === rfq_name
							);
							if (itemIndex !== -1) {
								let [item] = _allRelatedOrders.splice(itemIndex, 1);
								_allRelatedOrders?.unshift(item);
							}
						}
						setRelatedOrders(_allRelatedOrders);
					}
					setSalesOrderPdf(
						process.env.REACT_APP_LIVE_URL + result[1].data.data[0].file_url
					);
				}
				if (result[2].data.message?.data?.feedback_Send === 1) {
					setFeedbackSent(true);
					setSupplierFeedback(
						result[2].data.message.data.feedback_jason?.feedback
					);
				}

				setSalesOrder(result[0].data.data);
				if (
					result[0].data.data?.originated_from === "procurement" &&
					result[0].data.data.direct_po == 1
				) {
					setDirectPOFromProcurement(true);
				}
				dispatch(setPageLoading({ loading: false, loadingText: "" }));
			});
		} catch (error) {
			dispatch(setPageLoading({ loading: false, loadingText: "" }));
			const message =
				"There was an error in fetching order details. " +
				formatErrorMessage(error);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: message,
				},
			});
		}
	};
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	useEffect(() => {
		getOrderDetails();
	}, []);

	const getDataInsideParentheses = (str: string) => {
		var regex = /\(([^)]+)\)/;
		var match = regex.exec(str);
		return match ? match[1] : null; // returns null if no match is found
	};

	const downloadSalesOrder = async () => {
		// const response = await fetch(
		// 	`/api/method/frappe.utils.print_format.download_pdf?doctype=Sales%20Order&name=${salesOrder?.name}&format=Standard&no_letterhead=1&letterhead=No%20Letterhead&settings=%7B%7D&_lang=en-US`
		// );
		const response = await fetch(salesOrderPdf);
		const blob = await response.blob();
		saveAs(blob, salesOrder?.name);
	};

	const KeyValue = ({ title, val }) => (
		<Box>
			<Text fontSize="16px" color="#4BA18A" fontWeight="semibold">
				{title}
			</Text>
			<Text fontSize="16px" color="black" fontWeight="medium" mt="10px">
				{val}
			</Text>
		</Box>
	);

	return (
		<Box w="100%">
			<OrderStatusBar
				poId={salesOrder?.name}
				status={salesOrder?.portal_status}
				displayOrderStatusLink={
					salesOrder?.portal_status == "Live" ? true : false
				}
			/>
			<Show above="md">
				<Flex
					m="1rem"
					p="1rem"
					boxShadow="md"
					borderRadius="md"
					flexWrap="wrap"
					alignItems="center"
					justifyContent="space-between"
					gap="2rem"
					bg="#fff"
				>
					<Flex gap="2em" flexWrap="wrap">
						{!directPOFromProcurement && (
							<KeyValue
								title={"RFQ Name"}
								val={salesOrder?.rfq_name || salesOrder?.name}
							/>
						)}
						{!directPOFromProcurement && (
							<KeyValue
								title={"RFQ ID"}
								val={salesOrder?.opportunity?.split("|")[1]}
							/>
						)}
						{directPOFromProcurement && (
							<KeyValue
								title={"Company Name"}
								val={salesOrder?.company_name || salesOrder?.customer_name}
							/>
						)}
						<KeyValue
							title={directPOFromProcurement ? "Buyer Name" : "Created By"}
							val={
								salesOrder?.requester ||
								getDataInsideParentheses(salesOrder?.customer)
							}
						/>
						{!directPOFromProcurement && (
							<KeyValue
								title={"RFQ Date"}
								val={normalDate(salesOrder?.transaction_date)}
							/>
						)}
						{!directPOFromProcurement && (
							<KeyValue title={"PO Ref Id"} val={salesOrder?.po_no} />
						)}
						{directPOFromProcurement && (
							<KeyValue
								title="Phone number"
								val={salesOrder?.customer_mobile_no}
							/>
						)}
						{directPOFromProcurement && (
							<KeyValue title="Email" val={salesOrder?.customer_email} />
						)}
					</Flex>
					{directPOFromProcurement ? (
						<Flex
							alignItems="center"
							gap=".25rem"
							cursor="pointer"
							onClick={() => setViewPartsAndAttachments(true)}
						>
							<BsPaperclip color="#4BA18A" size="18px" />
							<Text
								color="#4BA18A"
								fontWeight={600}
								fontSize="1rem"
								textDecoration="underline"
							>
								View Parts & Attachments
							</Text>
						</Flex>
					) : (
						<Flex alignItems="center" gap="2em" justifySelf="flex-end">
							<Box>
								<Text
									color="#4BA18A"
									fontWeight="bold"
									textDecoration="underline"
									onClick={() => setViewRfq(true)}
									cursor="pointer"
								>
									View RFQ
								</Text>
							</Box>

							<Flex
								alignItems="center"
								gap="2px"
								cursor="pointer"
								onClick={() => downloadSalesOrder()}
							>
								<BsDownload size="20px" color="#4BA18A" />
								<Text color="#4BA18A" fontWeight="bold">
									Download PO
								</Text>
							</Flex>
						</Flex>
					)}
				</Flex>

				<Box
					boxShadow={"md"}
					display={"flex"}
					alignItems="center"
					justifyContent={"space-between"}
					borderRadius="md"
					p=".5rem 2rem"
					m=".75rem 1rem 1rem"
					bg="#fff"
				>
					<Text fontSize={"30px"} color="#4BA18A" fontWeight={"bold"}>
						Purchase Order
					</Text>
					{salesOrder?.portal_status === "PO Received" && (
						<Flex textAlign={"end"} gap="1em">
							<Button
								borderRadius={"5px"}
								colorScheme={"teal"}
								onClick={handleAcceptRFQPopup}
								size="md"
								bg="#4BA18A"
								color="white"
								isLoading={buttonLoading}
								_hover={{ bg: "#38846D" }}
							>
								Accept PO
							</Button>
							<Button
								size="md"
								borderRadius={"5px"}
								bg="#E2F1EE"
								color="#4BA18A"
								colorScheme={"teal"}
								variant="outline"
								onClick={handleOpenFeedbackPopup}
								_hover={{ bg: "#B7DDD4" }}
							>
								{feedbackSent ? "Feedback Sent" : "Send Feedback"}
							</Button>
						</Flex>
					)}
				</Box>
				{relatedOrders?.length > 1 ? (
					<Tabs marginX="1rem">
						<TabList
							bg="#fff"
							pt=".25rem"
							borderRadius=".75rem .75rem 0 0"
							width="100%"
							// overflowX="scroll"
						>
							{relatedOrders.map((order: any, index: number) => (
								<Tab
									key={index}
									color="#4BA18A"
									fontWeight="bold"
									_active={{
										bg: "#E2F1EE",
									}}
									onClick={() => setActiveTab(index)}
									bg={activeTab === index ? "#E2F1EE" : ""}
									borderRadius=".75rem .75rem 0 0"
								>
									{order.name}
								</Tab>
							))}
						</TabList>
						<TabPanels>
							{relatedOrders?.map((order: any, index: number) => (
								<TabPanel p={0} m={0} key={index}>
									<Flex
										alignItems="center"
										justifyContent="center"
										py=".6875rem"
										bg="#E2F1EE"
									>
										<Text fontSize="18px">
											{order.portal_status === "Cancelled" || index !== 0
												? "This PO was cancelled"
												: "This PO is revised"}
										</Text>
									</Flex>
									<Box height="57vh" overflowY="auto">
										{order?.salesOrder_pdf?.length > 0 || salesOrderPdf ? (
											<Worker
												workerUrl={`https://unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`}
											>
												<Viewer
													fileUrl={order?.salesOrder_pdf || salesOrderPdf}
													plugins={[defaultLayoutPluginInstance]}
												/>
											</Worker>
										) : (
											<></>
										)}
									</Box>
								</TabPanel>
							))}
						</TabPanels>
					</Tabs>
				) : (
					<Box
						height="57vh"
						overflowY="auto"
						m="1.5rem 1rem 1rem"
						borderRadius="0.75rem"
					>
						{salesOrderPdf && (
							<Worker
								workerUrl={`https://unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`}
							>
								<Viewer
									fileUrl={salesOrderPdf}
									plugins={[defaultLayoutPluginInstance]}
								/>
							</Worker>
						)}
					</Box>
				)}

				<Show above="md">
					<RfqPopupCard
						isOpen={isPopupOpen}
						onClose={handleCloseRFQPopup}
						title="Accept PO"
						message={`Are you sure you want to accept the PO with PO ID: ${salesOrder?.name} from karkhana.io`}
						onCancel={handleCloseRFQPopup}
						onAccept={acceptPO}
						imageURL={AcceptRfq}
						BCancel={"Cancel"}
						BAccept={"Accept"}
					/>
				</Show>
			</Show>
			<Show below="md">
				<Box m="0.5rem" gap="1rem">
					<ViewOrdersCard
						isDownload={true}
						companyName={salesOrder?.company_name || salesOrder?.customer_name}
						rfqName={salesOrder?.rfq_name || salesOrder?.name}
						rfqId={salesOrder?.opportunity?.split("|")[1]}
						receivedOn={normalDate(salesOrder?.transaction_date)}
						poId={salesOrder?.po_no}
						downloadSalesOrder={downloadSalesOrder}
						setViewRfq={setViewRfq}
						createdBy={
							salesOrder?.requester ||
							getDataInsideParentheses(salesOrder?.customer)
						}
					/>
					<PurchaseOrder
						setIsfeedbackPopupOpen={setIsfeedbackPopupOpen}
						setIsPopupOpen={setIsPopupOpen}
						salesOrderPdf={salesOrderPdf}
						defaultLayoutPluginInstance={defaultLayoutPluginInstance}
						status={salesOrder?.portal_status}
					/>
				</Box>
			</Show>

			{/* Feedback popup card */}
			<Show above="md">
				<BasicDialog
					isOpen={isfeedbackPopupOpen}
					showCloseButton={true}
					onClose={() => setIsfeedbackPopupOpen(false)}
					header="Send Feedback"
					size="lg"
					content={
						<Box m=".5rem .5rem 2rem .5rem">
							{feedbackSent ? (
								<Text>
									Feedback for Purchase Order (ID:&nbsp;{salesOrder?.name})
								</Text>
							) : (
								<Text>
									Send a Feedback on Purchase Order (ID:&nbsp;{salesOrder?.name}
									)
								</Text>
							)}
							<Textarea
								mt="1rem"
								placeholder="Enter here"
								value={supplierFeedback}
								onChange={(e) => setSupplierFeedback(e.target.value)}
								height="10rem"
								isDisabled={feedbackSent}
							/>
							{feedbackSent ? (
								<Text
									textStyle="primary.secondaryText"
									mt="1rem"
									fontSize="1rem"
								>
									Your feedback has been delivered to the buyer. You can await
									their response or reach out to them.
								</Text>
							) : (
								<Flex
									alignItems="center"
									justifyContent="flex-end"
									mt="1.75rem"
									gap="1.25rem"
								>
									<Button
										onClick={() => {
											setSupplierFeedback("");
											setIsfeedbackPopupOpen(false);
										}}
										border="1px solid #000"
										color="#000"
									>
										Cancel
									</Button>
									<Button
										color="#fff"
										bg="#4BA18A"
										onClick={() => sendFeedback()}
										_hover={{ bg: "#38846D" }}
									>
										Send
									</Button>
								</Flex>
							)}
						</Box>
					}
				/>

				{/* view RFQ popup */}
				<BasicDialog
					isOpen={viewRfq}
					showCloseButton={true}
					onClose={() => setViewRfq(false)}
					header="Request for Quotation"
					content={
						<Box height="80vh" overflow="scroll">
							<ViewRfq rfqId={salesOrder?.opportunity} />
						</Box>
					}
					size="6xl"
				/>
				<BasicDialog
					isOpen={viewPartsAndAttachments}
					showCloseButton={true}
					onClose={() => setViewPartsAndAttachments(false)}
					header="View Parts & Attachments"
					content={
						<Box height="80vh" overflow="scroll">
							<PartsAndAttachments
								salesOrder={salesOrder}
								getDataInsideParentheses={getDataInsideParentheses}
							/>
						</Box>
					}
					size="6xl"
				/>
			</Show>

			<Show below="md">
				<Overlay isOpen={isfeedbackPopupOpen}>
					<BottomSheet
						isOpen={isfeedbackPopupOpen}
						onClose={() => setIsfeedbackPopupOpen(false)}
					>
						<Box>
							<Text
								fontSize="18px"
								fontWeight="bold"
								color="#4BA18A"
								textAlign="center"
								mb="0.625rem"
							>
								Send Feedback
							</Text>
							<Box m=".5rem .5rem 2rem .5rem">
								<Text>
									Send a Feedback on Purchase Order (ID:&nbsp;{salesOrder?.name}
									)
								</Text>
								<Textarea
									mt="1rem"
									placeholder="Enter here"
									onChange={(e) => setSupplierFeedback(e.target.value)}
									height="10rem"
								/>
								<Flex
									alignItems="center"
									justifyContent="flex-end"
									mt="1.75rem"
									gap="1.25rem"
									width={{ base: "100%", md: "auto" }}
								>
									<Button
										onClick={() => {
											setSupplierFeedback("");
											setIsfeedbackPopupOpen(false);
										}}
										border="1px solid #000"
										color="#000"
										flexGrow={{ base: 1, md: 0 }}
									>
										Cancel
									</Button>
									<Button
										color="#fff"
										bg="#4BA18A"
										onClick={() => sendFeedback()}
										_hover={{ bg: "#38846D" }}
										flexGrow={{ base: 1, md: 0 }}
									>
										Send
									</Button>
								</Flex>
							</Box>
						</Box>
					</BottomSheet>
				</Overlay>

				<Overlay isOpen={viewRfq}>
					<BottomSheet
						isOpen={viewRfq}
						// showCloseButton={true}
						onClose={() => setViewRfq(false)}
						padding={0}
					>
						<Text
							textAlign="center"
							fontSize="18px"
							color="#4ba18a"
							fontWeight="bold"
							my="1rem"
						>
							Request For Quotation
						</Text>
						<Box>
							<ViewRfq rfqId={salesOrder?.opportunity} />
						</Box>
					</BottomSheet>
				</Overlay>

				<Overlay isOpen={isPopupOpen}>
					<BottomSheet isOpen={isPopupOpen} onClose={handleCloseRFQPopup}>
						<>
							<Text
								fontSize="18px"
								fontWeight="bold"
								color="#4BA18A"
								textAlign="center"
								mb="0.625rem"
							>
								Accept PO
							</Text>
							<Center>
								<Box
									backgroundColor="white"
									padding={{ base: "1rem", md: "2em" }}
									borderRadius="8px"
									boxShadow={{
										base: "none",
										md: "0px 4px 10px rgba(0, 0, 0, 0.1)",
									}}
									maxWidth="600px"
									width="100%"
								>
									<Center w="75%" mx="auto">
										<img src={AcceptRfq} alt="Modal Image" />
									</Center>
									<Center>
										<Text mt="4" textAlign="center" fontSize="18px">
											{`Are you sure you want to accept the PO with PO ID: ${salesOrder?.name} from karkhana.io`}
										</Text>
									</Center>
									<Flex
										justifyContent="flex-end"
										marginTop="1em"
										width={{ base: "100%", md: "auto" }}
									>
										<Button
											onClick={handleCloseRFQPopup}
											marginRight="1em"
											variant={"ghost"}
											flexGrow={{ base: 1, md: 0 }}
										>
											Cancel
										</Button>
										{"Accept" ? (
											<Button
												onClick={acceptPO}
												backgroundColor="#4BA18A"
												_hover={{ backgroundColor: "#38846D" }}
												color="#fff"
												flexGrow={{ base: 1, md: 0 }}
											>
												Accept
											</Button>
										) : (
											""
										)}
									</Flex>
								</Box>
							</Center>
						</>
					</BottomSheet>
				</Overlay>
			</Show>
		</Box>
	);
};

export default OrdersPoReceived;
