import React, { lazy } from "react";
import CreateQuote from "../pages/quotes/CreateQuote";
import ViewRfq from "../pages/rfq/ViewRfq";
import ResetPwd from "../pages/auth/ResetPwd";
import QuoteManagement from "../pages/quotes/QuoteManagement";
import InstructionsCard from "../pages/auth/InstructionsCard";
import ForgotPassword from "../pages/auth/ForgotPassword";
import CustomerManagement from "../pages/customer/CustomerManagement";
import CustomerDetails from "../pages/customer/CustomerDetails";
import OrdersUpdateBar from "../pages/orders/OrdersUpdateBar";
import OrdersLive from "../pages/orders/OrdersLive";
import OrdersPoReceived from "../pages/orders/OrdersPoReceived";
import OrdersPoView from "../pages/orders/OrdersPoView";
const Login = lazy(async () => await import("../pages/auth/Login"));
const Register = lazy(async () => await import("../pages/auth/Register"));
const Home = lazy(async () => await import("../pages/home/Home"));
const RfqMainTable = lazy(
	async () => await import("../pages/rfq/RfqMainTable")
);
const Quotes = lazy(async () => await import("../pages/quotes/Quotes"));
const Orders = lazy(async () => await import("../pages/orders/Orders"));
const DigitalFactoryHome = lazy(
	async () => await import("../pages/digitalFactory/DigitalFactoryHome")
);
const VerifyEmail = lazy(async () => await import("../pages/auth/VerifyEmail"));

const RoutesList: Array<{
	label: string;
	link: string;
	type: "public" | "private";
	component: React.FC;
}> = [
	// access pages
	{
		label: "Login",
		link: "/login/:uuid?",
		type: "public",
		component: Login,
	},
	{
		label: "Register",
		link: "/register/:uuid?",
		type: "public",
		component: Register,
	},
	{
		label: "OTPVerification",
		link: "/verifyEmail/:otpuuid?/:uuid?",
		type: "public",
		component: VerifyEmail,
	},
	{
		label: "ResetPwd",
		link: "/resetpwd/:changePasswordKey/:uuid?",
		type: "public",
		component: ResetPwd,
	},

	{
		label: "ForgotPassword",
		link: "/forgotPassword/:uuid?",
		type: "public",
		component: ForgotPassword,
	},

	// Home
	{
		label: "Home",
		link: "/home",
		type: "private",
		component: Home,
	},
	{
		label: "Rfq",
		link: "/rfqmain/:company_name?/:tab?",
		type: "private",
		component: RfqMainTable,
	},
	{
		label: "Quotes",
		link: "/quotes",
		type: "private",
		component: Quotes,
	},
	{
		label: "Quotes",
		link: "/quotes/:rfqId",
		type: "private",
		component: Quotes,
	},
	{
		label: "Create Quote",
		link: "/createquote/:quoteId",
		type: "private",
		component: CreateQuote,
	},
	{
		label: "ViewRfq",
		link: "/viewrfq/:rfqId",
		type: "private",
		component: ViewRfq,
	},
	{
		label: "QuoteManagement",
		link: "/quotemanagement/:quoteId",
		type: "private",
		component: QuoteManagement,
	},
	{
		label: "Orders",
		link: "/orders/:company_name?/:tab?",
		type: "private",
		component: Orders,
	},
	{
		label: "OrdersPoReceived",
		link: "/ordersporeceived/:rfq_name",
		type: "private",
		component: OrdersPoReceived,
	},
	{
		label: "OrdersPoReceived",
		link: "/orderspoview/:rfq_name",
		type: "private",
		component: OrdersPoView,
	},
	{
		label: "OrdersLive",
		link: "/orderslive/:rfq_name",
		type: "private",
		component: OrdersLive,
	},
	{
		label: "OrdersCompleted",
		link: "/orderscompleted/:rfq_name",
		type: "private",
		component: OrdersLive,
	},
	{
		label: "OrdersUpdateBar",
		link: "/Ordersupdatebar",
		type: "private",
		component: OrdersUpdateBar,
	},
	{
		label: "CustomerManagement",
		link: "/customer",
		type: "private",
		component: CustomerManagement,
	},
	{
		label: "CustomerProfile",
		link: "/customer/:companyId",
		type: "private",
		component: CustomerDetails,
	},
	{
		label: "DigitalFactory",
		link: "/digitalFactory/:step?/:tab?",
		type: "private",
		component: DigitalFactoryHome,
	},

	// {
	// 	label: "ResetPassword",
	// 	link: "/resetPassword/:changePasswordKey",
	// 	type: "public",
	// 	component: ResetPassword,
	// },
];

export default RoutesList;
