import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Text,
	Flex,
	chakra,
	Grid,
	GridItem,
	Tag,
	Tooltip,
	Image,
	Show,
	Circle,
	Divider,
	Accordion,
	AccordionItem,
	AccordionPanel,
	AccordionButton,
	AccordionIcon,
} from "@chakra-ui/react";
import { Mixpanel } from "../../Mixpanel.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
	GenericTables,
	AppDispatch,
	formatErrorMessage,
	SET_MESSAGE,
	ERROR_MSG,
	setPageLoading,
	supplierGetSingleQuotationService,
	normalDate,
	getItemAttachedFilesService,
	supplierGetSingleOpportunityService,
	BasicDialog,
	Thumbnail,
	supplierCreateQuotationService,
	cancelDoc,
	getSupplierCapability,
	Overlay,
	BottomSheet,
} from "@karkhanaui/react";
import {
	BsCalendarPlus,
	BsCalendar3,
	BsDownload,
	BsArrowRightCircle,
} from "react-icons/bs";
import Cookies from "js-cookie";
import ViewPartForm from "./ViewPartForm";
import ViewRfq from "../rfq/ViewRfq";
import CancelQuoteImage from "../../img/CancelQuote.png";
import { RFQPartsCard } from "../rfq/RfqMainCard";
const saveAs = require("file-saver").saveAs;
import TotalAndQty from "./TotalAndQty";

const QuoteManagement: React.FC<any> = () => {
	const params = useParams();
	const quoteId: any = params.quoteId;
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const loggedInSuppplierName = String(Cookies.get("full_name"));
	const [isPartOpenMob, setIsPartOpenMob] = useState(false);
	const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);
	const [isPartOpen, setIsPartOpen] = useState(false);
	const [quoteData, setQuoteData] = useState<any>();
	const [tagProps, setTagProps] = React.useState<any>({
		bg: "gray.100",
		color: "gray.400",
	});
	const [itemsData, setItemsData] = React.useState<any>([]);
	const [selectedItem, setSelectedItem] = React.useState<any>();
	const [opportunityItems, setOpportunityItems] = React.useState<any>([]);
	const [files, setFiles] = React.useState<any>([]);
	const [viewRfq, setViewRfq] = React.useState<boolean>(false);
	const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
	const [cancelQuoteConfirm, setCancelQuoteConfirm] =
		React.useState<boolean>(false);
	const [showRejectionDetails, setShowRejectionDetails] =
		React.useState<boolean>(false);
	const [supplierData, setSupplierData] = React.useState<any>();
	const [authState, supplier] = useSelector((state: any) => {
		return [state.authSupplier, state.getUser];
	});

	const name = "Lorem ipsum dolor sit amet, consectetur adipiscing elit";
	const column = React.useMemo(
		() => [
			{
				Header: "Part Name",
				accessor: "item_name",
				Cell: (i: any) => (
					<Flex>
						<Tooltip label={i.row.original.item_name}>
							<Text
								color="#4BA18A"
								textStyle="primary.link"
								onClick={() => {
									getOpportunityItemDetails(i.row.original.item_code);
									setIsPartOpen(true);
								}}
								cursor="pointer"
							>
								{i.row.original.item_name.slice(0, 20) || "-"}
								{i.row.original.item_name.length > 20 ? "..." : ""}
							</Text>
						</Tooltip>
					</Flex>
				),
			},
			{
				Header: "Category",
				accessor:
					quoteData?.originated_from === "procurement"
						? "item_category"
						: "item_type",
				Cell: (i: any) => (
					<Text>
						{(quoteData?.originated_from === "procurement"
							? i.row.original?.item_category
							: i.row.original?.item_type) || "-"}
					</Text>
				),
				disableSortBy: true,
			},
			{
				Header: "Quantity",
				accessor: "qty",
				Cell: (i: any) => (
					<Text>{i.row.original?.qty >= 0 ? i.row.original?.qty : "-"}</Text>
				),
				disableSortBy: true,
			},
			{
				Header: "UOM",
				accessor: "uom",
				Cell: (i: any) => <Text>{i.row.original?.uom || "-"}</Text>,
				disableSortBy: true,
			},
			{
				Header: "Rate",
				accessor: "rate",
				Cell: (i: any) => (
					<Text>{i.row.original?.rate >= 0 ? i.row.original?.rate : "-"}</Text>
				),
				disableSortBy: true,
			},
			{
				Header: "Amount",
				accessor: "amount",
				Cell: (i: any) => (
					<Text>
						{i.row.original?.amount >= 0 ? i.row.original?.amount : "-"}
					</Text>
				),
				disableSortBy: true,
			},
			{
				Header: "",
				accessor: "view_part",
				Cell: (i: any) => (
					<Text
						color="#4BA18A"
						textStyle="primary.link"
						onClick={() => {
							getOpportunityItemDetails(i.row.original.item_code);
							setIsPartOpen(true);
						}}
						cursor="pointer"
					>
						View Part
					</Text>
				),
				disableSortBy: true,
			},
		],

		[opportunityItems]
	);

	const getDateDiff = (expiryDate: any) => {
		const currentDate: any = new Date();
		const date2: any = new Date(expiryDate);
		const diffTime = date2 - currentDate;
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays;
	};

	const getOpportunityItemDetails = async (itemCode: string) => {
		let _selectedItem = await opportunityItems?.filter(
			(item: any) => item.item_code === itemCode
		)[0];
		setSelectedItem(_selectedItem);
	};

	const getStatusColor = (status: string) => {
		switch (status) {
			case "Active":
			case "Open":
				setTagProps({ bg: "#E2F1EE", color: "#4BA18A" });
				break;
			case "Expired":
				setTagProps({ bg: "rgba(253, 185, 19, 0.2)", color: "#EBA700" });
				break;
			case "Cancelled":
				setTagProps({
					bg: "rgba(0, 0, 0, 0.05)",
					color: "#rgba(0, 0, 0, 0.5)",
				});
				break;
			case "Draft":
				setTagProps({ bg: "rgba(0, 135, 238, 0.1)", color: "#0087EE" });
				break;
			case "Rejected":
				setTagProps({ bg: "rgba(239, 111, 108, 0.2)", color: "#EF6F6C" });
				break;
			default:
				setTagProps({ bg: "gray.100", color: "gray.400" });
		}
	};

	const fetchData = async () => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Loading...",
			})
		);
		let res: any = await supplierGetSingleQuotationService(quoteId);
		let attachedFilesRes: any = await getItemAttachedFilesService({
			filters: JSON.stringify([
				["attached_to_doctype", "=", "Quotation"],
				["attached_to_name", "in", quoteId],
			]),
			fields: JSON.stringify(["*"]),
			limit: 0,
		});
		let supplierDetails: any = await getSupplierCapability(
			String(Cookies.get("user_id"))
		);
		Promise.all([res, attachedFilesRes, supplierDetails])
			.then(async (response) => {
				if (response[0].data.data?.status === "Draft") {
					navigate(`/createquote/${quoteId}`);
				}
				setQuoteData(response[0].data.data);
				getStatusColor(response[0].data.data?.status);
				setItemsData([...response[0].data.data?.items]);
				setSupplierData(response[2].data.data);

				// fetch opportunity items to display part details
				try {
					let opp: any = await supplierGetSingleOpportunityService(
						response[0].data.data?.opportunity
					);
					if (opp.status === 200) {
						setOpportunityItems(opp.data.data?.items);
					}
				} catch (error) {
					dispatch(
						setPageLoading({
							loading: false,
							loadingText: "",
						})
					);
					dispatch({
						type: SET_MESSAGE,
						payload: {
							case: ERROR_MSG,
							data: formatErrorMessage(error),
						},
					});
				}

				// set files list
				let filesList: any = [];
				await response[1].data.data?.map((file: any) => {
					filesList.push({
						fileId: file.name,
						subtitle: file.file_name,
						file: process.env.REACT_APP_LIVE_URL + file.file_url,
						isPrivateFile: file.is_private,
						fileSize: (file.file_size / 1024).toFixed(2),
					});
				});
				setFiles(filesList);

				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
			})
			.catch((error) => {
				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: formatErrorMessage(error),
					},
				});
			});
	};

	useEffect(() => {
		fetchData();
	}, [quoteId]);

	const createNewQuote = async () => {
		setButtonLoading(true);
		const currentDate = Date.now();
		const dateObj = new Date(currentDate);

		let data = {
			quotation_to: "Customer",
			party_name: quoteData?.requester,
			rfq_expiry_date: quoteData?.rfq_expiry_date,
			customer_name: quoteData?.customer_name,
			transaction_date: dateObj.toISOString().split("T")[0],
			customer_address: quoteData?.customer_address,
			shipping_address_name: quoteData?.shipping_address_name,
			items: quoteData?.items,
			opportunity: quoteData?.opportunity,
			opportunity_uuid: quoteData?.opportunity_uuid,
			customer_rfq_id: quoteData?.customer_rfq_id,
			originated_from: quoteData?.originated_from,
			company_name: quoteData?.company_name,
			rfq_name: quoteData?.rfq_name,
			customer_billing_address: quoteData?.customer_billing_address,
			customer_shipping_address: quoteData?.customer_shipping_address,
			supplier_company_name: quoteData?.supplier_company_name,
			supplier_address: quoteData?.supplier_address,
			supplier_contact: quoteData?.supplier_contact,
			supplier_email: quoteData?.supplier_email,
			timeline: quoteData?.timeline,
			timeline_number: quoteData?.timeline_number,
			timeline_field: quoteData?.timeline_field,
			terms: quoteData?.terms,
			supplier_gst: quoteData?.supplier_gst,
			payment_terms: quoteData?.payment_terms,
			// inco_terms: quoteData?.inco_terms,
		};
		try {
			let res: any = await supplierCreateQuotationService(data);
			if (res.status === 200) {
				window.dataLayer?.push({
					event: "Quotation Created",
					page_name: window.location.pathname,
					quote_id: res.data.data.name,
					rfq_id: quoteData?.opportunity,
					user_email: supplier?.customer?.email,
					status: "draft",
					user_id: authState?.uniqueUserId,
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name:
						quoteData?.company_name || quoteData?.party_name,
					quote_amount: "Not calculated",
					total_parts: quoteData?.items?.length,
				});
				Mixpanel.track("quotationCreated", {
					page_name: window.location.pathname,
					quote_id: res.data.data.name,
					rfq_id: quoteData?.opportunity,
					user_email: supplier?.customer?.email,
					status: "draft",
					user_id: authState?.uniqueUserId,
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name:
						quoteData?.company_name || quoteData?.party_name,
					quote_amount: "Not calculated",
					total_parts: quoteData?.items?.length,
				});
				navigate(`/createquote/${res.data.data.name}`);
			}
		} catch (error) {
			setButtonLoading(false);
			const message =
				"Failed to create the quote. " + formatErrorMessage(error);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: message,
				},
			});
		}
	};

	const cancelQuote = async () => {
		setButtonLoading(true);

		let params = { doctype: "Quotation", docname: quoteId };
		try {
			let cancelRes: any = await cancelDoc(params);
			if (cancelRes.status === 200) {
				setButtonLoading(false);
				setCancelQuoteConfirm(false);
				fetchData();
				window.dataLayer?.push({
					event: "Quotation Cancelled",
					page_name: window.location.pathname,
					quote_id: quoteId,
					rfq_id: quoteData?.opportunity,
					user_email: supplier?.customer?.email,
					status: "Cancelled",
					user_id: authState?.uniqueUserId,
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name:
						quoteData?.company_name || quoteData?.party_name,
					quote_amount: quoteData?.base_grand_total,
					total_parts: quoteData?.items?.length,
				});
				Mixpanel.track("quotationCancelled", {
					page_name: window.location.pathname,
					quote_id: quoteId,
					rfq_id: quoteData?.opportunity,
					user_email: supplier?.customer?.email,
					status: "Cancelled",
					user_id: authState?.uniqueUserId,
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name:
						quoteData?.company_name || quoteData?.party_name,
					quote_amount: quoteData?.base_grand_total,
					total_parts: quoteData?.items?.length,
				});
			}
		} catch (error) {
			setButtonLoading(false);
			const message =
				"Failed to Cancel the Quote. " + formatErrorMessage(error);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: message,
				},
			});
		}
	};

	return (
		<Box w="100%">
			{/* quote id,status and date */}
			<Flex
				alignItems="center"
				justifyContent="space-between"
				bg="#fff"
				p="1rem"
				mt="1px"
			>
				<Flex
					alignItems="center"
					w={{ base: "100%", md: "auto" }}
					justifyContent={{ base: "space-between", md: "left" }}
					gap="3rem"
				>
					<Text>
						<chakra.span textStyle="primary.bold">Quote ID:&nbsp;</chakra.span>
						{quoteData?.name}
					</Text>
					<Flex alignItems="center" gap=".5rem">
						<Show above="md">
							<Text textStyle="primary.bold">Status:</Text>
						</Show>
						<Tag bg={tagProps?.bg} color={tagProps?.color}>
							{quoteData?.status === "Open" ? "Active" : quoteData?.status}
						</Tag>
					</Flex>
				</Flex>

				<Show above="md">
					<Text>
						<chakra.span textStyle="primary.bold">
							Quote Date:&nbsp;
						</chakra.span>
						{normalDate(quoteData?.creation)}
					</Text>
				</Show>
			</Flex>

			<Flex
				justifyContent={"space-between"}
				mx="1rem"
				mt="1rem"
				bg="#fff"
				borderRadius="12px 12px 0px 0px"
				alignItems="center"
				px={{ base: "1rem", md: "1.5rem" }}
			>
				<Text
					color={"#4BA18A"}
					textStyle="primary.large"
					fontSize={{ base: "22", md: "28" }}
					fontWeight="bold"
					alignContent={"center"}
					my={{ base: "0.625rem", md: "0" }}
				>
					Quotation
				</Text>
				<Show above="md">
					<Flex textAlign={"end"} gap="1em" m="1em 0 1em 1em">
						<Button
							bg="#4BA18A"
							color="#fff"
							onClick={() => setViewRfq(true)}
							isLoading={buttonLoading}
							_hover={{ bg: "#38846D" }}
						>
							View RFQ
						</Button>

						{["Cancelled", "Open"].indexOf(quoteData?.status) >= 0 && (
							<Button
								border="1px solid #4BA18A"
								color="#4BA18A"
								bg="#E2F1EE"
								onClick={() => createNewQuote()}
								isLoading={buttonLoading}
								_hover={{ bg: "#B7DDD4" }}
								isDisabled={
									getDateDiff(quoteData?.rfq_expiry_date) < 0 ? true : false
								}
							>
								Create New Quote
							</Button>
						)}

						{quoteData?.status === "Open" && (
							<Button
								border="1px solid #EF6F6C"
								color="#EF6F6C"
								bg="rgba(239, 111, 108, 0.2)"
								isLoading={buttonLoading}
								onClick={() => setCancelQuoteConfirm(true)}
								_hover={{ bg: "rgba(239, 111, 108, 0.4)" }}
							>
								Cancel Quote
							</Button>
						)}

						{quoteData?.status === "Rejected" && (
							<Button
								children="View Details"
								border="1px solid #4BA18A"
								color="#4BA18A"
								bg="#E2F1EE"
								isLoading={buttonLoading}
								_hover={{ bg: "#B7DDD4" }}
								onClick={() => setShowRejectionDetails(true)}
							/>
						)}
						{/* coming soon feature */}
						{/* {quoteData?.status === "Expired" && (
							<Button
								children="Extend Validity"
								leftIcon={<BsCalendarPlus color="#4BA18A" />}
								border="1px solid #4BA18A"
								color="#4BA18A"
								bg="#E2F1EE"
								isLoading={buttonLoading}
							/>
						)} */}
					</Flex>
				</Show>
				<Show below="md">
					<Text fontSize="14px">
						<chakra.span textStyle="primary.bold">Date:&nbsp;</chakra.span>
						{normalDate(quoteData?.creation)}
					</Text>
				</Show>
			</Flex>

			<Show above="md">
				<Box
					bg="#fff"
					m="0em 1em 1em 1em"
					height={{ base: "100%", md: "67vh" }}
					overflow="auto"
					p={{ base: "1rem", md: "1rem 1.5rem" }}
					mt="2px"
					borderRadius="0px 0px 12px 12px"
				>
					{/* Local component. Component made outside the current component. */}
					<BuyerSupplierDetails
						quoteData={quoteData}
						loggedInSuppplierName={loggedInSuppplierName}
						supplierData={supplierData}
					/>

					<hr />
					<Box mt="2.25rem">
						<Text
							fontWeight={"semibold"}
							color={"#4BA18A"}
							fontSize="18px"
							mb="1rem"
						>
							{`Parts (${itemsData?.length})`}
						</Text>

						<GenericTables
							column={column}
							data={itemsData}
							renderCheckBox={false}
							renderSerialNo={true}
							isShowSearch={false}
							paginationbg={"#4BA18A"}
							headerColor={"#E2F1EE"}
							borderColor={"#B7DDD4"}
						/>
					</Box>

					<Flex justifyContent="flex-end" mt="2.25rem" mb="4.25rem">
						<Box width="25rem">
							<TotalAndQty
								totalQty={quoteData?.total_qty}
								totalAmount={quoteData?.base_grand_total}
							/>
						</Box>
					</Flex>

					<hr />

					<Box mt="2rem">
						<Text color={"#4BA18A"} fontWeight="semibold" fontSize="18px">
							Payment Terms
						</Text>
						{/* Local Component */}
						<PaymentTerms quoteData={quoteData} />

						{/* <Text
						mt="2.75rem"
						color={"#4BA18A"}
						fontWeight="semibold"
						fontSize="18px"
					>
						Inco Terms
					</Text>
					{quoteData?.inco_terms ? (
						<Box
							mt=".75rem"
							width="100%"
							padding=".75rem 1rem"
							border="1px solid #BABCBE"
							borderRadius="6px"
						>
							{quoteData?.inco_terms}
						</Box>
					) : (
						<Text textStyle="primary.secondaryText">N/A</Text>
					)} */}
						<Text
							mt="2.75rem"
							color={"#4BA18A"}
							fontWeight="semibold"
							fontSize="18px"
						>
							Terms & Conditions
						</Text>

						{/* local component */}
						<TermsAndCondition quoteData={quoteData} />

						<Text
							mt="2.75rem"
							color={"#4BA18A"}
							fontWeight="semibold"
							fontSize="18px"
						>
							Documents
						</Text>

						{/* local component */}

						<DocumentsAdded files={files} />
					</Box>
				</Box>

				{/* View Item details popup */}
				<BasicDialog
					isOpen={isPartOpen}
					showCloseButton={true}
					onClose={() => setIsPartOpen(false)}
					header={`Part Name: ${selectedItem?.item_name}`}
					content={
						<Box maxHeight="80vh" overflow="scroll">
							<ViewPartForm
								itemData={selectedItem}
								dataOriginatedFrom={quoteData?.originated_from}
							/>
						</Box>
					}
					size="4xl"
				/>

				{/* view RFQ popup */}
				<BasicDialog
					isOpen={viewRfq}
					showCloseButton={true}
					onClose={() => setViewRfq(false)}
					header="Request for Quotation"
					content={
						<Box height="80vh" overflow="auto">
							<ViewRfq rfqId={quoteData?.opportunity} />
						</Box>
					}
					size="6xl"
				/>

				{/* cancel quote confirmation popup */}
				<BasicDialog
					isOpen={cancelQuoteConfirm}
					showCloseButton={true}
					onClose={() => setCancelQuoteConfirm(false)}
					header="Cancel Quote"
					content={
						<CancelQuote
							quoteData={quoteData}
							handleCancel={() => setCancelQuoteConfirm(false)}
							handleConfirm={() => cancelQuote()}
							buttonLoading={buttonLoading}
						/>
					}
				/>

				{/* show rejection details */}
				<BasicDialog
					isOpen={showRejectionDetails}
					showCloseButton={true}
					onClose={() => setShowRejectionDetails(false)}
					header="View Details"
					content={
						<Box padding="2rem .75rem 1.5rem .75rem">
							<Box
								border="1px solid #E4E6EF"
								minHeight="9.75rem"
								maxHeight="15rem"
								overflowY="scroll"
								borderRadius="6px"
								padding=".75rem 1rem"
							>
								<Text textStyle="primary.secondaryText" fontSize="18px">
									This Quote was rejected by the Customer due to the following
									reason:
								</Text>
								<Text
									textStyle="primary.secondaryText"
									fontSize="18px"
									fontWeight="bold"
									mt="1rem"
								>
									{quoteData?.rejection_reason}
								</Text>
							</Box>
							<Button
								children="Close"
								bg="#4BA18A"
								color="#fff"
								mt="2rem"
								mb="1rem"
								float="right"
								_hover={{ bg: "#38846D" }}
								onClick={() => setShowRejectionDetails(false)}
							/>
						</Box>
					}
				/>
			</Show>

			{/* ********* Mobile Screens ********* */}
			<Show below="md">
				<Accordion
					defaultIndex={[0]}
					allowMultiple
					// marginTop="1em"
					padding="0 0.5em"
					width="100%"
					mb={{ base: "5rem", sm: "6.5rem" }}
					px="1rem"
				>
					{/* Buyer and Supplier Details */}
					<AccordionItem
						marginBottom="1em"
						// borderRadius="lg"
						borderTop="none"
					>
						<h2>
							<AccordionButton
								_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
								fontSize="14px"
								// borderRadius="lg"
								bgColor="#fff"
								boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
							>
								<Box as="span" flex="1" textAlign="left" color="#4BA18A">
									Buyer and Supplier Details
								</Box>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4} bg="#fff" p="1rem">
							<BuyerSupplierDetails
								quoteData={quoteData}
								loggedInSuppplierName={loggedInSuppplierName}
								supplierData={supplierData}
								handleViewRfq={() => setViewRfq(true)}
							/>
						</AccordionPanel>
					</AccordionItem>

					{/* Parts data */}
					<AccordionItem marginBottom="1em" borderRadius="lg" borderTop="none">
						<h2>
							<AccordionButton
								_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
								fontSize="14px"
								borderRadius="lg"
								bgColor="#fff"
								boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
							>
								<Box as="span" flex="1" textAlign="left" color="#4BA18A">
									Parts&nbsp;({itemsData.length})
								</Box>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4} px="0.5rem" bg="#fff">
							<Box>
								{itemsData.map((item: any, index: number) => {
									return (
										<Box mb="1rem">
											<RFQPartsCard
												key={index}
												name={item.item_name}
												units={item.qty}
												category={
													quoteData?.originated_from === "procurement"
														? "item_category"
														: "item_type"
												}
												targetCost={item.target_cost}
												viewPartFunction={() => {
													setSelectedItem(item);
													setIsPartOpenMob(true);
													setSelectedItemIndex(index);
												}}
											/>
										</Box>
									);
								})}
								<Flex justifyContent="flex-end" mt="2.25rem">
									<Box width="25rem">
										<TotalAndQty
											totalQty={quoteData?.total_qty}
											totalAmount={quoteData?.base_grand_total}
										/>
									</Box>
								</Flex>
							</Box>
						</AccordionPanel>
					</AccordionItem>

					{/* Payment Terms */}
					<AccordionItem
						marginBottom="1em"
						borderRadius="lg"
						boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
						borderTop="none"
						bgColor="#fff"
					>
						<h2>
							<AccordionButton
								_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
								borderRadius="lg"
								bgColor="#fff"
								fontSize="14px"
							>
								<Box
									as="span"
									flex="1"
									textAlign="left"
									color="#4BA18A"
									borderRadius="lg"
								>
									Payment Terms
								</Box>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4} fontSize="12px">
							<PaymentTerms quoteData={quoteData} />
						</AccordionPanel>
					</AccordionItem>

					{/* Terms and Condition */}
					<AccordionItem
						marginBottom="1em"
						borderRadius="lg"
						boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
						borderTop="none"
						bgColor="#fff"
					>
						<h2>
							<AccordionButton
								_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
								borderRadius="lg"
								bgColor="#fff"
								fontSize="14px"
							>
								<Box
									as="span"
									flex="1"
									textAlign="left"
									color="#4BA18A"
									borderRadius="lg"
								>
									Terms and Conditions
								</Box>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4}>
							<TermsAndCondition quoteData={quoteData} />
						</AccordionPanel>
					</AccordionItem>

					{/* Documents */}
					<AccordionItem marginBottom="1em" borderRadius="lg" borderTop="none">
						<h2>
							<AccordionButton
								_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
								fontSize="14px"
								borderRadius="lg"
								bgColor="#fff"
								boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
							>
								<Box as="span" flex="1" textAlign="left" color="#4BA18A">
									Documents
								</Box>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4} bg="#fff" p="1rem">
							<DocumentsAdded files={files} />
						</AccordionPanel>
					</AccordionItem>
				</Accordion>

				{/* Different actions(buttons) for different quote status */}
				<Flex
					justifyContent="space-between"
					alignItems="center"
					position="fixed"
					bottom={0}
					left="0"
					w="100%"
					bg="#fff"
					padding="1rem"
					boxShadow="0 -4px 10px #00000010"
					gap="1rem"
				>
					{(quoteData?.status === "Open" || quoteData?.status === "Active") && (
						<Button
							w="47%"
							bg="#fff"
							color="#EF6F6C"
							fontStyle="bold"
							border="1px solid #EF6F6C"
							flexGrow={1}
							_hover={{ bg: "#fff" }}
							onClick={() => setCancelQuoteConfirm(true)}
						>
							Cancel Quote
						</Button>
					)}
					{/* Feature Coming soon  */}
					{/* {quoteData?.status === "Expired" && (
            <Button
								children="Extend Validity"
								leftIcon={<BsCalendarPlus color="#4BA18A" />}
								border="1px solid #4BA18A"
								color="#4BA18A"
								bg="#E2F1EE"
								isLoading={buttonLoading}
							/>
          )} */}
					{quoteData?.status === "Cancelled" && (
						<Button
							w="47%"
							bg="linear-gradient(0deg, #E2F1EE, #E2F1EE)"
							_hover={{ bg: "linear-gradient(0deg, #E2F1EE, #E2F1EE)" }}
							color="#4ba18a"
							fontStyle="bold"
							flexGrow={1}
							border="1px solid #4ba18a"
							onClick={() => createNewQuote()}
							isDisabled={
								getDateDiff(quoteData?.rfq_expiry_date) < 0 ? true : false
							}
						>
							Create Quote
						</Button>
					)}
					{quoteData?.status === "Rejected" && (
						<Button
							w="47%"
							children="View Details"
							border="1px solid #4BA18A"
							color="#4BA18A"
							bg="#E2F1EE"
							flexGrow={1}
							isLoading={buttonLoading}
							_hover={{ bg: "#B7DDD4" }}
							onClick={() => setShowRejectionDetails(true)}
						/>
					)}
					{["Cancelled", "Expired", "Rejected"].includes(quoteData?.status) ? (
						<Button
							bg="#4BA18A"
							w="47%"
							color="#fff"
							onClick={() => setViewRfq(true)}
							isLoading={buttonLoading}
							_hover={{ bg: "#38846D" }}
							flexGrow={1}
						>
							View RFQ
						</Button>
					) : (
						<Button
							w="47%"
							bg="linear-gradient(0deg, #E2F1EE, #E2F1EE)"
							_hover={{ bg: "linear-gradient(0deg, #E2F1EE, #E2F1EE)" }}
							color="#4ba18a"
							fontStyle="bold"
							border="1px solid #4ba18a"
							flexGrow={1}
							onClick={() => createNewQuote()}
							isDisabled={
								getDateDiff(quoteData?.rfq_expiry_date) < 0 ? true : false
							}
						>
							Create Quote
						</Button>
					)}
				</Flex>

				{/* ***** Mobile pop-ups ***** */}
				{/* View Part pop-ups */}
				<Overlay isOpen={isPartOpenMob}>
					<BottomSheet
						isOpen={isPartOpenMob}
						onClose={() => {
							setIsPartOpenMob(false);
						}}
					>
						<Text
							fontSize="14px"
							fontWeight="bold"
							color="#4BA18A"
							textAlign="center"
							mb="1rem"
						>
							{selectedItem?.item_name}
						</Text>
						<Box mb="3rem">
							<ViewPartForm
								itemData={selectedItem}
								dataOriginatedFrom={quoteData?.originated_from}
							/>
						</Box>
					</BottomSheet>
					<Flex
						w="100%"
						position="fixed"
						left="0"
						bottom="0"
						padding="0.75rem 1rem"
						bg="#fff"
						justifyContent="space-between"
						alignItems="center"
						boxShadow="0px -4px 8px 0px #0000000F"
					>
						<Button
							onClick={() => {
								if (selectedItemIndex > 0) {
									setSelectedItem(itemsData[selectedItemIndex - 1]);
									setSelectedItemIndex((prev) => prev - 1);
								}
							}}
							isDisabled={selectedItemIndex === 0}
							width="47%"
							bg="white"
							fontWeight="semibold"
							color="#4ba18a"
							_hover={{ backgroundColor: "white" }}
							border="1px solid #4ba18a"
						>
							Previous
						</Button>
						<Button
							onClick={() => {
								if (selectedItemIndex < itemsData.length - 1) {
									setSelectedItem(itemsData[selectedItemIndex + 1]);
									setSelectedItemIndex((prev) => prev + 1);
								}
							}}
							isDisabled={selectedItemIndex === itemsData.length - 1}
							width="47%"
							color="white"
							fontWeight="semibold"
							bg="#4ba18a"
							_hover={{ backgroundColor: "#4ba18a" }}
						>
							View Next
						</Button>
					</Flex>
				</Overlay>

				{/* View RFQ */}
				<Overlay isOpen={viewRfq}>
					<BottomSheet
						isOpen={viewRfq}
						onClose={() => setViewRfq(false)}
						padding={0}
					>
						<Text
							textAlign="center"
							fontSize="18px"
							color="#4ba18a"
							fontWeight="bold"
							my="1rem"
						>
							Request For Quotation
						</Text>
						<Box>
							<ViewRfq rfqId={quoteData?.opportunity} />
						</Box>
					</BottomSheet>
				</Overlay>

				{/* Cancel Quote Confirmation */}
				<Overlay isOpen={cancelQuoteConfirm}>
					<BottomSheet
						isOpen={cancelQuoteConfirm}
						onClose={() => setCancelQuoteConfirm(false)}
					>
						<Text
							fontSize="18px"
							my="1rem"
							fontWeight="bold"
							textAlign="center"
							color="#4ba18a"
						>
							Cancel Quote
						</Text>
						<CancelQuote
							quoteData={quoteData}
							handleCancel={() => setCancelQuoteConfirm(false)}
							handleConfirm={() => cancelQuote()}
							buttonLoading={buttonLoading}
						/>
					</BottomSheet>
				</Overlay>

				{/* Show Rejection Details */}
				<Overlay isOpen={showRejectionDetails}>
					<BottomSheet
						isOpen={showRejectionDetails}
						onClose={() => setShowRejectionDetails(false)}
						display="flex"
						alignItems="center"
						flexDir="column"
					>
						<Text
							fontSize="20px"
							fontWeight="bold"
							color="#4ba18a"
							textAlign="center"
							my="1rem"
						>
							View Details
						</Text>
						<Image src={CancelQuoteImage} w="75%" />
						<Box padding="2rem .75rem 1.5rem .75rem">
							<Box
								border="1px solid #E4E6EF"
								minHeight="9.75rem"
								maxHeight="15rem"
								overflowY="scroll"
								borderRadius="6px"
								padding=".75rem 1rem"
							>
								<Text
									textStyle="primary.secondaryText"
									fontSize="18px"
									textAlign="center"
									color="#000"
								>
									This Quote was rejected by the Customer due to the following
									reason:
								</Text>
								<Text
									textStyle="primary.secondaryText"
									fontSize="18px"
									fontWeight="bold"
									mt="1rem"
									textAlign="center"
									color="#000"
								>
									{quoteData?.rejection_reason}
								</Text>
							</Box>
						</Box>
					</BottomSheet>
				</Overlay>
			</Show>
		</Box>
	);
};

// Some local components common for both mobile and desktop screen

// Component for Buyer and Supplier details
const BuyerSupplierDetails = ({
	quoteData,
	loggedInSuppplierName,
	supplierData,
	handleViewRfq,
}: any) => {
	const navigate = useNavigate();

	return (
		<Box>
			<Text textStyle="primary.bold">Buyer Details:</Text>
			<Flex mt="1rem" alignItems="flex-start" width="100%">
				<Grid
					gridTemplateColumns={{
						base: "65px repeat(2, 1fr)",
						md: "100px repeat(2, 1fr)",
					}}
					bg="#fff"
					p={{ base: "0rem", md: "1em" }}
					width="100%"
					gap={1}
				>
					<GridItem>
						<Circle
							size={{ base: "50px", md: "80px" }}
							bg="green.50"
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<Text
								fontSize={{ base: "22px", md: "32px" }}
								fontWeight="semibold"
								color="#4BA18A"
							>
								{quoteData?.company_name
									? quoteData?.company_name?.slice(0, 1).toUpperCase()
									: quoteData?.party_name?.slice(0, 1).toUpperCase()}
							</Text>
						</Circle>
					</GridItem>

					<GridItem colSpan={2}>
						<Text
							fontSize={{ base: "20px", md: "22px" }}
							fontWeight="bold"
							color="#4BA18A"
						>
							{quoteData?.company_name || quoteData?.party_name}
						</Text>
						<Flex
							alignItems="center"
							justifyContent="space-between"
							flexWrap={"wrap"}
							gap="1rem"
						>
							<Text mt="0.2em" fontSize={{ base: "16px", md: "18px" }}>
								{quoteData?.party_name}
							</Text>
							<Show below="md">
								<Button
									variant="link"
									fontSize="13px"
									fontWeight="semibold"
									color="#4ba18a"
									onClick={handleViewRfq}
								>
									View RFQ&nbsp;
									<BsArrowRightCircle color="#4ba18a" />
								</Button>
							</Show>
						</Flex>
					</GridItem>
					<GridItem colStart={{ base: 1, md: 2 }} colSpan={{ base: 3, md: 1 }}>
						<Text fontSize="16px" fontWeight="semibold" mt="1em">
							Bill To:
						</Text>
						<Text fontSize={{ base: "14px", md: "16px" }}>
							{quoteData?.customer_billing_address}
						</Text>
					</GridItem>
					<GridItem colSpan={{ base: 3, md: 1 }}>
						<Text fontSize="16px" fontWeight="semibold" mt="1em">
							Ship To:
						</Text>
						<Text fontSize={{ base: "14px", md: "16px" }}>
							{quoteData?.customer_shipping_address}
						</Text>
					</GridItem>
				</Grid>
			</Flex>

			<Show below="md">
				<Divider bg="#00000060" my="1rem" />
			</Show>

			<Text textStyle="primary.bold">Supplier Details:</Text>
			<Flex mt="1rem" alignItems="flex-start" width="100%">
				{/* supplier Details: */}
				<Grid
					templateColumns={{
						base: "65px repeat(2, 1fr)",
						md: "100px repeat(2, 1fr)",
					}}
					bg="#fff"
					p={{ base: "0rem", md: "1em" }}
					width="100%"
				>
					<GridItem>
						<Circle
							size={{ base: "50px", md: "80px" }}
							bg="green.50"
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<Text
								fontSize={{ base: "22px", md: "32px" }}
								fontWeight="semibold"
								color="#4BA18A"
							>
								{quoteData?.supplier_company_name
									? quoteData?.supplier_company_name?.slice(0, 1).toUpperCase()
									: loggedInSuppplierName?.slice(0, 1).toUpperCase()}
							</Text>
						</Circle>
					</GridItem>
					<GridItem colSpan={2}>
						<Text
							fontSize={{ base: "20px", md: "22px" }}
							fontWeight="bold"
							color="#4BA18A"
						>
							{quoteData?.supplier_company_name || loggedInSuppplierName}
						</Text>
						<Text mt="0.2em" fontSize={{ base: "16px", md: "18px" }}>
							{loggedInSuppplierName}
						</Text>
					</GridItem>
					<GridItem colStart={{ base: 1, md: 2 }} colSpan={{ base: 3, md: 1 }}>
						<Flex alignItems="baseline" mt="1em">
							<Text
								fontSize={{ base: "14px", md: "16px" }}
								fontWeight="semibold"
							>
								Address:&nbsp;
							</Text>
							<Text fontSize={{ base: "14px", md: "16px" }}>
								{quoteData?.supplier_address}
							</Text>
						</Flex>
					</GridItem>
					<GridItem colSpan={{ base: 3, md: 1 }}>
						<Flex alignItems="center" mt={{ base: "0.5rem", md: "1em" }}>
							<Text
								fontSize={{ base: "14px", md: "16px" }}
								fontWeight="semibold"
							>
								Contact:&nbsp;
							</Text>
							<Text fontSize="14px">{quoteData?.supplier_contact}</Text>
						</Flex>
					</GridItem>
					<GridItem colStart={{ base: 1, md: 2 }} colSpan={{ base: 3, md: 1 }}>
						<Flex alignItems="center" mt={{ base: "0.5rem", md: "1em" }}>
							<Text
								fontSize={{ base: "14px", md: "16px" }}
								fontWeight="semibold"
							>
								GST:&nbsp;
							</Text>
							<Text fontSize="14px">{quoteData?.company_gstin}</Text>
							{supplierData?.gstin == "" && (
								<Text
									fontSize="14px"
									color="#4BA18A"
									textDecoration="underline"
									cursor="pointer"
									onClick={() => navigate("/settings/BusinessInformation")}
								>
									Add GST to your Profile
								</Text>
							)}
						</Flex>
					</GridItem>
					<GridItem colSpan={{ base: 3, md: 1 }}>
						<Flex alignItems="center" mt={{ base: "0.5rem", md: "1em" }}>
							<Text
								fontSize={{ base: "14px", md: "16px" }}
								fontWeight="semibold"
							>
								Email:&nbsp;
							</Text>
							<Text fontSize="14px">{quoteData?.supplier_email}</Text>
						</Flex>
					</GridItem>
				</Grid>
			</Flex>

			<Show below="md">
				<Divider bg="#00000060" my="1rem" />
			</Show>

			<Grid
				templateColumns={{ sm: "repeat(1,1fr)", md: "repeat(2,1fr)" }}
				gap={{ base: "0.5rem", md: "5rem" }}
				width="100%"
				my={{ base: "0", md: "2.5rem" }}
			>
				<GridItem>
					<Flex
						flexDirection={{ base: "row", md: "column" }}
						alignItems={{ base: "center", md: "flex-start" }}
						w="100%"
						gap={{ base: "0.625rem", md: "0" }}
					>
						<Text textStyle="primary.bold">Quote Valid Till: </Text>
						{quoteData?.valid_till ? (
							<Flex
								border={{ base: "none", md: "1px solid #BABCBE" }}
								borderRadius="6px"
								padding={{ base: "0", md: ".75rem 1rem" }}
								alignItems="center"
								justifyContent="flex-start"
								gap=".5rem"
								w={{ base: "auto", md: "100%" }}
							>
								<Show above="md">
									<BsCalendar3 />
								</Show>
								<Text>{normalDate(quoteData?.valid_till)}</Text>
							</Flex>
						) : (
							<Text textStyle="primary.secondaryText">N/A</Text>
						)}
					</Flex>
				</GridItem>
				<GridItem>
					<Flex
						flexDirection={{ base: "row", md: "column" }}
						alignItems={{ base: "center", md: "flex-start" }}
						w="100%"
						gap={{ base: "0.625rem", md: "0" }}
					>
						<Text textStyle="primary.bold">Timeline: </Text>
						{quoteData?.timeline_number ? (
							<Box
								border={{ base: "none", md: "1px solid #BABCBE" }}
								borderRadius="6px"
								padding={{ base: "0", md: ".75rem 1rem" }}
								w={{ base: "auto", md: "100%" }}
							>
								{`${quoteData?.timeline_number} ${
									quoteData?.timeline_field || ""
								}`}
							</Box>
						) : (
							<Text textStyle="primary.secondaryText">N/A</Text>
						)}
					</Flex>
				</GridItem>
			</Grid>
		</Box>
	);
};

// Payment Terms
const PaymentTerms = ({ quoteData }: any) => {
	return (
		<Box>
			{quoteData?.payment_terms ? (
				<Box
					mt=".75rem"
					width="100%"
					padding=".75rem 1rem"
					border="1px solid #BABCBE"
					borderRadius="md"
					whiteSpace="pre-wrap"
				>
					{quoteData?.payment_terms}
				</Box>
			) : (
				<Text textStyle="primary.secondaryText">N/A</Text>
			)}
		</Box>
	);
};

const TermsAndCondition = ({ quoteData }: any) => {
	return (
		<Box>
			{quoteData?.terms ? (
				<Box
					mt=".75rem"
					width="100%"
					padding=".75rem 1rem"
					border="1px solid #BABCBE"
					borderRadius="md"
					whiteSpace="pre-wrap"
				>
					{quoteData?.terms}
				</Box>
			) : (
				<Text textStyle="primary.secondaryText">N/A</Text>
			)}
		</Box>
	);
};

// Documents Added
const DocumentsAdded = ({ files }: any) => {
	const downloadFile = async (fileLink: string, fileName: string) => {
		const response = await fetch(fileLink);
		const blob = await response.blob();
		saveAs(blob, fileName);
	};

	return (
		<Box>
			{files.length > 0 ? (
				<Flex
					padding="1rem 1.8rem"
					alignItems="center"
					flexWrap="wrap"
					gap="2.8rem"
					border="1px solid rgba(228, 230, 239, 0.7)"
					borderRadius="6px"
					mt=".75rem"
				>
					{files?.map((file: any, index: number) => (
						<Flex
							alignItems="center"
							justifyContent={{ base: "space-between", md: "flex-start" }}
							key={index}
							gap=".8rem"
							width={{ base: "100%", md: "auto" }}
						>
							<Thumbnail
								fileUrl={file.file}
								isPrivateFile={file.isPrivateFile}
							/>
							<Box>
								<Tooltip label={file.subtitle}>
									<Text textStyle="primary.bold">
										{file.subtitle.slice(0, 20)}
									</Text>
								</Tooltip>
								<Text textStyle="primary.secondaryText">
									{file.fileSize}&nbsp;{file.fileSize > 1024 ? "MB" : "KB"}
								</Text>
							</Box>
							<Box
								bg="#E2F1EE"
								p="5px"
								borderRadius="6px"
								onClick={() => downloadFile(file.file, file.subtitle)}
								cursor="pointer"
							>
								<BsDownload size="22px" />
							</Box>
						</Flex>
					))}
				</Flex>
			) : (
				<Text textStyle="primary.secondaryText">No files attached</Text>
			)}
		</Box>
	);
};

// Cancel Quote Pop-up component
const CancelQuote = ({
	quoteData,
	handleCancel,
	buttonLoading,
	handleConfirm,
}: any) => {
	return (
		<Flex
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
			mt={{ base: "1rem", md: "1.75rem" }}
			mb={{ base: "1rem", md: "2.5rem" }}
		>
			<Image src={CancelQuoteImage} w="75%" />
			<Text mt="1.75rem" textAlign="center">
				On Cancelling, no PO will be raised against this quote. Are you sure you
				want to cancel this quote for&nbsp;
				<chakra.span color="#4BA18A">{quoteData?.company_name}</chakra.span>?
			</Text>
			<Flex
				alignItems="center"
				gap="1.25rem"
				mt="1rem"
				width={{ base: "100%", md: "auto" }}
			>
				<Button
					color="#000"
					bg="#fff"
					border="1px solid #000"
					_hover={{ bg: "#E2F1EE" }}
					onClick={handleCancel}
					isLoading={buttonLoading}
					flexGrow={{ base: 1, md: 0 }}
				>
					Cancel
				</Button>
				<Button
					color="#fff"
					bg="#4BA18A"
					_hover={{ bg: "#38846D" }}
					onClick={handleConfirm}
					isLoading={buttonLoading}
					flexGrow={{ base: 1, md: 0 }}
				>
					Confirm
				</Button>
			</Flex>
		</Flex>
	);
};

export default QuoteManagement;
