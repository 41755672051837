import React, { useEffect, useState, lazy } from "react";
import {
	Box,
	Text,
	Flex,
	Divider,
	Grid,
	GridItem,
	Link as ChakraLink,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Show,
	Tag,
	useMediaQuery,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Icon,
	Center,
	Img,
	TabIndicator,
} from "@chakra-ui/react";

import { Link as Lk } from "react-router-dom";
import { Mixpanel } from "../../Mixpanel.js";

import {
	Overlay,
	BottomSheet,
	ThemeButton,
	Button,
	get_doc,
	NoDataAvailable,
	BasicDialog,
	normalDate,
	yupDate,
	yupString,
	RenderTextField,
	RenderTextAreaField,
	RenderDropDown,
	AppDispatch,
	formatErrorMessage,
	SET_MESSAGE,
	ERROR_MSG,
	SUCCESS_MSG,
	suppllierCreateOrderUpdate,
	frappeMethodService,
	setPageLoading,
	FilesSlider,
	MobileOrderStatusCard,
} from "@karkhanaui/react";
import completed from "../../img/completed.svg";

import OrderStatusBar from "./OrderStatusBar";
import { Form, Formik } from "formik";
import UploadDoc from "../quotes/UploadDoc";
import Stepper from "./Stepper";
import { IoTimeOutline } from "react-icons/io5";
import OrdersUpdateBar from "./OrdersUpdateBar";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
const ViewRfq = lazy(() => import("../rfq/ViewRfq"));
import axios from "axios";
import { ViewOrdersCard } from "./OrdersMainCard.tsx";
import OrderUpdateStatusMobile from "./OrderUpdateStatusMobile.tsx";
import { MdPlusOne } from "react-icons/md/index.js";
import { BsPaperclip } from "react-icons/bs";
import PartsAndAttachments from "./PartsAndAttachments.tsx";
import Chat from "./Chat.tsx";
import OrderDocuments from "./OrderDocuments.tsx";

const KeyValue = ({ title, val }) => (
	<Box>
		<Text fontSize="16px" color="#4BA18A" fontWeight="semibold">
			{title}
		</Text>
		<Text fontSize="16px" color="black" fontWeight="medium" mt="10px">
			{val}
		</Text>
	</Box>
);

const OrdersLive: React.FC<any> = () => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const params = useParams();
	const { rfqId: PO_ID } = useParams();
	const [activeTab, setActiveTab] = useState(0);
	const [mancounter, setManCounter] = useState(0);
	const [transitCounter, setTransitCounter] = useState(0);
	const [deliveredCounter, setDeliveredCounter] = useState(0);
	const [activeStep, setActiveStep] = useState(3);
	const [po, setPO] = useState(PO_ID);
	const [isMark, setIsMark] = useState<boolean>(false);
	const [orderUpdates, setOrderUpdates] = React.useState([]);
	const [partUpdates, setPartUpdates] = React.useState([]);
	const [purchaseOrder, setPurchaseOrder] = React.useState(undefined);
	const [viewRfq, setViewRfq] = React.useState<boolean>(false);
	const [selectedFiles, setSelectedFiles] = React.useState<any>([]);
	const [isMarkClicked, setIsMarkClicked] = React.useState<any>(false);
	const [authState, supplier] = useSelector((state: any) => {
		return [state.authSupplier, state.getUser];
	});
	const [directPOFromProcurement, setDirectPOFromProcurement] =
		React.useState<boolean>(false);
	const [viewPartsAndAttachments, setViewPartsAndAttachments] =
		React.useState<boolean>(false);
	const [isMobileTablet] = useMediaQuery("(max-width: 768px)");
	const getStatusColor = (status: string) => {
		switch (status) {
			case "PO Received":
				return { bg: "rgba(253, 185, 19, 0.2)", color: "#EBA700" };
			case "Live":
				return { bg: "rgba(0, 135, 238, 0.1)", color: "#0087EE" };
			case "Cancelled":
				return { bg: "rgba(239, 111, 108, 0.2)", color: "#EF6F6C" };
			case "complete":
				return { bg: "#E2F1EE", color: "#4BA18A" };
			default:
				return { bg: "gray.100", color: "gray.400" };
		}
	};

	const fetchSalesOrder = async () => {
		if (params.rfq_name) {
			setPO(params.rfq_name);
			const doc = await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/resource/Sales Order/${params.rfq_name}`
			);
			if (doc.status === 200) {
				setPurchaseOrder(doc.data?.data);
				if (
					doc.data?.data?.originated_from === "procurement"
					//&& doc.data?.data.direct_po == 1
				) {
					setDirectPOFromProcurement(true);
				}
			}
		}
	};

	useEffect(() => {
		fetchSalesOrder();
	}, [params]);

	const fetchUpdates = () => {
		if (po) {
			const dc = get_doc({
				doc: "Client Order Update",
				filters: [["sales_order", "=", po]],
				limit: 0,
				fields: ["*"],
			});

			dc.then((s) => {
				setOrderUpdates(s.data.data);
			});
		}
	};

	const fetchPartUpdates = () => {
		if (po) {
			const dc = get_doc({
				doc: "Client Part Update",
				filters: [["sales_order", "=", po]],
				limit: 0,
				fields: ["*"],
			});

			dc.then((s) => {
				setPartUpdates(s.data.data);
			});
		}
	};

	useEffect(fetchUpdates, [po]);
	useEffect(fetchPartUpdates, [po]);

	const handleTabClick = (i: any) => {
		setActiveTab(i);
	};

	const orderUpdateValidation = Yup.object().shape({
		date: yupDate,
		status: yupString,
		comments: yupString,
	});

	const partUpdateValidation = Yup.object().shape({
		part: yupString,
		// date: yupDate,
	});

	const updateOrder = async (val: any, { resetForm }) => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Loading...",
			})
		);
		try {
			let res: any = await suppllierCreateOrderUpdate({
				...val,
				sales_order: po,
				opportunity: purchaseOrder.opportunity,
			});
			if (res.status === 200) {
				//uploaded the files to the order update
				if (selectedFiles?.length > 0) {
					dispatch(
						setPageLoading({
							loading: true,
							loadingText: `Uploading ${selectedFiles?.length} files...`,
						})
					);
					Promise.all(
						selectedFiles?.map(async (file: any) => {
							const formData = new FormData();
							formData.append("file", file._file);
							formData.append("is_private", 0);
							formData.append("folder", "Home/Attachments");
							formData.append("doctype", "Client Order Update");
							formData.append("docname", res.data.data.name);
							await frappeMethodService("upload_file", formData);
						})
					).then(() => {
						dispatch(
							setPageLoading({
								loading: false,
								loadingText: "",
							})
						);
						dispatch({
							type: SET_MESSAGE,
							payload: {
								case: SUCCESS_MSG,
								data: "Order Update Success",
							},
						});
						fetchUpdates();
						resetForm();
						setSelectedFiles([]);
					});
				} else {
					dispatch(
						setPageLoading({
							loading: false,
							loadingText: "",
						})
					);
					dispatch({
						type: SET_MESSAGE,
						payload: {
							case: SUCCESS_MSG,
							data: "Order Update Success",
						},
					});
					fetchUpdates();
					resetForm();
				}
				window.dataLayer?.push({
					event: "Order Update",
					po_id: po,
					user_email: supplier?.customer?.email,
					user_id: authState?.uniqueUserId,
					po_status: "Live",
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name: purchaseOrder?.company_name,
				});
				Mixpanel.track("orderUpdate", {
					po_id: po,
					user_email: supplier?.customer?.email,
					user_id: authState?.uniqueUserId,
					po_status: "Live",
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name: purchaseOrder?.company_name,
				});
			}
		} catch (error) {
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
			fetchUpdates();
		}
	};

	const updatePart = async (val: any, { resetForm }) => {
		let itemName = await purchaseOrder.items.filter(
			(item: any) => item.item_code == val.part.split("(")[0]
		)[0].item_name;
		axios
			.post(
				`${process.env.REACT_APP_BASE_URL}/api/resource/Client Part Update`,
				JSON.stringify({
					item_code: val.part.split("(")[0],
					item_name: itemName,
					date: val.date,
					manufacturing: mancounter,
					in_transit: transitCounter,
					delivered: deliveredCounter,
					sales_order: po,
					opportunity: purchaseOrder.opportunity,
				})
			)
			.then((s) => {
				fetchPartUpdates();
				resetForm();
				setManCounter(0);
				setTransitCounter(0);
				setDeliveredCounter(0);
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: SUCCESS_MSG,
						data: "Part Update Success",
					},
				});
				window.dataLayer?.push({
					event: "Part Update",
					po_id: po,
					user_email: supplier?.customer?.email,
					user_id: authState?.uniqueUserId,
					po_status: "Live",
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name: purchaseOrder?.company_name,
				});
				Mixpanel.track("partUpdate", {
					po_id: po,
					user_email: supplier?.customer?.email,
					user_id: authState?.uniqueUserId,
					po_status: "Live",
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name: purchaseOrder?.company_name,
				});
			})
			.catch((err) => {
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: formatErrorMessage(err),
					},
				});
				fetchPartUpdates();
				resetForm();
				setManCounter(0);
				setTransitCounter(0);
				setDeliveredCounter(0);
			});
	};

	const updateFile = async (uploadedFiles: any) => {
		let _filesList: any = [];
		await uploadedFiles?.map((file: any, index: number) => {
			_filesList.push({
				fileId: index,
				subtitle: file.path,
				file: file.name,
				_file: file,
				isPrivateFile: true, //to get the default thumbnail
			});
		});
		setSelectedFiles([...selectedFiles, ..._filesList]);
	};

	const deleteFileConfirm = (file: any) => {
		setSelectedFiles(
			selectedFiles.filter((item) => item.fileId !== file.fileId)
		);
	};
	// rfq popup for mark as competed mobile
	const handleConfirmRFQPopup = () => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Loading...",
			})
		);

		axios
			.put(
				`/api/resource/Sales Order/${purchaseOrder?.name}`,
				JSON.stringify({
					portal_status: "complete",
				})
			)
			.then((s) => {
				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
				setIsMark(false);
				setIsMarkClicked(true);
				window.location.reload();
			})
			.catch((error) => {
				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: formatErrorMessage(error),
					},
				});
			});
	};

	const getDataInsideParentheses = (str: string) => {
		var regex = /\(([^)]+)\)/;
		var match = regex.exec(str);
		return match ? match[1] : null; // returns null if no match is found
	};
	interface AccordionItem {
		icon: React.ComponentType; // Or any other suitable type for your icons
		title: string;
		content: () => JSX.Element;
	}
	const accordionArray: AccordionItem[] = [
		{
			icon: AiOutlinePlus,
			title: "Add Order Updates",
			content: () => {
				return (
					<>
						{purchaseOrder?.portal_status === "Live" && (
							<Formik
								initialValues={{
									date: "",
									status: "",
									comments: "",
								}}
								validationSchema={orderUpdateValidation}
								enableReinitialize={true}
								onSubmit={updateOrder}
							>
								{(formik) => {
									return (
										<Form>
											<Grid
												templateColumns={{
													base: "repeat(1,1fr)",
													md: "repeat(2,1fr)",
												}}
												gap={12}
												mt="1rem"
											>
												<GridItem>
													<RenderTextField
														name="date"
														label="Date"
														placeholder="Add Date"
														type="date"
														isMandatory={true}
													/>
												</GridItem>

												<GridItem>
													<RenderDropDown
														name="status"
														label="Status"
														placeholder="Status"
														isMandatory={true}
														values={[
															"In Production",
															"Raw Material Acquired",
															"Partially Shipped",
															"In Transit",
															"Delivered",
														]}
													/>
												</GridItem>
											</Grid>
											<Box mt="1.75rem">
												<RenderTextAreaField
													name="comments"
													label="Order Update"
													placeholder="Add your update here"
													padding=".75rem 1rem"
													size="xl"
													height="4.8rem"
													borderRadius={"5px"}
													isMandatory={true}
												/>
											</Box>
											<Box mt="1.5rem">
												<Text
													color={"black"}
													fontSize={"18px"}
													fontWeight={"semibold"}
													mb="0.5rem"
												>
													Add Attachments
												</Text>
												<Box overflowX="scroll">
													{selectedFiles?.length > 0 ? (
														<Box>
															<Text ml="1em">
																{selectedFiles.length}&nbsp; files uploaded
															</Text>
															<Box mt="1rem" ml="1rem">
																<FilesSlider
																	files={selectedFiles}
																	// alternativeImg={alternativeImg}
																	deleteIcon={true}
																	deleteFunction={deleteFileConfirm}
																/>
															</Box>
														</Box>
													) : (
														<Text fontStyle="Rubik">No files uploaded</Text>
													)}
												</Box>
												<UploadDoc isMultiple={true} updateFile={updateFile} />
											</Box>

											<Box display={"flex"} justifyContent="center" mt="2em">
												<Button
													type="submit"
													bg="#4BA18A"
													color="#fff"
													fontWeight="bold"
													width={"100%"}
													_hover={{ bg: "#38846D" }}
												>
													Update Order
												</Button>
											</Box>
										</Form>
									);
								}}
							</Formik>
						)}
					</>
				);
			},
		},
		{
			icon: IoTimeOutline,
			title: "Previous Updates",
			content: () => {
				return (
					<Box height="auto">
						{orderUpdates?.length > 0 ? (
							orderUpdates
								.sort((a: any, b: any) => new Date(b.date) - new Date(a.date))
								.map((each: any, i: number) => (
									<Box mt="1.25rem" key={i}>
										<OrderUpdateStatusMobile
											status={each.status}
											timestamp={each.date}
											orderUpdate={each.comments}
											attachmentsLink={each.name}
										/>
									</Box>
								))
						) : (
							<NoDataAvailable />
						)}
					</Box>
				);
			},
		},
	];
	interface partUpdateInterface {
		icon: React.ComponentType;
		title: string;
		content: () => JSX.Element;
	}
	const partUpdateAccordion: partUpdateInterface[] = [
		{
			icon: AiOutlinePlus,
			title: "Add part update",
			content: () => {
				return (
					<>
						{purchaseOrder?.portal_status === "Live" && (
							<Formik
								initialValues={{
									// date: "",
									part: "",
								}}
								validationSchema={partUpdateValidation}
								enableReinitialize={true}
								onSubmit={updatePart}
							>
								{(formik) => {
									return (
										<Form>
											<Grid
												templateColumns={{
													base: "repeat(1,1fr)",
													md: "repeat(2,1fr)",
												}}
												gap={12}
												mt="1rem"
											>
												<GridItem>
													<RenderDropDown
														name="part"
														label="Select Part Name"
														placeholder="Select Part Name"
														isMandatory={true}
														values={purchaseOrder?.items?.map(
															(item: any) =>
																item.item_code + "(" + item.item_name + ")"
														)}
													/>
												</GridItem>
											</Grid>
											<Box display="flex" flexDir="column" gap="2em" mt="2rem">
												<Flex flexDirection="column">
													<Text>Manufacturing</Text>
													<Box
														borderRadius="md"
														bg="#fff"
														display="flex"
														gap="1em"
														alignItems="center"
														mt=".5rem"
														ml=".5rem"
													>
														<Button
															color="#fff"
															bg="#000"
															onClick={() => {
																if (mancounter > 0)
																	setManCounter(mancounter - 1);
															}}
															disabled={mancounter === 0}
															alignItems="center"
															size="xs"
														>
															-
														</Button>
														<Text>{mancounter}</Text>
														<Button
															color="#fff"
															bg="#000"
															onClick={() => setManCounter(mancounter + 1)}
															disabled={mancounter === 0}
															alignItems="center"
															size="xs"
														>
															+
														</Button>
													</Box>
												</Flex>
												<Flex flexDirection="column">
													<Text ml="1.5em">In Transit</Text>
													<Box
														borderRadius="md"
														bg="#fff"
														display="flex"
														gap="1em"
														alignItems="center"
														mt=".5rem"
														ml=".5rem"
													>
														<Button
															color="#fff"
															bg="#000"
															onClick={() => {
																if (transitCounter > 0)
																	setTransitCounter(transitCounter - 1);
															}}
															disabled={transitCounter === 0}
															alignItems="center"
															size="xs"
														>
															-
														</Button>
														<Text>{transitCounter}</Text>
														<Button
															color="#fff"
															bg="#000"
															onClick={() =>
																setTransitCounter(transitCounter + 1)
															}
															disabled={transitCounter === 0}
															alignItems="center"
															size="xs"
														>
															+
														</Button>
													</Box>
												</Flex>
												<Flex flexDirection="column">
													<Text ml="1.5em">Delivered</Text>
													<Box
														borderRadius="md"
														bg="#fff"
														display="flex"
														gap="1em"
														alignItems="center"
														mt=".5rem"
														ml=".5rem"
													>
														<Button
															color="#fff"
															bg="#000"
															onClick={() => {
																if (deliveredCounter > 0)
																	setDeliveredCounter(deliveredCounter - 1);
															}}
															disabled={deliveredCounter === 0}
															alignItems="center"
															size="xs"
														>
															-
														</Button>
														<Text>{deliveredCounter}</Text>
														<Button
															color="#fff"
															bg="#000"
															onClick={() =>
																setDeliveredCounter(deliveredCounter + 1)
															}
															disabled={deliveredCounter === 0}
															alignItems="center"
															size="xs"
														>
															+
														</Button>
													</Box>
												</Flex>
											</Box>
											<Box display={"flex"} width="100%" mt="2rem">
												<Button
													bg="#4BA18A"
													color="#fff"
													fontWeight="bold"
													type="submit"
													width="100%"
													_hover={{ bg: "#38846D" }}
												>
													Update Order
												</Button>
											</Box>
										</Form>
									);
								}}
							</Formik>
						)}
					</>
				);
			},
		},
		{
			icon: IoTimeOutline,
			title: "Previous updates",
			content: () => {
				return (
					<>
						<Box height="auto">
							{partUpdates?.length > 0 ? (
								partUpdates?.map((each: any, index: number) => (
									<Box key={index}>
										<Stepper update={each} />
										{/* <Divider variant="solid" border="1px solid Gray55 " /> */}
									</Box>
								))
							) : (
								<NoDataAvailable />
							)}
						</Box>
					</>
				);
			},
		},
	];
	const tagColor = getStatusColor(purchaseOrder?.portal_status);
	return (
		<Box width="100%">
			<Show above="md">
				<OrderStatusBar
					poId={purchaseOrder?.name}
					status={purchaseOrder?.portal_status}
					// button={"Mark as Complete"}
				/>
			</Show>
			<Show below="md">
				<Box bg="#fff" p="0.75rem 1rem" mt="1px">
					<Flex justifyContent="space-between" alignItems="center">
						<Text mr="1em">
							<span style={{ fontWeight: "bold" }}>PO ID:</span>&nbsp;
							{purchaseOrder?.name}
						</Text>
						<Tag bg={tagColor.bg} color={tagColor.color}>
							{purchaseOrder?.portal_status}
						</Tag>
					</Flex>
				</Box>
			</Show>
			<Show above="md">
				<Flex
					m="1rem"
					p="1rem"
					boxShadow="md"
					borderRadius="md"
					flexWrap="wrap"
					alignItems="center"
					justifyContent="space-between"
					gap="2rem"
					bg="#fff"
				>
					<Flex gap="2em" flexWrap="wrap">
						{!directPOFromProcurement && (
							<KeyValue
								title={"RFQ Name"}
								val={purchaseOrder?.rfq_name || purchaseOrder?.name}
							/>
						)}
						{!directPOFromProcurement && (
							<KeyValue
								title={"RFQ ID"}
								val={purchaseOrder?.opportunity?.split("|")[1]}
							/>
						)}
						{directPOFromProcurement && (
							<KeyValue
								title={"Company Name"}
								val={
									purchaseOrder?.company_name || purchaseOrder?.customer_name
								}
							/>
						)}
						<KeyValue
							title={directPOFromProcurement ? "Buyer Name" : "Created By"}
							val={
								purchaseOrder?.requester ||
								getDataInsideParentheses(purchaseOrder?.customer)
							}
						/>
						{!directPOFromProcurement && (
							<KeyValue
								title={"RFQ Date"}
								val={normalDate(purchaseOrder?.transaction_date)}
							/>
						)}
						{!directPOFromProcurement && (
							<KeyValue title={"PO Ref Id"} val={purchaseOrder?.po_no} />
						)}
						{directPOFromProcurement && (
							<KeyValue
								title="Phone number"
								val={purchaseOrder?.customer_mobile_no}
							/>
						)}
						{directPOFromProcurement && (
							<KeyValue title="Email" val={purchaseOrder?.customer_email} />
						)}
					</Flex>
					<Flex alignItems="center" gap="2em">
						{directPOFromProcurement ? (
							<Flex
								alignItems="center"
								gap=".25rem"
								cursor="pointer"
								onClick={() => setViewPartsAndAttachments(true)}
							>
								<BsPaperclip color="#4BA18A" size="18px" />
								<Text
									color="#4BA18A"
									fontWeight={600}
									fontSize="1rem"
									textDecoration="underline"
								>
									View Parts & Attachments
								</Text>
							</Flex>
						) : (
							<Box>
								<Text
									color="#4BA18A"
									fontWeight="bold"
									textDecoration="underline"
									onClick={() => setViewRfq(true)}
									cursor="pointer"
								>
									View RFQ
								</Text>
							</Box>
						)}

						<Box>
							<Lk to={`/ordersporeceived/${purchaseOrder?.name}`}>
								<Button
									size="md"
									borderRadius={"5px"}
									bg="#fff"
									color="#4BA18A"
									m={"1em 1em 1em 0.5em"}
									colorScheme={"teal"}
									variant="outline"
									fontWeight="bold"
									_hover={{ bg: "#E2F1EE" }}
								>
									View Purchase Order
								</Button>
							</Lk>
						</Box>
					</Flex>
				</Flex>
			</Show>
			<Show below="md">
				<Box m="0.5rem" gap="1rem">
					<ViewOrdersCard
						name={purchaseOrder?.name}
						isDownload={false}
						companyName={
							purchaseOrder?.company_name || purchaseOrder?.customer_name
						}
						rfqName={purchaseOrder?.rfq_name || purchaseOrder?.name}
						rfqId={purchaseOrder?.opportunity?.split("|")[1]}
						receivedOn={normalDate(purchaseOrder?.transaction_date)}
						poId={purchaseOrder?.po_no}
						setViewRfq={setViewRfq}
						createdBy={
							purchaseOrder?.requester ||
							getDataInsideParentheses(purchaseOrder?.customer)
						}
					/>
				</Box>
			</Show>

			{/* Tabs */}
			<Box
				m="1rem"
				mb="6rem"
				p="1rem 1rem 0.5rem"
				bg="#fff"
				borderRadius="0.75rem"
				boxShadow="md"
			>
				<Tabs>
					<TabList>
						<Tab
							color={activeTab === 0 ? "#4BA18A" : "rgba(0,0,0,0.4)"}
							fontWeight={activeTab === 0 ? "bold" : ""}
							onClick={() => handleTabClick(0)}
							_active={{ color: "#4BA18A", fontWeight: "bold" }}
							width={isMobileTablet ? "67%" : "100%"}
						>
							Order Updates
						</Tab>

						{directPOFromProcurement ? (
							<Tab
								color={activeTab === 1 ? "#4BA18A" : "rgba(0,0,0,0.4)"}
								fontWeight={activeTab === 1 ? "bold" : ""}
								onClick={() => handleTabClick(1)}
								_active={{ color: "#4BA18A", fontWeight: "bold" }}
								width={isMobileTablet ? "67%" : "100%"}
							>
								Order Documents
							</Tab>
						) : (
							<Tab
								color={activeTab === 1 ? "#4BA18A" : "rgba(0,0,0,0.4)"}
								fontWeight={activeTab === 1 ? "bold" : ""}
								onClick={() => handleTabClick(1)}
								_active={{ color: "#4BA18A", fontWeight: "bold" }}
								width={isMobileTablet ? "67%" : "100%"}
							>
								Part Updates
							</Tab>
						)}
					</TabList>
					<TabPanels>
						<TabPanel p={0}>
							{/* for computer screens */}
							<Show above="md">
								{directPOFromProcurement ? (
									<Chat
										salesOrderId={purchaseOrder?.name}
										customerName={
											purchaseOrder?.company_name ||
											purchaseOrder?.customer ||
											purchaseOrder?.requester
										}
									/>
								) : (
									<Box>
										{purchaseOrder?.portal_status === "Live" && (
											<Formik
												initialValues={{
													date: "",
													status: "",
													comments: "",
												}}
												validationSchema={orderUpdateValidation}
												enableReinitialize={true}
												onSubmit={updateOrder}
											>
												{(formik) => {
													return (
														<Form>
															<Grid
																templateColumns={{
																	base: "repeat(1,1fr)",
																	md: "repeat(2,1fr)",
																}}
																gap={12}
																mt="1rem"
															>
																<GridItem>
																	<RenderTextField
																		name="date"
																		label="Date"
																		placeholder="Add Date"
																		type="date"
																		isMandatory={true}
																	/>
																</GridItem>

																<GridItem>
																	<RenderDropDown
																		name="status"
																		label="Status"
																		placeholder="Status"
																		isMandatory={true}
																		values={[
																			"In Production",
																			"Raw Material Acquired",
																			"Partially Shipped",
																			"In Transit",
																			"Delivered",
																		]}
																	/>
																</GridItem>
															</Grid>
															<Box mt="1.75rem">
																<RenderTextAreaField
																	name="comments"
																	label="Order Update"
																	placeholder="Add your update here"
																	padding=".75rem 1rem"
																	size="xl"
																	height="4.8rem"
																	borderRadius={"5px"}
																	isMandatory={true}
																/>
															</Box>
															<Box mt="1.5rem">
																<Text
																	color={"black"}
																	fontSize={"18px"}
																	fontWeight={"semibold"}
																	mb="0.5rem"
																>
																	Add Attachments
																</Text>
																<Box overflowX="scroll">
																	{selectedFiles?.length > 0 ? (
																		<Box>
																			<Text ml="1em">
																				{selectedFiles.length}&nbsp; files
																				uploaded
																			</Text>
																			<Box mt="1rem" ml="1rem">
																				<FilesSlider
																					files={selectedFiles}
																					// alternativeImg={alternativeImg}
																					deleteIcon={true}
																					deleteFunction={deleteFileConfirm}
																				/>
																			</Box>
																		</Box>
																	) : (
																		<Text fontStyle="Rubik">
																			No files uploaded
																		</Text>
																	)}
																</Box>
																<UploadDoc
																	isMultiple={true}
																	updateFile={updateFile}
																/>
															</Box>

															<Box
																display={"flex"}
																justifyContent="flex-end"
																mt="2em"
															>
																<Button
																	type="submit"
																	bg="#4BA18A"
																	color="#fff"
																	fontWeight="bold"
																	_hover={{ bg: "#38846D" }}
																>
																	Update Order
																</Button>
															</Box>
														</Form>
													);
												}}
											</Formik>
										)}

										<Text
											color={"black"}
											fontSize={"18px"}
											fontWeight={"semibold"}
											mt="1em"
											mb=".25em"
										>
											Previous Updates
										</Text>
										{orderUpdates?.length > 0 ? (
											orderUpdates
												.sort(
													(a: any, b: any) =>
														new Date(b.date) - new Date(a.date)
												)
												.map((each: any, i: number) => (
													<Box mt="1.25rem" key={i}>
														<OrdersUpdateBar
															status={each.status}
															timestamp={each.date}
															orderUpdate={each.comments}
															attachmentsLink={each.name}
														/>
													</Box>
												))
										) : (
											<NoDataAvailable />
										)}
									</Box>
								)}
							</Show>
							{/* for mobile screens */}
							<Show below="md">
								<Accordion allowMultiple gap={"1rem"}>
									{accordionArray
										.filter(
											(items, index) =>
												purchaseOrder?.portal_status !== "complete" ||
												index !== 0
										)
										.map((items, index) => {
											return (
												<AccordionItem key={index} border={"none"}>
													<AccordionButton
														bg={"#E2F1EE"}
														border={"1px solid #4BA18A"}
														color="#4BA18A"
														mb={"1rem"}
														mt="0.6rem"
														borderRadius={"md"}
													>
														<Box
															display={"flex"}
															flexDir={"row"}
															gap="1rem"
															as="span"
															flex={1}
														>
															<Box>
																<Icon as={items?.icon} />
															</Box>
															<Box>{items?.title}</Box>
														</Box>

														<AccordionIcon />
													</AccordionButton>
													<AccordionPanel>{items?.content()}</AccordionPanel>
												</AccordionItem>
											);
										})}
								</Accordion>
							</Show>
						</TabPanel>
						{directPOFromProcurement ? (
							//order documents
							<TabPanel p={0}>
								<OrderDocuments salesOrderId={purchaseOrder.name} />
							</TabPanel>
						) : (
							// part updates
							<TabPanel p={0}>
								<Show above="md">
									{purchaseOrder?.portal_status === "Live" && (
										<Formik
											initialValues={{
												// date: "",
												part: "",
											}}
											validationSchema={partUpdateValidation}
											enableReinitialize={true}
											onSubmit={updatePart}
										>
											{(formik) => {
												return (
													<Form>
														<Grid
															templateColumns={{
																base: "repeat(1,1fr)",
																md: "repeat(2,1fr)",
															}}
															gap={12}
															mt="1rem"
														>
															<GridItem>
																<RenderDropDown
																	name="part"
																	label="Select Part Name"
																	placeholder="Select Part Name"
																	isMandatory={true}
																	values={purchaseOrder?.items?.map(
																		(item: any) =>
																			item.item_code +
																			"(" +
																			item.item_name +
																			")"
																	)}
																/>
															</GridItem>

															{/* <GridItem>
														<RenderTextField
															name="date"
															label="Due Date"
															placeholder="Add Date"
															type="date"
															isMandatory={true}
														/>
													</GridItem> */}
														</Grid>
														<Box
															display="flex"
															gap="2em"
															alignItems="center"
															mt="2rem"
														>
															<Flex flexDirection="column" alignSelf="center">
																<Text>Manufacturing</Text>
																<Box
																	borderRadius="md"
																	bg="#fff"
																	display="flex"
																	gap="1em"
																	alignItems="center"
																	mt=".5rem"
																	ml=".5rem"
																>
																	<Button
																		color="#fff"
																		bg="#000"
																		onClick={() => {
																			if (mancounter > 0)
																				setManCounter(mancounter - 1);
																		}}
																		disabled={mancounter === 0}
																		alignItems="center"
																		size="xs"
																	>
																		-
																	</Button>
																	<Text>{mancounter}</Text>
																	<Button
																		color="#fff"
																		bg="#000"
																		onClick={() =>
																			setManCounter(mancounter + 1)
																		}
																		disabled={mancounter === 0}
																		alignItems="center"
																		size="xs"
																	>
																		+
																	</Button>
																</Box>
															</Flex>
															<Flex flexDirection="column" alignSelf="center">
																<Text ml="1.5em">In Transit</Text>
																<Box
																	borderRadius="md"
																	bg="#fff"
																	display="flex"
																	gap="1em"
																	alignItems="center"
																	mt=".5rem"
																	ml=".5rem"
																>
																	<Button
																		color="#fff"
																		bg="#000"
																		onClick={() => {
																			if (transitCounter > 0)
																				setTransitCounter(transitCounter - 1);
																		}}
																		disabled={transitCounter === 0}
																		alignItems="center"
																		size="xs"
																	>
																		-
																	</Button>
																	<Text>{transitCounter}</Text>
																	<Button
																		color="#fff"
																		bg="#000"
																		onClick={() =>
																			setTransitCounter(transitCounter + 1)
																		}
																		disabled={transitCounter === 0}
																		alignItems="center"
																		size="xs"
																	>
																		+
																	</Button>
																</Box>
															</Flex>
															<Flex flexDirection="column" alignSelf="center">
																<Text ml="1.5em">Delivered</Text>
																<Box
																	borderRadius="md"
																	bg="#fff"
																	display="flex"
																	gap="1em"
																	alignItems="center"
																	mt=".5rem"
																	ml=".5rem"
																>
																	<Button
																		color="#fff"
																		bg="#000"
																		onClick={() => {
																			if (deliveredCounter > 0)
																				setDeliveredCounter(
																					deliveredCounter - 1
																				);
																		}}
																		disabled={deliveredCounter === 0}
																		alignItems="center"
																		size="xs"
																	>
																		-
																	</Button>
																	<Text>{deliveredCounter}</Text>
																	<Button
																		color="#fff"
																		bg="#000"
																		onClick={() =>
																			setDeliveredCounter(deliveredCounter + 1)
																		}
																		disabled={deliveredCounter === 0}
																		alignItems="center"
																		size="xs"
																	>
																		+
																	</Button>
																</Box>
															</Flex>
														</Box>
														<Box
															display={"flex"}
															justifyContent="flex-end"
															mt="2rem"
														>
															<Button
																bg="#4BA18A"
																color="#fff"
																fontWeight="bold"
																type="submit"
																_hover={{ bg: "#38846D" }}
															>
																Update Order
															</Button>
														</Box>
													</Form>
												);
											}}
										</Formik>
									)}

									<Text
										fontSize={"18px"}
										fontWeight={"semibold"}
										mt="1em"
										mb="1em"
									>
										Previous Updates
									</Text>

									<Box
										height="auto"
										border="1px solid Gray"
										p="1.5rem 1.875rem"
										borderRadius="1em"
									>
										{partUpdates?.length > 0 ? (
											partUpdates?.map((each: any, index: number) => (
												<Box key={index}>
													<Stepper update={each} />
													<Divider variant="solid" border="1px solid Gray55 " />
												</Box>
											))
										) : (
											<NoDataAvailable />
										)}
									</Box>
								</Show>
								<Show below="md">
									<Accordion>
										{partUpdateAccordion
											?.filter(
												(items, index) =>
													purchaseOrder?.portal_status !== "complete" ||
													index !== 0
											)
											.map((items, index) => {
												return (
													<AccordionItem key={index} border={"none"}>
														<AccordionButton
															bg={"#E2F1EE"}
															border={"1px solid #4BA18A"}
															color="#4BA18A"
															mb={"1rem"}
															mt="0.6rem"
															borderRadius={"md"}
														>
															<Box
																display={"flex"}
																flexDir={"row"}
																gap="1rem"
																as="span"
																flex={1}
															>
																<Box>
																	<Icon as={items?.icon} />
																</Box>
																<Box>{items?.title}</Box>
															</Box>

															<AccordionIcon />
														</AccordionButton>
														<AccordionPanel>{items?.content()}</AccordionPanel>
													</AccordionItem>
												);
											})}
									</Accordion>
								</Show>
							</TabPanel>
						)}
					</TabPanels>
				</Tabs>
			</Box>

			{/* view RFQ popup */}
			<Show above="md">
				<BasicDialog
					isOpen={viewRfq}
					showCloseButton={true}
					onClose={() => setViewRfq(false)}
					header="Request for Quotation"
					content={
						<Box height="80vh" overflow="scroll">
							<ViewRfq rfqId={purchaseOrder?.opportunity} />
						</Box>
					}
					size="6xl"
				/>
				<BasicDialog
					isOpen={viewPartsAndAttachments}
					showCloseButton={true}
					onClose={() => setViewPartsAndAttachments(false)}
					header="View Parts & Attachments"
					content={
						<Box height="80vh" overflow="scroll">
							<PartsAndAttachments
								salesOrder={purchaseOrder}
								getDataInsideParentheses={getDataInsideParentheses}
							/>
						</Box>
					}
					size="6xl"
				/>
			</Show>

			<Show below="md">
				<Box
					bgColor="#fff"
					position={"fixed"}
					bottom={0}
					left={0}
					right={0}
					gap="1em"
					p="1rem"
				>
					{purchaseOrder?.portal_status !== "complete" && (
						<Button
							bg="#63B29D"
							color="#fff"
							_hover={{ bg: "#E2F1EE" }}
							width="100%"
							onClick={() => setIsMark(true)}
							fontSize="14px"
						>
							Mark as Complete
						</Button>
					)}
				</Box>
			</Show>
			<Show below="md">
				<Overlay isOpen={isMark}>
					<BottomSheet isOpen={isMark} onClose={() => setIsMark(false)}>
						<Text
							fontSize="14px"
							fontWeight="bold"
							color="#4BA18A"
							textAlign="center"
							mb="1rem"
						>
							Mark as Complete
						</Text>
						<Center w="75%" mx="auto">
							<img src={completed} />
						</Center>
						<Center>
							<Text mt="4" textAlign="center" fontSize={"18px"}>
								On marking this order as Complete, you will not be able to add
								any order updates.Are you sure want to mark the order as
								complete?
							</Text>
						</Center>
						<Flex
							flexDir={"row"}
							gap="1.5rem"
							justifyContent={"center"}
							alignItems={"center"}
							mt="1rem"
							mb="2rem"
						>
							<Button onClick={() => setIsMark(false)} mr="1em" variant="ghost">
								Cancel
							</Button>
							<Button
								onClick={handleConfirmRFQPopup}
								bg="#4BA18A"
								_hover={{ bg: "#38846D" }}
								color="#fff"
							>
								Accept
							</Button>
						</Flex>
					</BottomSheet>
				</Overlay>

				<Overlay isOpen={viewRfq}>
					<BottomSheet
						isOpen={viewRfq}
						// showCloseButton={true}
						onClose={() => setViewRfq(false)}
						padding={0}
					>
						<Text
							textAlign="center"
							fontSize="18px"
							color="#4ba18a"
							fontWeight="bold"
							my="1rem"
						>
							Request For Quotation
						</Text>
						<Box>
							<ViewRfq rfqId={purchaseOrder?.opportunity} />
						</Box>
					</BottomSheet>
				</Overlay>
			</Show>
		</Box>
	);
};

export default OrdersLive;
