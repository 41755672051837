import React, { useState, useEffect, useRef } from "react";
import { Box, Text, Flex, Textarea, Image } from "@chakra-ui/react";
import Cookies from "js-cookie";
import { FiPaperclip } from "react-icons/fi";
import {
	FilesSlider,
	ThemeButton,
	File,
	normalDate,
	type AppDispatch,
	setPageLoading,
	SET_MESSAGE,
	ERROR_MSG,
	formatErrorMessage,
	frappeMethodService,
	Thumbnail,
	correctImageOrientation,
} from "@karkhanaui/react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { BsArrowUp } from "react-icons/bs";
import NoUpdates from "../../img/NoUpdates.png";

const Chat = (props: any) => {
	const dispatch = useDispatch<AppDispatch>();
	const supplierName = Cookies.get("full_name") || "S";
	const [message, setMessage] = useState<string>("");
	const [customerName, setCustomerName] = useState<string>("C");
	const [prevMessages, setPrevMessages] = useState<any>([]);
	const [selectedFiles, setSelectedFiles] = useState<any>([]);
	const [sendButtonLoading, setSendButtonLoading] = useState<boolean>(false);
	const lastChatRef = useRef(null);
	const firstChatRef = useRef(null);

	const onDrop = React.useCallback(
		async (acceptedFiles: any) => {
			setSendButtonLoading(true);
			const files = await acceptedFiles?.map(async (item: any) => {
				if (item.type === "image/jpeg") {
					item = await correctImageOrientation(item).catch((error: any) => {
						console.error(
							"Error correcting orientation for:",
							item.name,
							error
						);
						return item; // Use the original file if correction fails
					});
				}
				return item;
			});

			const uploadedFiles = (await Promise.all(files)).filter(Boolean);
			setSelectedFiles([...selectedFiles, ...uploadedFiles]);
			setSendButtonLoading(false);
		},
		[selectedFiles]
	);
	const {
		getRootProps,
		getInputProps,
		open,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		onDrop,
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: true,
	});

	const deleteSelectedFile = (file: any) => {
		const updatedFiles = selectedFiles.filter((f: any) => f !== file);
		setSelectedFiles(updatedFiles);
	};

	const transformToDocs = (data: any) => {
		return data?.map((each: any) => ({
			active: false,
			date: normalDate(each.creation),
			fileName: each.file_name,
			fileSize: `${Math.round(each.file_size / 1024)}KB`,
			imgSrc: process.env.REACT_APP_LIVE_URL + each.file_url,
			allowDownload: true,
			thumbnail: (
				<Thumbnail
					fileUrl={process.env.REACT_APP_LIVE_URL + each.file_url}
					isPrivateFile={true}
				/>
			),
			isPrivateFile: each.is_private,
		}));
	};

	function formatDateTime(inputString: string) {
		const months = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		const dateParts = inputString.split(" ")[0].split("-");
		const timeParts = inputString.split(" ")[1].split(":");

		const year = dateParts[0];
		const month = months[parseInt(dateParts[1]) - 1];
		const day = dateParts[2];
		let hour = parseInt(timeParts[0]);
		const minute = timeParts[1];

		// Format hour and determine AM/PM
		let ampm = "AM";
		if (hour >= 12) {
			ampm = "PM";
		}
		if (hour > 12) {
			hour -= 12;
		}

		// Pad single-digit hour with a leading zero
		const formattedHour = hour.toString().padStart(2, "0");

		const formattedDateTime = `${formattedHour}:${minute} ${ampm} ${month} ${day}`;
		return formattedDateTime;
	}

	const transformChat = async (chats: any) => {
		let _chats: any = [];
		await chats?.map((chat: any) => {
			_chats.push({
				time: formatDateTime(chat.creation),
				content: chat.chattext,
				isSupplier: chat.status === "Received" ? false : true,
				files: transformToDocs(chat.files),
			});
		});
		return _chats;
	};

	const getChat = async () => {
		try {
			dispatch(setPageLoading({ loading: true, loadingText: "Loading..." }));
			const chats = await frappeMethodService("karkhanachat.get_chat", {
				payload: {
					doctype: "Sales Order",
					doc_id: props?.salesOrderId,
				},
			});
			if (chats.status === 200) {
				const _prevChats = await transformChat(chats.data?.data);
				setPrevMessages([..._prevChats]);
				dispatch(setPageLoading({ loading: false, loadingText: "" }));
			}
		} catch (error) {
			dispatch(setPageLoading({ loading: false, loadingText: "" }));
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	const sendMessage = async () => {
		setSendButtonLoading(true);
		let formData = new FormData();
		selectedFiles?.map((item: any, index: number) => {
			formData.append(`file_${index + 1}`, item);
		});
		formData.append("doctype", "Sales Order");
		formData.append("doc_id", props.salesOrderId);
		formData.append("chattext", message);
		try {
			const sendMessage = await frappeMethodService(
				"karkhanachat.send_chat",
				formData
			);
			if (sendMessage?.status === 200) {
				getChat();
				setSendButtonLoading(false);
				setMessage("");
				setSelectedFiles([]);
			}
		} catch (error) {
			setSendButtonLoading(false);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	useEffect(() => {
		getChat();
		setCustomerName(props?.customerName);
	}, [props?.salesOrderId]);

	useEffect(() => {
		if (lastChatRef.current) {
			lastChatRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	}, [prevMessages]);

	const getRef = (index: number, totalMessageCount: number) => {
		if (index === 0) {
			return firstChatRef;
		} else if (index === totalMessageCount - 1) {
			return lastChatRef;
		}
	};

	const scrollToFirstChat = () => {
		if (firstChatRef) {
			firstChatRef.current.scrollIntoView({
				behavior: "smooth",
				block: "end",
			});
		}
	};

	return (
		<Box>
			{prevMessages.length > 0 ? (
				<Box h="38.5vh" overflowY="auto" pb=".5rem">
					{/* <Flex
					alignItems="center"
					justifyContent="center"
					mt="1.5rem"
					cursor="pointer"
					onClick={() => scrollToFirstChat()}
				>
					<ThemeButton
						children="View all updates"
						bg="#4BA18A"
						position="fixed"
						rightIcon={<BsArrowUp size="1rem" />}
						borderRadius="2.5625rem"
						zIndex={10}
						onClick={() => scrollToFirstChat()}
					/>
				</Flex> */}
					{prevMessages?.map((message: any, index: number) => (
						<Flex
							alignItems="flex-start"
							justifyContent={message.isSupplier ? "flex-end" : "flex-start"}
							gap=".5rem"
							mt="1.25rem"
							key={index}
							// ref={getRef(index, prevMessages.length)}
							ref={index === prevMessages.length - 1 ? lastChatRef : null}
						>
							<Flex
								order={message.isSupplier ? 2 : 1}
								alignItems="center"
								justifyContent="center"
								height="2.8125rem"
								width="2.8125rem"
								bg={
									message.isSupplier
										? "rgba(75, 161, 138, 0.05)"
										: "rgba(0, 135, 238, 0.05)"
								}
								borderRadius="50%"
							>
								<Text
									fontSize="1.40625rem"
									color={message.isSupplier ? "#4BA18A" : "#0087EE"}
									fontWeight={600}
								>
									{message.isSupplier
										? supplierName[0]?.toUpperCase()
										: customerName[0]?.toUpperCase()}
								</Text>
							</Flex>
							<Flex
								order={message.isSupplier ? 1 : 2}
								bg={
									message.isSupplier
										? "rgba(75, 161, 138, 0.05)"
										: "rgba(0, 135, 238, 0.05)"
								}
								borderRadius=".5rem"
								p=".75rem"
								flexDir="column"
								alignItems={message.isSupplier ? "flex-end" : "flex-start"}
								maxW="80%"
							>
								<Text
									fontSize="1.125rem"
									color={message.isSupplier ? "#4BA18A" : "#0087EE"}
									fontWeight={600}
								>
									{message.isSupplier ? supplierName : customerName}
								</Text>
								<Text
									textStyle="primary.secondaryText"
									fontWeight={400}
									fontSize=".75rem"
								>
									{message.time}
								</Text>
								<Text
									textAlign={message.isSupplier ? "right" : "left"}
									mt=".75rem"
								>
									{message.content}
								</Text>
								<Flex
									alignItems="center"
									justifyContent="flex-start"
									mt=".75rem"
									gap=".5rem"
									w="100%"
									overflowX="auto"
									// flexWrap="wrap"
								>
									{message.files?.map((item: any, i: number) => (
										<Box key={i}>
											<File
												active={false}
												date={item.date}
												fileName={item.fileName}
												fileSize={item.size}
												imgSrc={item.imgSrc}
												allowDownload={true}
												thumbnail={item.thumbnail}
												colorTheme={message.isSupplier ? "green" : ""}
											/>
										</Box>
									))}
								</Flex>
							</Flex>
						</Flex>
					))}
				</Box>
			) : (
				<Flex
					h="38.5vh"
					alignItems="center"
					justifyContent="center"
					flexDir="column"
					gap=".625rem"
				>
					<Text
						textStyle="primary.secondaryText"
						fontSize="1rem"
						fontWeight={600}
						lineHeight="1.5rem"
					>
						No previous updates found
					</Text>
					<Image src={NoUpdates} height="50%" />
				</Flex>
			)}
			<Box
				boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
				p=".75rem"
				{...getRootProps()}
			>
				<Flex alignItems="flex-start">
					<Flex
						alignItems="center"
						justifyContent="center"
						height="2.8125rem"
						width="2.8125rem"
						bg="rgba(75, 161, 138, 0.05)"
						borderRadius="50%"
					>
						<Text fontSize="1.40625rem" color="#4BA18A" fontWeight={600}>
							{supplierName[0]?.toUpperCase()}
						</Text>
					</Flex>
					<Textarea
						placeholder="Add a message"
						border="none"
						value={message}
						onChange={(e: any) => setMessage(e.target.value)}
					/>
				</Flex>
				<hr />
				<Flex
					alignItems="center"
					justifyContent="space-between"
					gap="1rem"
					mt=".625rem"
				>
					<Flex width="90%" overflowX="auto">
						<FilesSlider
							files={selectedFiles}
							deleteIcon={true}
							deleteFunction={deleteSelectedFile}
							hideDownloadIcon={true}
						/>
					</Flex>
					<Flex alignItems="center" gap=".75rem" minWidth="10%">
						<input {...getInputProps({})} disabled={sendButtonLoading} />
						<FiPaperclip
							size="1.5rem"
							cursor="pointer"
							onClick={() => {
								if (!sendButtonLoading) {
									open();
								}
							}}
						/>
						<ThemeButton
							children="Send"
							bg="#4BA18A"
							isDisabled={
								message.trim().length < 1 && selectedFiles?.length < 1
							}
							onClick={() => sendMessage()}
							isLoading={sendButtonLoading}
						/>
					</Flex>
				</Flex>
			</Box>
		</Box>
	);
};

export default Chat;
