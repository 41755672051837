import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { theme, store } from '@karkhanaui/react';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
import './service-worker'

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </Provider>
);

if (process.env.NODE_ENV !== 'production') {
  const parcelSocket = new WebSocket('ws://localhost:1234/');
  parcelSocket.onmessage = () => {
    location.reload();
  };
}
