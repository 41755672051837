import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { getFileType, DisplayFile, FilesSlider } from "@karkhanaui/react";
import alternateImage from "../../img/k.svg"; // eslint-disable-line
import NoFilesAdded from "../../img/NoFilesAdded.png";
import { useFullScreenHandle } from "react-full-screen";

const PartConfigureLeftSide: React.FC<any> = (props) => {
	const [attachedFiles, setAttachedFiles] = React.useState<any>();
	const [selectedFile, setSelectedFile] = React.useState<any>();
	const handle = useFullScreenHandle();

	useEffect(() => {
		const newList: any = [];
		props.attachedFiles?.map((item: any) => {
			newList.push({
				fileId: item.name,
				subtitle: item.file_name,
				file:
					props.dataOriginatedFrom === "procurement"
						? process.env.REACT_APP_PROCUREMENT_URL + item.file_url
						: process.env.REACT_APP_LIVE_URL + item.file_url,
				isPrivateFile: item.is_private,
			});
		});
		setAttachedFiles(newList);

		let isCadFileAvailable = false;
		for (let i = 0; i < newList?.length; i++) {
			if (getFileType(newList[i].file) === "CAD_File") {
				isCadFileAvailable = true;
				setSelectedFile(newList[i]);
				break;
			}
		}
		if (isCadFileAvailable === false) {
			setSelectedFile(newList[0]);
		}
	}, [props]);

	const onClickFunction = (item: any) => {
		setSelectedFile(item);
	};

	return (
		<Box>
			{attachedFiles?.length > 0 ? (
				<Box>
					{selectedFile?.file != undefined && (
						<Flex
							alignItems="center"
							justifyContent="center"
							objectFit="contain"
						>
							<DisplayFile
								file={selectedFile.file}
								isPrivate={selectedFile.isPrivateFile}
							/>
						</Flex>
					)}
					<hr style={{ marginTop: "1rem" }} />
					<Box mt="1.5rem">
						<FilesSlider
							onClickFunction={onClickFunction}
							alternativeImg={alternateImage}
							files={attachedFiles}
						/>
					</Box>
				</Box>
			) : (
				<Flex
					alignItems="center"
					justifyContent="center"
					objectFit="contain"
					bg="rgba(226, 241, 238, 0.50)"
					borderRadius=".75rem"
					width="100%"
					height={{ base: "200px", md: "370px" }}
					flexDir="column"
					gap="1.75rem"
				>
					<Image src={NoFilesAdded} width="50%" />
					<Text fontSize="1rem">No files added</Text>
				</Flex>
			)}
		</Box>
	);
};

export default PartConfigureLeftSide;
