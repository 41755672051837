import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  Grid,
  Link as ChakraLink,
  chakra,
} from "@chakra-ui/react";
import OrderStatusBar from "./OrderStatusBar";
import { MdAttachFile } from "react-icons/md";
import {
  normalDate,
  BasicDialog,
  FileViewCard,
  Overlay,
  BottomSheet,
} from "@karkhanaui/react";

interface OrdersUpdateBarProps {
  status: string;
  timestamp: string;
  orderUpdate: string;
  attachmentsLink: string;
}

const OrderUpdateStatusMobile: React.FC<OrdersUpdateBarProps> = ({
  status,
  timestamp,
  orderUpdate,
  attachmentsLink,
}) => {
  const [showViewFiles, setShowViewFiles] = useState<boolean>(false);
  return (
    <Box
      fontSize="12px"
      borderRadius="lg"
      boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
      padding="1em 1.5em"
      width={"100%"}
      margin="0em auto"
      bgColor="#fff"
    >
      <Flex marginBottom="1em" flexDir={"column"}>
        <Flex flexDir={"row"} justifyContent={"space-between"} gap="1rem">
          <Text fontWeight="bold">
            <chakra.span color="#4BA18A">Status:&nbsp;</chakra.span>
            {status}
          </Text>
          <Text color="#4BA18A" fontWeight="bold" justifySelf="end">
            {/* future update */}
            {/* {`${timestamp.slice(11, 16)}${
							Number(timestamp.slice(11, 12)) < 12 ? "AM" : "PM"
						} on ${normalDate(timestamp)}`} */}
            {normalDate(timestamp)}
          </Text>
        </Flex>
        <Box width="100%">
          <Text mt="1.5rem">{orderUpdate}</Text>
        </Box>

        <Flex
          alignItems="center"
          gap=".5rem"
          justifyContent="flex-end"
          cursor="pointer"
          onClick={() => setShowViewFiles(true)}
        >
          <MdAttachFile size="20px" color="#4BA18A" />
          <Text color="#4BA18A" fontWeight="bold" textDecoration="underline">
            Attachments
          </Text>
        </Flex>
      </Flex>

      <Overlay isOpen={showViewFiles}>
        <BottomSheet
          isOpen={showViewFiles}
          onClose={() => setShowViewFiles(false)}
        >
          <Text
            fontSize="14px"
            fontWeight="bold"
            color="#4BA18A"
            textAlign="center"
            mb="1rem"
          >
            View Attachments
          </Text>
          <FileViewCard
            isStepT={true}
            item_id={attachmentsLink}
            environmentVariable={process.env.REACT_APP_LIVE_URL}
            documentName="Client Order Update"
          />
        </BottomSheet>
      </Overlay>

    
    </Box>
  );
};

export default OrderUpdateStatusMobile;
