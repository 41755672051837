import React from "react";
import { Box, Flex, Text, Button, Image, Show } from "@chakra-ui/react";
import {
	RenderTextField,
	yupString,
	yupContact,
	yupEmail,
	RenderPhoneField,
} from "@karkhanaui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InviteSent } from "../../img";

const AddCustomer = (props: any) => {
	return (
		<Box width="100%">
			{props?.inviteSent ? (
				<Flex
					flexDir="column"
					alignItems="center"
					p={{ base: ".5rem", md: "2rem" }}
				>
					<Image src={InviteSent} />
					<Text
						fontSize="1.125rem"
						mt={{ base: "1.375rem", md: "3.375rem" }}
						textAlign="center"
						mb={{ base: "2.2rem", md: "0" }}
					>
						Invite Sent to&nbsp;"{props.companyName}"
					</Text>
					<Show below="md">
						<Flex
							alignItems="center"
							justifyContent="space-between"
							pos="sticky"
							gap="1rem"
							left="0"
							bottom="0"
							zIndex={1}
							p="1rem"
							w="100vw"
							bg="#fff"
							boxShadow="dark-lg"
						>
							<Button
								children="Close"
								bg="#fff"
								border="1px solid #000"
								width="full"
								height="2.625rem"
								flex={1}
								onClick={props.closeFunction}
							/>
							<Button
								children="Add more Customers"
								backgroundColor="#4BA18A"
								color="white"
								width="full"
								height="2.625rem"
								flex={1.6}
								_hover={{ bg: "#38846D" }}
								onClick={() => props.setInviteSent(false)}
							/>
						</Flex>
					</Show>
					<Show above="md">
						<Flex alignItems="center" gap="1.25rem" mt="2rem">
							<Button
								children="Close"
								bg="#fff"
								border="1px solid #000"
								onClick={props.closeFunction}
							/>
							<Button
								children="Add more Customers"
								backgroundColor="#4BA18A"
								color="white"
								_hover={{ bg: "#38846D" }}
								onClick={() => props.setInviteSent(false)}
							/>
						</Flex>
					</Show>
				</Flex>
			) : (
				<Formik
					initialValues={{
						company_name: "",
						contact_name: "",
						contact_number: "",
						contact_email: "",
					}}
					onSubmit={props.inviteCustomer}
					validationSchema={Yup.object().shape({
						company_name: yupString,
						contact_name: yupString,
						contact_number: yupContact,
						contact_email: yupEmail,
					})}
					innerRef={props.formRef}
					enableReinitialize={true}
				>
					{(formik: any) => (
						<Form>
							<Flex
								flexDir="column"
								gap={{ base: "1.25rem", md: "1.5rem" }}
								mb={{ base: "4rem", md: "0" }}
								p={{ base: "1.25rem", md: "0" }}
							>
								<RenderTextField
									name="company_name"
									label="Company Name"
									placeholder="Enter Company Name"
									variant="outline"
									labelsize="1rem"
									isMandatory={true}
									onBlur={props.handleCompanyNameChange}
								/>
								<RenderTextField
									name="contact_name"
									label="Contact Name"
									placeholder="Enter Contact Name"
									variant="outline"
									labelsize="1rem"
									isMandatory={true}
								/>
								<RenderPhoneField
									name="contact_number"
									label="Contact Number"
									placeholder="+91 9999999999"
									isMandatory={true}
								/>
								<RenderTextField
									name="contact_email"
									label="Email"
									type="email"
									placeholder="Enter Email"
									variant="outline"
									labelsize="1rem"
									isMandatory={true}
								/>
							</Flex>
							<Show below="md">
								<Flex
									pos="sticky"
									left="0"
									bottom="0"
									zIndex={1}
									p="1rem"
									w="100vw"
									bg="#fff"
									boxShadow="dark-lg"
								>
									<Button
										children="Send Invite"
										backgroundColor="#4BA18A"
										color="white"
										type="submit"
										flex={1}
									/>
								</Flex>
							</Show>
							<Show above="md">
								<Flex
									justifyContent="flex-end"
									borderTop="1px solid #D7D7D7"
									mt="2.5rem"
									py="1.25rem"
								>
									<Button
										children="Send Invite"
										backgroundColor="#4BA18A"
										color="white"
										type="submit"
										_hover={{ bg: "#38846D" }}
									/>
								</Flex>
							</Show>
						</Form>
					)}
				</Formik>
			)}
		</Box>
	);
};

export default AddCustomer;
