import React from "react";
import { Box, Flex, useMediaQuery, Text, Img } from "@chakra-ui/react";
import { PageLoading } from "@karkhanaui/react";
import { useSelector } from "react-redux";
import PageLoaderURL from "../img/loader.gif"; // eslint-disable-line
import PublicIcon1 from "../img/publicIcon1.svg";
import PublicIcon2 from "../img/publicIcon2.svg";
import PublicIcon3 from "../img/publicIcon3.svg";
import PublicIcon4 from "../img/publicIcon4.svg";
import PublicIcon5 from "../img/publicIcon5.svg";

export const PublicLayout: React.FC<any> = ({ Component, ...rest }) => {
	const [pageLoadingData] = useSelector((state: any) => [state.pageLoading]);
	const [isMobileTablet] = useMediaQuery("(max-width: 768px)");
	const advantages = [
		{ title: "Get New orders", img: PublicIcon1 },
		{ title: "Receive Timely Payment", img: PublicIcon2 },
		{ title: "Access to global customer base", img: PublicIcon3 },
		{ title: "Create Instant Quotations", img: PublicIcon4 },
		{ title: "Easily Manage Customers", img: PublicIcon5 },
	];
	return (
		<React.Fragment>
			<Flex
				alignItems="center"
				justifyContent="center"
				bg="#E2F1EE"
				minH="100vh"
				w="100%"
				paddingX={isMobileTablet ? "0" : "2.25rem"}
			>
				<Box
					flex="auto"
					width="7.5%"
					display={isMobileTablet ? "none" : "block"}
				/>
				<Flex
					flex="auto"
					width="35%"
					direction={"column"}
					bg="#E2F1EE"
					h="100%"
					display={isMobileTablet ? "none" : "block"}
				>
					<Text
						color="#4BA18A"
						textStyle="primary.poster"
						fontSize={{ sm: "1.5rem", md: "2rem", lg: "2.5rem" }}
					>
						Grow Your Business With Kkonnect.io
					</Text>
					<Box mt="3.375rem">
						{advantages.map((item: any, index: number) => (
							<Flex
								key={index}
								alignItems="center"
								justifyContent="flex-start"
								gap="1rem"
								mb="1rem"
							>
								<Img src={item.img} alt="image" />

								<Text
									color="#000"
									textStyle="primary.title"
									fontWeight={400}
									fontSize={{ sm: "sm", md: "lg", lg: "2xl" }}
								>
									{item.title}
								</Text>
							</Flex>
						))}
					</Box>
				</Flex>
				<Box
					flex="auto"
					width="7.5%"
					display={isMobileTablet ? "none" : "block"}
				/>
				<Box
					width={isMobileTablet ? "100%" : "50%"}
					flex="auto"
					height={isMobileTablet ? "100vh" : "93vh"}
					overflowY={{ base: "auto", lg: "auto" }}
					borderRadius={isMobileTablet ? "0" : "1.5rem"}
					boxShadow="0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)"
					p={isMobileTablet ? "0rem" : "2rem"}
					bg="#fff"
				>
					<Component {...rest} />
				</Box>
				{pageLoadingData?.loading && (
					<PageLoading
						pageLoading={pageLoadingData}
						pageLoaderURL={PageLoaderURL}
					/>
				)}
			</Flex>
		</React.Fragment>
	);
};
