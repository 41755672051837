import React, { useState, useEffect } from "react";
import {
	Button,
	Table,
	Tbody,
	Tr,
	Box,
	Divider,
	Td,
	Input,
	Text,
	Flex,
	Show,
} from "@chakra-ui/react";
import {
	BasicDialog,
	RenderTextField,
	yupEmail,
	yupPassword,
	type AppDispatch,
	setPageLoading,
	SET_MESSAGE,
	ERROR_MSG,
	formatErrorMessage,
	SUCCESS_MSG,
	frappeMethodService,
} from "@karkhanaui/react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import * as Yup from "yup";

const AddRateRightSide: React.FC<any> = (props: any) => {
	const dispatch = useDispatch<AppDispatch>();
	const [rate, setRate] = useState<any>(props.rate);
	const [costBreakUp, setCostBreakUp] = useState<any>(props.costBreakUp);
	const [openAceIntegrationPopup, setOpenAceIntegrationPopup] =
		useState<boolean>(false);
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);
	const basicValidation = Yup.object().shape({
		userName: yupEmail,
		password: yupPassword,
	});

	const errorHandler = (error: any) => {
		dispatch(
			setPageLoading({
				loading: false,
				loadingText: "",
			})
		);
		dispatch({
			type: SET_MESSAGE,
			payload: {
				case: ERROR_MSG,
				data: formatErrorMessage(error),
			},
		});
	};

	useEffect(() => {
		if (props) {
			setRate(props.rate);
			setCostBreakUp(props.costBreakUp);
		}
	}, [props]);

	const getCostName = (keyName: string) => {
		if (keyName === "post_process") {
			return "Post Process Cost";
		} else if (keyName.includes("_")) {
			return keyName
				.split("_")
				.map((word) => {
					return word.charAt(0).toUpperCase() + word.slice(1);
				})
				.join(" ");
		} else if (keyName.includes(" ")) {
			return keyName
				.split(" ")
				.map((word) => {
					return word.charAt(0).toUpperCase() + word.slice(1);
				})
				.join(" ");
		} else {
			return keyName;
		}
	};

	const handleInputChange = (key: any, value: any) => {
		setCostBreakUp((prevData: any) => ({
			...prevData,
			[key]: value,
		}));
	};

	const connectToAce = async (event: any) => {
		setButtonLoading(true);
		frappeMethodService("ace_integration.api.auth.onboard_user", {
			password: event.password,
		})
			.then((res) => {
				setButtonLoading(false);
				setOpenAceIntegrationPopup(false);
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: SUCCESS_MSG,
						data: "Your profile is now ready to access ACE portal",
					},
				});
				props?.aceIntegrationStatusCheck();
			})
			.catch((error) => {
				setButtonLoading(false);
				errorHandler(error);
			});
	};

	const viewOnACE = async () => {
		setButtonLoading(true);
		if (props.aceIntegrationStatus.file_reference_key?.length > 0) {
			setButtonLoading(false);
			window.open(
				process.env.REACT_APP_ACE_URL +
					`/itemlibrary/item/${props.aceIntegrationStatus.file_reference_key}`
			);
		} else {
			await frappeMethodService("ace_integration.api.item.upload_file", {
				file: props.aceIntegrationStatus?.file,
				source: "procurement",
				file_name: props.aceIntegrationStatus?.file_name,
			})
				.then((res: any) => {
					console.log(res);
					setButtonLoading(false);
					props?.aceIntegrationStatusCheck();
					window.open(
						process.env.REACT_APP_ACE_URL +
							`/itemlibrary/item/${res.data.message?.reference_id}`
					);
				})
				.catch((error) => {
					errorHandler(error);
					setButtonLoading(false);
				});
		}
	};

	return (
		<Box>
			<Show above="md">
				<Flex alignItems="center" justifyContent="space-between">
					<Text textStyle="primary.bold" m="1rem 1.5rem">
						Add Rate
					</Text>
					{props?.dataOriginatedFrom == "procurement" &&
						props?.aceIntegrationStatus?.integrated_with_ace &&
						props.aceIntegrationStatus.file?.length > 0 && (
							<Button
								children={
									props.aceIntegrationStatus.file_reference_key?.length > 0
										? "View on ACE"
										: "Load file to ACE"
								}
								color="#fff"
								bg="#4BA18A"
								_hover={{ bg: "#38846D" }}
								onClick={() => viewOnACE()}
								isLoading={buttonLoading}
							/>
						)}
				</Flex>
				<Divider />
			</Show>
			<Box margin="1.5rem">
				{props?.dataOriginatedFrom == "procurement" &&
					props?.aceIntegrationStatus?.integrated_with_ace === false &&
					props.aceIntegrationStatus.file?.length > 0 && (
						<Text mb=".5rem">
							Calculate cost with&nbsp;
							<span
								style={{ textDecoration: "underline", cursor: "pointer" }}
								onClick={() =>
									window.open(process.env.REACT_APP_ACE_URL, "_blank")
								}
							>
								Automated Costing Engine
							</span>
							?&nbsp;
							<span
								style={{
									color: "#4BA18A",
									cursor: "pointer",
									fontWeight: "600",
								}}
								onClick={() => setOpenAceIntegrationPopup(true)}
							>
								{props.aceIntegrationStatus?.signed_up_on_ace
									? "Connect to ACE"
									: "Sign Up on ACE"}
							</span>
						</Text>
					)}
				<Flex>
					<Flex
						width="40%"
						border="1px solid #4BA18A"
						alignItems="center"
						justifyContent="center"
						borderRadius="8px 0px 0px 8px"
					>
						<Text color="#4BA18A" marginY="1rem" textStyle="primary.bold">
							Rate*
						</Text>
					</Flex>
					<Flex
						width="60%"
						alignItems="center"
						justifyContent="center"
						border="1px solid #4BA18A"
						borderRadius="0px 8px 8px 0px"
					>
						<Input
							placeholder="Add Rate"
							variant="Unstyled"
							value={rate}
							type="number"
							onChange={(e) => {
								setRate(e.target.value);
							}}
						></Input>
					</Flex>
				</Flex>
				{Object.keys(costBreakUp)?.length > 0 && (
					<Box mt="1.12rem">
						<Divider style={{ height: "2px" }} />
						<Text fontWeight="bold" mt="1.125rem">
							Breakup
						</Text>
						<Text color="rgba(0, 0, 0, 0.60)" fontSize=".875rem">
							Customer has asked for the following breakup cost. Share the
							breakup cost to increase the chances of getting order and a quick
							response from the customer
						</Text>
						<Table border="1px solid #4BA18A" mt="1.375rem">
							<Tbody>
								{Object.keys(costBreakUp)?.map((key: any) => (
									<Tr key={key}>
										<Td border="1px solid #4BA18A">
											<Text color="#4BA18A" textStyle="primary.bold">
												{getCostName(key)}
											</Text>
										</Td>
										<Td border="1px solid #4BA18A" p={0} m={0}>
											<Input
												placeholder="Add Rate"
												type="number"
												variant="Unstyled"
												value={costBreakUp[key]}
												onChange={(e) => {
													handleInputChange(key, e.target.value);
												}}
											/>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</Box>
				)}
				<Flex
					mt="2.8rem"
					alignItems="center"
					justifyContent={{ base: "center", md: "flex-end" }}
					gap="1.25rem"
					w={{ base: "100%", md: "auto" }}
				>
					<Button
						bg="#fff"
						border="1px solid #000"
						onClick={() => props.closeAddRateModal()}
						w={{ base: "50%", md: "auto" }}
					>
						Cancel
					</Button>
					<Button
						bg="#4BA18A"
						_hover={{ bg: "#38846D" }}
						color="#fff"
						onClick={() => props.updateRateAndCloseModal(rate, costBreakUp)}
						w={{ base: "50%", md: "auto" }}
					>
						Save
					</Button>
				</Flex>
			</Box>
			<BasicDialog
				isOpen={openAceIntegrationPopup}
				onClose={() => {
					setOpenAceIntegrationPopup(false);
				}}
				showCloseButton={true}
				header={
					props.aceIntegrationStatus?.signed_up_on_ace
						? "Connect your profile to ACE"
						: "Sign Up on ACE"
				}
				content={
					<Box m="1rem .5rem 2rem .5rem">
						<Formik
							initialValues={{ userName: Cookies.get("user_id"), password: "" }}
							validationSchema={basicValidation}
							onSubmit={connectToAce}
						>
							{(formik) => (
								<Form>
									<Flex flexDirection="column" gap="1rem">
										<RenderTextField
											label="Email ID"
											name="userName"
											type="email"
											placeholder="Enter Email Address"
											isMandatory={true}
											isDisabled={true}
										/>
										<RenderTextField
											label="Password"
											name="password"
											type="password"
											placeholder="********"
											isMandatory={true}
										/>
										<Button
											isLoading={buttonLoading}
											mt=".5rem"
											type="submit"
											color="#fff"
											bg="#4BA18A"
											_hover={{ bg: "#38846D" }}
											children={
												props.aceIntegrationStatus?.signed_up_on_ace
													? "Connect your profile to ACE"
													: "Sign Up on ACE"
											}
										/>
									</Flex>
								</Form>
							)}
						</Formik>
					</Box>
				}
			/>
		</Box>
	);
};

export default AddRateRightSide;
