import React, { FC, useState } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Center,
	Text,
	Select,
	Divider,
	Image,
	chakra,
	Textarea,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import {
	AppDispatch,
	formatErrorMessage,
	SET_MESSAGE,
	ERROR_MSG,
	supplierUpdateOpportunityService,
} from "@karkhanaui/react";
import { useDispatch } from "react-redux";

interface PopupCardProps {
	isOpen: boolean;
	onClose: () => void;
	title?: string;
	message?: string;
	onCancel?: () => void;
	onSave?: () => void;
	imageURL?: string;
	onAccept?: () => void;
	userConform?: string;
	rfqId: string;
	rfqName?: string;
	companyName?: string;
}

const RejectRfqPopup: FC<PopupCardProps> = ({
	isOpen,
	onClose,
	title,
	message,
	onCancel,
	onSave,
	imageURL,
	onAccept,
	userConform,
	rfqId,
	rfqName,
	companyName,
}) => {
	const dispatch = useDispatch<AppDispatch>();
	const [showReasonSelect, setShowReasonSelect] = useState(false);
	const [selectedReason, setSelectedReason] = useState<string>("");
	const [otherReason, setOtherReason] = useState<string>("");
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const rejectionReasons = [
		"Out of Capability",
		"Can't meet target cost",
		"Can't meet timeline",
		"Running at full Capacity",
		"Other(Description)",
	];

	const handleConfirmClick = () => {
		setShowReasonSelect(true);
	};

	const handleReasonSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedReason(e.target.value);
	};

	const rejectRfq = async () => {
		const currentDate = Date.now();
		const dateObj = new Date(currentDate);
		try {
			setButtonLoading(true);
			let res: any = await supplierUpdateOpportunityService(rfqId, {
				portal_status: "Rejected",
				card_status: "Lost",
				reject_rfq_reason:
					selectedReason === "Other(Description)"
						? otherReason
						: selectedReason,
				rfq_rejected_date: dateObj.toISOString().split("T")[0],
			});
			if (res.status === 200) {
				setButtonLoading(false);
				navigate("/rfqmain");
			}
		} catch (error) {
			setButtonLoading(false);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="lg">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{title}</ModalHeader>
				<Divider variant="solid" w="100%" />
				<ModalCloseButton />
				<ModalBody>
					{showReasonSelect ? (
						<>
							<Modal isOpen={isOpen} onClose={onClose} size="lg">
								<ModalOverlay />
								<ModalContent>
									<ModalHeader>Reject RFQ</ModalHeader>
									<ModalCloseButton />
									<ModalBody>
										<Text fontSize={"18px"} mb="2em">
											Select a reason for rejecting the RFQ
										</Text>
										{selectedReason === "Other(Description)" ? (
											<Textarea
												placeholder="Add a reason for declining the RFQ"
												height="9.75rem"
												width="100%"
												onBlur={(e) => setOtherReason(e.target.value)}
											/>
										) : (
											<Select
												placeholder="Select"
												onChange={handleReasonSelect}
												size="md"
											>
												{rejectionReasons.map((reason) => (
													<option value={reason} key={reason}>
														{reason}
													</option>
												))}
											</Select>
										)}
									</ModalBody>
									<ModalFooter mr="8em">
										<Button
											onClick={onCancel}
											mr="2"
											mt="2em"
											isLoading={buttonLoading}
										>
											Cancel
										</Button>
										<Button
											colorScheme="blue"
											onClick={() => rejectRfq()}
											bg="#4BA18A"
											_hover={{ bg: "#38846D" }}
											mt="2em"
											isLoading={buttonLoading}
										>
											Confirm
										</Button>
									</ModalFooter>
								</ModalContent>
							</Modal>
						</>
					) : (
						<>
							<Center>
								<Image width="75%" src={imageURL} alt="generic gif" />
							</Center>
							<Center>
								{rfqName === undefined ? (
									<Text>{message}</Text>
								) : (
									<Text textAlign="center" mt="2.5rem">
										Are you sure you want to reject the RFQ &nbsp;
										<chakra.span color="#4BA18A">{rfqName}</chakra.span>
										&nbsp;from&nbsp;
										<chakra.span color="#4BA18A">{companyName}</chakra.span>
									</Text>
								)}
							</Center>
							<ModalFooter mr="8em">
								<Button
									onClick={onCancel}
									mr="2"
									isLoading={buttonLoading}
									bg="#fff"
									_hover={{ bg: "#fff" }}
									border="1px solid #000"
								>
									Cancel
								</Button>
								<Button
									colorScheme="blue"
									onClick={handleConfirmClick}
									bg="#4BA18A"
									_hover={{ bg: "#38846D" }}
									isLoading={buttonLoading}
								>
									Confirm
								</Button>
							</ModalFooter>
						</>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default RejectRfqPopup;
