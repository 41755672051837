import { Box, Text } from "@chakra-ui/react";
import * as React from "react";
import { Link } from "react-router-dom";

const InstructionsCard: React.FC<any> = (props: any) => {
	return (
		<Box
			justifyContent="center"
			alignItems="center"
			display="flex"
			flexDirection="column"
		>
			<Box
				p={{ base: "1rem", lg: 7 }}
				border="1px solid lightgray"
				borderRadius="5px"
				boxShadow="lg"
			>
				<Box
					display="flex"
					justifyContent="center"
					fontWeight="bold"
					fontSize="20px"
					mb={5}
				>
					Instructions Sent!
				</Box>
				<Text color="gray" fontSize="18px" sx={{ textAlign: "center" }}>
					Please check your email and follow the instructions provided to finish
					resetting your password.
				</Text>
				<Text color="gray" fontSize="18px" sx={{ textAlign: "center" }}>
					If you did not receive an email you may resend instructions by
					clicking the link below.
				</Text>
				<Text
					mt={1}
					onClick={props.showCardChange}
					display="flex"
					justifyContent="center"
					fontSize="16px"
					fontWeight="bold"
					sx={{ textDecoration: "underline", cursor: "pointer" }}
				>
					Resend instructions
				</Text>
			</Box>
			<Box fontSize="16px" mt={2} display="flex" flexDirection="row">
				Go back to &nbsp;
				<Link
					to={`/login${props?.uuid != null ? `/?rfq=${props?.uuid}` : ""}${
						props?.SO_UUID != null ? `/?po=${props?.SO_UUID}` : ""
					}`}
					rel="preconnect"
				>
					<Text fontWeight="bold">Login</Text>
				</Link>
			</Box>
		</Box>
	);
};
export default InstructionsCard;
