import { Box, Flex, Center, Text, Divider } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import React from "react";
interface buyerInterface {
	buyerName: string;
	buyerString: string;
	rfq: number;
	quote: number;
	sales_order: number;
	disableCustomerNavigation?: boolean;
	company_hex: string;
}
export const CustomerCards = ({
	buyerName,
	buyerString,
	rfq,
	quote,
	sales_order,
	disableCustomerNavigation,
	company_hex,
}: buyerInterface) => {
	const navigate = useNavigate();
	return (
		<Box
			fontSize="12px"
			borderRadius="lg"
			boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
			padding="1em 1.5em"
			width={"100%"}
			margin="0em auto"
			bgColor="#fff"
		>
			<Flex
				gap="1em"
				alignItems="center"
				marginBottom="1em"
				cursor={disableCustomerNavigation ? "" : "pointer"}
				onClick={() => {
					if (!disableCustomerNavigation) navigate(`/customer/${buyerString}`);
				}}
			>
				<Center
					w="60px"
					h="60px"
					bg="#E2F1EE"
					color="#4BA18A"
					borderRadius="50%"
					fontSize="35px"
				>
					{buyerName?.slice(0, 1) || "K"}
				</Center>
				<Flex flexDirection="column">
					<Text fontWeight="bold" fontSize="14px">
						{buyerName}
					</Text>
					<Text fontFamily={"Rubik"} fontSize={"0.75rem"}>
						Buyer Id: {buyerName}
					</Text>
				</Flex>
				{/* <Flex flexDirection="column" mt="auto" ml="auto">
              {getStatusColor(status)}
            </Flex> */}
			</Flex>
			<Divider />
			<Flex justifyContent={"space-between"} gap="1rem" mt="0.5rem">
				<Flex flexDir="column" gap="0.3rem">
					<Text
						onClick={() =>
							navigate(`/rfqmain/filter?company_name=${company_hex}`)
						}
						style={{
							textDecoration: "none",
							color: "#4BA18A",
							fontWeight: "600",
							cursor: "pointer",
						}}
					>
						View RFQs&nbsp;({rfq})
					</Text>
					<Text fontFamily={"Rubik"} fontSize="0.625rem" color={"#00000080"}>
						RFQ History
					</Text>
				</Flex>

				<Flex flexDir="column" gap="0.3rem">
					<Text
						onClick={() =>
							navigate(`/quotes/filter?company_name=${company_hex}`)
						}
						style={{
							textDecoration: "none",
							color: "#4BA18A",
							fontWeight: "600",
							cursor: "pointer",
						}}
					>
						View Quotes&nbsp;({quote})
					</Text>
					<Text fontFamily={"Rubik"} fontSize="0.625rem" color={"#00000080"}>
						Quote History
					</Text>
				</Flex>

				<Flex flexDir="column" gap="0.3rem">
					<Text
						onClick={() =>
							navigate(`/orders/filter?company_name=${company_hex}`)
						}
						style={{
							textDecoration: "none",
							color: "#4BA18A",
							fontWeight: "600",
							cursor: "pointer",
						}}
					>
						View Orders&nbsp;({sales_order})
					</Text>
					<Text fontFamily={"Rubik"} fontSize="0.625rem" color={"#00000080"}>
						Order History
					</Text>
				</Flex>
			</Flex>
		</Box>
	);
};
