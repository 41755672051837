import React, { useEffect, useState } from "react";
import {
	Box,
	Text,
	Grid,
	Flex,
	GridItem,
	Image,
	Divider,
} from "@chakra-ui/react";
import {
	File,
	normalDate,
	Thumbnail,
	DisplayFile,
	getItemAttachedFilesService,
	type AppDispatch,
	formatErrorMessage,
	SET_MESSAGE,
	ERROR_MSG,
	setPageLoading,
	frappeMethodService,
} from "@karkhanaui/react";
import { BsDownload } from "react-icons/bs";
import { useDispatch } from "react-redux";

const OrderDocuments = (props: any) => {
	const dispatch = useDispatch<AppDispatch>();
	const [filesList, setFilesList] = useState<any>([]);
	const [activeFile, setActiveFile] = useState<number>(0);
	const [selectedFile, setSelectedFile] = useState<any>({});

	const transformToDocs = (data: any) => {
		return data?.map((each: any) => ({
			fileId: each.name,
			active: true,
			date: normalDate(each.creation),
			fileName: each.file_name,
			fileSize: `${Math.round(each.file_size / 1024)}KB`,
			imgSrc: process.env.REACT_APP_LIVE_URL + each.file_url,
			allowDownload: true,
			thumbnail: (
				<Thumbnail
					fileUrl={process.env.REACT_APP_LIVE_URL + each.file_url}
					isPrivateFile={true}
				/>
			),
			isPrivateFile: each.isPrivateFile,
		}));
	};

	const setData = async (filesData: any) => {
		const docs = await transformToDocs(filesData);
		setFilesList(docs);
		setSelectedFile(docs[0]);
	};

	const fetchFiles = async () => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Loading...",
			})
		);
		try {
			// get all chats for this sales order
			const chats = await frappeMethodService("karkhanachat.get_chat", {
				payload: {
					doctype: "Sales Order",
					doc_id: props.salesOrderId,
				},
			});
			if (chats.status === 200) {
				let _files: any = [];
				// get all files from each chat and push to a single array
				await chats.data.data?.map((chat: any) => {
					_files.push(...chat.files);
				});
				setData(_files);
				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
			}
		} catch (error) {
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	useEffect(() => {
		fetchFiles();
	}, [props.saleOrder]);

	return (
		<Box>
			<Grid
				templateColumns={{
					md: "repeat(2,1fr)",
					lg: "repeat(3,minmax(0, 1fr))",
				}}
				gap={{ xs: "2rem", md: "0rem" }}
				mt="1rem"
			>
				<GridItem
					borderRight={{ md: "1px solid rgba(0, 0, 0, 0.1)" }}
					pr={{ md: "2rem" }}
				>
					<Box height="55vh" overflowY="scroll">
						{filesList.map((file: any, index: number) => (
							<Box
								key={index}
								mb=".75rem"
								cursor="pointer"
								onClick={() => {
									setSelectedFile(file);
									setActiveFile(index);
								}}
							>
								<File
									active={index === activeFile ? true : false}
									date={file.date}
									fileName={file.fileName}
									fileSize={file.fileSize}
									imgSrc={file.imgSrc}
									allowDownload={file.allowDownload}
									thumbnail={file.thumbnail}
									colorTheme="green"
								/>
							</Box>
						))}
					</Box>
				</GridItem>
				<GridItem colSpan={{ lg: 2 }} pl={{ md: "1.5rem" }}>
					{selectedFile?.imgSrc != undefined && (
						<Box>
							<Flex
								alignItems="center"
								justifyContent="center"
								objectFit="contain"
								mb="1rem"
							>
								<DisplayFile
									file={selectedFile?.imgSrc}
									isPrivate={selectedFile?.isPrivateFile}
								/>
							</Flex>
							<hr />
							<Flex
								alignItems="center"
								justifyContent="space-between"
								mt="1.5rem"
							>
								<Box>
									<Flex alignItems="center" gap=".5rem">
										<Text fontSize="1rem">{selectedFile?.fileName}</Text>
										<Text textStyle="primary.secondaryText" fontWeight={600}>
											{selectedFile?.fileSize}
										</Text>
									</Flex>
									<Text textStyle="primary.secondaryText">
										{selectedFile?.date}
									</Text>
								</Box>
								<Flex
									alignItems="center"
									justifyContent="center"
									width="2.31875rem"
									height="2.125rem"
									bg="rgba(80, 184, 72, 0.80)"
									borderRadius="6px"
									cursor="pointer"
									onClick={() => window.open(selectedFile?.imgSrc)}
								>
									<BsDownload size="1rem" color="#fff" />
								</Flex>
							</Flex>
						</Box>
					)}
				</GridItem>
			</Grid>
		</Box>
	);
};

export default OrderDocuments;
