import React, { useState, useEffect } from "react";
import { Box, Text, Flex, Button } from "@chakra-ui/react";
import {
	normalDate,
	GenericTables,
	Thumbnail,
	BasicDialog,
	FilesExplorer,
	frappeMethodService,
	File,
	type AppDispatch,
	formatErrorMessage,
	SET_MESSAGE,
	ERROR_MSG,
	setPageLoading,
} from "@karkhanaui/react";
import { BsDownload } from "react-icons/bs";
import ViewPartForm from "../quotes/ViewPartForm";
import { useDispatch } from "react-redux";

const JSZip = require("jszip");
const saveAs = require("file-saver").saveAs;

const PartsAndAttachments = (props: any) => {
	const dispatch = useDispatch<AppDispatch>();
	const [selectedItem, setSelectedItem] = useState<any>();
	const [isPartOpen, setIsPartOpen] = useState(false);
	const [filesList, setFilesList] = useState<any>([]);
	const [showFiles, setShowFiles] = useState<boolean>(false);
	const [activeFile, setActiveFile] = React.useState<number>(0);
	const [rawFilesList, setRawFilesList] = useState<any>([]);
	const [allPOFiles, setAllPOFiles] = useState<any>([]);
	const [allItemFiles, setAllItemFiles] = useState<any>([]);

	const downloadAllItemFiles = async () => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Loading...",
			})
		);
		let _allItemFiles: any = [];

		try {
			await Promise.all(
				props.salesOrder?.items?.map(async (item: any) => {
					let res: any = await frappeMethodService(
						"crosslink.api.get_attachments_from_procurement",
						{
							doctype: "Item",
							uuid: item?.item_uuid,
						}
					);
					if (res.status === 200) {
						Promise.all(
							res.data.message.data.map(async (attachedFile: any) => {
								await _allItemFiles.push(
									process.env.REACT_APP_PROCUREMENT_URL + attachedFile.file_url
								);
							})
						);
					}
				})
			);
			setAllItemFiles([..._allItemFiles]);
			if (_allItemFiles.length === 0) {
				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: "No files were found",
					},
				});
			}
		} catch (error) {
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	const downloadAllPOAttachedFiles = async () => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Loading...",
			})
		);
		let _allPOFiles: any = [];
		try {
			await Promise.all(
				rawFilesList?.map(async (file: any) => {
					await _allPOFiles.push(
						process.env.REACT_APP_PROCUREMENT_URL + file.file_url
					);
				})
			);
			setAllPOFiles([..._allPOFiles]);
			if (_allPOFiles.length === 0) {
				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
			}
		} catch (error) {
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	const transformToDocs = (data: any) => {
		return data?.map((each: any) => ({
			active: true,
			date: normalDate(each.creation),
			fileName: each.file_name,
			fileSize: `${Math.round(each.file_size / 1024)}KB`,
			imgSrc: process.env.REACT_APP_LIVE_URL + each.file_url,
			allowDownload: true,
			thumbnail: (
				<Thumbnail
					fileUrl={process.env.REACT_APP_LIVE_URL + each.file_url}
					isPrivateFile={true}
				/>
			),
			isPrivateFile: each.isPrivateFile,
		}));
	};

	const getFilesAndPrepareData = async () => {
		try {
			const attachedFiles = await frappeMethodService(
				"crosslink.api.get_attachments_from_procurement",
				{
					doctype: "Purchase Order",
					uuid: props.salesOrder?.po_uuid,
				}
			);
			const _files = await transformToDocs(attachedFiles?.data.message?.data);
			setFilesList(_files.filter((file: any) => !file.fileName?.includes("Auto_generated")));
			setRawFilesList(attachedFiles?.data.message?.data.filter(
					(file: any) => !file.file_name?.includes("Auto_generated")));
		} catch (error) {
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	async function downloadFilesAsZip(urls: any, zipFilename: string) {
		const zip = new JSZip();

		// Use Promise.all to wait for all the files to be downloaded and added to the ZIP
		try {
			await Promise.all(
				urls.map(async (url: string, i: number) => {
					const response = await fetch(url);
					const blob = await response.blob();
					zip.file(url.substring(url.lastIndexOf("/") + 1), blob); //extract file name from url asd save file name for each file
				})
			);
		} catch (error) {
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);

			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}

		// Generate the ZIP file and trigger the download
		zip.generateAsync({ type: "blob" }).then((blob: any) => {
			saveAs(blob, zipFilename);
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
		});
	}

	useEffect(() => {
		if (allPOFiles.length > 0) {
			downloadFilesAsZip(allPOFiles, `${props?.salesOrder.name}.zip`);
		}
	}, [allPOFiles]);

	useEffect(() => {
		if (allItemFiles?.length > 0) {
			downloadFilesAsZip(
				allItemFiles,
				`${props?.salesOrder.name}_parts_files.zip`
			);
		}
	}, [allItemFiles]);

	useEffect(() => {
		getFilesAndPrepareData();
	}, [props?.salesOrder]);

	const column = [
		{ Header: "Part Name", accessor: "item_name", disableSortBy: true },
		{
			Header: "Category",
			accessor: "item_category",
			Cell: (i: any) => <Text>{i.row.original?.item_category || "-"}</Text>,
			disableSortBy: true,
		},
		{
			Header: "Quantity",
			accessor: "qty",
			Cell: (i: any) => (
				<Text>{i.row.original?.qty >= 0 ? i.row.original?.qty : "-"}</Text>
			),
			disableSortBy: true,
		},
		{
			Header: "UOM",
			accessor: "uom",
			Cell: (i: any) => <Text>{i.row.original?.uom || "-"}</Text>,
			disableSortBy: true,
		},
		{
			Header: "Target Cost (INR)",
			accessor: "target_cost",
			Cell: (i: any) => <Text>{i.row.original?.target_cost || "-"}</Text>,
			disableSortBy: true,
		},
		{
			Header: "",
			accessor: "view_part",
			Cell: (i: any) => (
				<Text
					color="#4BA18A"
					textStyle="primary.link"
					onClick={() => {
						setSelectedItem(i.row.original);
						setIsPartOpen(true);
					}}
					cursor="pointer"
				>
					View Part
				</Text>
			),
			disableSortBy: true,
		},
	];
	return (
		<Box mt=".5rem">
			<Flex alignItems="center" justifyContent="space-between">
				<Flex alignItems="center" justifyContent="flex-start" gap=".75rem">
					<Flex
						alignItems="center"
						justifyContent="center"
						width="4.875rem"
						height="4.875rem"
						borderRadius="50%"
						bg="rgba(226, 241, 238, 0.50)"
					>
						<Text
							color="#4BA18A"
							fontSize="3rem"
							fontWeight="600"
							lineHeight="1.5rem"
						>
							{props.salesOrder?.company_name[0]?.toUpperCase() ||
								props.salesOrder?.customer_name[0]?.toUpperCase()}
						</Text>
					</Flex>
					<Box>
						<Text
							fontSize="1.5rem"
							fontWeight="600"
							lineHeight="1.5rem"
							color="#4BA18A"
						>
							{props.salesOrder?.company_name ||
								props.salesOrder?.customer_name}
						</Text>
						<Text fontSize="1rem" lineHeight="1.5rem">
							{props.salesOrder?.requester ||
								props?.getDataInsideParentheses(props.salesOrder?.customer)}
						</Text>
					</Box>
				</Flex>
				<Text fontSize="1rem" lineHeight="1.5rem">
					<span style={{ color: "#4BA18A" }}>Received On:</span>{" "}
					{normalDate(props.salesOrder?.creation)}
				</Text>
			</Flex>
			<Flex alignItems="center" justifyContent="space-between" mt="1.5rem">
				<Text
					fontSize="1.125rem"
					fontWeight={600}
					lineHeight="1.5rem"
				>{`Parts (${props.salesOrder.items?.length})`}</Text>
				<Button
					color="#4BA18A"
					border="1px solid #4BA18A"
					bg="#fff"
					_hover={{ bg: "#E2F1EE" }}
					variant="outline"
					size="sm"
					p="0.7em 0.7em"
					leftIcon={<BsDownload size="18px" />}
					onClick={async () => {
						downloadAllItemFiles();
					}}
					children="Download files"
				/>
			</Flex>
			<Box mt="1.5rem">
				<GenericTables
					column={column}
					data={props.salesOrder?.items}
					headerColor="#E2F1EE"
					renderCheckBox={false}
					renderSerialNo={true}
					isShowSearch={false}
					paginationbg="#4BA18A"
					borderColor="#B7DDD4"
				/>
			</Box>
			<hr style={{ marginTop: "1rem" }} />
			<Flex alignItems="center" justifyContent="space-between" mt="1rem">
				<Text
					fontSize="1.125rem"
					fontWeight={600}
					lineHeight="1.5rem"
				>{`PO Attachments (${filesList?.length || 0})`}</Text>
				<Button
					color="#4BA18A"
					border="1px solid #4BA18A"
					bg="#fff"
					_hover={{ bg: "#E2F1EE" }}
					variant="outline"
					size="sm"
					p="0.7em 0.7em"
					leftIcon={<BsDownload size="18px" />}
					onClick={async () => {
						downloadAllPOAttachedFiles();
					}}
					children="Download files"
				/>
			</Flex>
			<Box mt="1.625rem">
				<Flex alignItems="center" flexWrap="wrap" gap=".75rem">
					{filesList?.map((file: any, index: number) => (
						<Box
							onClick={() => {
								setShowFiles(true);
								setActiveFile(index);
							}}
							key={index}
							cursor="pointer"
						>
							<File
								active={index === activeFile ? true : false}
								date={file.date}
								fileName={file.fileName}
								fileSize={file.fileSize}
								imgSrc={file.imgSrc}
								allowDownload={file.allowDownload}
								thumbnail={file.thumbnail}
								colorTheme="green"
							/>
						</Box>
					))}
				</Flex>
			</Box>
			<BasicDialog
				isOpen={isPartOpen}
				showCloseButton={true}
				onClose={() => {
					setIsPartOpen(false);
				}}
				header={`Part Name: ${selectedItem?.item_name}`}
				size="6xl"
				content={
					<Box maxHeight="80vh" overflow="scroll">
						<ViewPartForm
							itemData={selectedItem}
							dataOriginatedFrom="procurement"
						/>
					</Box>
				}
			/>
			<BasicDialog
				isOpen={showFiles}
				showCloseButton={true}
				onClose={() => {
					setShowFiles(false);
				}}
				size="6xl"
				header="View Attachments"
				content={
					<FilesExplorer
						files={rawFilesList}
						allowDownload={true}
						allowDelete={false}
						environmentVariable={String(process.env.REACT_APP_PROCUREMENT_URL)}
					/>
				}
			/>
		</Box>
	);
};

export default PartsAndAttachments;
