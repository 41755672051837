import {
	Flex,
	Image,
	Box,
	type FlexProps,
	Stack,
	Text,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	PopoverArrow,
	PopoverCloseButton,
	useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { type GrHomeRounded } from "react-icons/gr";
import {
	BsBell,
	BsCart2,
	BsClipboard,
	BsFileEarmark,
	BsPerson,
	// BsChat,
	BsGearWide,
	// BsSearch,
	BsArrowLeftCircle,
	BsColumnsGap,
	BsChevronDown,
	BsGear,
	BsBuildings,
} from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
// eslint-disable-next-line
import Kio from "../img/kio-mob.png";
import KarkahnaLogo from "../img/SupplierLogo.png";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
// import { CloseIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
	logout,
	type AppDispatch,
	PageLoading,
	history,
	getSupplierCapability,
	SET_MESSAGE,
	ERROR_MSG,
	formatErrorMessage,
	CUSTOMER_FETCH_SUCCESS,
	Overlay,
} from "@karkhanaui/react";
import { GoChevronLeft } from "react-icons/go";
import Cookies from "js-cookie";
import { Notifications } from "../components/Notifications";
import { FiLogOut } from "react-icons/fi";
import { ProductFruits } from "react-product-fruits";
import { datadogRum } from "@datadog/browser-rum";

interface IconTextProps extends FlexProps {
	Icon: typeof GrHomeRounded;
	text: string;
	active?: boolean;
	link: string;
}

interface IconMiniProps extends FlexProps {
	Icon: typeof GrHomeRounded;
	active?: boolean;
	link: string;
	text: string;
}
const IconText = ({
	Icon,
	text,
	link,
	active = false,
	...rest
}: IconTextProps) => {
	const width = window.innerWidth;
	const bgColor = width === 80 ? "#000" : "#fff";
	if (active) {
		return (
			<Flex
				justifyContent="start"
				alignItems="center"
				{...rest}
				width="100%"
				bg="#E2F1EE"
				as={Link}
				to={link}
				borderTopRightRadius="md"
				borderBottomRightRadius="md"
				borderLeft="4px solid #4BA18A"
			>
				<Icon size="20px" color="#4BA18A" />
				<Box ml="11px">
					<Text
						textStyle="primary.text"
						fontWeight={"700"}
						fontSize="14px"
						color="#4BA18A"
						wordBreak="break-all"
					>
						{text}
					</Text>
				</Box>
			</Flex>
		);
	}
	return (
		<Link to={link}>
			<Flex
				justifyContent="start"
				alignItems="center"
				{...rest}
				borderRadius={"10px"}
			>
				<Icon size="20px" />
				<Box ml="11px">
					<Text textStyle="primary.text" fontSize="14px" wordBreak="break-all">
						{text}
					</Text>
				</Box>
			</Flex>
		</Link>
	);
};

const IconMini = ({
	Icon,
	link,
	text,
	active = false,
	...rest
}: IconMiniProps) => {
	if (active) {
		return (
			<Flex
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				gap="0.3em"
				{...rest}
				bg="#E2F1EE"
				ml="0"
				as={Link}
				to={link}
				p="0.6em"
				borderTopRightRadius="md"
				borderBottomRightRadius="md"
				width="100%"
				borderLeft="4px solid #4BA18A"
			>
				<Icon size="26px" color="#4BA18A" />
				<Text
					fontSize="10px"
					fontWeight={"700"}
					color="#4BA18A"
					wordBreak="break-all"
				>
					{text}
				</Text>
			</Flex>
		);
	}
	return (
		<Flex
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			gap="0.3em"
			{...rest}
			borderRadius={"10px"}
			ml="0"
			p="0.6em"
			as={Link}
			to={link}
			width="100%"
		>
			<Icon size="26px" />
			<Text fontSize="11px" wordBreak="break-all">
				{text}
			</Text>
		</Flex>
	);
};

export const AppShell = ({
	children: Component,
	pageLoaderURL,
}: {
	children?: React.LazyExoticComponent<React.FC<any>>;
	pageLoaderURL: string;
}) => {
	const params = useParams();
	const [isMobile] = useMediaQuery("(max-width: 480px)");
	const [isMobileTablet] = useMediaQuery("(max-width: 768px)");
	const dispatch = useDispatch<AppDispatch>();
	const pathname = useLocation()?.pathname.split("/");
	const navigate = useNavigate();
	const [pageLoadingData] = useSelector((state: any) => [state.pageLoading]);
	// const { colorMode, toggleColorMode } = useColorMode();
	const [activeTab, setActiveTab] = React.useState<string>("/" + pathname[1]);
	const [collapsed, setCollapsed] = React.useState(true);
	// const [isExpanded, setExpanded] = React.useState(false);
	const [menu, setMenu] = React.useState(false);
	const [authState, supplier] = useSelector((state: any) => {
		return [state.authSupplier, state.getUser];
	});

	useEffect(() => {
		window.dataLayer?.push({
			event: "Pageview",
			page_url: window.location.href,
			page_name: window.location.pathname,
			user_id: authState?.uniqueUserId,
			organization_name: supplier?.customer?.supplier_name,
		});
	}, [window.location]);

	useEffect(() => {
		setActiveTabBackground();
	}, [pathname[1]]);

	useEffect(() => {
		if (isMobile || isMobileTablet) {
			setCollapsed(true);
		}
	}, [isMobileTablet, isMobile]);

	useEffect(() => {
		getSupplierDetails();
	}, []);

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	const setActiveTabBackground = () => {
		switch (pathname[1]) {
			case "home":
				setActiveTab("/home");
				break;
			case "rfqmain":
			case "viewrfq":
				setActiveTab("/rfqmain");
				break;
			case "quotes":
			case "createquote":
			case "quotemanagement":
				setActiveTab("/quotes");
				break;
			case "orders":
			case "ordersporeceived":
			case "orderslive":
			case "ordersporeceived":
			case "orderspoview":
			case "orderscompleted":
				setActiveTab("/orders");
				break;
			case "customer":
				setActiveTab("/customer");
				break;
			case "digitalFactory":
				setActiveTab("/digitalFactory");
				break;
			// case "settings":
			// 	setActiveTab("/settings");
			// 	break;
			default:
				break;
		}
	};

	const getSupplierDetails = async () => {
		try {
			let supplierDetails: any = await getSupplierCapability(
				String(Cookies.get("user_id"))
			);
			dispatch({
				type: CUSTOMER_FETCH_SUCCESS,
				payload: { customer: supplierDetails?.data?.data },
			});
		} catch (error) {
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	const menuItems = [
		{
			icon: BsColumnsGap,
			text: "Dashboard",
			link: "/home",
		},
		{
			icon: BsClipboard,
			text: "RFQ",
			link: "/rfqmain",
		},
		{
			icon: BsFileEarmark,
			text: "Quotes",
			link: "/quotes",
		},
		{
			icon: BsCart2,
			text: "Orders",
			link: "/orders",
		},
		{
			icon: BsPerson,
			text: "Customers",
			link: "/customer",
		},
		// TODO: Add Chat Option Later
		// {
		//   icon: BsChat,
		//   text: 'Chat',
		//   link: '/chat'
		// },
		{
			icon: BsBuildings,
			text: "Digital Factory",
			link: "/digitalFactory",
		},
		// {
		// 	icon: BsGearWide,
		// 	text: "Settings",
		// 	link: "/settings",
		// },
	];

	const handleSignout = () => {
		window.dataLayer?.push({
			event: "User Signout",
			page_url: window.location.href,
			page_exit: window.location.pathname,
			user_id: authState?.uniqueUserId,
			// userEmail: String(Cookies.get("user_id")),
		});
		//stop datadogrecording
		datadogRum.stopSessionReplayRecording();
		datadogRum.stopSession();
		datadogRum.clearUser();
		dispatch(logout());
	};

	const variants = {
		open: { opacity: 1, x: "0" },
		closed: { opacity: 1, x: "0" },
	};

	const sideVariants = {
		closed: {
			transition: {
				staggerChildren: 0.2,
				staggerDirection: -1,
			},
		},
		open: {
			transition: {
				staggerChildren: 0.2,
				staggerDirection: 1,
			},
		},
	};

	const breadcrumbs = () => {
		const path = window.location.pathname;
		let _breadCrumb: string = "";
		switch (path.split("/")[1]) {
			case "home":
				document.title = "Home";
				_breadCrumb = "Home";
				break;
			case "rfqmain":
				document.title = "RFQ Management";
				_breadCrumb = "RFQ Management";
				break;
			case "quotes":
			case "createquote":
			case "quotemanagement":
				document.title = "Quote Management";
				_breadCrumb = "Quote Management";
				break;
			case "orders":
			case "ordersporeceived":
			case "orderslive":
			case "ordersporeceived":
			case "orderspoview":
			case "orderscompleted":
				document.title = "Order Management";
				_breadCrumb = "Order Management";
				break;
			case "customer":
				document.title = "Customer Management";
				_breadCrumb = "Customer Management";
				break;
			case "viewrfq":
				document.title = "RFQ ID: " + params.rfqId?.split("|")[1];
				_breadCrumb = "RFQ ID: " + params.rfqId?.split("|")[1];
				break;
			case "digitalFactory":
				document.title = "Digital Factory";
				_breadCrumb = "Digital Factory";
				break;
			default:
				break;
		}
		return (
			<Flex
				alignItems="center"
				justifyContent="flex-start"
				gap=".75rem"
				width="100%"
				ml={{ base: "0", md: "1rem" }}
			>
				<motion.div
					initial={{ scale: 1 }}
					animate={{ rotateY: !collapsed ? 180 : 0 }}
					transition={{ duration: 0.5 }}
				>
					{collapsed && (
						<AiOutlineMenu
							size="1.5rem"
							onClick={toggleCollapsed}
							cursor="pointer"
							style={{ color: "#63B29D" }}
						/>
					)}
				</motion.div>
				{_breadCrumb !== "Home" && (
					<BsArrowLeftCircle
						color="#4BA18A"
						size="1.5rem"
						cursor="pointer"
						onClick={history.back}
					/>
				)}

				<Text fontSize={{ base: "16px", lg: "18px" }} fontWeight="bold">
					{_breadCrumb}
				</Text>
			</Flex>
		);
	};

	return (
		<Flex height="100vh" overflow="hidden" position="relative">
			{/* Sidebar */}
			{!(collapsed && isMobileTablet) && (
				<Box
					w={
						collapsed
							? isMobileTablet
								? "0%"
								: "7%"
							: isMobileTablet
								? "auto"
								: "16%"
					}
					as={motion.div}
					display="flex"
					flexShrink="0"
					zIndex={1001}
					h="100%"
					position={isMobileTablet ? "absolute" : "relative"}
					flexDirection="column"
					justifyContent="space-between"
					animate={{ x: "0" }}
					transition={{ type: "inertia" }}
					variants={variants}
					initial={{ x: "-100%" }}
					exit={{ x: "-100%" }}
					bg="#fff"
					overflow={"auto"}
				>
					<motion.div variants={variants}>
						<Flex
							flexDirection="column"
							w="100%"
							mt="10px"
							justifyContent="space-between"
						>
							{collapsed ? (
								<Flex flexDirection="column" width="100%" alignItems="center">
									<Image
										w="2.5rem"
										height="2.5rem"
										src={Kio}
										cursor="pointer"
										onClick={() => {
											navigate("/home");
											if (isMobileTablet) setCollapsed(true);
										}}
										m="0.8rem 0 1rem 0"
										alt="Kkonnect.io"
									/>
									{menuItems.map((item, index) => {
										return (
											<motion.div
												key={index}
												style={{
													width: "100%",
												}}
											>
												<IconMini
													key={item.text}
													mt="1em"
													mx="auto"
													Icon={item.icon}
													alignItems="center"
													active={activeTab === item.link}
													_hover={{ color: "#4BA18A" }}
													link={item.link}
													onClick={() => {
														setActiveTab(item.link);
													}}
													text={item.text}
												/>
											</motion.div>
										);
									})}
								</Flex>
							) : (
								<Box>
									<Flex
										alignItems="center"
										justifyContent="space-between"
										ml=".5rem"
										mb="2rem"
										mt=".8rem"
										cursor="pointer"
									>
										<Box
											width="90%"
											objectFit="contain"
											onClick={() => {
												navigate("/home");
												if (isMobileTablet) setCollapsed(true);
											}}
										>
											<Image
												maxWidth="200px"
												minWidth="100px"
												m="0 auto"
												src={KarkahnaLogo}
												alt="Kkonnect.io"
											/>
										</Box>
										{!isMobileTablet && (
											<GoChevronLeft
												size={25}
												onClick={toggleCollapsed}
												cursor="pointer"
												style={{ color: "#63B29D" }}
											/>
										)}
									</Flex>
									{isMobileTablet && (
										<Flex
											mb="1.5rem"
											ml="1rem"
											justifyContent="flex-start"
											alignItems="center"
											gap="1.25rem"
										>
											<Box
												borderRadius="50%"
												bg="#E2F1EE"
												color="#4BA18A"
												width="4.5rem"
												height="4.5rem"
												display="flex"
												alignItems="center"
												justifyContent="center"
											>
												<Text
													color="#4BA18A"
													fontSize="2.5rem"
													fontWeight="bold"
												>
													{supplier?.customer?.user_first_name
														?.slice(0, 1)
														.toUpperCase()}
												</Text>
											</Box>
											<Flex flexDir="column">
												<Text
													textStyle="primary.contentTitle"
													fontWeight="extrabold"
													wordBreak="break-word"
												>
													{`${supplier?.customer?.user_first_name} ${supplier?.customer?.user_last_name}`}
												</Text>
												<Text
													textStyle="primary.small"
													textColor="#4BA18A"
													onClick={handleSignout}
													cursor="pointer"
												>
													Sign Out
												</Text>
											</Flex>
										</Flex>
									)}
									{menuItems.map((item, index) => (
										<motion.div variants={sideVariants} key={item.text}>
											<Flex align="center" _hover={{ color: "#4BA18A" }}>
												<IconText
													key={item.text}
													Icon={item.icon}
													text={item.text}
													fontSize="14px"
													p={{ base: "1rem 1.5rem", md: "1rem 0.5rem" }}
													mb="1rem"
													active={activeTab === item.link}
													link={item.link}
													cursor="pointer"
													onClick={() => {
														setActiveTab(item.link);
														if (isMobileTablet) setCollapsed(true);
													}}
												/>
											</Flex>
										</motion.div>
									))}
								</Box>
							)}
						</Flex>
					</motion.div>
				</Box>
			)}
			{/* Topbar */}
			<Flex
				direction="column"
				w={
					collapsed
						? isMobileTablet
							? "100%"
							: "93%"
						: isMobileTablet
							? "100%"
							: "84%"
				}
			>
				<Overlay
					isOpen={isMobileTablet && !collapsed}
					onClick={() => setCollapsed(true)}
				></Overlay>
				<Box h="65px" flexShrink={0}>
					<Flex
						h="100%"
						alignItems="center"
						justifyContent="space-between"
						padding="10px"
						width="100%"
						position="relative"
						zIndex={999}
					>
						{breadcrumbs()}
						<Flex
							justifyContent="flex-end"
							alignItems="center"
							gap="1.5rem"
							width={{ base: "auto", md: "100%" }}
							mr="1rem"
						>
							<Flex direction="row" alignItems="center" gap="1rem">
								{/* <Button
									onClick={() => toggleColorMode()}
									children={colorMode === "dark" ? <BiMoon /> : <BsSun />}
									variant={"link"}
								/> */}
								{/* TODO:Search functionality
								<motion.div
									initial={{ width: "30px" }}
									animate={{ width: isExpanded ? "250px" : "30px" }}
									exit={{ width: "30px" }}
									transition={{ duration: 0.3 }}
								>
									{isExpanded ? (
										<InputGroup>
											<InputRightElement>
												<IconButton
													icon={<CloseIcon boxSize={2} color="gray.600" />}
													variant="unstyled"
													size="sm"
													onClick={() => setExpanded(false)}
													aria-label={""}
												/>
											</InputRightElement>
											<InputLeftElement ml="0.3em">
												<IconButton
													icon={<BsSearch size={18} color="gray" />}
													variant="unstyled"
													size="sm"
													onClick={() => setExpanded(false)}
													aria-label={""}
												/>
											</InputLeftElement>
											<Input bgColor="gray.100" placeholder="Search" />
										</InputGroup>
									) : (
										<InputGroup>
											<IconButton
												icon={<BsSearch size={20} />}
												variant="unstyled"
												size="sm"
												onClick={() => setExpanded(true)}
												aria-label={""}
											/>
										</InputGroup>
									)}
								</motion.div> */}
								<Popover
									isLazy
									size="4xl"
									variant="responsive"
									autoFocus={false}
								>
									<PopoverTrigger>
										{/* <span>
											<BsBell size="20px" cursor="pointer" />
										</span> */}
										<button aria-label="Notifications">
											<BsBell size="20px" cursor="pointer" />
										</button>
									</PopoverTrigger>
									<PopoverContent>
										<PopoverArrow />
										<PopoverCloseButton />
										<PopoverBody p="0px">
											<Notifications />
										</PopoverBody>
									</PopoverContent>
								</Popover>
							</Flex>
							{!isMobileTablet && (
								<Box>
									<Stack direction="row" alignItems="center">
										<Box textAlign={"right"}>
											<Text
												textStyle="primary.smallTitle"
												fontWeight="bold"
												fontSize="14px"
											>
												{`${supplier?.customer?.user_first_name} ${supplier?.customer?.user_last_name}`}
											</Text>
										</Box>
										<Menu isLazy={true}>
											<MenuButton
												transition="all 0.2s"
												onClick={() => {
													setMenu(!menu);
												}}
											>
												<Flex alignItems="center" gap="0.4em">
													<Flex
														rounded="full"
														width="26px"
														height="26px"
														bg="#E2F1EE"
														fontWeight="bold"
														alignItems="center"
														justifyContent="center"
														fontFamily="Rubik"
														color="#4BA18A"
													>
														{supplier?.customer?.user_first_name
															?.charAt(0)
															.toUpperCase()}
													</Flex>
													<motion.div
														initial={{ scale: 1 }}
														animate={{ rotateX: menu ? 180 : 0 }}
														transition={{ duration: 0.5 }}
													>
														<BsChevronDown size="12" />
													</motion.div>
												</Flex>
											</MenuButton>
											<MenuList zIndex={91000}>
												<MenuItem
													marginTop="0"
													padding="1em"
													backgroundColor="#E2F1EE"
													borderRadius="md"
													onClick={() => navigate("/digitalFactory")}
												>
													<Flex gap="1.5em" alignItems="center">
														<Flex
															rounded="20%"
															width="50px"
															height="50px"
															bg="#4BA18A"
															fontWeight="bold"
															alignItems="center"
															justifyContent="center"
															fontFamily="Rubik"
															color="white"
														>
															{supplier?.customer?.user_first_name
																?.charAt(0)
																.toUpperCase()}
														</Flex>
														<Stack>
															<Text fontWeight="bold" fontFamily="Rubik" mb="0">
																{`${supplier?.customer?.user_first_name} ${supplier?.customer?.user_last_name}`}
															</Text>
															<Text
																fontFamily="Rubik"
																margin="0px"
																fontSize="12px"
															>
																{Cookies.get("user_id")}
															</Text>
														</Stack>
													</Flex>
												</MenuItem>
												{/* <MenuItem
													icon={<BsGear color="#4BA18A" size="25" />}
													onClick={() => {
														setActiveTab("");
														navigate("/settings");
													}}
													marginTop="0.6em"
													borderRadius="md"
												>
													<Text
														fontWeight="semibold"
														fontSize="14px"
														fontFamily="Rubik"
													>
														Settings
													</Text>
												</MenuItem> */}
												<MenuItem
													icon={<FiLogOut color="#4BA18A" size="25" />}
													onClick={handleSignout}
													borderRadius="md"
												>
													<Text
														fontWeight="semibold"
														fontSize="14px"
														fontFamily="Rubik"
													>
														Sign Out
													</Text>
												</MenuItem>
											</MenuList>
										</Menu>
									</Stack>
								</Box>
							)}
						</Flex>
					</Flex>
				</Box>
				<Flex
					flexGrow="1"
					bg="#f3f6f9"
				// w="fit-content"
				// objectFit="contain"
				>
					<Box
						// borderRadius=".75rem"
						// p={{base:"0", md: "1rem"}}
						height="90vh"
						overflowY="auto"
						width="100%"
					>
						{!(Component == null) && <Component />}
					</Box>
				</Flex>
			</Flex>
			{pageLoadingData?.loading && (
				<PageLoading
					pageLoading={pageLoadingData}
					pageLoaderURL={pageLoaderURL}
				/>
			)}
			<ProductFruits
				workspaceCode={
					process.env.REACT_APP_NODE_ENV === "production"
						? "GOK7fSaiWyBynH3t"
						: "LYC4W1fdNwBWJ81O"
				}
				language="en"
				user={{
					username: String(Cookies.get("user_id")),
					email: String(Cookies.get("user_id")),
					firstname: String(Cookies.get("full_name")),
				}}
			/>
		</Flex>
	);
};
