import React, { useEffect } from "react";
import { Text, Grid, GridItem, Flex, Box } from "@chakra-ui/react";
import {
	getItemAttachedFilesService,
	AppDispatch,
	formatErrorMessage,
	SET_MESSAGE,
	ERROR_MSG,
	frappeMethodService,
} from "@karkhanaui/react";
import PartConfigureLeftSide from "./PartConfigureLeftSide";
import { useDispatch } from "react-redux";
import ItemDataDisplay from "./ItemDataDisplay";

const ViewPartForm = (props: any) => {
	const dispatch = useDispatch<AppDispatch>();
	const [attachedFiles, setAttachedFiles] = React.useState<any>([]);
	const getAttachedFiles = async () => {
		let params = {
			filters: JSON.stringify([
				["attached_to_doctype", "=", "Item"],
				["attached_to_name", "in", props.itemData?.item_code],
			]),
			fields: JSON.stringify(["*"]),
			limit: 0,
		};
		try {
			let res: any;
			if (props.dataOriginatedFrom === "procurement") {
				res = await frappeMethodService(
					"crosslink.api.get_attachments_from_procurement",
					{
						doctype: "Item",
						uuid: props.itemData?.item_uuid,
					}
				);
			} else {
				res = await getItemAttachedFilesService(params);
			}
			if (props.dataOriginatedFrom === "procurement") {
				if (res.status === 200) {
					setAttachedFiles(res.data.message.data);
				}
			} else {
				if (res.status === 200) {
					setAttachedFiles(res.data.data);
				}
			}
		} catch (error) {
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	useEffect(() => {
		getAttachedFiles();
	}, [props?.itemData]);

	return (
		<Flex m={{base: "0", md:"1em"}} display="flex" flexDirection={{base: "column", md:"row"}} justifyContent={"space-between"} width="100%">
			<Box width={{base: "100%", md:"40%"}} height={{base: "60%", md: "auto"}} gap="2em" mb={{base:"1.5rem", md: "0"}}>
				<PartConfigureLeftSide
					attachedFiles={attachedFiles}
					dataOriginatedFrom={props.dataOriginatedFrom}
				/>
			</Box>
			<Box width={{base: "100%", md:"60%"}} bg="#fff" px={{base:"0", md:"1em"}} height="auto" overflow={"auto"}>
				<ItemDataDisplay
					itemData={props?.itemData}
					dataOriginatedFrom={props.dataOriginatedFrom}
				/>
			</Box>
		</Flex>
	);
};

export default ViewPartForm;
