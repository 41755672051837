import React, { useEffect } from "react";
import {
	normalDate,
	getQuoteService,
	SET_MESSAGE,
	ERROR_MSG,
	formatErrorMessage,
	AppDispatch,
	NoDataAvailable,
	get_doc,
	supplierUpdateNotificationService,
} from "@karkhanaui/react";
import { useDispatch } from "react-redux";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const Notifications = () => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const [notificationList, setNotificationList] = React.useState<any>([]);

	const fetchData = async () => {
		await get_doc({
			doc: "Notification Log",
			fields: ["*"],
		})
			.then((s) => {
				setNotificationList(
					s.data.data?.sort((a: any, b: any) => {
						return new Date(b.creation) - new Date(a.creation);
					})
				);
			})
			.catch((error) => {
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: formatErrorMessage(error),
					},
				});
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleOrderNavigation = (status: string, rfqName: string) => {
		switch (status) {
			case "PO Received":
				return `/ordersporeceived/${rfqName}`;
			case "Live":
				return `/orderslive/${rfqName}`;
			case "Active":
				return `/ordersporeceived/${rfqName}`;
			case "Cancelled":
				return `/orderspoview/${rfqName}`;
			case "complete":
				return `/orderscompleted/${rfqName}`;
			default:
				return "#";
		}
	};

	const getLinkAndMarkItComplete = async (notification: any) => {
		let link = "";
		switch (notification.document_type) {
			case "Opportunity":
				link = `/viewrfq/${notification.document_name}`;
				break;
			case "Quotation":
				link = `/quotemanagement/${notification.document_name}`;
				break;
			case "Sales Order":
				try {
					let res: any = await get_doc({
						doc: "Sales Order",
						fields: ["*"],
						filters: [["name", "=", notification.document_name]],
					});
					if (res.staus === 200) {
						link = handleOrderNavigation(
							res.data.data[0].portal_status,
							res.data.data[0].name
						);
					}
				} catch (error) {
					dispatch({
						type: SET_MESSAGE,
						payload: {
							case: ERROR_MSG,
							data: formatErrorMessage(error),
						},
					});
				}
				break;
			default:
				link = "#";
				break;
		}

		try {
			let res: any = await supplierUpdateNotificationService(
				notification.name,
				{ read: 1 }
			);
			if (res.status === 200) {
				fetchData();
			}
		} catch (error) {
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
		navigate(link);
	};

	return (
		<Box>
			<Text textStyle="primary.title" padding=".5rem">
				Notifications
			</Text>
			<hr />
			{notificationList?.length > 0 ? (
				<Box mt="1rem" maxHeight="55vh" overflowY="scroll" width="100%">
					{notificationList?.map((item: any, index: number) => (
						<Box key={index} width="100%" cursor="pointer">
							<Flex
								gap=".5rem"
								_hover={{ bg: "rgba(0, 135, 238, 0.2)" }}
								padding=".5rem"
								bg={item.read ? "#fff" : "#E2F1EE"}
								onClick={() => getLinkAndMarkItComplete(item)}
								width="100%"
							>
								<Box width="100%">
									<Flex
										alignItems="center"
										justifyContent="space-between"
										width="100%"
									>
										<Text textStyle="primary.secondaryText" fontSize=".75rem">
											{normalDate(item?.creation)}
										</Text>
										<Text textStyle="primary.secondaryText" fontSize=".75rem">
											{item?.creation.substring(10, 16)}
										</Text>
									</Flex>
									<Box width="100%">
										<Text
											textStyle="primary.text"
											fontWeight={item.read ? "" : "semibold"}
										>
											{item.subject.replace(/<[^>]*>/g, "")}
										</Text>
									</Box>
								</Box>
							</Flex>
							<hr />
						</Box>
					))}
				</Box>
			) : (
				<NoDataAvailable />
			)}
		</Box>
	);
};
