import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Flex, Show, Text, Image, Tag } from "@chakra-ui/react";
import {
	GenericTables,
	formatErrorMessage,
	type AppDispatch,
	setPageLoading,
	SET_MESSAGE,
	NoDataAvailable,
	ERROR_MSG,
	frappeMethodService,
	BasicDialog,
	get_doc,
	Overlay,
	BottomSheet,
} from "@karkhanaui/react";
import { Link } from "react-router-dom";
import "./styles.css";
import { useDispatch } from "react-redux";
import { CustomerCards } from "./CustomerCards";
import Cookies from "js-cookie";
import { BsPlusLg } from "react-icons/bs";
import { CommonPopupDFCards } from "../digitalFactory/DigitalFactoryPopupCard";
import AddCustomer from "./AddCustomer";

const CustomerManagement = () => {
	const [tableData, setTableData] = useState<any>();
	const [inviteSent, setInviteSent] = useState<boolean>(false);
	const dispatch = useDispatch<AppDispatch>();
	const loggedInUser = Cookies.get("user_id");
	const [showAddCustomer, setShowAddCustomer] = useState<boolean>(false);
	const formRef = useRef(null);
	const [companyName, setCompanyName] = useState<string>("");

	const errorHandler = (error: any) => {
		dispatch(
			setPageLoading({
				loading: false,
				loadingText: "",
			})
		);
		dispatch({
			type: SET_MESSAGE,
			payload: {
				case: ERROR_MSG,
				data: formatErrorMessage(error),
			},
		});
	};

	const column = React.useMemo(
		() => [
			{
				Header: "Customer Name",
				accessor: "customerName",
				Cell: ({ row }: any) => (
					<Link
						to={`/customer/${row.original.name}`}
						style={{
							textDecoration: "none",
							color: "#4BA18A",
							fontWeight: "600",
						}}
					>
						{row.original.customerName || "-"}
					</Link>
				),
			},
			{
				Header: "NDA Status",
				accessor: "nda_status",
				Cell: ({ row }: any) => {
					let bg = "transparent";
					let color = "#000";
					let status = "-";
					switch (row.original?.nda_status) {
						case "NA":
							break;
						case "Pending":
							status = "Pending";
							bg = "rgba(253, 185, 19, 0.2)";
							color = "#EBA700";
							break;
						case "Signed":
							status = "Signed";
							bg = "#E8FFF3";
							color = "#50B848";
							break;
						default:
							break;
					}
					return (
						<Tag
							size="md"
							bg={bg}
							color={color}
							fontSize=".75rem"
							fontWeight="600"
						>
							{status}
						</Tag>
					);
				},
				disableSortBy: true,
			},
			{
				Header: "RFQ History",
				accessor: "rfq",
				Cell: ({ row }: any) => (
					<Link
						to={`/rfqmain/filter?company_name=${row.original?.customerID}`}
						style={{
							textDecoration: "none",
							color: "#4BA18A",
							fontWeight: "600",
						}}
					>
						View RFQs&nbsp;({row.original?.rfq})
					</Link>
				),
			},
			{
				Header: "Quote History",
				accessor: "quotes",
				Cell: ({ row }: any) => {
					return (
						<Link
							to={`/quotes/filter?company_name=${row.original?.customerID}`}
							style={{
								textDecoration: "none",
								color: "#4BA18A",
								fontWeight: "600",
							}}
						>
							View Quotes&nbsp;({row.original?.quote})
						</Link>
					);
				},
			},
			{
				Header: "Order History",
				accessor: "sales_order",
				Cell: ({ row }: any) => (
					<Link
						to={`/orders/filter?company_name=${row.original.customerID}`}
						style={{
							textDecoration: "none",
							color: "#4BA18A",
							fontWeight: "600",
						}}
					>
						View Orders&nbsp;({row.original?.sales_order})
					</Link>
				),
			},
		],
		[]
	);

	const onload = async () => {
		try {
			let res: any = await get_doc({
				doc: "Buyer Access",
				fields: ["company_name", "name", "company_hex", "nda_status"],
				limit: 0,
				order_by: "company_name asc",
			});
			if (res.status === 200) {
				let dataItems = res.data.data?.map(async (item: any) => {
					let response: any = await frappeMethodService(
						"supplierv2.api.customer.customer_count",
						{
							name: item?.name,
						}
					);
					if (response.status == 200) {
						return {
							name: item?.name,
							customerName: item?.company_name,
							customerID: item?.company_hex,
							nda_status: item?.nda_status,
							rfq: response.data.message.data?.rfq,
							quote: response.data.message.data?.quote,
							sales_order: response.data.message.data["sales order"],
						};
					} else {
						return {
							name: item?.name,
							customerName: item?.company_name,
							customerID: item?.company_hex,
							nda_status: item?.nda_status,
						};
					}
				});
				const _data = await Promise.all(dataItems);
				return _data;
			}
		} catch (error) {
			const message =
				"There was an error in fetching customer list. " +
				formatErrorMessage(error);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: message,
				},
			});
		}
	};

	const memoizedData = React.useMemo(() => tableData, [tableData]);

	const fetchData = async () => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Loading...",
			})
		);
		const dataItems = await onload();
		setTableData(dataItems);
		dispatch(
			setPageLoading({
				loading: false,
				loadingText: "",
			})
		);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleCompanyNameChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setCompanyName(event.target.value);
	};

	const inviteCustomer = async (event: any) => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Sending Invite...",
			})
		);
		try {
			await frappeMethodService("supplierv2.api.customer.customer_invitation", {
				payload: {
					...event,
					supplier: loggedInUser,
				},
			});
			setInviteSent(true);
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
		} catch (error) {
			errorHandler(error);
		}
		setInviteSent(true);
	};

	const AddCustomerButton = () => (
		<Button
			backgroundColor="#4BA18A"
			color="white"
			onClick={() => setShowAddCustomer(true)}
			_hover={{ bg: "#38846D" }}
		>
			+ Add Customer
		</Button>
	);

	return (
		<Box p="1rem" m="1rem" borderRadius="0.75rem" bg="#fff">
			{memoizedData?.length == 0 && (
				<Flex justifyContent="flex-end">{AddCustomerButton()}</Flex>
			)}
			<Show above="md">
				{memoizedData?.length > 0 ? (
					<GenericTables
						data={memoizedData}
						column={column}
						paginationbg={"#4BA18A"}
						headerColor={"#E2F1EE"}
						borderColor={"#B7DDD4"}
						isShowSearch={true}
						placeholderText="Search Customer Name, Customer ID..."
						topRightContent={AddCustomerButton()}
					/>
				) : (
					<NoDataAvailable />
				)}
				<BasicDialog
					isOpen={showAddCustomer}
					onClose={() => {
						setShowAddCustomer(false);
						setInviteSent(false);
					}}
					showCloseButton={true}
					header="Add Customer"
					size="lg"
					content={
						<AddCustomer
							inviteSent={inviteSent}
							companyName={companyName}
							closeFunction={() => {
								setShowAddCustomer(false);
								setInviteSent(false);
							}}
							setInviteSent={setInviteSent}
							inviteCustomer={inviteCustomer}
							formRef={formRef}
							handleCompanyNameChange={handleCompanyNameChange}
						/>
					}
				/>
			</Show>
			<Show below="md">
				{memoizedData?.map((data: any, index: number) => {
					return (
						<Box mb="1rem" key={index}>
							<CustomerCards
								buyerName={data.customerName}
								buyerString={data.name}
								rfq={data.rfq}
								quote={data.quote}
								sales_order={data.sales_order}
								company_hex={data.customerID}
							/>
						</Box>
					);
				})}
			</Show>
			<Show below="md">
				<Button
					borderRadius="full"
					bgColor="#4BA18A"
					color="#fff"
					fontSize="26px"
					height="60px"
					width="60px"
					position="fixed"
					bottom="1rem"
					right="1rem"
					_active={{ bgColor: "4BA18A" }}
					_hover={{ bgColor: "4BA18A" }}
					onClick={() => {
						setShowAddCustomer(true);
					}}
				>
					<BsPlusLg strokeWidth="2px" />
				</Button>

				<Overlay
					isOpen={showAddCustomer}
					onClose={() => {
						setShowAddCustomer(false);
						setInviteSent(false);
					}}
				>
					<BottomSheet
						isOpen={showAddCustomer}
						onClose={() => {
							setShowAddCustomer(false);
							setInviteSent(false);
						}}
					>
						<CommonPopupDFCards
							title="Add Customer"
							maxHeight="50vh"
							component={
								<AddCustomer
									inviteSent={inviteSent}
									companyName={companyName}
									closeFunction={() => {
										setShowAddCustomer(false);
										setInviteSent(false);
									}}
									setInviteSent={setInviteSent}
									inviteCustomer={inviteCustomer}
									formRef={formRef}
									handleCompanyNameChange={handleCompanyNameChange}
								/>
							}
						/>
					</BottomSheet>
				</Overlay>
			</Show>
		</Box>
	);
};

export default CustomerManagement;
