import React from "react";
import {
	Button,
	Text,
	Input,
	Flex,
	Img,
} from "@chakra-ui/react";

interface CommonPopupCardsProps {
	handleCancel?: any;
	handleSave?: any;
	handleDelete?: any;
	submitNewQuote?: any;
	image?: any;
	cardText?: string;
	title?: string;
	saveText?: string;
    cancel?: boolean;
    component?:any;
	currentExpiry?: any;
	maxHeight?: any;
	saveFunction?: any;
}

function CommonPopupDFCards({
	image,
	handleCancel,
	handleSave,
	handleDelete,
	cardText,
	title,
    cancel,
	maxHeight,
	saveText,
	component,
}: CommonPopupCardsProps) {
	return (
		<Flex flexDirection="column" h={`${maxHeight ? maxHeight : "60vh"}`} alignItems="center" gap=".25rem">
			{title && (
				<Text
					fontSize="18px"
					fontWeight="semibold"
					color="#4ba18a"
					textAlign="center"
				>
					{title}
				</Text>
			)}
			{image && <Img src={image} height="38vh" />}
			{cardText && (
				<Text textAlign="center">
					{cardText}
				</Text>
			)}
            {component && (
				<Flex justifyContent="center" pos="sticky" w="100vw" zIndex={1} >
					{component}
				</Flex>
			)}

			{(handleDelete || handleCancel ||  cancel || handleDelete) &&
			(
			<Flex justifyContent="center" pos="absolute" bottom="0" gap="1rem" zIndex={1} py="1rem" px="1rem" w="100vw" bg="#fff" boxShadow="0px -4px 10px rgba(0, 0, 0, 0.1)" borderRadius="0px 0px 10px 10px">
				{handleSave && (
				<Button 
					onClick={handleSave}
					color="white"
					bg="#4BA18A"
					height="2.625rem"
					flexGrow={1}
				>
					Save {saveText}
				</Button>
				)}
				{cancel && (
				<Button 
					onClick={handleCancel}
					variant="outline"
					color="#4BA18A"
					border=".05rem solid #4BA18A"
					flexGrow={1}
				>
					Cancel
				</Button>
				)}
				{handleDelete && (
					<Button
						onClick={handleDelete}
						color="white"
						bgColor="#EF6F6C"
						height="2.625rem"
						flexGrow={1}
					>
						Delete
					</Button>
				)}
			</Flex>
			)}
		</Flex>
	);
}

export {CommonPopupDFCards};