import React, { useEffect, useState } from "react";
import {
	Box,
	Text,
	Flex,
	Divider,
	Tag,
	Grid,
	GridItem,
	OrderedList,
	ListItem,
	Link as ChakraLink,
	Link,
	Spacer,
	chakra,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";

import { Link as Lk, useNavigate } from "react-router-dom";

import {
	GenericTables,
	Button,
	get_doc,
	NoDataAvailable,
} from "@karkhanaui/react";
import { AiOutlineEye, AiOutlineShareAlt } from "react-icons/ai";
import {
	BsDownload as BsDownloadIcon,
	BsDownload as BsDownloadIcon,
} from "react-icons/bs";
import AcceptRfq from "../../img/AcceptRfq.png"; // eslint-disable-line

import Group from "../../img/Group.png"; // eslint-disable-line
import { RfqPopupCard } from "../rfq/RfqPopupCard";
import OrderStatusBar from "./OrderStatusBar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
	type AppDispatch,
	setPageLoading,
	SET_MESSAGE,
	ERROR_MSG,
	formatErrorMessage,
	getSingleSalesOrder,
	getSingleOpportunity,
	supplierGetSingleOpportunityService,
	supplierUpdateOpportunityService,
	updateSalesOrder,
	submitDoc,
} from "@karkhanaui/react";

const OrdersPoView: React.FC<any> = () => {
	const { poId, rfq_name } = useParams();
	const PO_ID = rfq_name;

	const dispatch = useDispatch<AppDispatch>();

	const [isPopupOpen, setIsPopupOpen] = React.useState(false);
	const [isfeedbackPopupOpen, setIsfeedbackPopupOpen] = React.useState(false);
	const [tableData, setTableData] = React.useState<any>();
	const [rfqtableData, setRfqtableData] = React.useState<any>();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [orderDetails, setOrderDetails] = React.useState<any>({});
	const [companyName, setCompanyName] = React.useState<string>("");
	const [rfqName, setRfqName] = React.useState<any>();
	const [rfqid, setRfqId] = React.useState<any>();
	const [opportunity, setOpportunity] = React.useState<any>();
	const [mappedData, setMappedData] = React.useState<any>([]);
	const [owner, setOwner] = useState<any>();
	const [created, setCreated] = useState<any>();
	const [deliveryDate, setDeliveryDate] = useState<any>();
	const [rfq, setRfq] = React.useState<any>({});
	const [billingaddress, setBillingAddress] = useState<any>();
	const [shippingaddress, setShippingAddress] = useState<any>();
	const [itemsData, setItemsData] = useState<any>([]);
	const [showPOAccepted, setShowPOAccepted] = React.useState<any>(false);
	const [salesOrder, setSalesOrder] = React.useState<any>({});

	const navigate = useNavigate();

	const handleOpenModal = () => {
		const fetchRfqData = async () => {
			const dataItems = await fetchDetails();
			setRfqtableData(dataItems);
		};
		// fetchDetails();
		fetchRfqData();
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleOpenFeedbackPopup = () => {
		setIsfeedbackPopupOpen(true);
	};

	const handleCloseFeedbackPopup = () => {
		setIsfeedbackPopupOpen(false);
	};
	const handleAcceptRFQPopup = () => {
		setIsPopupOpen(true);
	};

	const handleCloseRFQPopup = () => {
		setIsPopupOpen(false);
	};

	const [isPartOpen, setIsPartOpen] = React.useState(false);
	const [actions, setActions] = React.useState(false);

	const Modalcolumn = React.useMemo(
		() => [
			{
				Header: "Part Name",
				accessor: "ItemName",
				Cell: ({ row }) => (
					<ChakraLink
						as={Link}
						color="teal"
						textDecoration="underline"
						to={""}
						fontWeight="semibold"
					>
						{row.original.ItemName}
					</ChakraLink>
				),
			},
			{
				Header: "Category",
				accessor: "category",
			},
			{
				Header: "Quantity",
				accessor: "Quantity",
			},
			{
				Header: "UOM",
				accessor: "uom",
			},
			{
				Header: "Target Cost",
				accessor: "targetcost",
			},
			{
				Header: "Rate",
				accessor: "rate",
			},
			{
				Header: "Amount",
				accessor: "Amount",
			},
			{
				Header: "",
				accessor: "view part",
			},
		],

		[]
	);

	const column = [
		{
			Header: "Item ID",
			accessor: "ItemId",
		},
		{
			Header: "Item Name",
			accessor: "ItemName",
		},
		{
			Header: "HSN / SAC",
			accessor: "hsn",
		},
		{
			Header: "Quantity",
			accessor: "Quantity",
		},
		{
			Header: "Rate",
			accessor: "rate",
		},
		{
			Header: "Amount",
			accessor: "Amount",
		},
	];

	const memoFunc = async () => {
		try {
			const res = await get_doc({
				doc: "Sales Order",
				fields: ["*"],
				limit: 0,
			});
			if (res.status === 200) {
				const dataItems = res.data.data.map((item: any) => {
					const dateTime = new Date(item.creation);
					const dateString = dateTime.toISOString().slice(0, 10);
					const str: any = item.opportunity;
					const rfqid = str.split(" | ")[1];
					return {
						cname: item.name,
						rfq_name: item.name,
						rfq_id: rfqid,
						po_number: item.po_no,
						po_amount: item.grand_total,
						status: item.event_status,
						receivedOn: dateString,
						delivery_date: item.delivery_date,
					};
				});

				const poReceivedItems = dataItems.filter(
					(item: { event_status: string }) =>
						item.event_status === "PO Received"
				);
				const liveItems = dataItems.filter(
					(item: { event_status: string }) => item.event_status === "Live"
				);
				const cancelledItems = dataItems.filter(
					(item: { event_status: string }) => item.event_status === "Cancelled"
				);
				const completed = dataItems.filter(
					(item: { status: string }) => item.status === "complete"
				);

				return dataItems;
			}
		} catch (error: any) {
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	const acceptPO = async () => {
		// api to accept PO
		dispatch(setPageLoading({ loading: true, loadingText: "Loading" }));
		const data = { accepted: true };
		try {
			setIsPopupOpen(false);
			setButtonLoading(true);
			const res: any = await updateSalesOrder(rfq_name, data);
			const params = {
				doctype: "Sales Order",
				docname: rfq_name,
			};
			// submit SO doc
			try {
				const submitRes: any = await submitDoc(params);

				supplierUpdateOpportunityService(opportunity, {
					portal_status: "Confirmed",
					card_status: "PO_ACCEPTED",
				});

				dispatch(setPageLoading({ loading: false, loadingText: "" }));
				setShowPOAccepted(true);
			} catch (e) {
				const message = formatErrorMessage(e);
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: message,
					},
				});
				dispatch(setPageLoading({ loading: false, loadingText: "" }));
			}
		} catch (error) {
			const message =
				"There was an error in updating the order. " +
				formatErrorMessage(error);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: message,
				},
			});
			dispatch(setPageLoading({ loading: false, loadingText: "" }));
		}
		setIsPopupOpen(false);
	};

	const getOrderDetails = async () => {
		try {
			const res: any = await getSingleSalesOrder(rfq_name);

			const resp = res.data.data.items.map((a: any) => a.name);

			if (res.status === 200) {
				const data = res.data.data;
				setSalesOrder(res.data.data);
				const dataArray = Object.keys(data).map((key: any) => ({
					key,
					value: data[key],
				}));

				const owner = dataArray.find((item) => item.key === "owner")?.value;
				const company_name = dataArray.find(
					(item) => item.key === "company_name"
				)?.value;
				const rfq_name = dataArray.find(
					(item) => item.key === "rfq_name"
				)?.value;
				const opportunity = dataArray.find(
					(item) => item.key === "opportunity"
				)?.value;
				const rfqid = opportunity.split(" | ")[1];
				const creation = dataArray.find(
					(item) => item.key === "creation"
				)?.value;
				const dateOnly = creation.split(" ")[0];
				const deliveryDate = dataArray.find(
					(item) => item.key === "delivery_date"
				)?.value;
				const value = creation.substring(0, 10);
				const name = dataArray.find((item) => item.key === "name")?.value;
				const billing_address = dataArray.find(
					(item) => item.key === "billing_address"
				)?.value;
				const shipping_address = dataArray.find(
					(item) => item.key === "shipping_address"
				)?.value;
				setOwner(owner);
				setCompanyName(company_name);
				setRfqName(rfq_name);
				setRfqId(rfqid);
				setCreated(dateOnly);
				setBillingAddress(billing_address);
				setShippingAddress(shipping_address);
				setOpportunity(opportunity);
				setDeliveryDate(deliveryDate);

				const dataItems = res.data.data.items.map((item: any) => {
					return {
						ItemId: item.name,
						ItemName: item.item_name,
						hsn: item.po_no,
						Quantity: item.qty,
						rate: item.rate,
						Amount: item.amount,
					};
				});
				return dataItems;
			}
		} catch (error) {
			const message =
				"There was an error in fetching order details. " +
				formatErrorMessage(error);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: message,
				},
			});
		}
	};

	const memoizedData = React.useMemo(() => tableData, [tableData]);
	const memoizedRfqData = React.useMemo(() => rfqtableData, [rfqtableData]);
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const acceptRfq = async () => {
		try {
			setIsPopupOpen(false);
			setButtonLoading(true);
			const res: any = await supplierUpdateOpportunityService(opportunity, {
				portal_status: "Accepted",
				card_status: "PREPARE_QUOTE",
			});
			if (res.status === 200) {
				setButtonLoading(false);
			}
		} catch (error) {
			setButtonLoading(false);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
		setIsPopupOpen(false);
	};

	const fetchDetails = async () => {
		try {
			const res = await supplierGetSingleOpportunityService(opportunity);

			const rfqdataItems = res.data.data.items.map((item: any) => {
				return {
					ItemId: item.name,
					ItemName: item.item_name,
					Quantity: item.qty,
					uom: item.po_no,
					targetcost: item.target_cost,
					rate: item.rate,
					Amount: item.amount,
				};
			});
			setOrderDetails(rfqdataItems);
			return rfqdataItems;
		} catch (error) {
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const dataItems = await getOrderDetails();
			setTableData(dataItems);
		};
		fetchData();
	}, []);

	return (
		<Box w="100%">
			<OrderStatusBar
				poId={salesOrder.po_no}
				status={salesOrder?.portal_status}
			/>
			<Box
				boxShadow="md"
				p="2em"
				m="0em 1em 1em 1em"
				bg="#fff"
				// display="flex"
				// justifyContent="space-between"
				borderRadius="md"
				position="sticky"
			>
				<Flex gap="2em">
					<Box>
						<Text fontSize="16px" color="#4BA18A" fontWeight="semibold">
							RFQ Name
						</Text>
						<Text fontSize="16px" color="black" fontWeight="medium" mt="10px">
							{salesOrder?.rfq_name}
						</Text>
					</Box>

					<Box>
						<Text fontSize="16px" color="#4BA18A" fontWeight="semibold">
							RFQ ID
						</Text>
						<Text fontSize="16px" color="black" fontWeight="medium" mt="10px">
							{salesOrder?.opportunity?.split(" | ")[1]}
						</Text>
					</Box>

					<Box>
						<Text fontSize="16px" color="#4BA18A" fontWeight="semibold">
							Company Name
						</Text>
						<Text fontSize="16px" color="black" fontWeight="medium" mt="10px">
							{salesOrder.company}
						</Text>
					</Box>

					<Box>
						<Text fontSize="16px" color="#4BA18A" fontWeight="semibold">
							Created by
						</Text>
						<Text fontSize="16px" color="black" fontWeight="medium" mt="10px">
							{salesOrder.owner}
						</Text>
					</Box>

					<Spacer />

					<Flex alignItems="center" gap="2em">
						<Box>
							<ChakraLink
								to={"/viewrfq"}
								as={Link}
								color="#4BA18A"
								fontWeight="bold"
								textDecoration="underline"
								onClick={() => {
									navigate(`/viewrfq/${salesOrder.opportunity}`);
								}}
							>
								View RFQ
							</ChakraLink>
						</Box>
						{/* 
            <Box>
              <ChakraLink
                as={Link}
                // to={"/viewrfq"}
                color="#4BA18A"
                fontWeight="bold"
                textDecoration="none"
                display={'flex'}
              >
                <Box as={BsDownloadIcon} size="20px" mr="0.5em" />
                Download PO
              </ChakraLink>
            </Box> */}
					</Flex>
				</Flex>
			</Box>

			<Box
				// width="98%"
				boxShadow={"md"}
				p="0em"
				m="1em 1em 1em 1em"
				bg="#fff"
				display={"flex"}
				justifyContent={"space-between"}
				borderRadius="md"
				position={"sticky"}
			>
				<Text fontSize={"30px"} color="#4BA18A" fontWeight={"bold"} m="1em">
					Purchase Order
				</Text>
				{/* {rfqData?.portal_status === "Open" && ( */}
				{/* <Flex textAlign={'end'} gap="1em" m="1em" ml="1em">
          <Button
            borderRadius={'5px'}
            colorScheme={'teal'}
            onClick={handleAcceptRFQPopup}
            size="md"
            bg="#4BA18A"
            color="white"
            m={'1em 1em 1em 0.5em'}
          >
            Accept PO
          </Button>
          <Button
            size="md"
            borderRadius={'5px'}
            bg="#E2F1EE"
            color="#4BA18A"
            m={'1em 1em 1em 0.5em'}
            colorScheme={'teal'}
            variant="outline"
            onClick={handleOpenFeedbackPopup}
          >
            Send Feedback
          </Button>
        </Flex> */}
				{/* )} */}
			</Box>
			<Box bg="#fff" m="0em 1em 1em 1em" height="auto" overflowY="auto">
				<Grid
					h="auto"
					templateRows="repeat(2, 1fr)"
					templateColumns="90px 1fr 1fr 1fr 1fr"
					m={"0.2em 1em 1em 1em"}
					bg="#fff"
					p="1em"
				>
					<GridItem rowSpan={2} colSpan={1}>
						<Box
							borderRadius={"full"}
							height="80px"
							width="80px"
							bg="green.50"
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<Text fontSize="40px" fontWeight={"semibold"} color="#4BA18A">
								C
							</Text>
						</Box>
					</GridItem>
					<GridItem display={"flex"} flexDirection="column">
						<Text fontSize="22px" fontWeight="semibold" color="#4BA18A">
							Customer Company Name
						</Text>
						<Text>{salesOrder.customer}</Text>
						<Flex>
							<Text fontSize={"14px"} fontWeight="semibold">
								GST No:
							</Text>
							<Text fontSize={"14px"} ml="0.3em">
								{" "}
								{/* TODO: Need GST Number */}
								Not Available
							</Text>
						</Flex>
						<Text fontSize={"14px"} fontWeight="semibold">
							Bill to
						</Text>
						<Text fontSize={"14px"}>{salesOrder.billing_address}</Text>
					</GridItem>

					<GridItem
						rowSpan={1}
						colSpan={3}
						display="flex"
						justifyContent={"end"}
					>
						<Flex flexDirection={"column"}>
							<Flex>
								<Text
									color="#4BA18A"
									fontSize="14px"
									mr="0.2em"
									fontWeight="semibold"
								>
									Purchase ID :
								</Text>
								<Text fontSize="14px"> {salesOrder.po_no} </Text>
							</Flex>

							<Flex>
								<Text color="#4BA18A" fontSize="14px" fontWeight="semibold">
									RFQ ID :
								</Text>
								<Text fontSize="14px" ml="0.3em">
									{rfq_name}
								</Text>
							</Flex>

							<Flex>
								<Text
									color="#4BA18A"
									fontSize="14px"
									mr="0.2em"
									fontWeight="semibold"
								>
									Created On :
								</Text>
								<Text fontSize="14px"> {created}</Text>
							</Flex>

							<Flex>
								<Text
									color="#4BA18A"
									fontSize="14px"
									mr="0.2em"
									fontWeight="semibold"
								>
									Required By :
								</Text>
								<Text fontSize="14px"> {deliveryDate} </Text>
							</Flex>
						</Flex>
					</GridItem>

					<GridItem display={"flex"} flexDirection="column" mt="1em">
						<Text fontSize="22px" fontWeight="semibold" color="#4BA18A">
							Supplier Full Name
						</Text>
						<Text>Supplier Name</Text>
						<Flex>
							<Text fontSize={"14px"} fontWeight="semibold">
								GST No:
							</Text>
							<Text fontSize={"14px"} ml="0.3em">
								{" "}
								AAA0000AAA
							</Text>
						</Flex>
						<Text fontSize={"14px"} fontWeight="semibold">
							Bill to
						</Text>
						<Text fontSize={"14px"}>{billingaddress}</Text>
					</GridItem>
					<GridItem display={"flex"} flexDirection="column" mt="3em" ml="2em">
						<Text fontWeight="semibold" fontSize="14px">
							Ship To
						</Text>
						<Text fontSize={"14px"}>{shippingaddress},</Text>
					</GridItem>
				</Grid>
				<Divider />
				<Text fontWeight="semibold" m="2em">
					Items
				</Text>
				<Box m="1em 2em 2em 2em">
					{memoizedData?.length > 0 ? (
						<GenericTables
							data={memoizedData || []}
							column={column}
							renderCheckBox={false}
							renderSerialNo={true}
							paginationbg={"#4BA18A"}
							headerColor={"#E2F1EE"}
							isShowSearch={false}
						/>
					) : (
						<NoDataAvailable />
					)}
				</Box>
				<Divider />
				<Text m="2em" fontWeight={"semibold"}>
					Terms and Conditions
				</Text>
				<Box m="1em 2em 2em 2em" border={"1px solid black"} borderRadius="md">
					<div dangerouslySetInnerHTML={{ __html: salesOrder.terms }} />
				</Box>
			</Box>
			<Modal
				isOpen={isModalOpen}
				onClose={handleCloseModal}
				size="70%"
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					bg="white"
					width="80%"
					position="absolute"
					top="0"
					right="35"
					bottom="0"
					transform="translateX(100%)"
					transition="transform 0.3s ease-in-out"
					overflowY="auto"
				>
					<ModalHeader color="#4BA18A" fontWeight="bold" fontSize="25px">
						Request for Quotation
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Grid
							templateRows="repeat(2, 1fr)"
							templateColumns="75px 1fr 0.23fr"
						>
							<GridItem mt="1em" ml="1em">
								<Box
									borderRadius="full"
									height="70px"
									width="70px"
									bg="green.50"
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Text fontSize="32px" fontWeight="semibold" color="#4BA18A">
										C
									</Text>
								</Box>
							</GridItem>
							<GridItem ml="1em" mt="1em">
								<Text
									mt="0.5em"
									fontSize="20px"
									fontWeight="bold"
									color="#4BA18A"
								>
									Karkhana.io
								</Text>
								<Text mt="0.2em" fontSize="18px">
									Requestor’s Name
								</Text>
							</GridItem>
							<GridItem mr="1em" mt="1em">
								<Text>
									<chakra.span color="teal" mr="0.2em">
										Recieved On:
									</chakra.span>
									{created}
								</Text>
								<Text>
									<chakra.span color="teal" mr="0.2em">
										Expires On:
									</chakra.span>
									{deliveryDate}
								</Text>
								<Text>
									<chakra.span color="teal" mr="0.2em">
										Expected Lead Time:
									</chakra.span>
									20 days
								</Text>
							</GridItem>
						</Grid>

						<Box m="1em" mt="4em" overflow="auto">
							{memoizedRfqData?.length > 0 ? (
								<GenericTables
									data={memoizedRfqData || []}
									column={Modalcolumn}
									renderCheckBox={false}
									renderSerialNo={true}
									showGlobalFilter={false}
									paginationbg={"#4BA18A"}
									headerColor={"#E2F1EE"}
								/>
							) : (
								<NoDataAvailable />
							)}

							<Box mt="2em" ml="1em" bg="white">
								<Text
									color={"#4BA18A"}
									fontSize={"18px"}
									fontWeight={"semibold"}
								>
									Payment Terms
								</Text>
								<Text fontSize={"18px"} mt="1em">
									{" "}
									Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
								</Text>
								<Text
									color={"#4BA18A"}
									mt="1em"
									fontSize={"18px"}
									fontWeight={"semibold"}
								>
									Documents Required
								</Text>
								{/* <Flex gap="3em" mt="1em">
                  <Text
                    fontSize={"18px"}
                    fontWeight={"semibold"}
                    color="black"
                    display={"flex"}
                  >
                    {" "}
                    <MdAttachFile size="25px" /> Material Certificate
                  </Text>
                  <Text
                    fontSize={"18px"}
                    fontWeight={"semibold"}
                    color="black"
                    display={"flex"}
                  >
                    {" "}
                    <MdAttachFile size="25px" /> Quality Certificate{" "}
                  </Text>
                </Flex> */}
								<Divider variant="solid" border="1px " mt="1em" />
								<Box mt="2em" ml="1em">
									<Text fontSize={"18px"}>
										{" "}
										<chakra.span
											color="black"
											mr="0.2em"
											fontSize={"18px"}
											fontWeight={"semibold"}
										>
											{" "}
											Billing Address :{" "}
										</chakra.span>{" "}
										{billingaddress}
									</Text>
									<Text fontSize={"18px"} mt="0.5em">
										{" "}
										<chakra.span
											color="black"
											mr="0.2em"
											fontSize={"18px"}
											fontWeight={"semibold"}
										>
											{" "}
											Shipping Address :{" "}
										</chakra.span>{" "}
										{shippingaddress}
									</Text>
								</Box>
								<Divider variant="solid" border="1px " mt="1em" />
								<Box mt="2em">
									<Text
										color={"#4BA18A"}
										fontSize={"18px"}
										fontWeight={"semibold"}
									>
										{" "}
										Terms & Conditions{" "}
									</Text>
									<Box
										m="1em 2em 2em 1em"
										border={"1px solid black"}
										borderRadius="md"
									>
										<div
											dangerouslySetInnerHTML={{ __html: salesOrder.terms }}
										/>
									</Box>
								</Box>
							</Box>
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
			<RfqPopupCard
				isOpen={isPopupOpen}
				onClose={handleCloseRFQPopup}
				title="Accept PO"
				message={`Are you sure you want to accept the PO with PO ID: ${poId} from karkhana.io`}
				onCancel={handleCloseRFQPopup}
				onAccept={acceptPO}
				imageURL={AcceptRfq}
				BCancel={"Cancel"}
				BAccept={"Accept"}
			/>
			<RfqPopupCard
				isOpen={isfeedbackPopupOpen}
				onClose={handleCloseFeedbackPopup}
				title="Send Feedback"
				message="Send a Feedback on Purchase Order (ID: PUR ORD-2022)"
				onCancel={handleCloseRFQPopup}
				onAccept={() => {
					//  accept RFQ logic goes here
					handleCloseRFQPopup();
				}}
				textarea={true}
				BCancel={"Cancel"}
				BAccept={"Send"}
			/>
		</Box>
	);
};

export default OrdersPoView;
