import {
  Box,
  Flex,
  Center,
  Text,
  VStack,
  Button,
  Divider,
  Tooltip,
  Tag,
} from "@chakra-ui/react";
import React from "react";
import * as PDFJS from "pdfjs-dist";

import { BsArrowRightCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Link as Lk } from "react-router-dom";

const getStatusColor = (status: string) => {
  let bg = "#fff";
  let color = "#000";
  let customLabel = "";
  switch (status) {
    case "PO Received":
      bg = "#FDB91320";
      color = "#EBA700";
      customLabel = "Quote has been sent";
      break;

    case "Live":
    case "Active":
      bg = "#0087ee20";
      color = "#0087EE";
      customLabel = "Quote validity has Expired";
      break;

    case "Cancelled":
      bg = "rgba(0, 0, 0, 0.05)";
      color = "#rgba(0, 0, 0, 0.5)";
      customLabel = "Quote has been cancelled";
      break;

    case "complete":
      bg = "#E2F1EE";
      color = "#4BA18A";
      customLabel = "Quote not submitted";
      break;

    default:
      break;
  }
  return (
    <Tag size="md" bg={bg} color={color}>
      {status}
    </Tag>
  );
};

interface OrdersMainCardsInterface {
  companyName: string;
  rfqName: string;
  rfqId: string;
  poId: any;
  status: string;
  poAmount: number;
  createdOn: any;
}

const OrdersMainCards = ({
  companyName,
  rfqName,
  rfqId,
  poId,
  status,
  poAmount,
  createdOn,
}: OrdersMainCardsInterface) => {
  const navigate = useNavigate();
  const handleNavigation = (status: string, rfqName: string) => {
    console.log({status})
    switch (status) {
      case "PO Received":
        navigate(`/ordersporeceived/${rfqName}`);
        break;
      case "Live":
        navigate(`/orderslive/${rfqName}`);
        break;
      case "Active":
        navigate(`/ordersporeceived/${rfqName}`);
        break;
      case "Cancelled":
        // navigate(`/orderspoview/${rfqName}`);
        navigate(`/orderslive/${rfqName}`);
        break;
      case "complete":
        navigate(`/orderscompleted/${rfqName}`);
        break;
      default:
        break;
    }
  };
  return (
    <Box
    fontSize="12px"
    borderRadius="lg"
    boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
    padding="1em 1.5em"
    width={"100%"}
    margin="0em auto"
    bgColor="#fff"
  >
    <Flex gap="1em" alignItems="center" marginBottom="1em">
        <Center
          w="60px"
          h="60px"
          bg="#E2F1EE"
          color="#4BA18A"
          borderRadius="50%"
          fontSize="35px"
          onClick={() => {
            handleNavigation(status, poId);
          }}
        >
          {companyName?.slice(0, 1) || "K"}
        </Center>
        <Flex flexDirection="column">
          <Text fontWeight="bold" fontSize="14px">
            {companyName}
          </Text>
          <Text>PO No: {poId}</Text>
          <Text>RFQ Name: {rfqName}</Text>
          <Text>RFQ ID: {rfqId}</Text>
        </Flex>
        <Flex flexDirection="column" mt="auto" ml="auto">
          {getStatusColor(status)}
        </Flex>
      </Flex>
      <Divider />
      <Flex justifyContent="space-between" marginTop="1em">
        <VStack alignItems="flex-start">
          <Text fontWeight="600" fontSize="12px" color="#4BA18A">
            PO Amount
          </Text>
          <Text mt="1px !important">
            {poAmount === undefined ? "-" : poAmount}
          </Text>
        </VStack>
        <VStack alignItems="flex-start">
          <Text fontWeight="600" fontSize="12px" color="#4BA18A">
            Delivery Date
          </Text>
          <Text mt="1px !important">
            {createdOn === undefined ? "-" : createdOn}
          </Text>
        </VStack>
        <Button
          color="#4BA18A"
          fontSize="14px"
          variant="link"
          onClick={() => {
            handleNavigation(status, poId);
          }}
        >
          View Order &nbsp;
          <BsArrowRightCircle color="#4BA18A" size="1.25rem" />
        </Button>
      </Flex>
    </Box>
  );
};

interface ViewOrdersCardProps {
  companyName: string;
  rfqName: string;
  createdBy: string;
  rfqId: string;
  poId?: string;
  receivedOn?: any;
  downloadSalesOrder?: any;
  viewRfqFunction?: any;
  createNewQuoteFunction?: any;
  setViewRfq?:any;
  status?: any;
  isDownload : any;
  name? : any;
}

function ViewOrdersCard({
  companyName,
  rfqName,
  rfqId,
  poId,
  receivedOn,
  downloadSalesOrder,
  createdBy,
  setViewRfq,
  isDownload,
  name
}: ViewOrdersCardProps) {
  const navigate = useNavigate();
  console.log(poId)
  return (
    <Box
      fontSize="12px"
      borderRadius="lg"
      boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
      padding="1em 1.5em"
      width={"100%"}
      margin="0em auto"
      bgColor="#fff"
    >
      <Flex gap="1em" alignItems="center" marginBottom="1em">
        <Center
          w="60px"
          h="60px"
          bg="#E2F1EE"
          color="#4BA18A"
          borderRadius="50%"
          fontSize="35px"
        >
          {companyName?.slice(0, 1) || "K"}
        </Center>
        <Flex flexDirection="column">
          <Text fontWeight="bold" fontSize="14px">
            {companyName}
          </Text>
          <Text fontFamily={"Rubik"} fontSize="0.75rem" fontStyle={"normal"}>
            PO Ref Id: {poId}
          </Text>
          <Text fontFamily={"Rubik"} fontSize="0.75rem" fontStyle={"normal"}>
            RFQ Name: {rfqName}
          </Text>
          <Text fontFamily={"Rubik"} fontSize="0.75rem" fontStyle={"normal"}>
            RFQ ID: {rfqId}
          </Text>
          <Text fontFamily={"Rubik"} fontSize="0.75rem" fontStyle={"normal"}>
            RFQ Date: {receivedOn}
          </Text>
          <Text fontFamily={"Rubik"} fontSize="0.625rem" fontStyle={"normal"}>
            {createdBy}
          </Text>
        </Flex>
      </Flex>
      <Divider />
      <Flex justifyContent="space-between" marginTop="1em">
        <Button
          size="md"
          borderRadius={"5px"}
          bg="#fff"
          color="#4BA18A"
          m={"1em 1em 1em 0.5em"}
          colorScheme={"teal"}
          variant="outline"
          fontWeight="bold"
          _hover={{ bg: "#38846D" }}
          onClick={()=>setViewRfq(true)}
        >
          View RFQ
        </Button>

       {isDownload?
        ( <Button
          borderRadius={"5px"}
          colorScheme={"teal"}
          onClick={downloadSalesOrder}
          m={"1em 1em 1em 0.5em"}
          size="md"
          bg="#4BA18A"
          color="white"
          _hover={{ bg: "#38846D" }}
        >
          Download PO
        </Button>):(
         <>

         	<Lk to={`/ordersporeceived/${name}`}>
							<Button
								size="md"
								borderRadius={"5px"}
								bg="#4BA18A"
								color="#fff"
								m={"1em 1em 1em 0.5em"}
								colorScheme={"teal"}
								_hover={{bg: "#4BA18A70"}}
								fontWeight="bold"
								
							>
								View PO
							</Button>
						</Lk>
         </>
        )}
      </Flex>
    </Box>
  );
}

interface purchaseOrderCard {
  setIsfeedbackPopupOpen : any;
  setIsPopupOpen : any;
  salesOrderPdf : any;
  defaultLayoutPluginInstance : any;
  status? : any

}
function PurchaseOrder({setIsfeedbackPopupOpen,setIsPopupOpen, salesOrderPdf, defaultLayoutPluginInstance , status}: purchaseOrderCard) {
  return (
    <Box mt="1rem">
      <Box>
        <Box
          borderTopLeftRadius={"md"}
          borderTopRightRadius={"md"}
          border="1px solid #4BA18A"
          color={"#4BA18A"}
          bg="#fff"
          p={2}
          boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
        >
          <Text
            fontFamily={"Rubik"}
            fontWeight={"normal"}
            fontSize="1rem"
            color="#4BA18A"
          >
            Purchase Order
          </Text>
        </Box>
        <Box bg="#fff" mt="0.3em"  borderRadius={"md"}>
        <Box
        
          height="57vh"
          overflowY="auto"
          m="1rem 1.5rem 1.5rem "
          borderRadius="0.75rem"
          mb="5rem"
        >
          {salesOrderPdf && (
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={salesOrderPdf}
                plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          )}
        </Box></Box>
      </Box>
     {status==="PO Received" && <Flex
        bgColor="#fff"
        justifyContent={"space-between"}
        position={"fixed"}
        bottom={0}
        left={0}
        right={0}
        gap="1em"
        p="1rem"
        
      >
        <Button
          bg="#fff"
          border="1px solid #63B29D"
          color="#4BA18A"
          _hover={{ bg: "#E2F1EE" }}
          onClick={()=>setIsfeedbackPopupOpen(true)}
          fontSize="14px"
        >
          Send Feedback
        </Button>

        <Button
          bg="#4BA18A"
          color="#FFFFFF"
          _hover={{ bg: "#4BA18A70" }}
          onClick={()=>setIsPopupOpen(true)}
          fontSize="14px"
        >
         Accept PO
        </Button>
      </Flex>}
    </Box>
  );
}
export { OrdersMainCards, ViewOrdersCard, PurchaseOrder };
