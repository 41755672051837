import React from "react";
import { Box, Text, Flex, Icon, Grid, GridItem } from "@chakra-ui/react";
import { GoGear } from "react-icons/go";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "swiper/swiper.css";
import "./swipermobile.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
const StepperMobile = ({ update }: any) => {
  return (
    <Swiper
      spaceBetween={20}
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      loop={true}
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <Box
          mb="3rem"
          width="45vw"
          flex={"1"}
          border="1px solid #E2F1EE"
          boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
          borderRadius="md"
        >
          <Flex width="100%" m="0.6rem">
            <GoGear size="24px" color="#4BA18A" />
          </Flex>
          <Box bg="#E2F1EE" width="100%">
            <Text
              fontSize="1.125rem"
              color="#4BA18A"
              ml="0.6rem"
              fontFamily="Rubik"
              fontWeight="bold"
            >
              {update?.manufacturing}
            </Text>
            <Text
              fontSize="1rem"
              ml="0.6rem"
              mr="0.6rem"
              fontFamily="Rubik"
              fontWeight="normal"
            >
              Manufacturing
            </Text>
          </Box>
        </Box>
      </SwiperSlide>

      <SwiperSlide>
        <Box
          mb="3rem"
          width="45vw"
          flex={"1"}
          border="1px solid #E2F1EE"
          boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
          borderRadius="md"
        >
          <Flex width="100%" m="0.6rem">
            <GoGear size="24px" color="#4BA18A" />
          </Flex>
          <Box bg="#E2F1EE" width="100%">
            <Text
              fontSize="1.125rem"
              color="#4BA18A"
              ml="0.6rem"
              fontFamily="Rubik"
              fontWeight="bold"
            >
              {update?.in_transit}
            </Text>
            <Text
              fontSize="1rem"
              ml="0.6rem"
              mr="0.6rem"
              fontFamily="Rubik"
              fontWeight="normal"
            >
              In Transit
            </Text>
          </Box>
        </Box>
      </SwiperSlide>

      <SwiperSlide>
        <Box
          mb="3rem"
          width="45vw"
          flex={"1"}
          border="1px solid #E2F1EE"
          boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
          borderRadius="md"
        >
          <Flex width="100%" m="0.6rem">
            <GoGear size="24px" color="#4BA18A" />
          </Flex>
          <Box bg="#E2F1EE" width="100%">
            <Text
              fontSize="1.125rem"
              color="#4BA18A"
              ml="0.6rem"
              fontFamily="Rubik"
              fontWeight="bold"
            >
              {update?.delivered}
            </Text>
            <Text
              fontSize="1rem"
              ml="0.6rem"
              mr="0.6rem"
              fontFamily="Rubik"
              fontWeight="normal"
            >
              Delivered
            </Text>
          </Box>
        </Box>
      </SwiperSlide>
    </Swiper>
  );
};
export default StepperMobile;
