import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import RoutesList from "./routes/routes";
import { PublicLayout } from "./layouts/PublicLayout";
import { PrivateLayout } from "./layouts/PrivateLayout";
import {
	Toastr,
	AppDispatch,
	setMessage,
	setLoading,
	logout,
	frappeMethodService,
	setPageLoading,
	SET_MESSAGE,
	ERROR_MSG,
	formatErrorMessage,
	SUCCESS_MSG,
} from "@karkhanaui/react";
import { Mixpanel } from "./Mixpanel.js";
import {
	ThemeProvider,
	theme,
	ColorModeProvider,
	CSSReset,
	useMediaQuery,
} from "@chakra-ui/react";
const Home = lazy(async () => await import("./pages/home/Home"));
const Login = lazy(async () => await import("./pages/auth/Login"));
const NotFound = lazy(async () => await import("./pages/error/NotFound"));
import TagManager from "react-gtm-module";
import { datadogRum } from "@datadog/browser-rum";
import Cookies from "js-cookie";
import { datadogLogs } from "@datadog/browser-logs";
import axios, { AxiosError, AxiosResponse } from "axios";
import { ServiceWorkerPrompt } from "./serviceWorkerPrompt";

const environment = process.env.REACT_APP_NODE_ENV;
// GTM
const tagManagerArgs = {
	production: {
		gtmId: "GTM-WFDDSBB",
		gtmAuth: "2OgWtA70KMm9QHqyxcy80Q",
	},
	development: {
		gtmId: "GTM-WFDDSBB",
		gtmAuth: "9ELQxlA-E8CJY52SGSdQDQ",
	},
	// Add more environments as needed
};
if (environment !== "development") {
	TagManager.initialize({
		gtmId: tagManagerArgs[environment].gtmId,
		dataLayer: { gtmAuth: tagManagerArgs[environment].gtmAuth },
	});
}
// --------------------DataDog implementation--------------------
const dataDodArgs = {
	production: {
		applicationId: "765f8f95-4c02-4315-81c5-7103def7f9c0",
		clientToken: "pub93fb50bf9034fbc4b4a6bf7dc21499c3",
		service: "supplier_frontend_prod",
	},
	development: {
		applicationId: "21c78c5e-10f1-4feb-9c3e-724640ee410d",
		clientToken: "pub7d0ace1640f05cc550a25723e237d12b",
		service: "supplier_frontend_dev",
	},
};
if (environment !== "development") {
	datadogRum.setUser({
		name: Cookies.get("full_name") || "Guest User",
		email: Cookies.get("user_id") || "Guest Email",
	});
	datadogRum.init({
		applicationId: dataDodArgs[environment].applicationId,
		clientToken: dataDodArgs[environment].clientToken,
		site: "datadoghq.com",
		service: dataDodArgs[environment].service,
		env: window.location.hostname.split(".")[0],
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: "mask-user-input",
	});

	datadogLogs.init({
		clientToken: dataDodArgs[environment].clientToken,
		site: "datadoghq.com",
		forwardErrorsToLogs: true,
		sessionSampleRate: 100,
		env: environment,
		service: "SUPPLIER",
	});

	datadogRum.startSessionReplayRecording();
}
// --------------------DataDog implementation--------------------

const App: React.FC = () => {
	const dispatch = useDispatch<AppDispatch>();
	const [authState] = useSelector((state: any) => {
		return [state.authSupplier];
	});
	const isLoggedIn = authState.isLoggedIn;
	const rfq_uuid = window.location.href.split("rfq=")[1];
	const so_uuid = window.location.href.split("po=")[1];
	const toastMsg = useSelector((state: any) => state.message);
	const [isMobileTablet] = useMediaQuery("(max-width: 768px)");
	if (environment !== "development") {
		Mixpanel.track_pageview();
	}
	axios.interceptors.response.use(
		function (response: AxiosResponse) {
			dispatch(setLoading(false));
			return response;
		},
		function (error: AxiosError) {
			dispatch(setLoading(false));
			if (error.response?.status === 403) {
				if (isLoggedIn) {
					dispatch(logout());
				}
			}
			return Promise.reject(error);
		}
	);

	const errorHandler = (error: any) => {
		dispatch({
			type: SET_MESSAGE,
			payload: {
				case: ERROR_MSG,
				data: formatErrorMessage(error),
			},
		});
		dispatch(
			setPageLoading({
				loading: false,
				loadingText: "",
			})
		);
	};

	const checkAndTriggerRfqShare = async () => {
		if (
			rfq_uuid != undefined &&
			isLoggedIn &&
			![
				"login",
				"register",
				"verifyemail",
				"resetpwd",
				"forgotpassword",
			].includes(window.location.pathname.split("/")[1]?.toLowerCase())
		) {
			dispatch(
				setPageLoading({
					loading: true,
					loadingText: "Initiating RFQ...",
				})
			);
			await frappeMethodService(
				"supplierv2.api.auth.create_Share_link_signup",
				{
					email: Cookies.get("user_id"),
					rfq_uuid: rfq_uuid,
					status: "Initiated",
				}
			)
				.then((res: any) => {
					dispatch(
						setPageLoading({
							loading: false,
							loadingText: "",
						})
					);
					dispatch({
						type: SET_MESSAGE,
						payload: {
							case: SUCCESS_MSG,
							data: "RFQ is Initialized, will be soon available in you RFQ dashboard",
						},
					});
				})
				.catch((error: any) => {
					errorHandler(error);
				});
		}
	};

	const checkAndTriggerPOShare = async () => {
		if (
			so_uuid != undefined &&
			isLoggedIn &&
			![
				"login",
				"register",
				"verifyemail",
				"resetpwd",
				"forgotpassword",
			].includes(window.location.pathname.split("/")[1]?.toLowerCase())
		) {
			dispatch(
				setPageLoading({
					loading: true,
					loadingText: "Initiating Sales Order...",
				})
			);
			await frappeMethodService("crosslink.api.fetch_po", {
				unique_identifier: so_uuid,
			})
				.then((res: any) => {
					dispatch(
						setPageLoading({
							loading: false,
							loadingText: "",
						})
					);
					dispatch({
						type: SET_MESSAGE,
						payload: {
							case: SUCCESS_MSG,
							data: "Sales Order is Initialized, will be soon available in you Orders dashboard",
						},
					});
				})
				.catch((error: any) => {
					errorHandler(error);
				});
		}
	};

	useEffect(() => {
		checkAndTriggerRfqShare();
	}, [rfq_uuid]);
	
	useEffect(() => {
		checkAndTriggerPOShare();
	}, [so_uuid]);

	useEffect(() => {
		if (toastMsg?.message?.data) {
			setTimeout(() => {
				dispatch(setMessage(""));
			}, 3000);
		}
	}, [toastMsg]);

	useEffect(() => {
		if (isLoggedIn && environment !== "development") {
			//setting  the logged in user session
			datadogRum.setUser({
				name: Cookies.get("full_name"),
				email: Cookies.get("user_id"),
			});
		}
	}, [isLoggedIn]);

	return (
		<ThemeProvider theme={theme}>
			<ServiceWorkerPrompt />
			<ColorModeProvider>
				<CSSReset />
				<BrowserRouter>
					<Routes>
						{RoutesList.filter((route: any) => route.type === "public").map(
							(route: any, index: number) =>
								isLoggedIn ? (
									<Route
										key={index}
										path="/home"
										element={
											<Suspense>
												<PrivateLayout Component={Home} />
											</Suspense>
										}
									/>
								) : (
									<Route
										key={index}
										path={route.link}
										element={
											<Suspense>
												<PublicLayout Component={route.component} />
											</Suspense>
										}
									/>
								)
						)}
						{RoutesList.filter((route: any) => route.type === "private").map(
							(route: any, index: number) =>
								isLoggedIn ? (
									<Route
										key={index}
										// exact
										path={route.link}
										element={
											<Suspense>
												<PrivateLayout Component={route.component} />
											</Suspense>
										}
									/>
								) : (
									<Route
										key={index}
										path="/login"
										element={
											<Suspense>
												<PublicLayout component={Login} />
											</Suspense>
										}
									/>
								)
						)}
						{isLoggedIn ? (
							<>
								{RoutesList.filter((route: any) => route.type === "public").map(
									(route: any, index: number) => (
										<Route
											key={index}
											// exact
											path={route.link}
											element={
												<Suspense>
													<Navigate to="/home" replace={false} />
												</Suspense>
											}
										/>
									)
								)}
								<Route
									path="*"
									element={
										<Suspense>
											<PrivateLayout Component={NotFound} />
										</Suspense>
									}
								/>
								<Route
									path="/"
									element={
										<Suspense>
											<Navigate to="/home" replace={false} />
										</Suspense>
									}
								/>
							</>
						) : (
							<Route
								path="*"
								element={
									<Suspense>
										<Navigate to="/login" replace={false} />
									</Suspense>
								}
							/>
						)}
					</Routes>
				</BrowserRouter>
				{toastMsg?.message?.data && <Toastr toastMsg={toastMsg} />}
			</ColorModeProvider>
		</ThemeProvider>
	);
};

export default App;
