import {
  Box,
  Flex,
  Text,
  chakra,
  Grid,
  GridItem,
  Show,
} from "@chakra-ui/react";
import React from "react";
import { normalDate } from "@karkhanaui/react";
import { Stepper as Steps, Step } from "react-form-stepper";
import { BsGear, BsTruck, BsBagCheck } from "react-icons/bs";
import StepperMobile from "./StepperMobile";

const Stepper: React.FC<any> = ({ update }) => {
  const activeStep = 3;

  return (
    <Box my=".5rem">
      <Show above="md">
        <Grid templateColumns="auto 1fr" alignItems="center">
          <Text fontWeight="semibold">
            <chakra.span color="#4BA18A" mr="0.2em">
              Part Name: :
            </chakra.span>
            {update?.item_name
              ? `${update?.item_name} (${update?.item_code})`
              : update?.item_code}
          </Text>
          <Text
            color="#4BA18A"
            fontWeight="semibold"
            justifySelf="end"
            mr="1em"
          >
            {normalDate(update?.creation)}
          </Text>
        </Grid>
        <Flex
          mt="1rem"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Box width="100%">
            <Steps
			
              activeStep={3}
              styleConfig={{ completedBgColor: "#B7DDD4", size: "2rem" }}
              connectorStyleConfig={{
                completedColor: "#B7DDD4",
                activeColor: "#B7DDD4",
                disabledColor: "#B7DDD4",
              }}
            >
              <Step
                children={<BsGear color="#4BA18A" size="19px" />}
                label={
                  <Flex flexDirection="column" alignItems="center">
                    <Text fontSize="16px" color="#4BA18A">
                      {update?.manufacturing}
                    </Text>
                    <Text fontSize="16px">Manufacturing</Text>
                  </Flex>
                }
              />
              <Step
                children={<BsTruck color="#4BA18A" size="19px" />}
                label={
                  <Flex flexDirection="column" alignItems="center">
                    <Text fontSize="16px" color="#4BA18A">
                      {update?.in_transit}
                    </Text>
                    <Text fontSize="16px">In Transit</Text>
                  </Flex>
                }
              />
              <Step
                children={<BsBagCheck color="#4BA18A" size="19px" />}
                label={
                  <Flex flexDirection="column" alignItems="center">
                    <Text fontSize="16px" color="#4BA18A">
                      {update?.delivered}
                    </Text>
                    <Text fontSize="16px">Delivered</Text>
                  </Flex>
                }
              />
            </Steps>
          </Box>
        </Flex>
      </Show>
      <Show below="md">
       <StepperMobile update = {update}/>
       
      </Show>
    </Box>
  );
};

export default Stepper;
