import React from "react";
// eslint-disable-next-line
import PageLoaderURL from "../img/loader.gif";
import { AppShell } from "./AppShell";

export const PrivateLayout: React.FC<any> = ({
	Component,
}: {
	Component: React.LazyExoticComponent<React.FC<any>>;
}) => {
	return <AppShell children={Component} pageLoaderURL={PageLoaderURL} />;
};
