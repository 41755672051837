import React from "react";
import { Box, Flex, Text, Tag, Button } from "@chakra-ui/react";
import { BsCardList } from "react-icons/bs";
import { BasicDialog } from "@karkhanaui/react";

interface Props {
	rfqname: string;
	status: string;
	button?: string;
	modified: string;
	customerRfqId: string;
	rejectReason?: string;
}

export { RfqStatusBar, getStatusColor };
const getStatusColor = (status: string) => {
	switch (status) {
		case "Accepted":
			return { bg: "rgba(253, 185, 19, 0.2)", color: "#EBA700" };
		case "Lost":
		case "Cancelled":
		case "Rejected":
			return { bg: "rgba(239, 111, 108, 0.2)", color: "#EF6F6C" };
		case "Quoted":
			return { bg: "rgba(0, 135, 238, 0.1)", color: "#0087EE" };
		case "Open":
			return {
				bg: "linear-gradient(0deg, #F5EFFF, #F5EFFF), rgba(253, 185, 19, 0.2)",
				color: "rgba(169, 117, 255, 1)",
			};
		case "Confirmed":
			return { bg: "#E2F1EE", color: "#4BA18A" };
		default:
			return { bg: "gray.100", color: "gray.400" };
	}
};

const RfqStatusBar: React.FC<Props> = ({
	rfqname,
	status,
	modified,
	customerRfqId,
	rejectReason,
}) => {
	const [openDetailsBox, setOpenDetailsBox] = React.useState<boolean>(false);
	const tagColor = getStatusColor(status);

	return (
		<Box width="100%" bg="#fff" p="1rem">
			<Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
				<Flex alignItems="center">
					<Text textStyle="primary.bold">RFQ Name:&nbsp;</Text>
					<Text mr="1rem">{rfqname}</Text>
					<Text textStyle="primary.bold">Reference ID:&nbsp;</Text>
					<Text mr="1rem">{customerRfqId}</Text>
					<Text textStyle="primary.bold">Status:</Text>
					<Tag bg={tagColor.bg} ml="1em" color={tagColor.color}>
						{status}
					</Tag>
				</Flex>
				<Flex justifyContent="space-between" alignItems="center" gap="1rem">
					{status === "Rejected" && (
						<Button
							children="View Details"
							variant="outline"
							color="#4BA18A"
							borderColor="#4BA18A"
							_hover={{ bg: "#E2F1EE" }}
							leftIcon={<BsCardList />}
							size="sm"
							onClick={() => setOpenDetailsBox(true)}
						/>
					)}
					<Text textStyle="primary.secondaryText" fontSize="1rem">
						Updated On:&nbsp;{modified}
					</Text>
				</Flex>
			</Flex>
			<BasicDialog
				isOpen={openDetailsBox}
				onClose={() => setOpenDetailsBox(false)}
				showCloseButton={true}
				size="xl"
				header={
					<Text display="flex" alignItems="center" gap=".5rem">
						<BsCardList />
						View Details
					</Text>
				}
				content={
					<Box p="2rem .5rem 1.5rem .5rem">
						<Box
							border="1px solid #E4E6EF"
							borderRadius=".375rem"
							p="1rem"
							fontSize="1.125rem"
							lineHeight="1.5rem"
							color="#00000060"
						>
							<Text>
								This RFQ was rejected by you due to the following reason:
							</Text>
							<Text fontWeight={700} color="#00000080">
								<br />
								{rejectReason || "No reason provided"}
							</Text>
						</Box>
						<Flex justifyContent="flex-end">
							<Button
								children="Close"
								color="#fff"
								bg="#4BA18A"
								_hover={{ backgroundColor: "#38846D" }}
								mt="3rem"
								onClick={() => setOpenDetailsBox(false)}
							/>
						</Flex>
					</Box>
				}
			/>
		</Box>
	);
};

export default RfqStatusBar;
