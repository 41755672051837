import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Text,
	Flex,
	chakra,
	// Link as ChakraLink,
	Divider,
	// useDisclosure,
	Image,
	Show,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Tooltip,
	Img,
	Select,
	Textarea,
} from "@chakra-ui/react";
import { Mixpanel } from "../../Mixpanel.js";
import { GiAlarmClock } from "react-icons/gi";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
	BasicDialog,
	GenericTables,
	type AppDispatch,
	formatErrorMessage,
	SET_MESSAGE,
	ERROR_MSG,
	setPageLoading,
	supplierGetSingleOpportunityService,
	normalDate,
	supplierUpdateOpportunityService,
	getQuotationList,
	getItemAttachedFilesService,
	supplierCreateQuotationService,
	get_doc,
	frappeMethodService,
	BottomSheet,
	Overlay,
} from "@karkhanaui/react";
import { useDispatch, useSelector } from "react-redux";
import { MdAttachFile } from "react-icons/md";
import {
	// BsBell,
	BsChat,
	BsDownload,
	// BsCalendarPlus,
	BsCardChecklist,
} from "react-icons/bs";
import { RfqPopupCard, CommonPopupCards } from "./RfqPopupCard";
import { useNavigate, useParams } from "react-router-dom";
import RejectRfqPopup from "./RejectRfqPopup";
import RejectRfq from "../../img/RejectRfq.png";
import AcceptRfq from "../../img/AcceptRfq.png";
import Extend from "../../img/Extend.png";
import {
	ViewRFQCard,
	RFQPartsCard,
	BackButton,
	ViewRFQActions,
} from "./RfqMainCard";

import ViewPartForm from "../quotes/ViewPartForm";
import { RfqStatusBar, getStatusColor } from "./RfqStatusBar";
import noParts from "../../img/capability.svg";

const JSZip = require("jszip");
const saveAs = require("file-saver").saveAs;

const ViewRfq: React.FC<any> = (props) => {
	const params = useParams();
	const rfqId = params.rfqId || props.rfqId; // for calling this page in a popup from other screens
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const [isPartOpen, setIsPartOpen] = useState(false);
	const [isPartOpenMob, setIsPartOpenMob] = useState(false);
	const [rfqData, setRfqData] = useState<any>({});
	const [itemsData, setItemsData] = useState<any>([]);
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [isRejectRFQPopupOpen, setIsRejectRQFPopupOpen] = useState(false);
	const [isExtensionPopupOpen, setIsExtensionPopupOpen] = useState(false);
	const [showReasonSelect, setShowReasonSelect] = useState(false);
	const [selectedReason, setSelectedReason] = useState("");
	const [selectedItem, setSelectedItem] = useState<any>();
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);
	const [quotes, setQuotes] = useState<any>();
	const [isExtensionOpen, setIsExtensionOpen] = React.useState(false);
	const [isAcceptOpen, setIsAcceptOpen] = React.useState(false);
	const [isRejectOpen, setIsRejectOpen] = React.useState(false);
	const [isSubmitQuoteOpen, setIsSubmitQuoteOpen] = React.useState(false);
	const [documentsRequired, setDocumentsRequired] = React.useState<any>([]);
	const [allFiles, setAllFiles] = React.useState<any>([]);
	const [authState, supplier] = useSelector((state: any) => {
		return [state.authSupplier, state.getUser];
	});
	const [paginationVal, setPaginationVal] = useState<number>(5);
	const [pages, setPages] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);
	const [rejectionStep, setRejectionStep] = useState<number>(1);
	const [otherReason, setOtherReason] = useState<any>("");

	const rejectionReasons = [
		"Out of Capability",
		"Can't meet target cost",
		"Can't meet timeline",
		"Running at full Capacity",
		"Other(Description)",
	];

	const acceptRfq = async () => {
		try {
			setIsPopupOpen(false);
			setButtonLoading(true);
			const res: any = await supplierUpdateOpportunityService(rfqId, {
				portal_status: "Accepted",
				card_status: "PREPARE_QUOTE",
			});
			if (res.status === 200) {
				setButtonLoading(false);
				handleClose("accept");
				fetchData();
				window.dataLayer?.push({
					event: "rfq Accept",
					rfq_id: rfqId,
					user_email: supplier?.customer?.email,
					status: "Accepted",
					user_id: authState?.uniqueUserId,
					organization_name: supplier?.customer?.supplier_name,
					total_parts: rfqData.items?.length,
					buyer_organization_name:
						rfqData?.company_name || rfqData?.requester || rfqData?.party_name,
				});
				Mixpanel.track("rfqAccept", {
					rfq_id: rfqId,
					user_email: supplier?.customer?.email,
					status: "Accepted",
					user_id: authState?.uniqueUserId,
					organization_name: supplier?.customer?.supplier_name,
					total_parts: rfqData.items?.length,
					buyer_organization_name:
						rfqData?.company_name || rfqData?.requester || rfqData?.party_name,
				});
			}
		} catch (error) {
			setButtonLoading(false);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
		setIsPopupOpen(false);
	};

	const handleRejectRFQPopup = () => {
		setIsRejectRQFPopupOpen(true);
		setShowReasonSelect(false);
	};

	const handleCloseRejectRFQPopup = () => {
		setIsRejectRQFPopupOpen(false);
	};

	const handleAcceptExtension = () => {
		setIsExtensionPopupOpen(true);
	};

	const handleCloseExtension = () => {
		setIsExtensionPopupOpen(false);
	};

	const handleSave = (expiryDate: Date) => {
		handleCloseExtension();
	};

	const fetchData = async () => {
		try {
			dispatch(
				setPageLoading({
					loading: true,
					loadingText: "Loading...",
				})
			);
			const res = await supplierGetSingleOpportunityService(rfqId);
			if (res.status === 200) {
				const documents: any = [];
				setRfqData(res.data.data);
				if (res.data.data?.quality_certificate) {
					await documents.push("Quality Certificate");
				}
				if (res.data.data?.material_certificate) {
					await documents.push("Material Certificate");
				}
				if (res.data.data?.other_documents) {
					if (Object.keys(JSON.parse(res.data.data?.other_documents_names))) {
						await Object.keys(
							JSON.parse(res.data.data.other_documents_names)
						)?.map(async (item: any) => await documents.push([item]));
					} else {
						await documents.push("Other Documents");
					}
				}
				setDocumentsRequired(documents);
				const params = {
					fields: JSON.stringify(["*"]),
					filters: JSON.stringify([["opportunity", "=", rfqId]]),
					limit: 0,
				};
				const result: any = await getQuotationList(params);
				if (result.status === 200) {
					setQuotes(result.data.data);
				}

				setItemsData([...res.data.data?.items]);
				if (res.data.data?.items.length < 5)
					setPaginationVal(res.data.data?.items.length);
				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
			}
		} catch (error) {
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	useEffect(() => {
		fetchData();
	}, [rfqId]);

	const column = [
		{
			Header: "Part Name",
			accessor: "item_name",
			Cell: (i: any) => (
				<Flex cursor="pointer">
					<Tooltip label={i.row.original.item_name}>
						<Text
							color="#4BA18A"
							textStyle="primary.link"
							onClick={() => {
								setSelectedItem(i.row.original);
								setIsPartOpen(true);
							}}
						>
							{i.row.original.item_name.slice(0, 20) || "-"}
							{i.row.original.item_name.length > 20 ? "..." : ""}
						</Text>
					</Tooltip>
				</Flex>
			),
		},
		{
			Header: "Category",
			accessor:
				rfqData?.rfq_originated_from === "procurement"
					? "item_category"
					: "item_type",
			Cell: (i: any) => (
				<Text>
					{rfqData?.rfq_originated_from === "procurement"
						? i.row.original?.item_category
						: i.row.original?.item_type || "-"}
				</Text>
			),
			disableSortBy: true,
		},
		{
			Header: "Quantity",
			accessor: "qty",
			Cell: (i: any) => (
				<Text>{i.row.original?.qty >= 0 ? i.row.original?.qty : "-"}</Text>
			),
			disableSortBy: true,
		},
		{
			Header: "UOM",
			accessor: "uom",
			Cell: (i: any) => <Text>{i.row.original?.uom || "-"}</Text>,
			disableSortBy: true,
		},
		{
			Header: "Target Cost",
			accessor: "target_cost",
			Cell: (i: any) => <Text>{i.row.original?.target_cost || "-"}</Text>,
			disableSortBy: true,
		},
		{
			Header: "",
			accessor: "view_part",
			Cell: (i: any) => (
				<Text
					color="#4BA18A"
					textStyle="primary.link"
					onClick={() => {
						setSelectedItem(i.row.original);
						setIsPartOpen(true);
					}}
					cursor="pointer"
				>
					View Part
				</Text>
			),
			disableSortBy: true,
		},
	];

	const getDateDiff = (expiryDate: any) => {
		const currentDate: any = new Date();
		const date2: any = new Date(expiryDate);
		const diffTime = date2 - currentDate;
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays;
	};

	const statusColor = getStatusColor(rfqData?.portal_status);

	const handleOpen = (sheetName: string) => {
		switch (sheetName) {
			case "extension":
				setIsExtensionOpen(true);
				break;

			case "parts":
				break;

			case "accept":
				setIsAcceptOpen(true);
				break;

			case "reject":
				setIsRejectOpen(true);
				break;

			case "quote":
				setIsSubmitQuoteOpen(true);
				break;

			default:
				break;
		}
	};

	const handleClose = (sheetName: string) => {
		switch (sheetName) {
			case "extension":
				setIsExtensionOpen(false);
				break;

			case "parts":
				break;

			case "accept":
				setIsAcceptOpen(false);
				break;

			case "reject":
				setRejectionStep(1);
				setSelectedReason("");
				setIsRejectOpen(false);
				break;

			case "quote":
				setIsSubmitQuoteOpen(false);
				break;

			default:
				break;
		}
	};

	const rejectRfq = async () => {
		const currentDate = Date.now();
		const dateObj = new Date(currentDate);
		try {
			setButtonLoading(true);
			const res: any = await supplierUpdateOpportunityService(rfqId, {
				portal_status: "Rejected",
				card_status: "Lost",
				reject_rfq_reason:
					selectedReason === "Other(Description)"
						? otherReason
						: selectedReason,
				rfq_rejected_date: dateObj.toISOString().split("T")[0],
			});
			if (res.status === 200) {
				setButtonLoading(false);
				window.dataLayer?.push({
					event: "RFQ Action",
					rfq_id: rfqId,
					user_email: supplier?.customer?.email,
					status: "Rejected",
					user_id: authState?.uniqueUserId,
					organization_name: supplier?.customer?.supplier_name,
					total_parts: rfqData.items?.length,
					buyer_organization_name:
						rfqData?.company_name || rfqData?.requester || rfqData?.party_name,
				});
				Mixpanel.track("rfqAction", {
					rfq_id: rfqId,
					event: "RFQ Action",
					user_email: supplier?.customer?.email,
					status: "Rejected",
					user_id: authState?.uniqueUserId,
					organization_name: supplier?.customer?.supplier_name,
					total_parts: rfqData.items?.length,
					buyer_organization_name:
						rfqData?.company_name || rfqData?.requester || rfqData?.party_name,
				});

				navigate("/rfqmain");
			}
		} catch (error) {
			setButtonLoading(false);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	async function downloadFilesAsZip(urls: any, zipFilename: string) {
		const zip = new JSZip();

		// Use Promise.all to wait for all the files to be downloaded and added to the ZIP
		try {
			await Promise.all(
				urls.map(async (url: string, i: number) => {
					const response = await fetch(url);
					const blob = await response.blob();
					zip.file(url.substring(url.lastIndexOf("/") + 1), blob); //extract file name from url asd save file name for each file
				})
			);
		} catch (error) {
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}

		// Generate the ZIP file and trigger the download
		zip.generateAsync({ type: "blob" }).then((blob: any) => {
			saveAs(blob, zipFilename);
		});
	}

	useEffect(() => {
		if (allFiles.length > 0) {
			downloadFilesAsZip(allFiles, `${rfqId.split(" | ")[1]}.zip`);
		}
	}, [allFiles]);

	const downloadAllFiles = async () => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Loading...",
			})
		);
		let _allFiles: any = [];
		if (rfqData?.rfq_originated_from === "procurement") {
			//opportunitis coming from procurement have files in procurement server
			// iterate through each item and get all the files attached to them and add it to the allFiles variable
			try {
				await Promise.all(
					itemsData?.map(async (item: any) => {
						let res: any = await frappeMethodService(
							"crosslink.api.get_attachments_from_procurement",
							{
								doctype: "Item",
								uuid: item?.item_uuid,
							}
						);
						if (res.status === 200) {
							Promise.all(
								res.data.message.data.map(async (attachedFile: any) => {
									await _allFiles.push(
										process.env.REACT_APP_PROCUREMENT_URL +
											attachedFile.file_url
									);
								})
							);
						}
					})
				);
			} catch (error) {
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: formatErrorMessage(error),
					},
				});
			}
			setAllFiles([..._allFiles]);
		} else {
			//opportunity from erp have files in supplier server itself
			// iterate through each item and get all the files attached to them and add it to the allFiles variable
			try {
				await Promise.all(
					itemsData?.map(async (item: any) => {
						let params = {
							filters: JSON.stringify([
								["attached_to_doctype", "=", "Item"],
								["attached_to_name", "in", item?.item_code],
							]),
							fields: JSON.stringify(["*"]),
							limit: 0,
						};
						let res: any = await getItemAttachedFilesService(params);
						if (res.status === 200) {
							await Promise.all(
								res.data.data.map(async (attachedFile: any) => {
									await _allFiles.push(
										process.env.REACT_APP_LIVE_URL + attachedFile.file_url
									);
								})
							);
						}
					})
				);
			} catch (error) {
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: formatErrorMessage(error),
					},
				});
			}
			setAllFiles([..._allFiles]);
		}
		dispatch(
			setPageLoading({
				loading: false,
				loadingText: "",
			})
		);
	};

	const createQuote = async () => {
		setButtonLoading(true);
		const currentDate = Date.now();
		const dateObj = new Date(currentDate);

		const data = {
			quotation_to: "Customer",
			party_name: rfqData?.requester,
			rfq_expiry_date: rfqData?.valid_till,
			customer_name: rfqData?.customer_name,
			transaction_date: dateObj.toISOString().split("T")[0],
			customer_address: rfqData?.customer_address,
			shipping_address_name: rfqData?.shipping_address_name,
			items: rfqData?.items,
			opportunity: rfqData?.name,
			opportunity_uuid: rfqData?.uuid,
			customer_rfq_id: rfqData?.customer_rfq_id,
			originated_from: rfqData?.rfq_originated_from,
			company_name: rfqData?.company_name,
			rfq_name: rfqData?.rfq_name,
			customer_billing_address: rfqData?.billing_address_,
			customer_shipping_address: rfqData?.shipping_address_,
		};
		try {
			const res: any = await supplierCreateQuotationService(data);
			if (res.status === 200) {
				window.dataLayer?.push({
					event: "Quotation Created",
					page_name: window.location.pathname,
					quote_id: res.data.data.name,
					rfq_id: rfqData?.name,
					user_email: supplier?.customer?.email,
					status: "draft",
					user_id: authState?.uniqueUserId,
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name:
						res.data.data.company_name || res.data.data.party_name,
					quote_amount: "Not calculated",
					total_parts: res.data.data?.items?.length,
				});
				Mixpanel.track("quotationCreated", {
					page_name: window.location.pathname,
					quote_id: res.data.data.name,
					rfq_id: rfqData?.name,
					user_email: supplier?.customer?.email,
					status: "draft",
					user_id: authState?.uniqueUserId,
					organization_name: supplier?.customer?.supplier_name,
					buyer_organization_name:
						res.data.data.company_name || res.data.data.party_name,
					quote_amount: "Not calculated",
					total_parts: res.data.data?.items?.length,
				});
				navigate(`/createquote/${res.data.data.name}`);
			}
		} catch (error) {
			setButtonLoading(false);
			const message =
				"Failed to create the quote. " + formatErrorMessage(error);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: message,
				},
			});
		}
	};

	const handleOrdersNavigation = (status: string, orderId: string) => {
		switch (status) {
			case "PO Received":
				navigate(`/ordersporeceived/${orderId}`);
				break;
			case "Live":
				navigate(`/orderslive/${orderId}`);
				break;
			case "Active":
				navigate(`/ordersporeceived/${orderId}`);
				break;
			case "Cancelled":
				navigate(`/orderspoview/${orderId}`);
				break;
			case "complete":
				navigate(`/orderscompleted/${orderId}`);
				break;
			default:
				break;
		}
	};

	const getPurchaseOrderAndNavigate = async (rfqId: string) => {
		setButtonLoading(true);
		try {
			const res: any = await get_doc({
				doc: "Sales Order",
				fields: ["name", "portal_status"],
				filters: [["opportunity", "=", rfqId]],
			});
			if (res.status === 200) {
				setButtonLoading(false);
				handleOrdersNavigation(
					res.data.data[0].portal_status,
					res.data.data[0].name
				);
			}
		} catch (error) {
			setButtonLoading(false);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	const createMarkup = (html: any) => {
		return { __html: html };
	};

	const zeroScreenComponent = (
		<Flex
			flexDir="column"
			alignItems="center"
			justifyContent="center"
			gap="1rem"
		>
			<Text fontWeight="semibold" color="#00000050" fontSize="14px">
				No Parts Found
			</Text>
			<Img src={noParts} alt="illustration" />
		</Flex>
	);

	useEffect(() => {
		if (paginationVal !== 0)
			setTotalPages(Math.ceil(itemsData.length / paginationVal));
		setPages(1);
	}, [paginationVal, itemsData]);

	function handlePrevPage() {
		if (pages !== 1) setPages((prev) => prev - 1);
	}

	function handleNextPage() {
		if (pages < totalPages) setPages((prev) => prev + 1);
	}

	const handleReasonSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedReason(e.target.value);
	};

	return (
		<Box w="100%" bgColor="transparent">
			{!props.rfqId && (
				<Show above="md">
					<Box mt="1px">
						<RfqStatusBar
							rfqname={rfqData?.rfq_name}
							status={rfqData?.portal_status}
							modified={normalDate(rfqData?.modified)}
							customerRfqId={rfqData?.customer_rfq_id}
							rejectReason={rfqData?.reject_rfq_reason}
						/>
					</Box>
				</Show>
			)}
			<Show above="md">
				{/* dont show it for view rfq called from other pages */}
				<Box m="1rem" bg="#fff" py="0.75rem" borderRadius=".75rem">
					{!props.rfqId && (
						<Box>
							<Flex
								justifyContent={"space-between"}
								mx="1.5rem"
								alignItems="center"
								mb=".75rem"
							>
								<Text
									color={"#4BA18A"}
									textStyle="primary.large"
									fontSize={"28"}
									fontWeight="bold"
								>
									Request for Quotation
								</Text>
								{rfqData?.portal_status === "Open" && (
									<Flex textAlign={"end"} gap="1em" m="1em" ml="1em">
										<Button
											bg="#4BA18A"
											_hover={{ bg: "#38846D" }}
											color="#fff"
											onClick={() => {
												setIsPopupOpen(true);
											}}
											isLoading={buttonLoading}
										>
											Accept RFQ
										</Button>
										<Button
											onClick={handleRejectRFQPopup}
											bg="#E2F1EE"
											border="1px solid #4BA18A"
											color="#4BA18A"
											_hover={{ bg: "#B7DDD4" }}
										>
											Reject RFQ
										</Button>
									</Flex>
								)}
								{rfqData?.portal_status === "Accepted" && (
									<Button
										children="Create New Quote"
										bg="#4BA18A"
										_hover={{ bg: "#38846D" }}
										color="#fff"
										onClick={async () => {
											await createQuote();
										}}
										isDisabled={getDateDiff(rfqData?.valid_till) < 0}
									/>
								)}
								{rfqData?.portal_status === "Confirmed" && (
									<Button
										children="View Order"
										bg="#4BA18A"
										_hover={{ bg: "#38846D" }}
										color="#fff"
										onClick={async () => {
											await getPurchaseOrderAndNavigate(rfqId);
										}}
										isLoading={buttonLoading}
									/>
								)}
								{rfqData?.portal_status === "Quoted" && (
									<Flex textAlign={"end"} gap="1em" m="1em" ml="1em">
										<Button
											bg="#4BA18A"
											_hover={{ bg: "#38846D" }}
											color="#fff"
											onClick={() => {
												if (quotes?.length === 1) {
													navigate(`/quotemanagement/${quotes[0].name}`);
												} else if (quotes?.length > 1) {
													navigate(`/quotes/filter?opportunity=${rfqId}`);
												}
											}}
											isLoading={buttonLoading}
										>
											{`View Quote (${quotes?.length})`}
										</Button>
										<Button
											onClick={async () => {
												await createQuote();
											}}
											bg="#E2F1EE"
											border="1px solid #4BA18A"
											color="#4BA18A"
											_hover={{ bg: "#B7DDD4" }}
											isDisabled={getDateDiff(rfqData?.valid_till) < 0}
										>
											Create New Quote
										</Button>
									</Flex>
								)}
							</Flex>
							{!["Lost", "Rejected", "Cancelled"].includes(
								rfqData?.portal_status
							) && (
								<Box
									bg="#E2F1EE"
									h="39px"
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Text
										fontSize={"18px"}
										textAlign="center"
										display="flex"
										alignItems="center"
									>
										<span>
											{getDateDiff(rfqData?.valid_till) < 0 ? (
												<Flex gap="0.2em">
													<GiAlarmClock size={"28px"} /> This RFQ expired
													<chakra.span fontWeight="bold">
														{Math.abs(getDateDiff(rfqData?.valid_till)) || "--"}
													</chakra.span>
													&nbsp;days ago
												</Flex>
											) : (
												<Flex gap="0.2em">
													<GiAlarmClock size={"28px"} /> This RFQ expires
													in&nbsp;
													<chakra.span fontWeight="bold">
														{getDateDiff(rfqData?.valid_till) || "--"}
													</chakra.span>
													&nbsp;days
												</Flex>
											)}
										</span>
										<Box mx="1em" />
										{/* <chakra.span color="teal" fontWeight="bold">
									<Text>
										<Flex
											gap="0.5rem"
											cursor="pointer"
											onClickCapture={() => setIsExtensionPopupOpen(true)}
										>
											<BsCalendarPlus size={"22px"} />
											<Link textDecoration="underline">
												Request for Extension
											</Link>
										</Flex>
									</Text>
								</chakra.span> */}
									</Text>
								</Box>
							)}
						</Box>
					)}
					<Box bg="white" m="0em 1.5rem 1rem" overflowY={"auto"}>
						<Flex
							alignItems="flex-start"
							justifyContent="space-between"
							mt="1.5rem"
						>
							<Box>
								<Flex alignItems="center" gap=".75rem">
									<Box
										borderRadius={"full"}
										height="70px"
										width="70px"
										bg="green.50"
										display="flex"
										alignItems="center"
										justifyContent="center"
									>
										<Text
											fontSize="32px"
											fontWeight={"semibold"}
											color="#4BA18A"
										>
											{rfqData?.company_name
												? rfqData?.company_name?.slice(0, 1).toUpperCase()
												: rfqData?.requester
												? rfqData?.requester?.slice(0, 1).toUpperCase()
												: rfqData?.party_name?.slice(0, 1).toUpperCase()}
										</Text>
									</Box>
									<Box>
										<Text
											mt="0.5em"
											fontSize={"20px"}
											fontWeight="bold"
											color="#4BA18A"
										>
											{rfqData?.company_name || rfqData?.party_name}
										</Text>
										<Text mt="0.2em" fontSize={"18px"}>
											{rfqData?.requester}
										</Text>
										{rfqData?.customer_phone && (
											<Text color="gray" fontSize=".875rem">
												Contact No:&nbsp;
												<a href={`tel:${rfqData?.customer_phone}`}>
													{rfqData?.customer_phone}
												</a>
											</Text>
										)}
									</Box>
								</Flex>
							</Box>
							<Box>
								<Text>
									<chakra.span color="teal" mr="0.2em">
										Recieved On:&nbsp;
									</chakra.span>
									{normalDate(rfqData?.creation)}
								</Text>
								<Flex alignItems="center">
									<Text color="teal" mr="0.2em">
										Expires On:&nbsp;
									</Text>
									<Text>
										{rfqData?.valid_till
											? normalDate(rfqData?.valid_till)
											: "--"}
										&nbsp;
									</Text>
									{/* {["Lost", "Rejected", "Cancelled"].indexOf(
										rfqData?.portal_status
									) < 0 && (
										<BsCalendarPlus
											size="1rem"
											color="#38846D"
											cursor="pointer"
											onClick={() => setIsExtensionPopupOpen(true)}
										/>
									)} */}
								</Flex>
								<Text>
									<chakra.span color="teal" mr="0.2em">
										Expected Lead Time:
									</chakra.span>
									{rfqData?.lead_time ? rfqData?.lead_time : "--"}
								</Text>
							</Box>
						</Flex>
						<Flex
							alignItems="flex-end"
							justifyContent={"space-between"}
							mt="2.375rem"
						>
							<Text fontWeight={"semibold"} color={"#4BA18A"}>
								Parts&nbsp;({rfqData.items?.length})
							</Text>
							{!props.rfqId && (
								<Button
									variant="outline"
									border="1px solid #4BA18A"
									color="#4BA18A"
									_hover={{ bg: "#E2F1EE" }}
									size="md"
									leftIcon={<BsDownload size="20px" />}
									onClick={async () => {
										await downloadAllFiles();
									}}
								>
									Download files
								</Button>
							)}
						</Flex>
						<Box overflow="auto" mt=".75rem">
							<GenericTables
								headerColor={"#E2F1EE"}
								column={column}
								data={itemsData}
								renderCheckBox={false}
								renderSerialNo={true}
								isShowSearch={false}
								paginationbg={"#4BA18A"}
								borderColor={"#B7DDD4"}
								zeroScreenComponent={zeroScreenComponent}
							/>
							<Divider my="1em" color="#00000040" />
							<Box bg="white">
								<Text
									color={"#4BA18A"}
									fontSize={"18px"}
									fontWeight={"semibold"}
								>
									Payment Terms
								</Text>
								{rfqData.payment_terms ? (
									<Text fontSize={"17px"} mt="0.5em">
										{rfqData.payment_terms}
									</Text>
								) : (
									<Text
										fontSize="1.125rem"
										textStyle="primary.secondaryText"
										mt=".75rem"
									>
										No Payment Terms added
									</Text>
								)}
								<Text
									color={"#4BA18A"}
									mt="1.25em"
									fontSize={"18px"}
									fontWeight={"semibold"}
								>
									Documents Required
								</Text>
								{documentsRequired?.length > 0 ? (
									<Flex
										gap="3em"
										mt="0.5em"
										alignItems="center"
										flexWrap="wrap"
									>
										{documentsRequired?.map(
											(certificate: any, index: number) => (
												<Text
													key={index}
													fontSize={"17px"}
													fontWeight={"semibold"}
													color="black"
													display={"flex"}
												>
													<MdAttachFile size="25px" /> {certificate}
												</Text>
											)
										)}
									</Flex>
								) : (
									<Text
										fontSize="1.125rem"
										textStyle="primary.secondaryText"
										mt=".75rem"
									>
										No Documents were requested
									</Text>
								)}
								<Divider my="1em" color="#00000040" />
								<Box>
									<Text
										color={"#4BA18A"}
										fontSize={"18px"}
										fontWeight={"semibold"}
										mb="0.5rem"
									>
										Address
									</Text>
									<Text fontSize={"17px"}>
										<chakra.span
											color="black"
											mr="0.2em"
											fontSize={"17px"}
											fontWeight={"semibold"}
										>
											Billing Address:
										</chakra.span>
										{rfqData?.billing_address_ || (
											<chakra.span fontSize="1.125rem" color="gray">
												No Billing Address provided
											</chakra.span>
										)}
									</Text>
									<Text fontSize={"17px"} mt="0.5em">
										<chakra.span
											color="black"
											mr="0.2em"
											fontSize={"17px"}
											fontWeight={"semibold"}
										>
											Shipping Address:
										</chakra.span>
										{rfqData?.shipping_address_ || (
											<chakra.span fontSize="1.125rem" color="gray">
												No Shipping Address provided
											</chakra.span>
										)}
									</Text>
								</Box>
								<Divider my="1em" color="#00000040" />
								<Box mb="1rem">
									<Text
										color={"#4BA18A"}
										fontSize={"18px"}
										fontWeight={"semibold"}
									>
										Terms & Conditions
									</Text>
									{rfqData?.terms ? (
										rfqData?.rfq_originated_from === "procurement" ? (
											<Box
												mt="1rem"
												border={"1px solid #4BA18A"}
												borderRadius="md"
												padding=".75rem 1rem"
												whiteSpace="pre-wrap"
											>
												{rfqData?.terms}
											</Box>
										) : (
											<Box
												mt="1rem"
												border={"1px solid #4BA18A"}
												borderRadius="md"
												padding=".75rem 1rem"
												dangerouslySetInnerHTML={createMarkup(rfqData?.terms)}
											></Box>
										)
									) : (
										<Flex
											alignItems="center"
											justifyContent="center"
											border="1px solid #38846D"
											borderRadius=".75rem"
											width="100%"
											height="17.4375rem"
											mt="1rem"
											flexDir="column"
											gap=".75rem"
										>
											<BsCardChecklist size="3.75rem" color="#B7DDD4" />
											<Text textStyle="primary.secondaryText" fontSize="1rem">
												No terms & conditions added
											</Text>
										</Flex>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>

				{/* accept rfq pop up */}
				<BasicDialog
					isOpen={isPopupOpen}
					onClose={() => {
						setIsPopupOpen(false);
					}}
					header="Accept RFQ"
					showCloseButton="true"
					content={
						<Box marginY="3rem" marginX="4rem">
							<Flex
								alignItems="center"
								justifyContent="center"
								flexDirection="column"
							>
								<Image width="75%" src={AcceptRfq} />
								<Text textAlign="center" mt="2.5rem">
									Are you sure you want to accept the RFQ &nbsp;
									<chakra.span color="#4BA18A">{rfqData?.rfq_name}</chakra.span>
									&nbsp;from&nbsp;
									<chakra.span color="#4BA18A">
										{rfqData?.company_name ||
											rfqData?.party_name ||
											rfqData?.requester}
									</chakra.span>
								</Text>
								<Flex
									alignItems="center"
									justifyContent="center"
									mt="2rem"
									gap="1.25rem"
								>
									<Button
										children="Cancel"
										bg="#fff"
										color="#000"
										border="1px solid #000"
										onClick={() => {
											setIsPopupOpen(false);
										}}
									/>
									<Button
										children="Confirm"
										bg="#4BA18A"
										color="#fff"
										_hover={{ bg: "#38846D" }}
										onClick={async () => {
											await acceptRfq();
										}}
									/>
								</Flex>
							</Flex>
						</Box>
					}
				/>

				<RejectRfqPopup
					isOpen={isRejectRFQPopupOpen}
					onClose={handleCloseRejectRFQPopup}
					title="Reject RFQ"
					message="Are you sure you want to reject this RFQ?"
					onCancel={handleCloseRejectRFQPopup}
					onAccept={() => {
						// accept RFQ logic

						handleCloseRejectRFQPopup();
					}}
					imageURL={RejectRfq}
					rfqName={rfqData?.rfq_name}
					companyName={
						rfqData?.company_name || rfqData?.party_name || rfqData?.requester
					}
					rfqId={rfqId}
				/>

				<RfqPopupCard
					userRequest={"form"}
					isOpen={isExtensionPopupOpen}
					onClose={handleCloseExtension}
					title="Request for Extension"
					// message="Please choose the current expiry date and the requested expiry date:"
					onCancel={handleCloseExtension}
					onSave={handleSave}
					imageURL={Extend}
					BCancel={"Cancel"}
					BSave={"Save"}
				/>
				<BasicDialog
					isOpen={isPartOpen}
					showCloseButton={true}
					onClose={() => {
						setIsPartOpen(false);
					}}
					header={`Part Name: ${selectedItem?.item_name}`}
					content={
						<Box maxHeight="80vh" overflowY="auto">
							<ViewPartForm
								itemData={selectedItem}
								dataOriginatedFrom={rfqData?.rfq_originated_from}
							/>
						</Box>
					}
					size="5xl"
				/>
			</Show>

			{/* ***************************************mobile display***************************************** */}
			<Show below="md">
				{!props.rfqId && (
					<>
						<Divider />
						<Flex
							justifyContent="space-between"
							alignItems="center"
							gap="1em"
							p="0.5rem 1rem"
							boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06)"
							bg="#fff"
						>
							<BackButton />
							<Text fontWeight="semibold" fontSize="14px">
								RFQ ID: {rfqId?.split(" | ")[1]}
							</Text>
							<Text
								padding="0.3em 0.4em"
								marginLeft="auto"
								bg={statusColor.bg}
								color={statusColor.color}
							>
								{rfqData?.portal_status}
							</Text>
							<Box position="relative">
								<BsChat size={"20px"} />
								<Box
									position="absolute"
									fontSize="6px"
									color="#E2F1EE"
									top="-1.5px"
									right="1px"
									backgroundColor="#4BA18A"
									padding="1px"
									borderRadius="50%"
									fontWeight="600"
									width="10px"
									display="flex"
									justifyContent="center"
									alignItems="center"
								>
									1
								</Box>
							</Box>
							{/* <BsBell size="20px" cursor="pointer" /> */}
						</Flex>
						{!["Lost", "Rejected", "Cancelled"].includes(
							rfqData?.portal_status
						) && (
							<Box
								bg="#E2F1EE"
								h="auto"
								p="0.5rem 1rem"
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<Text
									fontSize={"18px"}
									textAlign="center"
									display="flex"
									alignItems="center"
								>
									<span>
										<Flex gap="0.2em">
											<Show above="sm">
												<GiAlarmClock size={"28px"} />
											</Show>
											{getDateDiff(rfqData?.valid_till) < 0 ? (
												<Text as="span" fontSize="14px">
													This RFQ expired&nbsp;
													<chakra.span fontWeight="bold">
														{Math.abs(getDateDiff(rfqData?.valid_till)) || "--"}
													</chakra.span>
													&nbsp;days ago
												</Text>
											) : (
												<Text as="span" fontSize="14px">
													This RFQ expires in &nbsp;
													<chakra.span fontWeight="bold">
														{getDateDiff(rfqData?.valid_till) || "--"}
													</chakra.span>
													&nbsp;days
												</Text>
											)}
										</Flex>
									</span>
									<Box mx="1em" />
									{/* <chakra.span color="teal" fontWeight="bold">
								<Text>
									<Flex gap="0.2em">
										<BsCalendarPlus
											size={"22px"}
											onClick={() => {
												handleOpen("extension");
											}}
										/>
										<Show above="sm">
											<Link
												onClick={handleAcceptExtension}
												textDecoration="underline"
											>
												Request for Extension
											</Link>
										</Show>
									</Flex>
								</Text>
							</chakra.span> */}
								</Text>
							</Box>
						)}
					</>
				)}
				<Box m={{ sm: 0, md: "1em" }} padding="1rem" bg="#f3f6f9">
					<Box width="100%" mx="auto">
						{rfqData?.portal_status === "Open" && (
							<ViewRFQCard
								companyName={rfqData?.company_name || rfqData?.party_name}
								partyName={rfqData?.requester}
								rfqName={rfqData?.rfq_name}
								rfqId={rfqId}
								contactNo={rfqData?.customer_phone}
								expectedLeadTime={rfqData?.lead_time}
								receivedOn={normalDate(rfqData?.creation)}
								rfqExpiry={normalDate(rfqData?.valid_till)}
							/>
						)}
						{rfqData?.portal_status === "Accepted" && (
							<ViewRFQCard
								companyName={rfqData?.company_name || rfqData?.party_name}
								partyName={rfqData?.requester}
								rfqName={rfqData?.rfq_name}
								rfqId={rfqId}
								expectedLeadTime={rfqData?.lead_time}
								receivedOn={normalDate(rfqData?.creation)}
								rfqExpiry={normalDate(rfqData?.valid_till)}
								status="Accepted"
							/>
						)}
						{rfqData?.portal_status === "Quoted" && (
							<ViewRFQCard
								companyName={rfqData?.company_name || rfqData?.party_name}
								partyName={rfqData?.requester}
								rfqName={rfqData?.rfq_name}
								rfqId={rfqId}
								expectedLeadTime={rfqData?.lead_time}
								receivedOn={normalDate(rfqData?.creation)}
								rfqExpiry={normalDate(rfqData?.valid_till)}
							/>
						)}
						{rfqData?.portal_status === "Confirmed" && (
							<ViewRFQCard
								companyName={rfqData?.company_name || rfqData?.party_name}
								partyName={rfqData?.requester}
								rfqName={rfqData?.rfq_name}
								rfqId={rfqId}
								expectedLeadTime={rfqData?.lead_time}
								receivedOn={normalDate(rfqData?.creation)}
								rfqExpiry={normalDate(rfqData?.valid_till)}
							/>
						)}
						{["Lost", "Cancelled", "Rejected"].includes(
							rfqData?.portal_status
						) && (
							<ViewRFQCard
								companyName={rfqData?.company_name || rfqData?.party_name}
								partyName={rfqData?.requester}
								rfqName={rfqData?.rfq_name}
								rfqId={rfqId}
								expectedLeadTime={rfqData?.lead_time}
								receivedOn={normalDate(rfqData?.creation)}
								rfqExpiry={normalDate(rfqData?.valid_till)}
							/>
						)}
					</Box>
					{/* Accordian for mobile screen */}
					<Accordion
						defaultIndex={[0]}
						allowMultiple
						marginTop="1em"
						padding="0 0.5em"
						width="100%"
						px="0"
						mb="5rem"
					>
						{/* View Parts */}
						<AccordionItem
							marginBottom="1em"
							borderRadius="lg"
							borderTop="none"
						>
							<h2>
								<AccordionButton
									_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
									fontSize="14px"
									borderRadius="lg"
									bgColor="#fff"
									boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
								>
									<Box as="span" flex="1" textAlign="left" color="#4BA18A">
										Parts&nbsp;({rfqData.items?.length})
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4} px="0em">
								<Flex
									width="100%"
									justifyContent="space-between"
									alignItems="center"
									my="0.75rem"
								>
									<Box>
										<Flex alignItems="center">
											<Text fontSize="12">Showing</Text>
											<Select
												size="xs"
												px="5px"
												fontSize="12px"
												value={paginationVal}
												onChange={(e) =>
													setPaginationVal(parseInt(e.target.value))
												}
											>
												<option value={Math.min(5, itemsData.length)}>
													{Math.min(5, itemsData.length)}
												</option>
												{itemsData.length > 10 && (
													<option value={10}>10 </option>
												)}
												{itemsData.length > 20 && (
													<option value={20}>20</option>
												)}
												{itemsData.length > 50 && (
													<option value={50}>50</option>
												)}
											</Select>
											<Text fontSize="12">of&nbsp;{itemsData.length}</Text>
										</Flex>
									</Box>
									<Box display="flex" gap="0.3rem" alignItems="center">
										<ChevronLeftIcon
											boxSize={6}
											p="0.3rem"
											bgColor={pages == 1 ? "gray" : "#4ba18a"}
											borderRadius="md"
											color="#fff"
											onClick={() => handlePrevPage()}
										/>
										<ChevronRightIcon
											boxSize={6}
											p="0.3rem"
											bgColor={pages == totalPages ? "gray" : "#4ba18a"}
											borderRadius="md"
											color="#fff"
											onClick={() => handleNextPage()}
										/>
									</Box>
								</Flex>
								<Flex justifyContent="flex-end" px="0.5em">
									<Button
										color="#4BA18A"
										border="1px solid #4BA18A"
										bg="#fff"
										_hover={{ bg: "#E2F1EE" }}
										variant="outline"
										size="sm"
										p="0.7em 0.7em"
										leftIcon={<BsDownload size="18px" />}
										onClick={async () => {
											await downloadAllFiles();
										}}
									>
										Download files
									</Button>
								</Flex>
								{itemsData
									.slice(
										pages * paginationVal - paginationVal,
										pages * paginationVal
									)
									?.map((item: any, index: number) => (
										<RFQPartsCard
											key={index}
											name={item.item_name}
											units={item.qty}
											category={
												rfqData?.rfq_originated_from === "procurement"
													? item.item_category
													: itemsData.item_type
											}
											targetCost={item.target_cost}
											viewPartFunction={() => {
												setSelectedItem(item);
												setIsPartOpenMob(true);
												setSelectedItemIndex(index);
											}}
										/>
									))}
							</AccordionPanel>
						</AccordionItem>

						{/* Payment Terms */}
						<AccordionItem
							marginBottom="1em"
							borderRadius="lg"
							boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
							borderTop="none"
							bgColor="#fff"
						>
							<h2>
								<AccordionButton
									_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
									borderRadius="lg"
									bgColor="#fff"
									fontSize="14px"
								>
									<Box
										as="span"
										flex="1"
										textAlign="left"
										color="#4BA18A"
										borderRadius="lg"
									>
										Payment Terms
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4} fontSize="12px">
								{rfqData?.payment_terms}
							</AccordionPanel>
						</AccordionItem>

						{/* Documents Required */}
						<AccordionItem
							marginBottom="1em"
							borderRadius="lg"
							boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
							borderTop="none"
							bgColor="#fff"
						>
							<h2>
								<AccordionButton
									_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
									borderRadius="lg"
									bgColor="#fff"
									fontSize="14px"
								>
									<Box
										as="span"
										flex="1"
										textAlign="left"
										color="#4BA18A"
										borderRadius="lg"
									>
										Documents Required&nbsp;({documentsRequired?.length})
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
								<Flex
									gap="1em"
									mt="1em"
									alignItems="flex-start"
									flexWrap="wrap"
									flexDirection="column"
								>
									{documentsRequired?.map((certificate: any, index: number) => (
										<Text
											key={index}
											color="black"
											display={"flex"}
											fontSize="12px"
										>
											<MdAttachFile size="20px" /> {certificate}
										</Text>
									))}
								</Flex>
							</AccordionPanel>
						</AccordionItem>

						{/* Cost Breakup */}
						<AccordionItem
							marginBottom="1em"
							borderRadius="lg"
							boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
							borderTop="none"
							bgColor="#fff"
						>
							<h2>
								<AccordionButton
									_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
									borderRadius="lg"
									bgColor="#fff"
									fontSize="14px"
								>
									<Box
										as="span"
										flex="1"
										textAlign="left"
										color="#4BA18A"
										borderRadius="lg"
									>
										Cost Breakup
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
								<Flex
									gap="1em"
									mt="1em"
									alignItems="flex-start"
									flexWrap="wrap"
									flexDirection="column"
								></Flex>
							</AccordionPanel>
						</AccordionItem>

						{/* Address */}
						<AccordionItem
							marginBottom="1em"
							borderRadius="lg"
							boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
							borderTop="none"
							bgColor="#fff"
						>
							<h2>
								<AccordionButton
									_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
									borderRadius="lg"
									bgColor="#fff"
									fontSize="14px"
								>
									<Box as="span" flex="1" textAlign="left" color="#4BA18A">
										Address
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
								<Text fontSize={"12px"}>
									<chakra.span
										color="black"
										mr="0.2em"
										fontSize={"13px"}
										fontWeight={"semibold"}
									>
										Billing Address :&nbsp;
									</chakra.span>
									{rfqData?.billing_address_}
								</Text>
								<Text fontSize={"12px"} mt="0.5em">
									<chakra.span
										color="black"
										mr="0.2em"
										fontSize={"13px"}
										fontWeight={"semibold"}
									>
										Shipping Address :&nbsp;
									</chakra.span>
									{rfqData?.shipping_address_}
								</Text>
							</AccordionPanel>
						</AccordionItem>

						{/* Terms and Condition */}
						<AccordionItem
							marginBottom="1em"
							borderRadius="lg"
							boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
							borderTop="none"
							bgColor="#fff"
						>
							<h2>
								<AccordionButton
									_expanded={{ bg: "#E2F1EE", color: "#4BA18A" }}
									borderRadius="lg"
									bgColor="#fff"
									fontSize="14px"
								>
									<Box as="span" flex="1" textAlign="left" color="#4BA18A">
										Terms & Condition
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
								{rfqData?.rfq_originated_from === "procurement" ? (
									<Box borderRadius="md" whiteSpace="pre-wrap" fontSize="12px">
										{rfqData?.terms}
									</Box>
								) : (
									<Box
										borderRadius="md"
										dangerouslySetInnerHTML={createMarkup(rfqData?.terms)}
									></Box>
								)}
							</AccordionPanel>
						</AccordionItem>
					</Accordion>

					{/* Different actions for different rfq status */}
					{!props.rfqId && (
						<Box width="100%" mt="auto">
							{rfqData?.portal_status === "Open" && (
								<ViewRFQActions
									rfqId={rfqId}
									acceptButtonFunction={() => {
										handleOpen("accept");
									}}
									rejectButtonFunction={() => {
										handleOpen("reject");
									}}
								/>
							)}
							{rfqData?.portal_status === "Accepted" && (
								<ViewRFQActions
									rfqId={rfqId}
									createNewQuoteFunction={() => {
										handleOpen("quote");
									}}
									status="Accepted"
								/>
							)}
							{rfqData?.portal_status === "Quoted" && (
								<ViewRFQActions
									rfqId={rfqId}
									createNewQuoteFunction={() => {
										handleOpen("quote");
									}}
									quotes={quotes}
								/>
							)}
							{rfqData?.portal_status === "Confirmed" && (
								<ViewRFQActions
									rfqId={rfqId}
									getPurchaseOrderAndNavigate={getPurchaseOrderAndNavigate}
								/>
							)}
							{["Lost", "Cancelled", "Rejected"].includes(
								rfqData?.portal_status
							) && <ViewRFQActions rfqId={rfqId} quotes={quotes} />}
						</Box>
					)}

					{/* *****Phone Screen Popups***** */}

					{/* Bottom sheet for extension */}
					<Overlay
						isOpen={isExtensionOpen}
						// onClick={() => {
						// 	handleClose("extension");
						// }}
					>
						<BottomSheet
							isOpen={isExtensionOpen}
							onClose={() => handleClose("extension")}
						>
							<CommonPopupCards
								image={Extend}
								saveFunction={() => {
									console.log("Save Extension clicked");
								}}
								handleCancel={() => {
									handleClose("extension");
								}}
								title={"Request for RFQ Extension"}
								currentExpiry={"23-10-2022"}
							/>
						</BottomSheet>
					</Overlay>

					{/* accept quote bottomsheet */}
					<Overlay
						isOpen={isAcceptOpen}
						// onClick={() => {
						// 	handleClose("accept");
						// }}
					>
						<BottomSheet
							isOpen={isAcceptOpen}
							onClose={() => handleClose("accept")}
						>
							<CommonPopupCards
								image={AcceptRfq}
								cardText={`Are you sure you want to accept the RFQ ${
									rfqData?.rfq_name
								} from ${
									rfqData?.company_name ||
									rfqData?.party_name ||
									rfqData?.requester
								}? `}
								handleAccept={acceptRfq}
								handleCancel={() => {
									handleClose("accept");
								}}
								title={"Accept RFQ"}
							/>
						</BottomSheet>
					</Overlay>

					{/* reject quote overlay */}
					<Overlay
						isOpen={isRejectOpen}
						// onClick={() => {
						// 	handleClose("reject");
						// }}
					>
						<BottomSheet
							isOpen={isRejectOpen}
							onClose={() => handleClose("reject")}
						>
							{rejectionStep === 1 ? (
								<CommonPopupCards
									image={RejectRfq}
									cardText={`Are you sure you want to reject the RFQ ${
										rfqData?.rfq_name
									} from ${
										rfqData?.company_name ||
										rfqData?.party_name ||
										rfqData?.requester
									}? `}
									handleReject={() =>
										setRejectionStep((prev: number) => prev + 1)
									}
									handleCancel={() => {
										handleClose("reject");
									}}
									title={"Reject RFQ"}
								/>
							) : (
								<Box>
									<Text fontSize={"18px"} mb="2em" textAlign="center">
										Select a reason for rejecting the RFQ
									</Text>
									{selectedReason === "Other(Description)" ? (
										<Textarea
											placeholder="Add a reason for declining the RFQ"
											height="9.75rem"
											width="100%"
											onChange={(e) => setOtherReason(e.target.value)}
											mb="4rem"
										/>
									) : (
										<Select
											placeholder="Select"
											onChange={handleReasonSelect}
											size="md"
											mb="10rem"
										>
											{rejectionReasons.map((reason) => (
												<option value={reason} key={reason}>
													{reason}
												</option>
											))}
										</Select>
									)}
									<Flex
										alignItems="center"
										justifyContent="space-between"
										width="100%"
										gap="0.625rem"
									>
										<Button
											onClick={() => handleClose("reject")}
											width="45%"
											flexGrow={1}
										>
											Cancel
										</Button>
										<Button
											onClick={rejectRfq}
											color="white"
											bgColor="red.300"
											width="45%"
											px="0.75em"
											flexGrow={1}
											isDisabled={
												selectedReason === "Other(Description)"
													? otherReason === ""
													: selectedReason === ""
											}
										>
											Reject RFQ
										</Button>
									</Flex>
								</Box>
							)}
						</BottomSheet>
					</Overlay>

					{/* create new quote overlay */}
					<Overlay
						isOpen={isSubmitQuoteOpen}
						// onClick={() => {
						// 	handleClose("quote");
						// }}
					>
						<BottomSheet
							isOpen={isSubmitQuoteOpen}
							onClose={() => handleClose("quote")}
						>
							<CommonPopupCards
								cardText={"Do you want to create a new quote"}
								submitNewQuote={async () => {
									await createQuote();
								}}
								handleCancel={() => {
									handleClose("quote");
								}}
								title={"Create New Quote"}
							/>
						</BottomSheet>
					</Overlay>

					{/* View Parts bottomsheet */}
					<Overlay
						isOpen={isPartOpenMob}
						// onClick={() => {
						// 	setIsPartOpenMob(false);
						// }}
					>
						<BottomSheet
							isOpen={isPartOpenMob}
							onClose={() => {
								setIsPartOpenMob(false);
							}}
						>
							<Text
								fontSize="14px"
								fontWeight="bold"
								color="#4BA18A"
								textAlign="center"
								mb="1rem"
							>
								{selectedItem?.item_name}
							</Text>
							<Box mb="3rem">
								<ViewPartForm
									itemData={selectedItem}
									dataOriginatedFrom={rfqData?.rfq_originated_from}
								/>
							</Box>
						</BottomSheet>
						<Flex
							w="100%"
							position="fixed"
							left="0"
							bottom="0"
							padding="0.75rem 1rem"
							bg="#fff"
							justifyContent="space-between"
							alignItems="center"
							boxShadow="0px -4px 8px 0px #0000000F"
						>
							<Button
								onClick={() => {
									if (selectedItemIndex > 0) {
										setSelectedItem(itemsData[selectedItemIndex - 1]);
										setSelectedItemIndex((prev) => prev - 1);
									}
								}}
								isDisabled={selectedItemIndex === 0}
								width="47%"
								bg="white"
								fontWeight="semibold"
								color="#4ba18a"
								_hover={{ backgroundColor: "white" }}
								border="1px solid #4ba18a"
							>
								Previous
							</Button>
							<Button
								onClick={() => {
									if (selectedItemIndex < itemsData.length - 1) {
										setSelectedItem(itemsData[selectedItemIndex + 1]);
										setSelectedItemIndex((prev) => prev + 1);
									}
								}}
								isDisabled={selectedItemIndex === itemsData.length - 1}
								width="47%"
								color="white"
								fontWeight="semibold"
								bg="#4ba18a"
								_hover={{ backgroundColor: "#4ba18a" }}
							>
								View Next
							</Button>
						</Flex>
					</Overlay>
				</Box>
			</Show>
		</Box>
	);
};

export default ViewRfq;
