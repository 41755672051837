import React from "react";
import { Box, Flex, Text, Tag, Button } from "@chakra-ui/react";
import { RfqPopupCard } from "../rfq/RfqPopupCard";
import completed from "../../img/completed.svg";
import {
	type AppDispatch,
	formatErrorMessage,
	SET_MESSAGE,
	ERROR_MSG,
	setPageLoading,
} from "@karkhanaui/react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Link as Lk } from "react-router-dom";

interface Props {
	poId: string;
	status: string;
	button?: string;
	displayOrderStatusLink?: boolean;
}

const getStatusColor = (status: string) => {
	switch (status) {
		case "PO Received":
			return { bg: "rgba(253, 185, 19, 0.2)", color: "#EBA700" };
		case "Live":
			return { bg: "rgba(0, 135, 238, 0.1)", color: "#0087EE" };
		case "Cancelled":
			return { bg: "rgba(239, 111, 108, 0.2)", color: "#EF6F6C" };
		case "complete":
			return { bg: "#E2F1EE", color: "#4BA18A" };
		default:
			return { bg: "gray.100", color: "gray.400" };
	}
};

const OrderStatusBar: React.FC<Props> = ({
	poId,
	status,
	button,
	displayOrderStatusLink,
}) => {
	const dispatch = useDispatch<AppDispatch>();
	const [isPopupOpen, setIsPopupOpen] = React.useState(false);
	const handleAcceptRFQPopup = () => {
		setIsPopupOpen(true);
	};

	const handleCloseRFQPopup = () => {
		setIsPopupOpen(false);
	};
	const handleConfirmRFQPopup = () => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Loading...",
			})
		);

		axios
			.put(
				`/api/resource/Sales Order/${poId}`,
				JSON.stringify({
					portal_status: "complete",
				})
			)
			.then((s) => {
				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
				setIsPopupOpen(false);
				window.location.reload();
			})
			.catch((error) => {
				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: formatErrorMessage(error),
					},
				});
			});
	};

	const tagColor = getStatusColor(status);

	return (
		<Box bg="#fff" p="0.75rem 1rem" mt="1px">
			<Flex justifyContent="space-between" alignItems="center">
				<Flex alignItems="center">
					<Text mr="1em">
						<span style={{ fontWeight: "bold" }}>PO ID:</span>&nbsp;{poId}
					</Text>
					<Text fontWeight="bold">Status:&nbsp;</Text>
					<Tag bg={tagColor.bg} color={tagColor.color}>
						{status}
					</Tag>
				</Flex>
				{status != "complete" && button && (
					<>
						<Button
							bg="#4BA18A"
							color="#fff"
							fontWeight="bold"
							onClick={handleAcceptRFQPopup}
							_hover={{ bg: "#38846D" }}
						>
							{button}
						</Button>

						<RfqPopupCard
							isOpen={isPopupOpen}
							onClose={handleCloseRFQPopup}
							title="Mark as Complete"
							message="On marking this order as Complete, you will not be able to add any order updates.Are you sure want to mark the order as complete?"
							onCancel={handleCloseRFQPopup}
							onAccept={() => {
								//  accept RFQ logic
								handleConfirmRFQPopup();
							}}
							imageURL={completed}
							BCancel={"Cancel"}
							BAccept={"Confirm"}
						/>
					</>
				)}
				{displayOrderStatusLink && (
					<Lk to={`/orderslive/${poId}`}>
						<Text
							color="#4BA18A"
							fontWeight={600}
							fontSize="1rem"
							lineHeight=".875rem"
							textDecoration="underline"
							cursor="pointer"
						>
							View Order Updates
						</Text>
					</Lk>
				)}
			</Flex>
		</Box>
	);
};

export default OrderStatusBar;
