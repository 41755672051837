import React, { FC } from "react";
import {
	Button,
	Center,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Divider,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Flex,
	Select,
	useDisclosure,
	Textarea,
	Img,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { number } from "yup";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

interface PopupCardProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	message?: string;
	onCancel: () => void;
	onAccept?: () => void;
	onSave?: (expiryDate: Date) => void;
	imageURL?: string;
	BCancel?: string;
	BAccept?: string;
	BSave?: string;
	userRequest?: string;
	Quote_Id?: number;
	userConform?: string;
	textarea?: boolean;
}

const RfqPopupCard: FC<PopupCardProps> = ({
	isOpen,
	onClose,
	title,
	message,
	onCancel,
	onAccept,
	onSave,
	imageURL,
	BCancel,
	BAccept,
	BSave,
	userRequest,
	userConform,
	Quote_Id,
	textarea,
}) => {
	const formik = useFormik({
		initialValues: {
			currentExpiryDate: "",
			requestedExpiryDate: "",
		},
		onSubmit: (values) => {
			onSave(new Date(values.requestedExpiryDate));
		},
	});

	const navigate = useNavigate();
	const [selectedReason, setSelectedReason] = React.useState("");
	const { onOpen } = useDisclosure();
	const handleReasonSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedReason(e.target.value);
	};

	let [value, setValue] = React.useState("");

	let handleInputChange = (e: any) => {
		let inputValue = e.target.value;
		setValue(inputValue);
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="lg"
			motionPreset="slideInBottom"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{title}</ModalHeader>
				<Divider variant="solid" border="1px solid grey " w="100%" />
				<ModalCloseButton />
				<Divider variant="solid" border="0.9px " mt="1em" />
				<ModalBody>
					<Center w="75%" mx="auto">
						<img src={imageURL} />
					</Center>
					<Center>
						<Text mt="4" textAlign="center" fontSize={"18px"}>
							{message}
						</Text>
					</Center>
					{textarea ? (
						<Center>
							<Textarea
								placeholder="Enter Here"
								value={value}
								onChange={handleInputChange}
								size="xl"
								h="10em"
								pt="1em"
								pl="1em"
								mt="1em"
								border="1px solid black"
							/>
						</Center>
					) : (
						""
					)}
					{Quote_Id ? (
						<Center>
							<Text fontSize={"16px"} fontWeight="semibold" mt="1em">
								{" "}
								Quote ID : {Quote_Id}
							</Text>
						</Center>
					) : (
						""
					)}
					{userRequest === "form" ? (
						<>
							<FormControl mb="4">
								<Flex>
									<FormLabel ml="2em">Current Expiry Date:</FormLabel>
									<Input
										ml="1.5em"
										size="sm"
										w="15em"
										type="date"
										value={formik.values.currentExpiryDate}
										onChange={formik.handleChange("currentExpiryDate")}
									/>
								</Flex>
							</FormControl>

							<FormControl>
								<Flex>
									<FormLabel ml="2em">Requested Expiry Date:</FormLabel>
									<Input
										ml="0.1em"
										size="sm"
										w="15em"
										type="date"
										value={formik.values.requestedExpiryDate}
										onChange={formik.handleChange("requestedExpiryDate")}
									/>
								</Flex>
								<FormErrorMessage>
									{formik.errors.requestedExpiryDate}
								</FormErrorMessage>
							</FormControl>
						</>
					) : (
						<></>
					)}
				</ModalBody>
				<ModalFooter mr="8em">
					<Button onClick={onCancel} mr="1em">
						{BCancel}
					</Button>
					{BAccept ? (
						<Button
							onClick={onAccept}
							bg="#4BA18A"
							_hover={{ bg: "#38846D" }}
							color="#fff"
						>
							{BAccept}
						</Button>
					) : (
						""
					)}
					{userRequest === "form" && BSave ? (
						<Button
							onClick={formik.handleSubmit}
							bg="#4BA18A"
							_hover={{ bg: "#38846D" }}
							color="#fff"
						>
							{BSave}
						</Button>
					) : (
						""
					)}
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

interface CommonPopupCardsProps {
	handleCancel?: any;
	handleAccept?: any;
	handleReject?: any;
	submitNewQuote?: any;
	image?: any;
	cardText?: string;
	title?: string;
	currentExpiry?: any;
	saveFunction?: any;
}

function CommonPopupCards({
	image,
	handleCancel,
	handleAccept,
	handleReject,
	cardText,
	title,
	submitNewQuote,
	currentExpiry,
	saveFunction,
}: CommonPopupCardsProps) {
	return (
		<Flex flexDirection="column" alignItems="center" padding="1em" gap="1em">
			{title && (
				<Text
					fontSize="18px"
					fontWeight="semibold"
					color="#4ba18a"
					textAlign="center"
				>
					{title}
				</Text>
			)}
			{image && <Img src={image} width="150px" height="150px" />}
			{cardText && (
				<Text textAlign="center" margin="1em 0">
					{cardText}
				</Text>
			)}
			{currentExpiry && (
				<Flex flexDirection="column" alignItems="center">
					<Text>Current Expiry Date :{currentExpiry}</Text>
					<Flex gap="0.7em">
						<Text>Requested Expiry Date</Text>
						<Input
							type="date"
							width="45%"
							placeholder="Select Requested Expiry"
						/>
					</Flex>
				</Flex>
			)}

			<Flex alignItems="center" justifyContent="space-between" width="100%" gap="0.625rem">
				<Button onClick={handleCancel} width="45%" flexGrow={1}>
					Cancel
				</Button>
				{handleAccept && (
					<Button
						onClick={handleAccept}
						color="white"
						bg="#4ba18a"
						_hover={{ bg: "#38846D" }}
						width="45%"
						px="0.75em"
						flexGrow={1}
					>
						Accept RFQ
					</Button>
				)}
				{handleReject && (
					<Button
						onClick={handleReject}
						color="white"
						bgColor="red.300"
						width="45%"
						px="0.75em"
						flexGrow={1}
					>
						Reject RFQ
					</Button>
				)}
				{submitNewQuote && (
					<Button
						onClick={submitNewQuote}
						color="white"
						bg="#4ba18a"
						_hover={{ bg: "#38846D" }}
						width="45%"
						px="0.75em"
						flexGrow={1}
					>
						Submit New Quote
					</Button>
				)}
				{saveFunction && (
					<Button
						onClick={submitNewQuote}
						color="white"
						bg="#4ba18a"
						_hover={{ bg: "#38846D" }}
						width="45%"
						px="0.75em"
						flexGrow={1}
					>
						Save
					</Button>
				)}
			</Flex>
		</Flex>
	);
}

export { RfqPopupCard, CommonPopupCards };
